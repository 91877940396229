import {useTranslation} from "react-i18next";
import MISDialog from "../../MISDialog";
import React, {useEffect, useState} from "react";
import ReactTable from "react-table";
import {toMomentDateTimeFormat, useFilter} from "../../../../helper";
import {settingService} from "../../../../services";
import Pagination from "../../../table/Pagination";
import Moment from "moment";
import {useMISOpt} from "../../../misopt";


const LicenseHistoryPopup = ({title, onClose }) => {
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);
    const {t} = useTranslation();

    const [filter, setFilter, onPageChange] = useFilter({
        pageSize : 10,
        page: 0,
        reload: false
    });


    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0,
    });

    useEffect(() => {
        fetchData();
    }, [filter]);

    const fetchData = () => {
        const {page,pageSize} = filter;
        setData({...data, loading: true});
        settingService.fetchLicenseHistory(page)
            .then(res => setData({
                    ...data,
                    loading: false,
                    items: res.items,
                    totalCount: res.totalCount,
                    pages: Math.ceil(res.totalCount / pageSize)
                })
            )
    }


    const {items = [], loading = false, totalCount = 0, pages = 0} = data;


    const columns = [

        {
            Header: t("TABLE_TYPE_P"),
            accessor: "type",
            width : 60,
            sortable: false
        },

        {
            Header: t("COM_TEXT_LICENSE_KEY_P"),
            accessor: 'licenseKey',
            width : 190,
            sortable: false
        },
        {
            Header: t("COM_TEXT_INFORMATION_P"),
            accessor: "description",
            width : 420,
            sortable: false
        },
        {
            Header: t("TABLE_CREATE_DATE_P"),
            accessor: "date",
            sortable: false,
            Cell: ({value}) => <span>{Moment(value).format(dateTimeFormat)}</span>
        }
    ]
    return (
        <div>
            <MISDialog
                dialog={{
                    title: title,
                    closeOnEscape: true,
                    modal: true,
                    width: 820,
                    height: 650,
                    position: {x: -300, y: -400},
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons: [
                        {
                            id: 'ACTIVATION_ONLINE_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose
                        }
                    ]
                }}>
                <div>
                    <ReactTable
                        data={items}
                        loading={loading}
                        columns={columns}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        manual
                        style={{ height : 600 ,border: '0px'}}
                        showPagination={false}
                        columns={columns}
                    />
                    <Pagination totalCount={totalCount} page={filter.page} pageSize = {filter.pageSize} pages={pages} onPageChange={onPageChange}
                                style={{ width : 720, border: '0px', bottom:'auto'}}
                    />
                </div>

            </MISDialog>

        </div>
    )
};

export default LicenseHistoryPopup;