import React, {useEffect, useMemo, useState} from 'react';
import "../../components/table/react-table.css";
import WhiteButton from "../../components/button/WhiteButton";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import {useTranslation} from 'react-i18next';
import {userService} from "../../services";
import Pagination from "../../components/table/Pagination";
import {commonConstants, contentConstants} from "../../constants";
import {connect} from "react-redux";
import {popupAction} from "../../actions";
import {toastr} from 'helper/toastrIntercept';
import {useMISOpt} from "../../components/misopt";
import {getPageSize, useCheckRefWithSelectedCnt as useCheckRef, useFilter} from "../../helper";
import {getErrorMessage} from "../../helper/responseHandler";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../helper/tables";

const OrganizationGroup = (props) => {
    const {t} = useTranslation();
    const {getAuthority} = useMISOpt();
    const userAuthority = getAuthority("USER");

    const [style, setStyle] = useState({
        height: '762px'
    });

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        page: 0,
        pageSize: getPageSize('organization_group', contentConstants.PAGE_SIZE_OPTIONS[3]),
        keyword: '',
    });

    const [data, setState] = useState({
        items: [],
        loading: false,
        totalCount: 0,
    });

    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, keyword} = filter;
    const {addPopup, closePopup} = props;

    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        getOrganizationGroupList();
    }, [filter]);

    const updateDimensions = () => {
        let height = window.innerHeight - 204;
        setStyle({
            ...style,
            height: height
        })
    };

    const addOrganizationGroupPopup = () => {
        addPopup({
            type: commonConstants.ADD_ORGANIZATION_GROUP,
            id: commonConstants.ADD_ORGANIZATION_GROUP,
            onClose: () => closePopup(commonConstants.ADD_ORGANIZATION_GROUP),
            onSave: () => {
                closePopup(commonConstants.ADD_ORGANIZATION_GROUP);
                getOrganizationGroupList();
            }
        });
    };

    const editOrganizationGroupPopup = () => {
        let groupId = items[selected.current[0]].organizationGroupId;

        if (selectedCnt > 1) {
            addPopup({
                type: commonConstants.COMMON_ALERT_POPUP,
                id: commonConstants.COMMON_ALERT_POPUP,
                title: t("TEXT_TITLE_WARNING_P"),
                message: t("MESSAGE_COMMON_SELECT_ONE_CHECKBOX_P"),
                onClose: () => closePopup(commonConstants.COMMON_ALERT_POPUP),
            });
        } else {
            addPopup({
                type: commonConstants.EDIT_ORGANIZATION_GROUP,
                id: commonConstants.EDIT_ORGANIZATION_GROUP,
                groupId: groupId,
                onSave: () => {
                    closePopup(commonConstants.EDIT_ORGANIZATION_GROUP);
                    getOrganizationGroupList();
                },
                onClose: () => closePopup(commonConstants.EDIT_ORGANIZATION_GROUP)
            });
        }
    };

    const deleteOrganizationGroupPopup = () => {
        addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("MIS_SID_SERVER_DELETE_ORGANIZATION_GROUP"),
            message: t("ALERT_WANT_DELETE"),
            width: 310,
            height: 130,
            onClickYes: () => {
                closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                deleteOrganizationGroups();
            },
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        });
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox id={'ALL'}
                              classname={"table"}
                              name={"check"}
                              ref={checkAll}
                              onChange={toggleSelectAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox id={items[row.index].organizationGroupId}
                              classname="table"
                              name="check"
                              index={row.index}
                              onChange={toggleRow}
                              ref={setCheckBoxRefs}
                    />
                )
            },
            width: 40,
            resizable: false,
            sortable: false
        },
        {
            Header: t("MIS_SID_ORGANIZATION_GROUP"),
            accessor: "organizationGroupName",
            Cell: (props) => <span className={"list_column_data"} title={props.original.organizationGroupName}>{props.original.organizationGroupName}</span>,
            width: 220,
            sortable: false
        },
        {
            Header: t("TABLE_ORGANIZATION_P"),
            accessor: 'orgName',
            Cell: (props) => {
                let organizationDataList = props.original.organizationDataList;
                let organizationName = Array.prototype.map.call(organizationDataList, s => s.organizationName).join(", ");
                return <span className={"list_column_data"} title={organizationName}>{organizationName}</span>
            },
            resizable: false,
            sortable: false
        },
    ], [items]);

    const getOrganizationGroupList = () => {
        setState({...data, loading: true});
        const {page, pageSize, keyword} = filter;
        userService.fetchOrganizationGroups({
                startIndex: page === 0 ? page + 1 : page * pageSize + 1,
                pageSize: pageSize,
                searchText: keyword,
            }).then(res => {
            let result = [];
            res.items.forEach((item) => {
                result.push({
                    ...item,
                });
            })
            setState({
                ...data,
                loading: false,
                items: result,
                totalCount: res.totalCount,
            })
        }).catch(err => {
            setState({...data, loading: false});
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    const deleteOrganizationGroups = () => {
        let ids = selected.current.map(key => items[key].organizationGroupId);
        console.log(ids);
        userService.deleteOrganizationGroups({
            ids: ids,
        }).then(res => {
            toastr.success(t('ALERT_SUCCESS_DELETE'));
            let pageMin = page * Number(pageSize);
            let deletedTotalCount = totalCount - res.items.successList.length;
            let deletePage = page;
            if(deletedTotalCount <= pageMin) {
                deletePage = page - 1 < 0 ? 0 : page - 1;
            }
            setFilter({...filter, page: deletePage});
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'organization_group_tr_group');

    const {CREATE = false, MANAGE = false} = userAuthority;
    return (
        <div style={{width: '100%', height: '100%', display: props.currContent === 'ORGANIZATION_GROUP' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"ADD"} name={t("COM_BUTTON_ADD")} disable={false} onClick={addOrganizationGroupPopup} authority={CREATE || MANAGE}/>
                    <WhiteButton id={"EDIT"} name={t("COM_BUTTON_EDIT")} disable={selectedCnt !== 1} onClick={editOrganizationGroupPopup}  authority={CREATE || MANAGE}/>
                    <WhiteButton id={"DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} onClick={deleteOrganizationGroupPopup}  authority={CREATE || MANAGE}/>
                </div>
                <div className="rightButton">
                    <SearchBar id="searchOrganizationGroup"
                               placeholder={t('TABLE_ORGANIZATION_P')}
                               keyword={keyword}
                               onClickSearch={onKeywordChange}/>
                </div>
            </div>
            <div className="organization_group_list_view">
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    minRows={0}
                    sortable={false}
                    showPagination={false}
                    columns={columns}
                    pageSize={items.length}
                    className="-striped -highlight"
                    style={style}
                    getTbodyProps={() => {
                        return {
                            className: 'organization_group_table_body',
                        };
                    }}
                    getTrGroupProps={getTrGroupPropsType2}
                    getTdProps={() => {
                        return {
                            className: 'organization_group_td_props'
                        };
                    }}/>
                <Pagination totalCount={totalCount}
                            page={page}
                            pageSize={pageSize}
                            pageSizeOptions={contentConstants.PAGE_SIZE_OPTIONS}
                            onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            divide='organization_group'/>
            </div>
        </div>
    );
};

export default connect(
    state => ({
        menu: state.menu
    }),
    dispatch => ({
        openDetailView: (type) => dispatch(popupAction.openDetailView(type)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)(OrganizationGroup);

