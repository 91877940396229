import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import ReactTable from "react-table";
import {useTranslation} from 'react-i18next';
import {snakeCase, toInteger} from 'lodash';
import fileDownload from 'js-file-download';
import {commonConstants, DEVICE_PAGE_SIZE_OPTIONS} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {deviceService} from '../../services';
import {menuAction, playlistAction, popupAction} from "../../actions";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import Pagination from '../../components/table/Pagination';
import PlayerIcon from '../../components/icon/players';
import ContentThumbnail from "../../components/image/ContentThumbnail";
import DeviceRelativeTimeCell from '../../components/device/DeviceRelativeTimeCell';
import {getPageSize, useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import Size from '../../components/utils/Size';
import './PlaylistToExpire.css';
import {useTrGroupProps} from '../../helper/tables';


const getPlaylistTypeStr = type => {
    if(type === '0') {
        return 'COM_TEXT_GENERAL_P';
    } else if(type === '1') {
        return 'MIS_SID_AUDIENCE-TARGETED';
    } else if(type === '2') {
        return 'COM_DID_RMS_DEVICE_VIDEO_WALL';
    } else if(type === '3') {
        return 'MIS_SID_SYNC_PLAY';
    } else if(type === '4') {
        return 'COM_DID_LFD_ADVERTISEMENT';
    } else if(type === '5') {
        return 'MIS_TEXT_TAG_P'
    }
};

const defaultSort = {id: 'playlistName', desc: true};
const heightOffset = 204;

const PlaylistToExpire = ({currContent}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange] = useFilter({
        page: 0,
        pageSize: getPageSize('device', DEVICE_PAGE_SIZE_OPTIONS[0]),
        keyword: '',
        sorted: [defaultSort],
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;

        setData({...data, loading: true});

        deviceService.fetchDevicePlaylistToExpires({
            startIndex: page * pageSize + 1,
            pageSize,
            searchText: keyword,
            sortName: snakeCase(id),
            sortOrder: desc ? 'desc' : 'asc',
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount});
        });
    };

    const onKeywordChange = value => {
        setFilter({...filter, keyword: value ? value : '', page: 0, sorted: [defaultSort]});
    };

    const onClickPlaylist = playlistId => {
        dispatch(popupAction.openDetailView({
            type: commonConstants.COMMON_DETAIL_VIEW, 
            viewType: 'PLAYLIST', 
            id: playlistId
        }));
    };

    const onEdit = () => {
        if(selected.current.length === 0) {
            return;
        }

        const playlist = items[selected.current[0]];
        const addPlaylistTab = () => {
            dispatch(menuAction.addTab({
                id: 'EDIT_PLAYLIST', 
                title: t("TEXT_PLAYLIST_EDIT_P"), 
                active: true, 
                close: true
            }));
            return Promise.resolve();
        };

        addPlaylistTab()
            .then(() => dispatch(playlistAction.initEditPlaylist(playlist)))
            .then(() => dispatch(playlistAction.loadPlaylist(playlist)))
            .then(() => dispatch(menuAction.loadContent('EDIT_PLAYLIST')))
    };

    const onExport = exportType => {
        const {keyword, sorted: [{id, desc}]} = filter;
        exportType = 'EXCEL';
        deviceService.exportDevicePlaylistToExpires({
            exportType,
            searchText: keyword,
            sortColumn: snakeCase(id),
            sortOrder: desc ? 'desc' : 'asc',
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(error => console.log(error));
    };

    useEffect(() => {
        setStyle({height: window.innerHeight - heightOffset});
    }, []);

    useEffect(() => {
        fetchData();
    }, [filter]);

    const searchPlaceHolder = `Playlist Name`;

    const columns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllDevice_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={'AllDevice_'+row.index}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs} 
                    />
                );
            },
        },
        {
            Header: t('DID_ADMIN_NOTICE_CONTENT'),
            accessor: 'thumbFileId',
            width: 150,
            sortable: false,
            Cell: data => <ContentThumbnail id={data.value} />
        },
        {
            Header: t('TABLE_PLAYLIST_NAME_P'),
            accessor: 'playlistName',
            Cell: ({original, value}) => <span className='link_cell' onClick={() => onClickPlaylist(original.playlistId)}>{value}</span>
        },
        {
            Header: t('BUTTON_DETAIL_P'),
            width: 200,
            sortable: false,
            Cell: ({original: {totalSize, playTime, playlistType}}) => {
                return (
                    <>
                        <Size size={totalSize} />
                        <div>{playTime}</div>
                        <div>{t(getPlaylistTypeStr(playlistType))}</div>
                    </>
                );
            }
        },
        {
            Header: t('COM_MAPP_SID_SUPPORTED_DEVICES'),
            accessor: 'supportedDeviceType',
            sortable: false,
            width: 150,
            Cell: ({original}) => <PlayerIcon text={false} deviceType={original.deviceType} deviceTypeVersion={toInteger(original.deviceTypeVersion)} />
        },
        {
            Header: t('COM_TEXT_MODIFY_DATE_P'),
            accessor: 'lastModifiedDate',
            width: 200,
            Cell: data => <DeviceRelativeTimeCell value={data.value} />
        },
        {
            Header: t('TEXT_CREATOR_P'),
            width: 200,
            accessor: 'creatorId',
        },
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'device_tr_group');

    return (
        <div style={{width: '100%',display: currContent === 'PLAYLIST_TO_EXPIRE' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id='COM_BUTTON_EDIT' name={t('COM_BUTTON_EDIT')} disable={selectedCnt !== 1} onClick={onEdit} />
                    <WhiteButton id={"DEVICE_EXPORT"} name={t('BUTTON_EXPORT_P')} onClick={onExport} style={{borderColor: '#5e5e5e'}} />
                </div>
                <div className="rightButton">
                    <SearchBar id="deviceSearch" placeholder={searchPlaceHolder} onClickSearch={onKeywordChange} keyword={filter.keyword}/>
                </div>
            </div>
            <div className='device_list_view_wrap'>
                <ReactTable
                    data={items}
                    loading={loading}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns= {columns}
                    showPagination={false}
                    className="-striped -highlight"
                    style={style}
                    manual
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} divide={'device'} />
            </div>
        </div>
    );
};

export default PlaylistToExpire;
