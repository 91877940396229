import React from 'react';

const MediaTagFilterStatus = ({selectedMediaTag}) => {
    return (
        <span id="filter_box_tagFilter">
            {
                selectedMediaTag && selectedMediaTag.map(item => (
                    <span className="filter_status_tag_filter">
                        <span>{item.tagName}</span>
                    </span>
                ))
            }
        </span>
    )
}
export default MediaTagFilterStatus;