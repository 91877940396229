import React, {useEffect, useRef, useState} from 'react';
import {fabric} from 'fabric';
import {toastr} from 'helper/toastrIntercept';
import {useTranslation} from "react-i18next";
import classNames from 'classnames';
import {useDrag, useDrop} from 'react-dnd';
import {ResizableBox} from 'react-resizable';
import WhiteButton from "../button/WhiteButton";

const PixelFrame = ({frames, wrapWidth, wrapHeight, displayW, displayH, addPixelFrame, updateFrame, removeFrame, onSelectFrame, selectedFrame = -1, forceRender}) => {

    const {t} = useTranslation();

    const [dragId, setDragId] = useState({
        index: -1
    })

    const canvas = useRef(undefined);

    const [selectOneFrame, setSelectOneFrame] = useState(undefined);

    const checkPosition = (index, width, height, left, top) => {
        let check = 0;
        const rel_w = Math.abs(Math.floor((width*displayW)/wrapWidth));
        const rel_h = Math.abs(Math.floor((height*displayH)/wrapHeight));
        if (rel_w < 47 || rel_h < 47){
            check = 3;
        } else if (wrapWidth < width + left || left < 0) {
            check = 1;
        } else if (wrapHeight < height + top || top < 0) {
            check = 1;
        } else {
            frames.map(
                frame => {
                    if (frame.index !== index) {
                        let ret;
                        ret = isPointInsideRect( frame.x,  frame.y, left, top, width, height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( frame.x,  frame.y + frame.height, left, top, width, height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( frame.x + frame.width,  frame.y, left, top, width, height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( frame.x + frame.width,  frame.y + frame.height, left, top, width, height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( left, top, frame.x,  frame.y, frame.width, frame.height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( left, top + height, frame.x,  frame.y, frame.width, frame.height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( left + width, top, frame.x,  frame.y, frame.width, frame.height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( left + width, top + height, frame.x,  frame.y, frame.width, frame.height);
                        if(ret){check = 2;}
                    }
                }
            )
        }
        return check;
    }

    const checkPositionForBtn = (selectFrame, width, height, left, top) =>{
        let check = 0;
        if (width < 50 || height < 50){
            return 3;
        }
        var divPosition = getRelativeFrameDimension(0,0,width,height);
        width = divPosition.width;
        height = divPosition.height;

        if (wrapWidth < width + left || left < 0) {
            check = 1;
        } else if (wrapHeight < height + top || top < 0) {
            check = 1;
        } else {
            frames.map(
                (frame, index) => {
                    if (index !== selectFrame) {
                        const frameValue = frame;
                        let ret = isPointInsideRect( frameValue.x,  frameValue.y, left, top, width, height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( frameValue.x,  frameValue.y + frameValue.height, left, top, width, height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( frameValue.x + frameValue.width,  frameValue.y, left, top, width, height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( frameValue.x + frameValue.width,  frameValue.y + frameValue.height, left, top, width, height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( left, top, frameValue.x,  frameValue.y, frameValue.width, frameValue.height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( left, top + height, frameValue.x,  frameValue.y, frameValue.width, frameValue.height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( left + width, top, frameValue.x,  frameValue.y, frameValue.width, frameValue.height);
                        if(ret){check = 2;}
                        ret = isPointInsideRect( left + width, top + height, frameValue.x,  frameValue.y, frameValue.width, frameValue.height);
                        if(ret){check = 2;}
                    }
                }
            )
        }
        return check;
    }

    const isPointInsideRect = (p_find_x,  p_find_y, x, y, w, h) =>{
        if (p_find_x > x && p_find_x < (w + x))
        {
            if (p_find_y > y && p_find_y < (h + y))
            {
                return true;
            }
        }
        return false;
    }

    const getRelativeDisplaySolution = (x,y,w,h) => {
        const rel_w = Math.abs(Math.floor((w*displayW)/wrapWidth));
        const rel_h = Math.abs(Math.floor((h*displayH)/wrapHeight));
        return rel_w+'*'+rel_h;
    }

    const getRelativeDisplayDimension = (x,y,w,h) => {
        const rel_x = Math.floor((x*displayW)/wrapWidth);
        const rel_y = Math.floor((y*displayH)/wrapHeight);
        const rel_w = Math.floor(Math.floor((w*displayW)/wrapWidth));
        const rel_h = Math.floor(Math.floor((h*displayH)/wrapHeight));
        return {x: rel_x, y: rel_y, width: rel_w, height : rel_h};
    }

    const saveToJson = (width, height, left, top) =>{
        const count = frames.length;
        const resolution = getRelativeDisplaySolution(left, top, width, height);
        const realData = getRelativeDisplayDimension(left, top, width, height);
        //index, frameType, frameName, resolution,x,y,width,height, oriX, oriY, oriWidth, oriHeight
        const pixelFrame = new Pixel(count,'frame '+count, 'custom', resolution, Number(left), Number(top), Number(width), Number(height), Number(realData.x), Number(realData.y), Number(realData.width), Number(realData.height));
        addPixelFrame(pixelFrame);
    }

    useEffect(()=> {

        if (canvas.current !== undefined) {
            canvas.current.dispose();
            canvas.current = undefined;
        }
        if (canvas.current === undefined) {

            const fabricCanvas = new fabric.Canvas('customFrameCanvas', {
                selection : false
            });
            canvas.current = fabricCanvas;

            fabricCanvas.setDimensions({width: wrapWidth, height: wrapHeight});
            let rect, isDown, origX, origY;
            fabricCanvas.on('mouse:down', function(o) {
                isDown = true;
                const pointer = fabricCanvas.getPointer(o.e);
                origX = pointer.x;
                origY = pointer.y;
                rect = new fabric.Rect({
                    left : pointer.x,
                    top : pointer.y,
                    width: pointer.x-origX,
                    height: pointer.y-origY,
                    fill:'transparent',
                    stroke: '#ddd',
                    strokeWidth: 2,
                    selectable : false
                });
                fabricCanvas.add(rect);
            });

            fabricCanvas.on('mouse:move', function(o) {

                if (!isDown)
                    return;
                const pointer = fabricCanvas.getPointer(o.e);
                if (rect != null) {
                    if(origX>pointer.x){
                        rect.set({ left: Math.abs(pointer.x) });
                    }
                    if(origY>pointer.y){
                        rect.set({ top: Math.abs(pointer.y) });
                    }
                    rect.set({ width: Math.abs(origX - pointer.x) });
                    rect.set({ height: Math.abs(origY - pointer.y) });
                }
                fabricCanvas.renderAll();
            });

            fabricCanvas.on('mouse:up', function(o) {
                isDown = false;
                if (rect != null) {
                    const width = rect.get('width');
                    const height = rect.get('height');
                    const left = rect.get('left');
                    const top = rect.get('top');
                    const rtn = checkPosition('',width, height, left, top);

                    switch (rtn) {
                        case 0 :
                            saveToJson(width, height, left, top);
                            break;
                        case 1 :
                            toastr.error(t('MESSAGE_SCHEDULE_OUT_OF_RESO_LIMIT_P'));
                            break;
                        case 2 :
                            toastr.error(t('MESSAGE_SCHEDULE_OVERLAPPING_NOT_ALLOWED_P'));
                            break;
                    }
                    if (rect)
                        fabricCanvas.remove(rect);
                }

            });
        }

    }, [forceRender])

    const updatePixelFrame = (index,resolution,x,y,w,h, mode, oriX, oriY, oriWidth, oriHeight) => {
        const realData = getRelativeDisplayDimension(x, y, w, h);
        const frame = frames[index];
        frame.x = x;
        frame.y = y;
        frame.width = w;
        frame.height = h;
        if (mode === 'resize') {
            frame.oriWidth = realData.width;
            frame.oriHeight = realData.height;
        } else if (mode === 'drag') {
            frame.oriX = realData.x;
            frame.oriY = realData.y;
        } else {
            frame.oriX = oriX;
            frame.oriY = oriY;
            frame.oriWidth = oriWidth;
            frame.oriHeight = oriHeight;
        }
        frame.resolution = resolution;
        updateFrame(index, frame);
    }

    const onDrop = (item, monitor) => {
        let {index, resolution, x, y, width, height} = item;
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(x + delta.x);
        const top = Math.round(y + delta.y);
        const rtn = checkPosition(index, width, height, left, top);
        switch (rtn) {
            case 0 :
                updatePixelFrame(index,resolution, left, top, width, height, 'drag');
                break;
            case 1 :
                toastr.error(t('MESSAGE_SCHEDULE_OUT_OF_RESO_LIMIT_P'));
                break;
            case 2 :
                toastr.error(t('MESSAGE_SCHEDULE_OVERLAPPING_NOT_ALLOWED_P'));
                break;
            case 3 :
                toastr.error(t('MESSAGE_SCHEDULE_MIN_WIDTH_HEIGHT_LIMIT_P'));
                break;
        }
        return item;
    }

    const [ ,drop] = useDrop({
        accept: 'PixelFrame',
        drop : (item, monitor)=> onDrop(item, monitor)
    })

    const selectFrame = index => {
        setDragId({index: index});
    }

    const remove = index => {
        removeFrame(index);
    }

    const selectedOneFrame = (id) => {
        setSelectOneFrame(frames[id]);
        if (onSelectFrame) {
            onSelectFrame(id)
        }
    }

    const Frame = ({index, frameType, frameName, resolution,x,y,width,height, oriX, oriY, oriWidth, oriHeight}) => {

        const [rect, setRect] = useState({
            width: width,
            height: height
        });

        const [{isDragging}, drag] = useDrag({
            item: {
                type: 'PixelFrame',
                index:index,
                frameType:frameType,
                frameName:frameName,
                resolution:resolution,
                x:x,
                y:y,
                width:width,
                height:height,
                oriX: oriX,
                oriY: oriY,
                oriWidth: oriWidth,
                oriHeight: oriHeight
            },
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const onResizeStart = (e, data) => {
            e.preventDefault();

        }
        const onResizeStop = (e, data, index) => {
            e.preventDefault();
            const {width, height} = data.size;
            const frame = frames[index];
            const resolution = getRelativeDisplaySolution(frame.oriX, frame.oriY, width, height);
            const rtn = checkPosition(index, width, height, frame.x, frame.y);
            switch (rtn) {
                case 0 :
                    updatePixelFrame(index,resolution, frame.x, frame.y, width, height, 'resize');
                    break;
                case 1 :
                    toastr.error(t('MESSAGE_SCHEDULE_OUT_OF_RESO_LIMIT_P'));
                    return undefined;
                    break;
                case 2 :
                    toastr.error(t('MESSAGE_SCHEDULE_OVERLAPPING_NOT_ALLOWED_P'));
                    return undefined;
                    break;
                case 3 :
                    toastr.error(t('MESSAGE_SCHEDULE_MIN_WIDTH_HEIGHT_LIMIT_P'));
                    return undefined;
                    break;
            }
            return undefined;
        }
        const onResize = (e, data) => {
            setRect({width: data.size.width, height: data.size.height})
        }
        if (isDragging) {
            return <div ref={drag} />
        }

        return (
            <div ref={drag} className={classNames("pixelFrameMenu",{'on' : selectedFrame === index})} style={{overflow:'hidden',zIndex:2,cursor:'pointer',border:'2px solid #ddd',position:'absolute',left:x, top:y, width: rect.width, height: rect.height}} onClick={()=>selectedOneFrame(index)} >
                <ResizableBox width={width} height={height} minConstraints={[47,47]} maxConstraints={[wrapWidth, wrapHeight]} onResizeStart={onResizeStart} onResizeStop={(e, data)=>onResizeStop(e, data, index)} onResize={onResize}>
                    <div className="closeBtn" onClick={()=>remove(index)}></div>
                    <div className="frame_size">{resolution}</div>
                </ResizableBox>
            </div>
        )
    }

    const updateFrameSize = (e, type) => {
        const {value} = e.target;
        switch (type) {
            case 'x':
                setSelectOneFrame({...selectOneFrame, oriX : value});
                break;
            case 'y':
                setSelectOneFrame({...selectOneFrame, oriY : value});
                break;
            case 'width':
                setSelectOneFrame({...selectOneFrame, oriWidth : value});
                break;
            case 'height':
                setSelectOneFrame({...selectOneFrame, oriHeight : value});
                break;
        }

        const length = value.length;
        setTimeout(() => {
            if (type === 'x') {
                oriXRef.current.setSelectionRange(length, length);
            } else if (type === 'y'){
                oriYRef.current.setSelectionRange(length, length);
            } else if (type === 'width'){
                oriWidthRef.current.setSelectionRange(length, length);
            } else if (type === 'height'){
                oriHeightRef.current.setSelectionRange(length, length);
            }
        }, 0);
    }

    const getRelativeFrameDimension = (x,y,w,h) => {
        const rel_x = Math.floor((x*wrapWidth)/displayW);
        const rel_y = Math.floor((y*wrapHeight)/displayH);
        const rel_w = Math.floor((w*wrapWidth)/displayW);
        const rel_h = Math.floor((h*wrapHeight)/displayH);
        return {x: rel_x, y: rel_y, width: rel_w, height : rel_h};
    }

    const updateFrameSizeNPosition = () => {
        const {oriX, oriY, oriWidth, oriHeight} = selectOneFrame;
        const divPosition = getRelativeFrameDimension(oriX, oriY, oriWidth, oriHeight);
        const rtn = checkPositionForBtn(selectedFrame, oriWidth, oriHeight, divPosition.x, divPosition.y);
        switch (rtn) {
            case 0 :
                updatePixelFrame(selectedFrame, oriWidth+'*'+oriHeight, divPosition.x, divPosition.y, divPosition.width, divPosition.height, '', oriX, oriY, oriWidth, oriHeight);
                break;
            case 1 :
                toastr.error(t('MESSAGE_SCHEDULE_OUT_OF_RESO_LIMIT_P'));
                break;
            case 2 :
                toastr.error(t('MESSAGE_SCHEDULE_OVERLAPPING_NOT_ALLOWED_P'));
                break;
            case 3 :
                toastr.error(t('MESSAGE_SCHEDULE_MIN_WIDTH_HEIGHT_LIMIT_P'));
                break;
        }
        //updateFrame(selectedFrame, selectOneFrame)
    }

    const oriXRef = useRef(null);
    const oriYRef = useRef(null);
    const oriWidthRef = useRef(null);
    const oriHeightRef = useRef(null);

    return(

        <div>
            <div ref={drop} style={{height: wrapHeight, position: 'relative'}}>
                <canvas id="customFrameCanvas" width={wrapWidth} height={wrapHeight} style={{border:'1px solid #ddd', zIndex: 2, position: 'absolute'}}></canvas>
                <div style={{width:wrapWidth,height:wrapHeight, position:'absolute'}} id="pixelFrameOverlay" >

                    {
                        frames !== undefined && frames.length > 0 &&
                        frames.map(
                            (frame, index) => <Frame {...frame}/>
                        )
                    }

                </div>
            </div>
            <div className="detail_view mini_p mt14" id="framePositionWrap">
                <table className="positionTable">
                    {
                        selectOneFrame !== undefined &&
                        <tbody>
                        <tr>
                            <td></td>
                            <td>{t("TEXT_LEFT_P")}</td>
                            <td>{t("COM_DID_ADMIN_URGENT_UPPER")}</td>
                            <td></td>
                            <td>{t("COM_IDS_DS_PREVIEW_WIDTH")}</td>
                            <td>{t("COM_IDS_HEIGHT")}</td>
                        </tr>

                        <tr>
                            <td>{t("TEXT_POSITION_P")}</td>
                            <td>
                                {/*<TextInput width={50} disabled={selectOneFrame !== undefined && selectOneFrame.oriX !== '' ? false:true} value={selectOneFrame !== undefined ? selectOneFrame.oriX : ''} onChange={(e)=>updateFrameSize(e, 'x')} length={4}/>*/}
                                <input width={50} style={{width: 50}} maxLength={4} value={selectOneFrame !== undefined && selectOneFrame.oriX} onChange={(e)=>updateFrameSize(e, 'x')} length={4} ref={oriXRef}/>


                            </td>
                            <td>
                                {/*<TextInput width={50} disabled={selectOneFrame !== undefined && selectOneFrame.oriY !== '' ? false:true} value={selectOneFrame !== undefined ? selectOneFrame.oriY : ''} onChange={(e)=>updateFrameSize(e, 'y')} length={4}/>*/}
                                <input width={50} style={{width: 50}} maxLength={4} value={selectOneFrame !== undefined && selectOneFrame.oriY} onChange={(e)=>updateFrameSize(e, 'y')} length={4} ref={oriYRef}/>
                            </td>
                            <td>{t("COM_TEXT_SIZE_P")}</td>
                            <td>
                                {/*<TextInput width={50} disabled={selectOneFrame !== undefined && selectOneFrame.oriWidth !== '' ? false:true} value={selectOneFrame !== undefined ? selectOneFrame.oriWidth : ''} onChange={(e)=>updateFrameSize(e, 'width')} length={4}/>*/}
                                <input width={50} style={{width: 50}} maxLength={4} value={selectOneFrame !== undefined && selectOneFrame.oriWidth} onChange={(e)=>updateFrameSize(e, 'width')} length={4} ref={oriWidthRef}/>
                            </td>
                            <td>
                                {/*<TextInput width={50} disabled={selectOneFrame !== undefined && selectOneFrame.oriHeight !== '' ? false:true} value={selectOneFrame !== undefined ? selectOneFrame.oriHeight : ''} onChange={(e)=>updateFrameSize(e, 'height')} length={4}/>*/}
                                <input width={50} style={{width: 50}} maxLength={4} value={selectOneFrame !== undefined && selectOneFrame.oriHeight} onChange={(e)=>updateFrameSize(e, 'height')} length={4} ref={oriHeightRef}/>
                            </td>
                            <td>
                                <WhiteButton name={t("BUTTON_APPLY")} onClick={updateFrameSizeNPosition} disable={selectOneFrame !== undefined ? false:true}/>
                            </td>
                        </tr>

                        </tbody>

                    }

                </table>
            </div>
        </div>
    )
}

function Pixel(index, frameType, frameName, resolution,x,y,width,height, oriX, oriY, oriWidth, oriHeight){
    this.index = index;
    this.frameType = frameType;
    this.frameName = frameName;
    this.resolution = resolution;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;

    this.oriX = oriX;
    this.oriY = oriY;
    this.oriWidth = oriWidth;
    this.oriHeight = oriHeight;
}


export default PixelFrame;