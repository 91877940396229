import React from 'react';

export const checkContextMenu = (contextMenu, id, depth, resourceCount) => {
    const authority = JSON.parse(localStorage.getItem('user')).authority;
    const isServerAdmin = JSON.parse(localStorage.getItem('user')).serverAdmin;

    switch (id) {
        case 'BY_USER':
            contextMenu[0].disabled = true;
            contextMenu[1].disabled = true;
            contextMenu[2].disabled = true;
            break;
        case 'SHARED_CONTENT':
            if(!isServerAdmin){
                contextMenu[0].disabled = true;
                contextMenu[1].disabled = true;
                contextMenu[2].disabled = true;
                contextMenu[3].disabled = true;
            }else{
                if (parseInt(depth) < 0) {
                    contextMenu[1].disabled = true;
                    contextMenu[2].disabled = true;
                    contextMenu[3].disabled = true;
                } else {
                    contextMenu[1].disabled = false;
                    contextMenu[2].disabled = false;
                    contextMenu[3].disabled = false;
                }
            }
            break;
        case 'MY_CONTENT':
            if(!(authority.CONTENT_PLAYLIST_RULESET.CREATE || authority.CONTENT_PLAYLIST_RULESET.MANAGE)){
                contextMenu[0].disabled = true;
                contextMenu[1].disabled = true;
                contextMenu[2].disabled = true;
            }else {
                if (parseInt(depth) < 1) {
                    contextMenu[1].disabled = true;
                    contextMenu[2].disabled = true;
                } else {
                    contextMenu[1].disabled = false;
                    contextMenu[2].disabled = false;
                }
            }
            break;
        case 'MY_PLAYLIST':
            if (parseInt(depth) < 1) { // default
                contextMenu[0].disabled = false;
                contextMenu[1].disabled = true;
                contextMenu[2].disabled = true;
            } else {
                contextMenu[0].disabled = false;
                contextMenu[1].disabled = false;
                contextMenu[2].disabled = false;
            }
            break;
        case 'PLAYLIST_BY_USER':
            contextMenu[0].disabled = true;
            contextMenu[1].disabled = true;
            contextMenu[2].disabled = true;
            break;
        case 'CONTENT_SCHEDULE_BY_GROUP':
        case 'MESSAGE_SCHEDULE_BY_GROUP':
        case 'EVENT_SCHEDULE_BY_GROUP':
            if (parseInt(depth) < 2) {
                contextMenu[0].disabled = false;
                contextMenu[1].disabled = true;
                contextMenu[2].disabled = true;
            } else {
                contextMenu[0].disabled = false;
                contextMenu[1].disabled = false;
                contextMenu[2].disabled = false;
            }

            break;
        case 'USER_BY_GROUP':
            contextMenu[0].disabled = false;
            contextMenu[1].disabled = false;
            contextMenu[2].disabled = false;
            break;
        case 'DEVICE_BY_GROUP':
            if (parseInt(depth) < 2) {
                contextMenu[0].disabled = false;
                contextMenu[1].disabled = true;
                contextMenu[2].disabled = true;
            } else {
                contextMenu[0].disabled = false;
                contextMenu[1].disabled = false;
                contextMenu[2].disabled = resourceCount > 0 ? true : false;
            }
            break;
        default: {
            if (parseInt(depth) < 2) {
                contextMenu[1].disabled = true;
                contextMenu[2].disabled = true;
            } else {
                contextMenu[1].disabled = false;
                contextMenu[2].disabled = false;
            }
        }
    }
    return contextMenu;
}

