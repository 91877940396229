import React from 'react';
import './PrivacyPolicyFooter.css';
import isEmpty from "lodash/isEmpty";
import {useTranslation} from 'react-i18next';
import {getLocationSID} from "../../constants";

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

const PrivacyPolicyFooter = ({privacyPolicyList, privacyPolicySettings}) => {

    const {t} = useTranslation();

    const showPrivacyPolicy = (location, privacyPolicyList) => {

        let windowObject = window.open(backendHost+'/privacypolicy', '', '');

        privacyPolicyList.map(e => {
            if(e.location === location){
                let policy = "";
                policy = e.policyNotice;

                if(isEmpty(policy)){
                    policy = e.policyNoticeAgree;
                }

                setTimeout(() => {
                    windowObject.document.writeln("<link rel='shortcut icon' type='image/x-icon' href="+backendHost+"'/logo.ico' />");
                    windowObject.document.writeln("<title>MagicINFO Privacy Policy</title>");
                    windowObject.document.writeln(policy);
                    windowObject.document.close();

                }, 1000);

            }

        });



    }

    if(privacyPolicySettings && privacyPolicySettings.privacyPolicyLocation!=null && !isEmpty(privacyPolicySettings.privacyPolicyLocation)) {
        return (
            <a href="#" className="privacy_policy" onClick={()=>showPrivacyPolicy(privacyPolicySettings.privacyPolicyLocation, privacyPolicyList)}> {t('COM_TV_SID_PRIVACY_POLICY')} </a>
        )
    }else{
        return (
            <>
            <span> {t('COM_TV_SID_PRIVACY_POLICY')} ( </span>
                {
                    privacyPolicyList.length > 0 ?
                        privacyPolicyList.map(e => {
                            return (
                                <a href="#" className="privacy_policy"
                                   onClick={() => showPrivacyPolicy(e.location, privacyPolicyList)}> { t(getLocationSID(e.location))} </a>
                            );
                        }) : ""
                }
                <span> ) </span>
                </>
         )
    }
};

PrivacyPolicyFooter.defaultProps = {
    login: false,
    privacyPolicyList : '',
    privacyPolicySettings : ''
};

export default PrivacyPolicyFooter;
