import React, {useEffect, useMemo, useState} from 'react';
import ReactTable from "react-table";
import {useTranslation} from 'react-i18next';
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {playlistService} from '../../services';
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import SupportedDeviceIcon from "../../components/icon/SupportedDeviceIcon";
import Size from "../../components/utils/Size";
import {commonConstants, playlistConstants} from "../../constants";
import {connect} from "react-redux";
import {menuAction, playlistAction, popupAction} from "../../actions";
import Pagination from '../../components/table/Pagination';
import {toastr} from 'helper/toastrIntercept';
import ContentThumbnail from "../../components/image/ContentThumbnail";
import DeviceRelativeTimeCell from "../../components/device/DeviceRelativeTimeCell";
import ICON_SHARE from '../../images/icon/icon_share.png';
import {useMISOpt} from "../../components/misopt";
import {getPageSize, useCheckRefForContent, useFilter} from "../../helper";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import './AllPlaylist.css'
import {useTrGroupProps} from "../../helper/tables";


const DeletedPlaylist = (props) => {

    const {t} = useTranslation();
    const {getAuthority} = useMISOpt();
    const [authority, setAuthority] = useState({});
    const [showNoData, setNoData] = useState(false);

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        page: 0,
        pageSize: getPageSize('playlist'),
        keyword: '',
        sorted: [{id: 'lastModifiedDate', desc: true}],
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0
    });

    const [style, setStyle] = useState({
        height: '740px'
    });


    const [didMount, setMount] = useState(false);

    const {items = [], loading = false, totalCount = 0, pages = 0,} = data;
    //searchTex -> keyword
    const {page, pageSize, keyword,  sorted }= filter;
    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRefForContent(items);

    const {addPopup, closePopup, reloadGroup} = props;
    const {loadTrashBasketPopup, loadTrashBasket} = props;

    const updateDimensions = () => {
        let height = window.innerHeight - 204;
        setStyle({
            ...style,
            height: height
        })
    }

    const fetchData = () => {

        setNoData(false);
        if (!loading) {
            setData({...data, loading: true});
        }
        playlistService.fetchPlaylistFilter({
            groupType: 'DELETED',
            playlistType: ['on'],
            page: page + 1,
            pageSize,
            searchCreator: '',
            startModifiedDate: '',
            endModifiedDate: '',
            searchText: keyword,
            sorted: sorted,
        }).then(res => {
            if (res.totalCount === 0) {
                setNoData(true);
            }
            setData({
                ...data,
                loading: false,
                items: res.items,
                totalCount: res.totalCount
            })

            if(loadTrashBasket){
                //if(res.items.length > 0){
                    onDeleteAllPlaylist();
                //}
                loadTrashBasketPopup(false);
            }
        }).catch(error => {
            console.log(error);
            setData({...data, loading: false});
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
        }).finally(() => {
        });
    };

    const setCheckAll = (check) => {
        if (checkAll && checkAll.current) {
            checkAll.current.checked = check;
        }
    };

    const getCheckedPlaylistId = () => {
        return selected.current.map(key => items[key].playlistId);
    };

    const getCheckedPlaylistItems = () => {
        const ids = getCheckedPlaylistId();
        const checkedItems = [];
        ids.forEach(id => {
            checkedItems.push(items.find(item => item.playlistId === id));
        });

        return checkedItems;
    };

    const handleDelete = () => {
        const ids = getCheckedPlaylistId();
        const obj = {playlistIds: ids, deleteMethod: 'DELETE_FORCE', productType: ''};

        playlistService.deletePlaylist(obj).then(res => {
            toastr.success(t("COM_TEXT_SUCCESS_P"));
            setFilter({
                ...filter,
                page: 0,
            });
        }).catch(error => {
            console.log(error);
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
        }).finally(() => {
            //fetchData();
            closePopup(commonConstants.COMMON_CONFIRM_POPUP);
        });
    };

    const onDeletePlaylist = () => {
        addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t('COM_BUTTON_DELETE'),
            message: t('MESSAGE_CONTENT_CONFIRM_DELETE_PLAYLIST_P'),
            height: 90,
            onClickYes: handleDelete,
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        });
    };

    const handleDeleteAll = () => {
        playlistService.deleteRecyclebin().then(res => {
            toastr.success(t("COM_TEXT_SUCCESS_P"));
            setFilter({
                ...filter,
                page: 0,
            });
        }).catch(error => {
            let errorMessage = error.errorMessage;
            switch (error.errorCode) {
                case '400012':
                    errorMessage = t('MESSAGE_CONTENT_RECYCLE_BIN_IS_EMPTY_P');
                    break;
                default :
                    errorMessage = t('COM_IDS_MSG_UNEXPEXTED_ERROR');
                    break;
            }
            toastr.error(errorMessage);
        }).finally(() => {
            //fetchData();
            closeDeleteAllPopup();
        });
    };

    const onDeleteAllPlaylist = () => {
        addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t('COM_BUTTON_DELETE'),
            message: t('MESSAGE_CONTENT_CONFIRM_EMPTY_RECYCLE_BIN_P'),
            height: 90,
            onClickYes: handleDeleteAll,
            onClose: () => closeDeleteAllPopup()
        });
    };

    const closeDeleteAllPopup = () => {

        if (loadTrashBasket) {
            loadTrashBasketPopup(false);
        }
        closePopup(commonConstants.COMMON_CONFIRM_POPUP);
    }

    const handleRestore = () => {
        const ids = getCheckedPlaylistId();
        const obj = {playlistIds: ids};

        playlistService.restorePlaylist(obj).then(res => {
            toastr.success(t("COM_TEXT_SUCCESS_P"));
            setFilter({
                ...filter,
                page: 0,
            });

            // reload group Tree
            reloadGroup("MY_PLAYLIST", 0);
            reloadGroup('PLAYLIST_BY_USER');

        }).catch(error => {
            console.log(error);
            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'));
        }).finally(() => {
            //fetchData();
            closePopup(commonConstants.COMMON_CONFIRM_POPUP);
        });
    };

    const onRestorePlaylist = () => {
        addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t('BUTTON_RESTORE_P'),
            message: t('MESSAGE_CONTENT_CONFIRM_RESTORE_PLAYLIST_P'),
            height: 90,
            onClickYes: handleRestore,
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        });
    };

    const getTrGroupProps = (state, rowInfo) => {
        if (rowInfo) {
            return {
                onClick: (e) => {
                    const idx = rowInfo.index;

                    if(
                        e.target.classList.contains('data_name') ||
                        e.target.classList.contains('rt-expander') ||
                        e.target.classList.contains('status_wrap') ||
                        e.target.nodeName === 'A'||
                        e.target.tagName === 'LABEL' ||
                        e.target.tagName === 'INPUT') {
                        return;
                    }
                    checkBoxes.current[idx].checked = !checkBoxes.current[idx].checked;
                    toggleRow({target: checkBoxes.current[idx]});
                },
                className: 'playlist_tr_group'
            };
        }
        return {};
    };

    const renderPlaylistType = (playlist) => {
        let playlistTypeName;
        switch (playlist.playlistType) {
            case '0' :
                playlistTypeName = t('COM_TEXT_GENERAL_P');
                break;
            case '2' :
                playlistTypeName = t('TEXT_TITLE_VIDEOWALL_P');
                break;
            case '3' :
                playlistTypeName = t('MIS_SID_SYNC_PLAY');
                break;
            case '4' :
                playlistTypeName = t('COM_DID_LFD_ADVERTISEMENT');
                break;
            case '5' :
                playlistTypeName = t('MIS_TEXT_TAG_P');
                break;
            case '6' :
                playlistTypeName = t('MIS_SID_NESTED');
                break;
            default :
                playlistTypeName = t('COM_TEXT_GENERAL_P');
                break;
        }

        if (playlist.playlistType === '5') {
            if (playlist.ignoreTag === 1) {
                playlistTypeName += ', ' + t('MIS_SID_SERVER_IGNORE_TAG');
            }
            if (playlist.evennessPlayback === 1) {
                playlistTypeName += ', ' + t('MIS_SID_SERVER_PLAY_EVENTLY');
            }
        }

        return <div>
            <div><Size size={playlist.totalSize}/></div>
            <div><span>{playlist.playTime}</span></div>
            <div><span>{playlistTypeName}</span></div>
        </div>
    };

    const renderNameCell = (row) => {
        return (
            <span title={row.original.playlistName} >{row.value}</span>
        )
    };

    const SupportedDeviceIconCell = ({original}) => {
        return (<SupportedDeviceIcon deviceType={original.deviceType} deviceTypeVersion={original.deviceTypeVersion}/>)
    };


    const renderThumbnail = (original) => {
        if (original.thumbnailInfo != null) {
            const images = original.thumbnailInfo.split("|");

            if (images.length > 1) {
                if (original.hasSubPlaylist) {
                    return (
                        <div className="playlist_thumb_render">
                            <div className="playlist_thumb_wrap_nestedPlaylist">
                                <ContentThumbnail id={images[0]} width={100} height={56}
                                                  style={{display: 'block', height: '100%'}}/>
                            </div>
                            <div className="playlist_thumb_wrap_nestedIcon"><img src={ICON_SHARE}/></div>
                        </div>);
                } else {
                    if (original.playlistType === "5") { // tagplaylist
                        return (
                            <div className="playlist_thumb_render">
                                <div className="playlist_thumb_wrap playlist_folder_thumbnail">
                                <span className="float_l" style={{width: '52px'}}>
                                    <ContentThumbnail id={images[0]} height={29} style={{verticalAlign: 'middle'}}/>
                                </span>
                                </div>
                            </div>);
                    } else {
                        return (
                            <div className="playlist_thumb_render">
                                <div className="playlist_thumb_wrap">
                                <span style={{paddingLeft: 4}}>
                                    <ContentThumbnail id={images[0]} width={100} height={56}
                                                      style={{display: 'block', height: '100%'}}/>
                                </span>
                                </div>
                            </div>);
                    }
                }
            } else {
                return ''
            }
        }
        return '';
    };

    //const columns = useMemo(() => [items]);
    const renderModifiedDate = (lastModifiedDate) => {
        return <DeviceRelativeTimeCell value={lastModifiedDate}/>
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'DeletedPlaylist_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox
                        id={items[row.index].playlistId}
                        index={row.index}
                        classname="table"
                        name="check"
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                )
            },
            width: 46,
            sortable: false,
            resizable: false,
            style: {"textAlign": "center"}
        },
        {
            accessor: "playlistId",
            show: false
        },
        {
            Header: t("TEXT_TITLE_CONTENT_P"),
            width: 147,
            accessor: 'thumbnailInfo',
            Cell: props => renderThumbnail(props.original),
            sortable: false,
        },
        {
            Header: t("TABLE_PLAYLIST_NAME_P"),
            accessor: "playlistName",
            width: 550,
            Cell: renderNameCell,
        },
        {
            Header: t("BUTTON_DETAIL_P"),
            width: 250,
            Cell: props => renderPlaylistType(props.original),
            sortable: false
        },
        {
            Header: t("COM_MAPP_SID_SUPPORTED_DEVICES"),
            width: 250,
            Cell: SupportedDeviceIconCell,
            sortable: false
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "lastModifiedDate",
            width: 180,
            Cell: props => renderModifiedDate(props.original.lastModifiedDate)

        },
        {
            Header: t("TEXT_CREATOR_P"),
            accessor: "creatorId"
        }
    ], [items]);


    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow,'playlist_tr_group');

    const renderListView = () => {
        return (
            <div className="playlist_table_wrap">
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    page={page}
                    pages={pages}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    onSortedChange={onSortedChange}
                    getTrGroupProps={getTrGroupPropsType2}
                    noDataText={t("MESSAGE_COMMON_NO_DATA_P")}
                    columns={columns}
                    className="-striped -highlight"
                    manual
                    showPagination={false}
                    style={style}
                />
                <Pagination totalCount={totalCount}
                            page={page}
                            pageSize={pageSize}
                            pageSizeOptions={playlistConstants.PAGE_SIZE_OPTIONS}
                            onPageChange={onPageChange}
                            nPageSizeChange={onPageSizeChange}
                            divide={'playlist'}/>
            </div>
        )
    };


// 최초
    useEffect(() => {
        updateDimensions();
        setAuthority(getAuthority('CONTENT_PLAYLIST_RULESET'));
        fetchData();
        setMount(true);
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        if (didMount) {
            fetchData();
        }
    }, [filter]);


/*    useEffect(() => {
        if (didMount) {

            if (props.mode === 'GROUPED' && (props.groupId === undefined && props.userId === undefined)) {
                return;
            }
            if (props.id === 'ALL_PLAYLIST') {
                setFilter({
                    page: 0,
                    pageSize: getPageSize('playlist'),
                    categoryIds: [],
                    playlistType: [],
                    searchCreator: '',
                    startDate: '',
                    endDate: '',
                    searchText: '',
                    sorted: [{id: 'lastModifiedDate', desc: true}],
                    userId: '',
                    groupId: ''
                });
            } else {
                setFilter({
                    page: 0,
                    pageSize: getPageSize('playlist'),
                    keyword: '',
                    sorted: [{id: 'lastModifiedDate', desc: true}],
                });
            }
        }
    }, [props.groupId, props.userId]);*/


    return (
        <div style={{width: '100%', display: (props.currContent === 'PLAYLIST_RECYCLE_BIN') ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton
                        id={"PLAYLIST_RESTORE"}
                        name={t("BUTTON_RESTORE_P")}
                        onClick={onRestorePlaylist}
                        disable={ selectedCnt < 1}
                        authority={authority.CREATE || authority.MANAGE}
                    />
                    <WhiteButton
                        id={"PLAYLIST_DELETE"}
                        name={t("COM_BUTTON_DELETE")}
                        onClick={onDeletePlaylist}
                        disable={ selectedCnt < 1}
                        authority={authority.CREATE || authority.MANAGE}
                    />
                    <WhiteButton
                        id={"PLAYLIST_DELETE_ALL"}
                        name={t("COM_BUTTON_DELETE_ALL_P")}
                        onClick={onDeleteAllPlaylist}
                        authority={authority.CREATE || authority.MANAGE}
                    />
                </div>
                <div className="rightButton">
                    <SearchBar
                        id="playlistSearch"
                        placeholder={t("TABLE_PLAYLIST_NAME_P")}
                        onClickSearch={onKeywordChange}
                        enableDetail={false}
                        keyword={keyword}
                    />
                </div>
            </div>
            {renderListView()}
        </div>
    )
};

export default connect(
    state => ({
        loadTrashBasket : state.playlist.loadTrashBasket
    }),
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        loadTrashBasketPopup: loadTrashBasket => dispatch(playlistAction.loadTrashBasketPopup(loadTrashBasket)),
        reloadGroup: (submenuId, groupId) => dispatch(menuAction.reloadGroup(submenuId, groupId)),
    })
)(DeletedPlaylist);