import React, {useEffect, useState} from 'react';
import {scheduleService} from "../../../../services";
import {connect, useDispatch} from "react-redux";
import {menuAction, popupAction, scheduleAction} from "../../../../actions";
import MISDialog from "../../MISDialog";
import {useTranslation, withTranslation} from "react-i18next";
import EventConditionContentDetail from "./EventConditionContentDetail";
import EventConditionDetail from "./EventConditionDetail";
import {getErrorMessage} from "../../../../helper/responseHandler";
import {toastr} from 'helper/toastrIntercept';

const EventScheduleManagerDetailView = ({id,zIndex}) => {

    const [event, setEvent] = useState(undefined);
    const [index, setIndex] = useState(0);
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(()=> {
        scheduleService.fetchEventConditionById(id).then(
            res=> {
                setEvent(res.items)
                setOpen(true);
            }
        ).catch(e=> toastr.error(getErrorMessage(e)))
    }, [])


    const changeEvent = move => {
        if (event !== undefined && event.eventConditions != undefined && event.eventConditions.length > 0) {
            switch (move) {
                case 'prev':
                    if (index > 0 && (index + 1) <= event.eventConditions.length ) {
                        setIndex(index-1)
                    }
                    break;
                case 'next':
                    if (index+1 < event.eventConditions.length) {
                        setIndex(index+1)
                    }
                    break;
            }
        }
    }

    const closePopup = (programId) => {
        dispatch(popupAction.closePopup(programId));
    }

    const editEventSchedule = () => {
        const tab = {id: 'EDIT_EVENT_CONDITION', title: t("MIS_TEXT_EVENT_MANAGEMENT_P"), active: true, close: true};
        const editEvent = Object.assign({}, event);
        new Promise(() => {
            dispatch(scheduleAction.initEventCondition('edit', editEvent))
        }).then(
            dispatch(menuAction.addTab(tab))
        ).then(
            dispatch(menuAction.loadContent('EDIT_EVENT_CONDITION'))
        ).finally(
            dispatch(popupAction.closePopup(id))
        )
    }


    const dialogProps = {title: event !== undefined ?  event.eventName : '',isDraggable: true, closeOnEscape : true, allowMinimize : true, width :880, height :550, position: {x: -300, y: -400}, zIndex: zIndex, popupId:id, onClose: () => closePopup(id)};
    const buttons = {
        rightButtons: [
            {
                id: 'EDIT_EVENT_CONDITION',
                title: t("TEXT_EDIT_P"),
                onClick: () => editEventSchedule()
            },
            {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: () => closePopup(event.eventId)}
        ]
    }

    return (
        <>
        {
            open &&
            <MISDialog
                dialog={dialogProps}
                buttons={buttons}
                height={400}
            >

                <div className="popup_contents clearfix">
                    <div className="playlist_imgview mt25" style={{width:400}}>
                        <EventConditionContentDetail event={event} index={index} changeEvent={changeEvent}/>
                    </div>
                    <div className="playlist_detail ml25 mb5" style={{width:'45%'}}>
                        <EventConditionDetail event={event} index={index}/>
                    </div>
                </div>

            </MISDialog>
        }
        </>
    )





}
export default connect(
    null,
    dispatch => ({
        closePopup: (id)=>dispatch(popupAction.closePopup(id))
    })

)(withTranslation()(EventScheduleManagerDetailView));