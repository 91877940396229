import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import ReactTable from "react-table";
import {useTranslation} from 'react-i18next';
import {snakeCase} from 'lodash';
import {commonConstants, DEVICE_PAGE_SIZE_OPTIONS} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {deviceService} from '../../services';
import {popupAction} from "../../actions";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import Pagination from '../../components/table/Pagination';
import {getPageSize, useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import DeviceRelativeTimeCell from "../../components/device/DeviceRelativeTimeCell";
import {toastr} from 'helper/toastrIntercept';
import fileDownload from "js-file-download";
import {useTrGroupProps} from '../../helper/tables';

const heightOffset = 204;
const defaultSort = {id: 'jobName', desc: false};

const RemoteJob = ({currContent}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange] = useFilter({
        page: 0,
        pageSize: getPageSize('device', DEVICE_PAGE_SIZE_OPTIONS[0]),
        keyword: '',
        sorted: [defaultSort],
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAllRef, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;

        setData({...data, loading: true});

        deviceService.fetchDeviceRemoteJobs({
            startIndex: page * pageSize + 1,
            pageSize,
            searchText: keyword,
            sortColumn: snakeCase(id),
            sortOrder: desc ? 'desc' : 'asc',
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount});
        });
    };

    const onKeywordChange = value => {
        setFilter({...filter, keyword: value, page: 0, sorted: [defaultSort]});
    };

    useEffect(() => {
        setStyle({height: window.innerHeight - heightOffset});
    }, []);

    useEffect(() => {
        fetchData();
    }, [filter]);

    const searchPlaceHolder = t('TABLE_JOB_NAME_P');

    const onAdd = () => {
        console.log("onAdd");

        deviceService.fetchDeviceAddRemoteJobs().then(
            response => {
                fileDownload(response.blob, response.fileName);

                if (response.ok) {
                    toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                    fetchData();
                }
            })
            .catch(error => {
                console.log(error);
                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
            });
    };

    const onReuse = () => {
        if(selectedCnt > 1) {
            toastr.error(t('MESSAGE_DEVICE_JOB_ONE_SELECT_P'));
            return;
        }
        console.log("onReuse");

        const selectedIds = getSelectIds();

        deviceService.fetchDeviceReuseRemoteJobs(selectedIds[0]).then(
            response => {
                fileDownload(response.blob, response.fileName);

                if (response.ok) {
                    toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                    fetchData();
                }

            })
            .catch(error => {
                console.log(error);
                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
            });
    };

    const onEdit = () => {
        if(selectedCnt > 1) {
            toastr.error(t('MESSAGE_DEVICE_JOB_ONE_SELECT_P'));
            return;
        }

        const selectedIds = getSelectIds();

        deviceService.fetchDeviceEditRemoteJobs(selectedIds[0], 'job_edit.jnlp').then(
            response => {
                fileDownload(response.blob, response.fileName);

                if (response.ok) {
                    toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                    fetchData();
                }
            })
            .catch(error => {
                console.log(error);
                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
            });
    };

    const onCancel = () => {
        const cancelIds = getSelectIds();

        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t('TEXT_TITLE_WARNING_P'),
            message: t('MESSAGE_DEVICE_CONFIRM_CANCEL_P'),
            onClickYes: () => handleCancel(cancelIds),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    };

    const getSelectIds = () => {
        return checkBoxRefs.current.filter(checkBox => {
            return checkBox.checked === true;
        }).map(checkBox => checkBox.id);
    };

    const handleCancel = (cancelIds) => {
        console.log("Cancel Ids : " + cancelIds);

        deviceService.fetchDeviceCancelRemoteJobs({ids: cancelIds}).then(
            res => {
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));

                if (res.status === 'Success') {
                    toastr.success(t('MESSAGE_DEVICE_SUCCESS_CANCEL_JOB_P'));
                    fetchData();
                }
            })
            .catch(error => {
                console.log(error);
                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
            });
    };

    const columns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllJob_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAllRef}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={items[row.index].jobId}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs} 
                    />
                );
            },
        },
        {
            Header: t('TABLE_JOB_NAME_P'),
            accessor: 'jobName',
        },
        {
            Header: t('TABLE_JOB_TYPE_P'),
            accessor: 'jobType',
        },
        {
            Header: t('TEXT_STATUS_P'),
            accessor: 'isCanceled',
        },
        {
            Header: t('TABLE_CREATE_USER_P'),
            accessor: 'userId',
        },
        {
            Header: t('TABLE_CREATE_DATE_P'),
            accessor: 'createDate',
            Cell: data => <DeviceRelativeTimeCell value={data.value} />
        },
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'device_tr_group');

    return (
        <div style={{width: '100%', display: currContent === 'I_PLAYER_REMOTE_JOB' ? 'block':'none' }}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id='ADD_REMOTE_JOB' name={t('COM_BUTTON_ADD')} onClick={onAdd}/>
                    <WhiteButton id='REUSE_REMOTE_JOB' name={t('BUTTON_RE_USE_P')} disable={selectedCnt <= 0} onClick={onReuse} />
                    <WhiteButton id='EDIT_REMOTE_JOB' name={t('COM_BUTTON_EDIT')} disable={selectedCnt <= 0} onClick={onEdit} />
                    <WhiteButton id='CANCEL_REMOTE_JOB' name={t('BUTTON_CANCEL_P')} disable={selectedCnt <= 0} onClick={onCancel} />
                </div>
                <div className="rightButton">
                    <SearchBar id="jobSearch" placeholder={searchPlaceHolder} onClickSearch={onKeywordChange} keyword={filter.keyword}/>
                </div>
            </div>
            <div className='device_list_view_wrap'>
                <ReactTable
                    data={items}
                    loading={loading}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns= {columns}
                    showPagination={false}
                    className="-striped -highlight"
                    style={style}
                    manual
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} divide={'device'}/>
            </div>
        </div>
    );
};

export default RemoteJob;