import React, {useEffect, useState} from 'react';
import './TooltipIcon.css';
import {useTranslation} from "react-i18next";

const TooltipIcon = ({message}) => {
    const {t} = useTranslation();

    const [data, setData] = useState({
        visibility: false,
    });

    const onChangeVisible = () => {
        const isVisible = visibility;

        setData({visibility: !isVisible});
    };
    const {visibility} = data;

    return (
        <div style={{display:'inline-block', position:'relative'}}>
            <span className="tooltip_icon" onMouseOver={onChangeVisible} onMouseOut={onChangeVisible}/>
            <div className="tooltip_msg" style={{display: visibility ? '' : 'none'}}>{t(message)}</div>
        </div>
    )
};

export default TooltipIcon;