import React, {useEffect, useState} from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import Select from "../../../selectbox/Select";
import RadioGroup from "../../../radio/RadioGroup";
import './NewFramePopup.css';
import PercentFrame from "../../../schedule/PercentFrame";
import {toastr} from 'helper/toastrIntercept';
import {usePrevious} from "../../../../helper";
import Backend from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import PixelFrame from "../../../schedule/PixelFrame";
import {scheduleService} from "../../../../services";
import TextInput from "../../../input/TextInput";
import {getErrorMessage} from "../../../../helper/responseHandler";
import WhiteButton from "../../../button/WhiteButton";

const NewFramePopup = ({close, reload, originFrame}) => {

    const {t} = useTranslation();

    const [style, setStyle] =  useState({
        displayW: 1920,
        displayH: 1080,
        width: 646,
        height: 365,
        mode: 'percent',
        resolution: '1920*1080'
    })

    const [percentFrame, setPercentFrame] = useState({
        frames: [],
        forceRender : false
    })

    const [pixelFrame, setPixelFrame] = useState({
        frames: [],
        forceRender : false
    })

    const [select, setSelect] = useState({
        id: -1
    })

    const [line, setLine] = useState({
        data : []
    });

    const [isNew, setIsNew] = useState(true)

    useEffect(()=> {

        if (originFrame !== undefined) {

            setIsNew(false);

            const {width, height} = originFrame.resolution;
            if (originFrame.lineInfos !== undefined) {
                // percent frame
                const lines = [];
                originFrame.lineInfos.map(
                    lineInfo => {
                        lines.push(new Line(lineInfo.index, lineInfo.startX, lineInfo.startY, lineInfo.endX, lineInfo.endY, lineInfo.orientationType === 'VERTICAL' ? '1' : '0'))
                    }
                )
                setLine({data: lines});
            } else {
                // pixel frame
                let wrapWidth = 646, wrapHeight = 365, displayW, displayH;
                const resolutionStr = width +'*'+ height;
                switch (resolutionStr) {
                    case '3840*2160' : displayW = 3840; displayH = 2160; wrapWidth = 646; wrapHeight = 365;	break;
                    case '1920*1080' : displayW = 1920; displayH = 1080; wrapWidth = 646; wrapHeight = 365;	break;
                    case '1366*768' : displayW = 1366; displayH = 768; wrapWidth = 646; wrapHeight = 365; break;
                    case '1360*768' : displayW = 1360; displayH = 768; wrapWidth = 646; wrapHeight = 365; break;
                    case '1280*1024' : displayW = 1280; displayH = 1024; wrapWidth = 646; wrapHeight = 365;	break;
                    case '768*1366' : displayW = 768; displayH = 1366; wrapWidth = 230; wrapHeight = 360; break;
                    case '768*1360' : displayW = 768; displayH = 1360; wrapWidth = 230; wrapHeight = 360; break;
                    case '1080*1920' : displayW = 1080; displayH = 1920; wrapWidth = 230; wrapHeight = 360;	break;
                    case '1024*1280' : displayW = 1024; displayH = 1280; wrapWidth = 230; wrapHeight = 360;	break;
                    case '2160*3840' : displayW = 2160; displayH = 3840; wrapWidth = 230; wrapHeight = 360;	break;
                    default : displayW = 1020;displayH = 1080;wrapWidth = 646;wrapHeight = 365;break;
                }
                setStyle({...style, displayH: displayH, displayW : displayW, width: wrapWidth, height: wrapHeight, resolution: resolutionStr, mode : 'pixel'});
                let index = 0;
                const originPixelFrames = [];
                originFrame.frameInfos.map(
                    frameInfo => {
                        const frame = {};
                        frame.frameType = '';
                        frame.frameName = frameInfo.frameName;
                        frame.index = index;
                        frame.oriWidth = frameInfo.width;
                        frame.oriHeight = frameInfo.height;
                        frame.oriX = frameInfo.x;
                        frame.oriY = frameInfo.y;
                        frame.resolution = wrapWidth+'*'+wrapHeight;

                        const positions = getRelativeFrameDimension(frameInfo.x, frameInfo.y, frameInfo.width, frameInfo.height, displayH, displayW, wrapWidth, wrapHeight);
                        frame.x = positions.x;
                        frame.y = positions.y;
                        frame.width = positions.width;
                        frame.height = positions.height;
                        originPixelFrames.push(frame)
                        index++;
                    }
                )
                setPixelFrame({frames: originPixelFrames, forceRender: !pixelFrame.forceRender})
            }
        }
    }, [])

    const getRelativeFrameDimension = (x,y,w,h, displayH, displayW, wrapWidth, wrapHeight) => {
        const rel_x = Math.floor((x*wrapWidth)/displayW);
        const rel_y = Math.floor((y*wrapHeight)/displayH);
        const rel_w = Math.floor((w*wrapWidth)/displayW);
        const rel_h = Math.floor((h*wrapHeight)/displayH);
        return {x: rel_x, y: rel_y, width: rel_w, height : rel_h};
    }

    const prevLineData = usePrevious(line);


    const selectResolution = (e, value)  => {
        let width = 646, height = 365, displayW, displayH;
        switch (value) {
            case '3840*2160' : displayW = 3840; displayH = 2160; width = 646; height = 365;	break;
            case '1920*1080' : displayW = 1920; displayH = 1080; width = 646; height = 365;	break;
            case '1366*768' : displayW = 1366; displayH = 768; width = 646; height = 365; break;
            case '1360*768' : displayW = 1360; displayH = 768; width = 646; height = 365; break;
            case '1280*1024' : displayW = 1280; displayH = 1024; width = 646; height = 365;	break;
            case '768*1366' : displayW = 768; displayH = 1366; width = 230; height = 360; break;
            case '768*1360' : displayW = 768; displayH = 1360; width = 230; height = 360; break;
            case '1080*1920' : displayW = 1080; displayH = 1920; width = 230; height = 360;	break;
            case '1024*1280' : displayW = 1024; displayH = 1280; width = 230; height = 360;	break;
            case '2160*3840' : displayW = 2160; displayH = 3840; width = 230; height = 360;	break;
            default : displayW = 1020;displayH = 1080;width = 646;height = 365;break;
        }
        if (style.mode === 'percent') {
            setPercentFrame({...percentFrame, forceRender : !percentFrame.forceRender});
        } else {
            setPixelFrame({frames: [], forceRender : !pixelFrame.forceRender});
        }
        setStyle({...style, displayH: displayH, displayW : displayW, width: width, height: height, resolution: value});

    }

    const onChangeUsage = (e, value) => {
        setStyle({...style, mode: value});
    }

    const addPixelFrame = (pixel) => {
        const pixelFrames = pixelFrame.frames;
        pixelFrames.push(pixel);
        setPixelFrame({frames: pixelFrames})
    }

    const removePixelFrame = (id) => {
        const pixelFrames = pixelFrame.frames;
        delete pixelFrames[id];
        setPixelFrame({frames: pixelFrames});
    }

    const onSelectFrame = (selectedFrame) => {
        setSelect({id: selectedFrame})
    }

    const onRemoveFrame = (selectedFrame) => {
        setLine({data: line.data.filter((l, index) => index !== selectedFrame)});
    }

    const onHorizontal = () => {
        const {id} = select;
        if (id < 0) {
        } else {
            if (line.data.length > 2) {
                toastr.error(t("MESSAGE_SCHEDULE_FRAME_LIMT_EXCEEDED_P"));
            } else {
                const x = percentFrame.frames[id].x;
                const y = percentFrame.frames[id].y;
                const width = percentFrame.frames[id].width;
                const height = percentFrame.frames[id].height;
                const index = percentFrame.frames.length;

                setLine({data: line.data.concat(new Line(index,  x, Number(Number(y+(height/2))), Number(x+width), Number(Number(y+(height/2))), '0'))});
                setSelect({id: -1})
            }
        }
    }

    const onVertical = () => {
        const {id} = select;
        if (id < 0) {

        } else {
            if (line.data.length > 2) {
                toastr.error(t("MESSAGE_SCHEDULE_FRAME_LIMT_EXCEEDED_P"));
            } else {
                const x = percentFrame.frames[id].x;
                const y = percentFrame.frames[id].y;
                const width = percentFrame.frames[id].width;
                const height = percentFrame.frames[id].height;
                const index = percentFrame.frames.length;

                setLine({data: line.data.concat(new Line(index,  Number(Number(x+(width/2))), y, Number(Number(x+(width/2))), Number(y+height), '1'))});
                setSelect({id: -1})
            }
        }
    }

    const getIntersectionPointsWithRect = (st, ed , type, rectObj) => {
        let startIx = -1;
        let startIy = -1;
        let endIx = -1;
        let endIy = -1;
        if(type == '0'){
            if(st.x < ed.x){
                if((st.x <= rectObj.x) && (ed.x > rectObj.x)){
                    if( (rectObj.y<st.y) && (st.y<(rectObj.y+rectObj.height)) ){
                        startIx = rectObj.x;
                        startIy = st.y;
                    }
                }
                const x2 = rectObj.x + rectObj.width - 1;
                if((ed.x>=x2)&& (startIx != -1)){
                    endIx = x2;
                    endIy = st.y;
                }
            }else{
                if((st.x > rectObj.x) && (ed.x <= rectObj.x)){
                    if( (rectObj.y<st.y) && (st.y<(rectObj.y+rectObj.height))){
                        startIx = rectObj.x;
                        startIy = st.y;
                    }
                }
                const x2 = rectObj.x + rectObj.width - 1;
                if((st.x>=x2)&& (startIx != -1)){
                    endIx = x2;
                    endIy = st.y;
                }
            }
        }
        if(type == '1'){
            if(st.y < ed.y){
                if((st.y <= rectObj.y) && (ed.y > rectObj.y)){
                    if( (rectObj.x<st.x) && (st.x<(rectObj.x+rectObj.width)) ){
                        startIx = st.x;
                        startIy = rectObj.y;
                    }
                }
                const y2 = rectObj.y + rectObj.height - 1;
                if((ed.y>=y2)&& (startIx != -1)){
                    endIx = st.x;
                    endIy = y2;
                }
            }else{
                if((st.y > rectObj.y) && (ed.y <= rectObj.y)){
                    if( (rectObj.x<st.x) && (st.x<(rectObj.x+rectObj.width))){
                        startIx = st.x;
                        startIy = rectObj.y;
                    }
                }
                const y2 = rectObj.y + rectObj.height - 1;
                if((st.y>=y2)&& (startIx != -1)){
                    endIx = st.x;
                    endIy = y2;
                }
            }
        }
        return {x1 : startIx, y1 : startIy, x2: endIx, y2: endIy};
    }

    const updateFrame =  (id, frame) => {
        const {frames} = pixelFrame;
        const updateFrames = frames.map(
            (f, index) => index === id ? frame : f
        )
        setPixelFrame({...pixelFrame, frames: updateFrames});
    }


    const dialogProps ={title: t("MIS_SID_20_CUSTOM_FRAME"), closeOnEscape : true, width :680, height :600 , modal:true, position: {x:700/2*-1, y:730/2 * -1}, onClose: ()=>close()};

    useEffect(()=>{
        if (line.data.length > 0 && line.data.length !== prevLineData.data.length) {
            let frameData = [], lineIndex = 0;
            const lineData = line.data;
            frameData.push(new frame(0, '', 'frame', 0, 0, 100, 100, false));
            lineData.map(
                data => {
                    const x1 = data.sx;
                    const y1 = data.sy;
                    const x2 = data.ex;
                    const y2 = data.ey;
                    const type = data.type;
                    const customFrame = frameData.slice();
                    frameData = [];
                    try {
                        customFrame.map(
                            custom => {
                                const x = custom.x;
                                const y = custom.y;
                                const width = Math.floor(custom.width * (100 * 0.01));
                                const height = Math.floor(custom.height * (100 * 0.01));
                                const st = {x: x1, y: y1};
                                const ed = {x: x2, y: y2};
                                const pts = getIntersectionPointsWithRect(st, ed, type, custom);
                                const index = frameData.length;
                                if (pts.x1 < 0 || pts.y1 < 0 || pts.x2 < 0 || pts.y2 < 0) {
                                    frameData.push(new frame(index, '', 'frame', x, y, width, height, false, lineIndex, x, y, custom.width, custom.height));
                                } else {
                                    if (type == '1') {
                                        frameData.push(new frame(index, 'left', 'frame', x, y, (width - (pts.x1 - x)), height, false, lineIndex, x, y, (custom.width - (x1 - x)), custom.height));
                                        frameData.push(new frame(index+1, 'right', 'frame', pts.x1, y, (width - (pts.x1 - x)), height, false, lineIndex, x1, y, (custom.width - (x1 - x)), custom.height));
                                    } else {
                                        frameData.push(new frame(index, 'top', 'frame', x, y, width, (height - (pts.y1 - y)), false, lineIndex, x, y, custom.width, (custom.height - (y1 - y))));
                                        frameData.push(new frame(index+1, 'bottom', 'frame', x, pts.y1, width, (height - (pts.y1 - y)), false, lineIndex, x, y1, custom.width, (custom.height - (y1 - y))));
                                    }
                                }
                            }
                        )
                        lineIndex++;
                    } catch (e) {
                        console.log('error ',e);
                    }

                }
            )
            if (frameData.length  > 0) {
                setPercentFrame({...percentFrame, frames: frameData});
            }
        } else {
            const frameData = [];
            frameData.push(new frame(0, 'frame', 'frame', 0, 0, 100, 100, true, 0, 0, 0, 100, 100));
            setPercentFrame({...percentFrame, frames: frameData});
        }
    }, [line]);

    const saveNewFrame = () => {

        const data = {};
        let valid = true;
        const resolutionArray = style.resolution.split('*');
        data.resolution = {width: parseInt(resolutionArray[0], 10), height: parseInt(resolutionArray[1], 10)}
        data.templateName = "CUSTOM";


        if (style.mode === 'pixel') {
            data.frameInfos = [];
            if (pixelFrame.frames !== undefined && pixelFrame.length === 0) {
                const frame = {};
                frame.x = 0;
                frame.y = 0;
                frame.width = data.resolution.width;
                frame.height = data.resolution.height;
                frame.isSingleFrame = true;
                frame.index= 1;
                data.frameInfos.push(frame);
            } else {
                let index = 1;
                for (const f of pixelFrame.frames) {
                    if (f) {
                        const frame = {};
                        frame.isSingleFrame = false;
                        frame.index = index;
                        frame.x = f.oriX;
                        frame.y = f.oriY;
                        frame.width = f.oriWidth;
                        frame.height = f.oriHeight;
                        index++;
                        data.frameInfos.push(frame);
                    }
                }
            }
            data.templateType = "CUSTOM";

            if (data.frameInfos.length > 4) {
                toastr.error(t("MESSAGE_SCHEDULE_FRAME_LIMT_EXCEEDED_P"))
                valid = false;
            }
        } else if (style.mode === 'percent') {

            /*data.templateType = 'CUSTOM_FIXED'*/
            data.templateType = 'FIXED'
            data.lineInfos  = [];
            data.frameInfos = [];

            if (line !== undefined && line.data !== undefined && line.data.length > 0) {
                let index = 1;
                for (const l of line.data) {
                    const lineData = {}
                    lineData.index = index;
                    lineData.endX = l.ex;
                    lineData.endY = l.ey;
                    lineData.startX = l.sx;
                    lineData.startY = l.sy;
                    lineData.orientationType = l.type === '1' ? 'VERTICAL' : 'HORIZONTAL'
                    data.lineInfos.push(lineData);
                    index++;
                }
                if (index > 4) {
                    toastr.error(t("MESSAGE_SCHEDULE_FRAME_LIMT_EXCEEDED_P"))
                    valid = false;
                }
            }
        }
        if (valid) {
            if (isNew) {
                scheduleService.createContentScheduleNewFrame(data).then(
                    res=> {
                        if (res) {
                            toastr.success(t("COM_TEXT_SUCCESS_P"));
                        }
                    }
                ).catch(e=> toastr.error(getErrorMessage(e)))
                    .then(()=>{
                        reload()
                    })
                    .finally(()=>{
                        close()
                    })
            } else {
                scheduleService.updateContentScheduleNewFrame(originFrame.templateId, data).then(
                    res=> {
                        if (res) {
                            toastr.success(t("COM_TEXT_SUCCESS_P"));
                        }
                    }
                ).catch(e=> toastr.error(getErrorMessage(e)))
                    .then(()=>{
                        reload()
                    })
                    .finally(()=>{
                        close()
                    })
            }
        }
    }

    return (
        <MISDialog
            dialog={dialogProps}
            buttons = {{
                rightButtons: [
                    {title : t("COM_BUTTON_SAVE"), id: 'createNewFrameSave', onClick: ()=> saveNewFrame()},
                    {title : t("BUTTON_CANCEL_P"), id: 'createNewFrameCancel', onClick: ()=> close()},
                ]
            }}
        >
            <div className={'new_frame_wrap'}>
                <div>
                    <table>
                        <colgroup>
                            <col width="182px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>{t("TEXT_TITLE_DISPLAY_RESOLUTION_P")}</th>
                            <td>
                                <Select
                                    value={style.resolution}
                                    selects={[
                                        {title: '3840 x 2160 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE')+')', value: '3840*2160', multiLang: false},
                                        {title: '1920 x 1080 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE')+')', value: '1920*1080', multiLang: false},
                                        {title: '1366 x 768 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE')+')', value: '1366*768', multiLang: false},
                                        {title: '1360 x 768 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE')+')', value: '1360*768', multiLang: false},
                                        {title: '1280 x 1024 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE')+')', value: '1280*1024', multiLang: false},
                                        {title: '768 x 1366 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT')+')', value: '768*1366', multiLang: false},
                                        {title: '768 x 1360 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT')+')', value: '768*1360', multiLang: false},
                                        {title: '1080 x 1920 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT')+')', value: '1080*1920', multiLang: false},
                                        {title: '1024 x 1280 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT')+')', value: '1024*1280', multiLang: false},
                                        {title: '2160 x 3840 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT')+')', value: '2160*3840', multiLang: false}
                                    ]}
                                    onChange={selectResolution}
                                    multiLang={false}
                                    optionStyle={{zIndex: 5, marginTop:-65}}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{t("TEXT_TITLE_FRAME_LAYOUT_TYPE_P")}</th>
                            <td>
                                <RadioGroup
                                    propertyName={"isContentSyncOn"}
                                    selects={[
                                        {title: "TEXT_FULL_FRAME_LAYOUT_P", value: 'percent'},
                                        {title: "TEXT_CUSTOM_LAYOUT_P", value: 'pixel'}
                                    ]} value={style.mode} onChange={onChangeUsage} multiLang={true}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{display:'inline-block',textAlign:'right'}}>
                    <div id="percentFrameWrap" style={{display: style.mode === 'percent' ? '':'none'}}>
                        <button className="circle_btn base horizontal float_l" onClick={onHorizontal}>
                            <span style={{marginTop:7,marginLeft:5}}></span>
                        </button>
                        <button className="circle_btn base vertical float_l" onClick={onVertical}>
                            <span style={{marginTop:7,marginLeft:5}}></span>
                        </button>
                    </div>

                </div>

                {
                    style.mode === 'percent' &&
                        <PercentFrame frames={percentFrame.frames} wrapHeight={style.height} wrapWidth={style.width} onSelectFrame={onSelectFrame} selectedFrame={select.id} removeFrame={onRemoveFrame}/>
                }

                {
                    style.mode === 'pixel' &&
                        <DndProvider backend={Backend}>
                            <PixelFrame frames={pixelFrame.frames} forceRender={pixelFrame.forceRender} wrapHeight={style.height} wrapWidth={style.width} displayW={style.displayW} displayH={style.displayH} onSelectFrame={onSelectFrame} selectedFrame={select.id} addPixelFrame={addPixelFrame} updateFrame={updateFrame} removeFrame={removePixelFrame}/>
                        </DndProvider>
                }

                {
                    style.mode === 'percent' &&
                    <div className="detail_view mini_p mt14" id="framePositionWrap">
                        <table className="positionTable">
                            <tbody>
                            <tr>
                                <td></td>
                                <td>{t("TEXT_LEFT_P")}</td>
                                <td>{t("COM_DID_ADMIN_URGENT_UPPER")}</td>
                                <td></td>
                                <td>{t("COM_IDS_DS_PREVIEW_WIDTH")}</td>
                                <td>{t("COM_IDS_HEIGHT")}</td>
                            </tr>
                            {
                                style.mode === 'percent' &&
                                <tr>
                                    <td>{t("TEXT_POSITION_P")}</td>
                                    <td>
                                        <TextInput width={50} disabled={select.id > -1 && percentFrame.frames[select.id] !== undefined && percentFrame.frames[select.id].oriX !== '' ? false:true} value={select.id > -1 && percentFrame.frames[select.id] !== undefined ? percentFrame.frames[select.id].oriX : ''}/>
                                    </td>
                                    <td>
                                        <TextInput width={50} disabled={select.id > -1 && percentFrame.frames[select.id] !== undefined && percentFrame.frames[select.id].oriY !== '' ? false:true} value={select.id > -1 && percentFrame.frames[select.id] !== undefined ? percentFrame.frames[select.id].oriY : ''}/>
                                    </td>
                                    <td>{t("COM_TEXT_SIZE_P")}</td>
                                    <td>
                                        <TextInput width={50} disabled={select.id > -1 && percentFrame.frames[select.id] !== undefined && percentFrame.frames[select.id].oriWidth !== '' ? false:true} value={select.id > -1 && percentFrame.frames[select.id] !== undefined ? percentFrame.frames[select.id].oriWidth : ''}/>
                                    </td>
                                    <td>
                                        <TextInput width={50} disabled={select.id > -1 && percentFrame.frames[select.id] !== undefined && percentFrame.frames[select.id].oriHeight !== '' ? false:true} value={select.id > -1 && percentFrame.frames[select.id] !== undefined ? percentFrame.frames[select.id].oriHeight : ''}/>
                                    </td>
                                    <td>
                                        <WhiteButton name={t("BUTTON_APPLY")} disable={true}/>
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                }

            </div>
        </MISDialog>
    )
}
export default NewFramePopup;

function frame(index,frameType, frameName, x, y, width, height, type, lineIndex, oriX, oriY, oriWidth, oriHeight){
    this.index = index;
    this.frameType = frameType;
    this.frameName = frameName;
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
    this.type = type;
    this.lineIndex = lineIndex;

    this.oriX = oriX;
    this.oriY = oriY;
    this.oriWidth = oriWidth;
    this.oriHeight = oriHeight;
}

function Line(index, sx, sy, ex, ey, type) {
    this.index = index;
    this.sx = sx;
    this.sy = sy;
    this.ex = ex;
    this.ey = ey;
    this.type = type;
}

