import React, {memo, useEffect, useMemo, useState} from 'react';
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {contentService} from '../../services';
import Checkbox from "../../components/checkbox/Checkbox";
import SupportedDeviceIcon from "../../components/icon/SupportedDeviceIcon";
import Size from "../../components/utils/Size";
import {useDispatch} from "react-redux";
import {commonConstants, contentConstants} from "../../constants";
import ContentThumbnail from "../../components/image/ContentThumbnail";
import {useTranslation} from 'react-i18next';
import Pagination from "../../components/table/Pagination";
import {toastr} from 'helper/toastrIntercept';
import {menuAction, popupAction} from "../../actions";
import DeviceRelativeTimeCell from "../../components/device/DeviceRelativeTimeCell";
import {getPageSize, useCheckRefForContent, useFilter} from "../../helper";
import {useMISOpt} from "../../components/misopt";
import {snakeCase} from "lodash";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../helper/tables";

const ContentTemplate = (props) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {getAuthority} = useMISOpt();
    const [authority, setAuthority] = useState({});

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        force: false,
        page: 0,
        pageSize: getPageSize('content_template'),
        keyword: '',
        groupId: props.groupId !== undefined ? props.groupId : '',
        sorted: [{id: 'lastModifiedDate', desc: true}],
        reload: false,
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0
    });

    const [style, setStyle] = useState({
        height: '500px'
    });

    const [didMount, setMount] = useState(false);

    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted, groupId, sorted: [{id, desc}]} = filter;

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt, initData, toggleSelectAllForCardView, toggleCard, toggleRowWithIndex] = useCheckRefForContent(items);

    const fetchData = () => {
        contentService.fetchContentFilter({
            contentTypes: ["TLFD"],
            groupId: groupId,
            startIndex: (page * pageSize) + 1,
            pageSize: pageSize,
            groupType: 'GROUPED',
            sortColumn: snakeCase(id).toUpperCase(),
            sortOrder: desc === true ? 'DESC' : 'ASC',
        })
            .then(res => {
                setData({
                    ...data,
                    loading: false,
                    items: res.items,
                    totalCount: res.totalCount
                });
            });
    };

    const deleteContent = (id) => {
        const requestBody = {
            contentIds: getCheckedContentId(),
            deleteMethod: contentConstants.DELETE_FORCE
        };

        contentService.deleteContentById(requestBody)
            .then(res => {
                if(res.items.status === 'undelete'){
                    //show check popup
                }else if(res.status === 'Success'){
                    toastr.success(t('MIS_MESSAGE_CONTENT_DELETE_CONTENT_FROM_PLAYLIST_SUCCESS_P'));
                    dispatch(menuAction.reloadGroup("CONTENT_TEMPLATE_BY_FOLDER"));
                }
                fetchData();
            }).catch(error => {
                if(error.errorCode === '403001'){
                    toastr.error(t('ERROR_NO_ROLE'));
                }
            }).finally(() => {
                dispatch(popupAction.closePopup(id));
        });
    };

    const getCheckedContentId = () => {
        return selected.current.map(key => items[key].contentId);
    };

    const moveContent = (groups) => {
        const checkedContentIds = {
            contentIds: getCheckedContentId()
        };

        contentService.movedContent(checkedContentIds, groups[0].groupId).then(res => {
            if(res.status == 'Success') {
                toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"));
                dispatch(menuAction.reloadGroup("CONTENT_TEMPLATE_BY_FOLDER", groupId));
            }
            fetchData();
        }).catch(error => {
            let errorMessage = error.errorMessage;
            switch(error.errorCode) {
                case '403001':
                    errorMessage = t('ERROR_NO_ROLE');
                    break;
                default:
                    errorMessage = t('COM_IDS_MSG_UNEXPEXTED_ERROR');
                    break;
            }
            toastr.error(errorMessage);
        }).finally(() => {
            dispatch(popupAction.closePopup("CONTENT_MOVE"));
        });
    };

    const moveContentPopup = (id) => {
        const params = {
            groupType : contentConstants.TEMPLATE_GROUP
        };
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_GROUP_POPUP,
            id: id,
            mode: 'content',
            save: moveContent,
            params: params,
            close: () => dispatch(popupAction.closePopup(id))
    }));
    };

    const deleteContentPopup = (id) => {
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: id,
            title: t("COM_BUTTON_DELETE"),
            message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
            onClickYes: () => deleteContent(id),
            onClose: () => dispatch(popupAction.closePopup(id))
        }));
    };

    const renderModifiedDate = (lastModifiedDate) => {
        return <DeviceRelativeTimeCell value={lastModifiedDate} />
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'AllContent_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox
                        id={items[row.index].contentId}
                        index={row.index}
                        classname="table"
                        name="check"
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                )
            },
            width : 46,
            sortable: false,
            resizable: false
        },
        {
            Header: t("DID_MAIN_CONTENT"),
            width: 127,
            accessor: 'thumbFileId',
            sortable: false,
            Cell: (props) => <div><ContentThumbnail id={props.original.thumbFileId} width={100} height={56} textAlign={true} /></div>
        },
        {
            Header: t("TEXT_CONTENT_NAME_P"),
            accessor: "contentName",
            width: 479,
            Cell: (props) => <span title={props.original.contentName}>{props.original.contentName}</span>
        },
        {
            Header: t("COM_TV_SID_DETAILS"),
            width: 220,
            sortable: false,
            Cell: props => <div><span>{props.original.mediaType}</span><Size value={", "} size={props.original.totalSize} /></div>
        },
        {
            Header: t("COM_MAPP_SID_SUPPORTED_DEVICES"),
            accessor: "deviceType",
            width: 260,
            sortable: false,
            Cell: props => <SupportedDeviceIcon
                deviceType={props.original.deviceType}
                deviceTypeVersion={props.original.deviceTypeVersion}
                mediaType={props.original.mediaType}
                fileType={props.original.mainFileExtension}
            />
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "lastModifiedDate",
            width: 182,
            Cell: props => renderModifiedDate(props.original.lastModifiedDate)

        },
        {
            Header: t("TEXT_CREATOR_P"),
            accessor: "creatorId",
            width: 120,
        }
    ], [items]);

    useEffect(() => {
        if(didMount){
            setFilter({...filter, groupId:props.groupId});
        }
    }, [props.groupId]);

    useEffect(() => {
        setStyle({height: window.innerHeight - 198});
        setAuthority(getAuthority('CONTENT_PLAYLIST_RULESET'));
        fetchData();
        setMount(true);
    }, []);

    useEffect(() => {
        if(didMount){
            fetchData();
        }
    }, [filter]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow,'content_tr_group');

    return (
        <div style={{width: '100%',display: props.currContent === 'CONTENT_TEMPLATE_BY_FOLDER' ? 'block':'none'}}>
            <div className="contents_buttonWrap overflow_hidden">
                <div className="leftButton">
                    <WhiteButton id={"CONTENT_DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1}
                                 onClick={()=>deleteContentPopup("CONTENT_DELETE")}
                                 authority={authority.CREATE || authority.MANAGE || authority.LOCK}/>
                    <WhiteButton id={"CONTENT_MOVE"} name={t("BUTTON_MOVE_P")} disable={selectedCnt < 1}
                                 onClick={()=>moveContentPopup('CONTENT_MOVE')}
                                 authority={authority.CREATE || authority.MANAGE || authority.LOCK}/>
                </div>
            </div>
            <div className='content_list_view'>
                <MagicInfoTable
                    data={items}
                    page={page}
                    pages={pages}
                    loading={loading}
                    minRows={0}
                    sorted={sorted}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    showPagination={false}
                    columns={columns}
                    className="-striped -highlight"
                    manual
                    style={style}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize}
                            pageSizeOptions={contentConstants.PAGE_SIZE_OPTIONS} onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange} divide={'content_template'} />
            </div>
        </div>
    );
};


export default memo(ContentTemplate);
