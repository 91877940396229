import React, {useEffect, useState} from 'react';
import {settingService} from "../../../services";
import {commonConstants, contentConstants} from "../../../constants";
import {useTranslation} from "react-i18next";
import './TagValueSelectCell.css';
import {toastr} from 'helper/toastrIntercept';
import {useDispatch} from "react-redux";
import {popupAction} from "../../../actions";
import {getErrorMessage} from "../../../helper/responseHandler";

const TagValueSelectCell = ({mode, tagListType, originalTag, tagList, selectedTagList, onChangeCondition, onChangeTagList, useAdd = false, useSelectAll = true, outSide}) => {
    const isSelected = selectedTagList.find(tag => tag.tagId === originalTag.tagId);
    const selectTag = tagList.find((tag) => tag.tagId === originalTag.tagId);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [data, setData] = useState({
        tagConditionTitle: undefined,
        addCondition: {
            tagId: '',
            tagCondition: '',
        }
    });

    const {tagConditionTitle, addCondition} = data;

    useEffect(() => {
        if (tagConditionTitle === undefined && tagListType === 'variable') {
            setData({...data, tagConditionTitle: isSelected && isSelected.tagConditionTitle}); // DF200407-00746
            if (!selectTag.tagConditionTitle && isSelected && isSelected.tagCondition) {
                selectTag.tagConditionTitle = isSelected.tagCondition;
            }
            if (isSelected && selectTag.contentTagConditions) {
                selectTag.contentTagConditions.map((condition) => {
                    if (condition.tagConditionId === isSelected.tagConditionId && selectTag.tagConditionTitle === condition.tagCondition) {
                        condition.checkCondition = true;
                    }
                })
            }
        }
    },[]);


    const disableShowConditionOfOtherCell = (isSelected) => {
        let updatedTagList = tagList.map(tag => {
            if (tag.tagId === isSelected.tagId) {
                tag.showCondition = selectTag.showCondition;
            } else {
                tag.showCondition = false;
            }

            return tag;
        });

        onChangeTagList && onChangeTagList(updatedTagList);
    };

    const onClickCondition = (isSelected) => {
        if(selectTag.contentTagConditions === undefined) {
            selectTag.contentTagConditions = [];
        }

        if (!selectTag.showCondition && selectTag.tagType === contentConstants.TAG_TYPE_TEXT) {
            dispatch(popupAction.addPopup({id: 'FETCH_TAG_CONDITION_LOADING', type: commonConstants.LOADING_POPUP}));
            settingService.fetchContentTagConditions(originalTag.tagId).then(
                res => {
                    if(res.items.length > 0) {
                        res.items.map((item) => {
                            if(selectTag.contentTagConditions.find(condition => condition.tagConditionId === item.tagConditionId) === undefined) {
                                if (item.tagConditionId !== -1 || tagListType !== 'variable' && item.tagConditionId === -1) {
                                    if (mode === 'playlistGrid' || mode === 'playlist') {
                                        selectTag.contentTagConditions.push({
                                            tagId: item.tagId,
                                            tagConditionId: item.tagConditionId,
                                            tagCondition: item.tagCondition,
                                            checkCondition:  isSelected.tagConditions && isSelected.tagConditions.find(condition => condition.conditionId === item.tagConditionId) ? true : false
                                        });
                                    } else {
                                        const checkTagCondition = isSelected.contentTagConditions && isSelected.contentTagConditions.find(condition => condition.tagConditionId === item.tagConditionId);
                                        selectTag.contentTagConditions.push({
                                            tagId: item.tagId,
                                            tagConditionId: item.tagConditionId,
                                            tagCondition: item.tagCondition,
                                            checkCondition:  checkTagCondition && checkTagCondition.checkCondition
                                        });
                                    }
                                }
                            }
                        });
                        selectTag.showCondition = !selectTag.showCondition ;
                        selectTag.checkAll = (res.items.length === selectTag.contentTagConditions.filter((condition)=> condition.checkCondition).length);
                    } else {
                        if(selectTag.contentTagConditions === undefined || selectTag.contentTagConditions.length === 0) {
                            selectTag.contentTagConditions.push({
                                tagId: isSelected.tagId,
                                tagConditionId: 'none',
                                tagCondition: t('TEXT_NONE_P'),
                                checkCondition: false
                            });
                        }
                        selectTag.showCondition = !selectTag.showCondition ;
                    }
                    setData({...data});

                    //Close the tagCondition list of other cells
                    disableShowConditionOfOtherCell(isSelected);
                }
            ).finally(() => dispatch(popupAction.closePopup('FETCH_TAG_CONDITION_LOADING')));
        } else {
            if(selectTag.showCondition !== undefined) {
                selectTag.showCondition = !selectTag.showCondition;
            }
            setData({...data});
        }

        disableShowConditionOfOtherCell(isSelected);
    };

    const onClickConditionCheck = (selectConditionId, selectTagId) => {
        const selectedConditionId = parseInt(selectConditionId.toString());

        let conditionTitle = '';

        const isCheckCondition = selectTag.contentTagConditions.find(condition => condition.tagConditionId === selectedConditionId).checkCondition ? true : false;

        //When click tag checkbox in tagCellList, then update checkCondition for selectTag here
        selectTag.contentTagConditions.find(condition => condition.tagConditionId === selectedConditionId).checkCondition = !isCheckCondition;

        if(tagListType === 'variable') {
            selectTag.contentTagConditions.map(condition => {
                if(condition.tagConditionId !== selectedConditionId) {
                    condition.checkCondition = false;
                }
            });
        }

        let selectConditionCount = 0;

        selectTag.contentTagConditions.map((condition, index) => {
            if(condition.checkCondition) {
                selectConditionCount++;

                conditionTitle += condition.tagCondition === 'NOT_ASSIGN' ? t('COM_DID_VWL_NOT_ASSIGNED') : condition.tagCondition;
                conditionTitle += ',';
            }
            if (index === (selectTag.contentTagConditions.length - 1)) {
                conditionTitle = conditionTitle.substring(0, conditionTitle.lastIndexOf(','));
            }
        });

        selectTag.checkAll = (selectConditionCount === selectTag.contentTagConditions.length);

        setData({...data, tagConditionTitle: conditionTitle});

        onChangeTagCondition(conditionTitle);

    };

    const onChangeTagCondition = (conditionTitle) => {
        selectTag.tagConditionTitle = conditionTitle;
        let updatedSelectedTagList = selectedTagList.map(tag => {
            if(tag.tagId === selectTag.tagId) {
                return {...tag, ...selectTag};
            } else {
                return tag;
            }
        });

        onChangeCondition(updatedSelectedTagList, selectTag);
    };

    const onClickAllConditionCheck = (tagId) => {
        const isCheckAll = selectTag.checkAll;

        selectTag.contentTagConditions.forEach(condition => {
            if(isCheckAll) {
                onClickConditionCheck(condition.tagConditionId, tagId);
            } else if(!condition.checkCondition) {
                onClickConditionCheck(condition.tagConditionId, tagId);
            }
        });
        selectTag.checkAll = isCheckAll === undefined || isCheckAll === false;
    };

    const onChangeConditionAdd = (e, tagId) => {
        setData({
            ...data,
            addCondition: {
                tagId: tagId,
                tagCondition: e.target.value,
            }
        })
    };

    const onClickConditionAdd = (tagId) => {
        if (addCondition.tagCondition === "" || addCondition.tagCondition === '') {
            toastr.error(t('MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P'));
            return;
        }

        const requestBody = {tagId: addCondition.tagId, tagCondition: addCondition.tagCondition};

        settingService.addTagCondition(tagId, requestBody).then(
            res => {
                selectTag.checkAll = false;

                if(selectTag.contentTagConditions) {
                    selectTag.contentTagConditions.push({
                        tagId: res.items.tagId,
                        tagConditionId: res.items.tagConditionId,
                        tagCondition: res.items.tagCondition,
                        checkCondition: false,
                    });
                }

                setData({
                    ...data,
                    addCondition: {
                        tagId: '',
                        tagCondition: '',
                    }
                });
            }
        ).catch(error => {
            if(error.errorCode === '500003') {
                toastr.error(getErrorMessage(error, error.errorMessage));
            }
        });
    };

    const onBlurTagValueNumber = (event) => {
        if(selectTag.contentTagConditions === undefined) {
            selectTag.contentTagConditions = [];
            selectTag.contentTagConditions.push({
                tagId: isSelected.tagId, tagConditionId: 'none', tagCondition: '', checkCondition: true
            });
        }

        if(selectTag.contentTagConditions.length > 0){
            let tagConditionTitle = event.target.value;
            selectTag.contentTagConditions[0].tagCondition = tagConditionTitle;
            onChangeTagCondition(tagConditionTitle);
        }
    };

    const onChangeTagValueNumber = (event) => {
        let tagConditionTitle = event.target.value.trim().replace(',', '');
        if (selectTag.tagType === contentConstants.TAG_TYPE_NUMBER) {
            const regex = /^([0-9]*)$/;

            const str = tagConditionTitle.trim();

            if (!regex.test(str)) {
                toastr.error(t('COM_TV_SID_HOTEL_ONLY_NUMBERS_ARE_ALLOWED'));
                return;
            }
        }
        selectTag.tagConditionTitle = tagConditionTitle;

        if(selectTag.contentTagConditions.length === 0) {
            selectTag.contentTagConditions.push({
                tagId: selectTag.tagId,
                tagCondition: tagConditionTitle
            });
        } else {
            selectTag.contentTagConditions[0] = {
                tagId: selectTag.tagId,
                tagCondition: tagConditionTitle
            };
        }

        setData({
            ...data,
            tagConditionTitle: tagConditionTitle,
            addCondition: {
                tagId: selectTag.tagId,
                tagCondition: tagConditionTitle,
            }
        });
    };

    const onClickRadioCheck = (isCheck) => {
        selectTag.checkAll = isCheck;
        selectTag.contentTagConditions[0].tagConditionId = isCheck ? -2 : -3;
        selectTag.contentTagConditions[0].tagCondition = isCheck.toString();

        let tagConditionTitle = isCheck ? t("MIS_TEXT_TRUE_P") : t("MIS_TEXT_FALSE_P");
        setData({...data, tagConditionTitle: tagConditionTitle});
        onChangeTagCondition(tagConditionTitle);
    };

    return (
        <div style={{width: 150, height: 28}} ref={outSide}>
            {
                selectTag.tagType === contentConstants.TAG_TYPE_NUMBER &&
                    <input id={originalTag.tagId}
                           className='tag_condition_input'
                           disabled={!isSelected}
                           style={{width: mode ==='playlistGrid'|| mode=== 'playlist' ? 300 : mode === 'popup' ? '150px' : '78px'}}
                           onBlur={(event) => onBlurTagValueNumber(event)}
                           onChange={(event) => onChangeTagValueNumber(event)}
                           value={selectTag ? selectTag.tagConditionTitle : ''}
                           placeholder={mode=== 'playlistGrid' ? "ex) 1-10, 1, 11": ""}
                    />
            }
            {
                selectTag.tagType !== contentConstants.TAG_TYPE_NUMBER &&
                    <button className='tag_condition_btn'
                            onClick={() => onClickCondition(isSelected)}
                            style={{width: mode ==='playlistGrid'|| mode=== 'playlist' ? 300 : 150, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                            disabled={!isSelected}>
                    <span className='tag_condition_title'>{selectTag ? selectTag.tagConditionTitle : ''}
                        <span className="arrow"></span>
                    </span>
                    </button>
            }
            {
                selectTag.showCondition &&
                <div className={mode==='playlist' ? "tag_condition_option_list playlist" : "tag_condition_option_list"}>
                    {
                        selectTag.tagType === contentConstants.TAG_TYPE_TEXT &&  //Text
                        <ul id={selectTag.tagId}>
                            {
                                selectTag.contentTagConditions && selectTag.contentTagConditions.map((condition) => {
                                    return (condition.tagConditionId === 'none' ?
                                        <li>{t('TEXT_NONE_P')}</li>
                                       :
                                        <li id={condition.tagConditionId} key={condition.tagConditionId} rel="-1">
                                            <input type="checkbox"
                                                   id={'checkCondition_' + condition.tagConditionId}
                                                   className={condition.checkCondition ? "on" : ''}
                                            />
                                            <label
                                                id={condition.tagConditionId}
                                                onClick={(event) => onClickConditionCheck(event.target.id, selectTag.tagId)}
                                                style={{textIndent: 0}}>{condition.tagCondition}</label>
                                        </li>
                                    )
                                })
                            }
                            {
                                selectTag.contentTagConditions.length === 0 &&
                                <li>{t('TEXT_NONE_P')}</li>
                            }
                            {  useSelectAll && selectTag.contentTagConditions[0].tagConditionId !== 'none' &&
                                <li key='-1' rel="-1">
                                    <input type="checkbox"
                                           id={'checkCondition_all_' + selectTag.tagId}
                                           className={selectTag.checkAll ? 'on' : ''}
                                    />
                                    <label
                                        id={'checkCondition_all_' + selectTag.tagId}
                                        onClick={() => onClickAllConditionCheck(selectTag.tagId)}
                                        style={{textIndent: 0}}>{t('BUTTON_ALL_SELECT_P')}</label>
                                </li>
                            }
                            {
                                useAdd &&
                                <li key='-10' rel="-10" style={{display: 'flex'}}>
                                    <input type="text"
                                           id={'checkCondition_add_' + selectTag.tagId}
                                           className='checkCondition_add'
                                           value={addCondition.tagId === selectTag.tagId ? addCondition.tagCondition : ''}
                                           style={{
                                               display: 'block',
                                               width: '110px'
                                           }}
                                           onChange={(event) => onChangeConditionAdd(event, selectTag.tagId)}
                                           maxLength={50}
                                    />
                                    <button
                                        id={'checkCondition_add_btn_' + selectTag.tagId}
                                        className='base'
                                        onMouseDown={() => onClickConditionAdd(selectTag.tagId)}
                                    >
                                        {/*// style={{width: '35px'}}*/}
                                        {/*// >*/}
                                        {t('COM_BUTTON_ADD')}
                                    </button>
                                </li>
                            }
                        </ul>
                    }
                    {
                        selectTag.tagType === contentConstants.TAG_TYPE_BOOLEAN &&  //True or False
                        <ul>
                            <li key={t('MIS_TEXT_TRUE_P')} rel={true}>
                                <input type="radio" checked={selectTag.contentTagConditions[0].tagCondition.toString() === 'true'? 'checked' : ''} value={true}/>
                                <label className="mr29" id={'checkCondition_' + selectTag.contentTagConditions[0].tagCondition + '_true'} onClick={() => {onClickRadioCheck(true)}}>
                                    {t("MIS_TEXT_TRUE_P")}
                                </label>
                            </li>
                            <li key={t('MIS_TEXT_FALSE_P')} rel={false}>
                                <input type="radio" checked={selectTag.contentTagConditions[0].tagCondition.toString() === 'false' ? 'checked' : ''} value={false}/>
                                <label id={'checkCondition_' + selectTag.contentTagConditions[0].tagCondition + 'false'} onClick={() => {onClickRadioCheck(false)}}>
                                    {t("MIS_TEXT_FALSE_P")}
                                </label>
                            </li>
                        </ul>
                    }
                </div>
            }
        </div>
    );
};

TagValueSelectCell.defaultProps = {
    tag: {},
    tagList: []
};

export default TagValueSelectCell;