import React from 'react';
import './PlaylistTagHistory.css';
import {withTranslation} from "react-i18next";
import Moment from "react-moment";




class PlayListTagHistory extends React.Component {

    constructor(props) {
        super(props);
    }

    eventTypeName = (type) => {
        const {t} = this.props;
        if(type === '0' ){
            return t('COM_BUTTON_ADD');
        } else if ( type === '1' ){
            return t('COM_BUTTON_DELETE');
        }
    }

    formattedUpdateDate = (timeValue ,  user) => {
        if(typeof user === 'undefined' || user === '') return "";
        const dateFormat = (user.dateFormat || 'YYYY-MM-DD').toUpperCase();
        const timeFormat = user.timeFormat || '24hour';
        let format = '';

        if (timeFormat === 'ampm') {
            format = dateFormat + ' hh:mm A';
        } else {
            format = dateFormat + ' HH:mm';
        }
        return (
            <span><Moment format={format}>{timeValue}</Moment></span>
        );
    }

    render() {
        const {t, tagHistory} = this.props;
        const user = JSON.parse(localStorage.getItem('user'));

        return (
            <div style={{paddingTop: '30px', position: 'relative', overflowX: 'scroll'}}>
                <div className='pop_tagHistory_header'>
                    <table style={{width: '100%'}}>
                        <colgroup>
                            <col width="129px"/>
                            <col width="70px"/>
                            <col width="101px"/>
                            <col width="130px"/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th style={{maxWidth: 129 ,width: '129px'}}>{t("MIS_TEXT_TAG_NAME_P_KR_MIS20")}</th>
                            <th style={{maxWidth: 70 ,width: '70px'}}>{t("TABLE_EVENT_TYPE_P")}</th>
                            <th style={{maxWidth: 101 ,width: '101px'}}>{t("TEXT_CONTENT_NAME_P")}</th>
                            <th style={{maxWidth: 130 ,width: '126px'}}>{t("COM_TEXT_MODIFY_DATE_P")}</th>
                        </tr>
                        </thead>
                    </table>
                </div>
                <div className='pop_tagHistory_list' style={{height: "350px", marginTop: "15px"}}>
                    <table style={{width: '100%'}}>
                        <colgroup>
                            <col width="129px"/>
                            <col width="90px"/>
                            <col width="100px"/>
                            <col width="130px"/>
                        </colgroup>
                        <tbody>
                        {
                            tagHistory.map((el, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{maxWidth: 129 ,width: '129px'}}><span>{el.tagName}</span></td>
                                        <td style={{maxWidth: 90 ,width: '90px'}}><span>{this.eventTypeName(el.eventType)}</span></td>
                                        <td style={{maxWidth: 100 ,width: '100px'}}><span>{el.contentName}</span></td>
                                        <td style={{maxWidth: 130 ,width: '130px'}}><span>{this.formattedUpdateDate(el.eventTime, user)}</span></td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PlayListTagHistory);
