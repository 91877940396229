import React from 'react';
import {useTranslation} from "react-i18next";
import {deviceOptions} from '../misopt';


// TODO MISOPT 에 넣을순 없나 ??
const deviceTypeTitle = (deviceFullType) => {
    let title = "";
    if( deviceFullType === 'iPLAYER' || deviceFullType === 'IPLAYER') {
        title = 'I Player';
    }else if (deviceFullType === 'APLAYER'){
        title = 'Android PLAYER';
    }else if (deviceFullType === 'WPLAYER'){
        title = 'W PLAYER';
    }else if (deviceFullType === 'LPLAYER') {
        title = 'LITE PLAYER';
    }else if (deviceFullType === '3rdPartyPLAYER'){
        title = '3rdParty PLAYER';
    }else if (deviceFullType.startsWith('LEDBOX')){
        let ver = '';
        if(deviceFullType.length > 6) {
            ver = 'S' + deviceFullType.substring(6) + ' ';
        }
        title = ver + 'LEDBOX';
    }else if (deviceFullType.startsWith('FLIP')){
        let ver = '';
        if(deviceFullType.length > 4) {
            ver = ' ' + deviceFullType.substring(4);
        }
        title = 'FLIP' + ver;
    }else if (deviceFullType.startsWith('R')){
        let ver = '';
        if(deviceFullType.substr(1, 7) === 'SIGNAGE') {
            if(deviceFullType.length > 8){
                ver = 'S' + deviceFullType.substring(8) + ' ';
            }
            title = ver + 'RM SIGNAGE';
        } else if(deviceFullType.substr(1, 7) === 'SPLAYER') {
            if(deviceFullType.length > 8){
                ver = 'S' + deviceFullType.substring(8) + ' ';
            }
            title = ver + 'RM SPLAYER';
        } else if(deviceFullType.substr(1, 7) === 'IPLAYER') {
            if(deviceFullType.length > 8){
                ver = 'S' + deviceFullType.substring(8) + ' ';
            }
            title = ver + 'RM SPLAYER';
        } else {
            title = '[R] No Title1';
        }
    }else if (deviceFullType.startsWith('SIGNAGE')){
        let ver = '';
        if(deviceFullType.length > 7) {
            ver = 'S' + deviceFullType.substring(7) + ' ';
        }
        title = ver + 'SIGNAGE PLAYER';
    }else if (deviceFullType.startsWith('S') && deviceFullType.endsWith('PLAYER')){
        let ver = 'S' + deviceFullType.substring(1).substring(0, deviceFullType.length - 7) + ' ';
        title = ver + 'PLAYER';
    }
    else {
        title = 'No Title';
    }
    return title;
}

const DeviceFilterStatus = ({deviceTypes, connectionStatusOption, selected, deviceTypeChanged, sourceChanged}) => {
    const {t} = useTranslation();
    return (
        <>
            {
                deviceTypeChanged &&
                <span>
                {
                    selected.devices && (selected.devices.length === deviceTypes.length) ?
                        <span className="filter_status_device_type_filter">
                            <span>{t("COM_SID_ALL")}</span>
                        </span>
                        :
                        selected.devices.map((device, i) => (
                            <span key={`filter_status_device_type_${i}`} className="filter_status_device_type_filter">
                                <span>{deviceTypeTitle(device)}</span>
                            </span>
                        ))
                }
                </span>
            }
            {
                connectionStatusOption && connectionStatusOption.length > 0 && 
                <span>
                {
                    <span className="filter_status_device_type_filter" style={{background:'#ffc446'}}>
                        {
                            selected.connectionStatus === connectionStatusOption[0] ?
                            <span>{t("COM_SID_ALL")}</span>
                            :
                            <span>{selected.connectionStatus.label}</span>
                        }
                    </span>
                }
                </span>                
            }
            {
                selected.notifications.length > 0 &&
                <span>
                    {
                        selected.notifications.map((noti, i) => {
                            return (
                                <span key={i} className="filter_status_device_type_filter" style={{background:'#60cfa6'}}>
                                    <span>{t(noti.label)}</span>
                                </span>
                            );
                        })
                    }
                </span>
            }
            {
                selected.functions.length > 0 &&
                <span>
                    {
                        selected.functions.map((f, i) => {
                            return (
                                <span key={i} className="filter_status_device_type_filter" style={{background:'#93bde8'}}>
                                    <span>{t(f.label)}</span>
                                </span>
                            );
                        })
                    }
                </span>
            }
            {
                selected.mediaTags.length > 0 &&
                <span>
                    {
                        selected.mediaTags.map((tag, i) => {
                            return (
                                <span key={i} className="filter_status_user_filter">
                                    <span>{tag.tagName}</span>
                                </span>
                            );
                        })
                    }
                </span>
            }
            {
                sourceChanged &&
                <span>
                    {
                        selected.sources.length === deviceOptions.inputSource.length ?
                        <span className="filter_status_device_type_filter" style={{background:'#883939'}}>
                            <span>{t("COM_SID_ALL")}</span>
                        </span>
                        :
                        selected.sources.map((source, i) => {
                            return (
                                <span key={i} className="filter_status_device_type_filter" style={{background:'#883939'}}>
                                    <span>{source.title}</span>
                                </span>
                            );
                        })
                    }
                </span>
            }
        </>
    );
};

export default DeviceFilterStatus;