import React, {useEffect, useState} from 'react';
import SubMenuContainer from "./SubMenuContainer";
import './ContentContainer.css';
import {useDispatch, useSelector} from "react-redux";
import {menuAction, popupAction} from "../actions";
import Container from './Container';
import WScroll from "../components/utils/WScroll";
import {usePrevious} from "../helper";

const ContentsContainer = () => {

    const dispatch = useDispatch();

    const {menu, common} = useSelector(state=> ({
        menu : state.menu,
        common : state.common
    }))

    const [style, setStyle] = useState({
        height: 900,
        width: 1000,
        position: 'absolute',
        overflow: 'hidden'
    })

    const [reload, setReload] = useState(false);

    const [node, setNode] = useState({})

    const _updateDimensions = () => {
        let height = window.innerHeight - 75;
        let width = window.innerWidth - 95;
        setStyle({
            ...style,
            height: height,
            width: width
        })
    }

    const handleGlobalKeydown = (e) => {
        const {activePopupId} = common;
        if ( e.keyCode == 27) {
            dispatch(popupAction.closePopup(activePopupId))
        }
    }

    useEffect(()=> {
        _updateDimensions();
        window.addEventListener('resize', _updateDimensions);
        document.addEventListener('keydown', handleGlobalKeydown);
        return () => {
            window.removeEventListener('resize', _updateDimensions);
            window.removeEventListener('keydown', handleGlobalKeydown);
        }
    }, [])

    const preGroupId = usePrevious(menu.groupId !== undefined && menu.groupId || undefined);
    const preUserId = usePrevious(menu.userId !== undefined && menu.userId || undefined);
    const preOrganizationId = usePrevious(menu.organizationId !== undefined && menu.organizationId || undefined);

    useEffect(()=> {
        const updateNode = (groupId, userId, organizationId) => {
            const nodeArray = [];
            const loop = (nodes) => {
                return nodes.find((node) => {
                    if (node.children != null && node.children.length > 0) {
                        return loop(node.children);
                    }
                    if (node.id === currentContentId) {
                        if( (node.hasGroup == true && groupId == undefined && userId == undefined && organizationId == undefined) ) {
                            //No need to load container just load each left group(ex. MyContent, SharedConent)
                        }else {
                            nodeArray.push(node);
                            return node;
                        }
                    }
                });
            };
            if (submenu != null && submenu.nodes != null) {
                loop(submenu.nodes);
            }

            const updateNode = nodeArray.length > 0 && nodeArray[0];
            // if (groupId !== undefined && updateNode !== null && updateNode !== false) {
            //     updateNode.groupId = groupId;
            // }
            if ( typeof userId !== 'undefined' && updateNode !== null && updateNode !== false ) {
                updateNode.userId = userId;
            }
            if ( typeof organizationId !== 'undefined' && organizationId !== '' && updateNode !== null && updateNode !== false ) {
                updateNode.organizationId = organizationId;
            }
            setNode(updateNode);
        }

        if ((preGroupId !== undefined || preUserId !== undefined || preOrganizationId !== undefined)
            && (menu.groupId !== undefined || menu.userId !== undefined || menu.organizationId !== undefined)) {
            const {groupId, userId, organizationId} = menu;
            if (groupId !== undefined && preGroupId !== undefined && preGroupId.current !== groupId ||
                userId !== undefined && preUserId !== undefined && preUserId.current !== userId ||
                organizationId !== undefined && preOrganizationId !== undefined && preOrganizationId.current !== organizationId) {
                    updateNode(groupId, userId, organizationId);
            }
        }
    }, [menu])

    useEffect(()=> {
        dispatch(menuAction.forceReloadContent())
    }, [menu.forceReload])

    const {currentContentId, submenu, submenuWidth} = menu;
    const paddingLeft = submenu != null && submenu.hasSubmenu && submenu.showSubmenu ? submenuWidth : 0;

    return(
        <div className="contentsBox" style={style}>

            <SubMenuContainer/>
            {
                submenu.showSubmenu &&
                <WScroll left={paddingLeft}/>
            }
            <Container className={'noSelect'} content={currentContentId} style={{paddingLeft: paddingLeft}} {...node} groupId={menu.groupId} reload={reload}/>

        </div>
    )

}
export default ContentsContainer;