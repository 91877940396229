import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import ContentThumbnail from "../../../image/ContentThumbnail";
import '../ScheduleDetailViewCalendar.css';

const EventConditionContentDetail = ({event, index, changeEvent}) => {
    const {t} = useTranslation();
    return (
        <div className="detail_View_calendar calendar_wrap_pop ml25" align="center">
            <div className="channel_wrap">
                <div className="float_l">
                    <button className="calendar_arrow_pop prev" onClick={()=>changeEvent('prev')}>
                        <span></span></button>
                </div>
                <div className="float_r">
                    <button className="calendar_arrow_pop next" onClick={()=>changeEvent('next')}>
                        <span></span></button>
                </div>
                <span id="scheduleViewChannelName">Event {index+1}</span>
            </div>
            <div className="mt5" style={{width:320}}>
                <div style={{position:'relative'}}>
                    {
                        event !== undefined && event.eventConditions !== undefined && event.eventConditions.length > index && event.eventConditions[index] !== undefined && event.eventConditions[index].thumbFileId !== undefined && event.eventConditions[index].thumbFileId !== null && event.eventConditions[index].thumbFileId !== ''
                        ? <ContentThumbnail id={event.eventConditions[index].thumbFileId} width={340} height={220} resolution ={'ORIGIN'}/> :
                            ''//<ContentThumbnail id={'NOIMAGE_THUMBNAIL'} width={340} height={220}/>
                    }
                </div>
            </div>
            <div className="mt5" style={{width:320}}>
                <div style={{position:'relative', textAlign:'left'}}>
                    {
                        event !== undefined && event.eventConditions !== undefined && event.eventConditions.length > index && event.eventConditions[index] !== undefined && event.eventConditions[index].channelNo !== undefined && event.eventConditions[index].channelNo !== null && event.eventConditions[index].channelNo > 0
                        ? t("COM_TEXT_CHANNEL_P") + ' : ' + event.eventConditions[index].channelNo : ''
                    }
                </div>
            </div>
            <div className="mt5" style={{width:320}}>
                <div style={{position:'relative', textAlign:'left'}}>
                    {
                        event !== undefined && event.eventConditions !== undefined && event.eventConditions.length > index && event.eventConditions[index] !== undefined && event.eventConditions[index].messageText !== undefined && event.eventConditions[index].messageText !== null && event.eventConditions[index].messageText !== ''
                        ? t("TEXT_MESSAGE_P") + ' : ' + event.eventConditions[index].messageText : ''
                    }
                </div>
            </div>
        </div>
    )
}
export default EventConditionContentDetail;