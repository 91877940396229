import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import MISDialog from "../../MISDialog";
import {rulesetService} from "../../../../services";
import Tree, {TreeNode} from "rc-tree/es";
import {jsonToTreeForDatalinkTable} from "../../../../helper";
import {Loading} from "../../../loading/Loading";
import ReactDataGrid from "react-data-grid";
import {toastr} from 'helper/toastrIntercept';
import './SelectDataLinkTablePopup.css';

const SelectDatalinkTablePopup = ({ type, onApply, onClose }) => {
    const { t } = useTranslation();

    const [treeData, setTreeData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [layout, setLayout] = useState([]);
    const [datalinkTableName,setDatalinkTableName] = useState(''); 
    const [serverAddress, setServerAddress] = useState('');

    let selectData = '';
    const [selectedUrl, setSelectedUrl]  =useState('');

    useEffect(() => {
        openTableTree();
    }, []);


    const openTableTree = () => {
        setLoading(true);
        rulesetService.fetchDatalinkServerList().then(
            res=> {
                if(res.items){
                    setLoading(false);
                    setTreeData(jsonToTreeForDatalinkTable(res.items));
                }                
            }
        )
    };

    const loadTable = (url) => {

        const updatedUrl = url.substr(0,url.lastIndexOf("/"));  
        setSelectedUrl(updatedUrl);
        let arrUrl = url.split("/");
        setDatalinkTableName(arrUrl[arrUrl.length-2]);
                
        rulesetService.fetchDatalinkTable(updatedUrl).then(
            res=> {
                if(res.items){

                    const json = JSON.parse(res.items)

                    const header = json.layout.map((v,i) => {
                        return {
                        key : v.data,
                        name: v.data,
                        sortable: false,
                        width : v.width        
                    }});

                    setColumns(header);
                    setRows(json.data);
                    setLayout(json.layout);                
                    setLoading(false);
                }
            }
        )
    }

    const dialogProps = {
        title: t("MIS_SID_DATALINK"),
        width: 900,
        height: 450,
        position: { x: -400, y: -100 },
        modal: true,
        isDraggable: false,
        closeOnEscape: true,
        allowMinimize: false,
        onClose: () => onClose()
    };

    const loop = (data) => {        
        return data.map((item) => {            
            if (item.children) {        
                return <TreeNode title={item.name} key={item.key}>{loop(item.children)}</TreeNode>;
            }            
            return (                
                <TreeNode   title={item.name} key={item.key} isLeaf={item.isLeaf} />
            );
        });
    };

    const onSelect = (e, treeNode) => {
        
        let url = treeNode.selectedNodes[0].key;        
        if(url !== null && url.indexOf("DataLink") > 0){
            setServerAddress(url);
            loadTable(url);             
        }else{

        }
    }

    const treeNodes = loop(treeData);

    return (
        <MISDialog
            dialog={dialogProps}
            buttons={{
                rightButtons: [
                    {
                        title: t("COM_BUTTON_SAVE"),
                        onClick: ()=> {
                            if(selectData === ''){
                                toastr.error(t("COM_MESSAGE_DEVICE_SELECT_ITEM_P"));
                            }else{
                                onApply(selectData, selectedUrl, serverAddress);
                            }      
                        }
                    },
                    {
                        title: t("BUTTON_CANCEL_P"),
                        onClick: onClose
                    }
                ]
            }}
        >
            <div className="popup_contents" style={{ padding: "10px 20px" }}>
                <div className="pop_list rulsetDatalinkWrap" style={{overflowY: "hidden", height: "460px",border: "1px solid rgb(231, 231, 231)"}}>
                        <div
                            id="datalinkTree"
                            className="float_l"
                            style={{ width: "29%", height: "460px", overflow: "auto", borderRight: "1px solid rgb(231, 231, 231)" }}>                                                
                            { treeData.length > 0 &&
                                <div className={"ruleset" + " " + type} style={{}}>
                                    <Tree
                                        style={{'display':'block'}}
                                        showIcon={false}                                      
                                        onSelect={(key, event) => onSelect(key, event)}    
                                        defaultExpandAll={false}
                                        selectedKeys={selected}
                                        loadedKeys={selected}
                                    >
                                    {treeNodes}
                                </Tree>
                            </div>}
                        </div>
                   
                    <div
                    id="datalinkTable"
                    className="float_r"
                    style={{
                        height: "100%", 
                        width:  "70%",
                        overflow: "auto",
                        marginTop: "5px",
                        display : 'inline'
                    }}
                    >
                   <div id="DataLinkContainer">
                   
                   {
                        loading &&
                        <div style={{width:'100%', height:'100%', background: '#fff', display: 'flex'}}><Loading /></div>

                    }
                    {
                        !loading && serverAddress ==='' && <span>{t("MIS_MESSAGE_COMMON_SELECT_DATALINK_TABLE_P")}</span>
                    }    
                   {!loading && serverAddress !=='' && rows !== undefined && rows.length > 0 && columns !== undefined && columns.length > 0 &&
                    <ReactDataGrid 
                    columns={columns} 
                    minColumnWidth={30} 
                    rowGetter={i=> rows[i]}
                    rowsCount={rows.length}
                    minWidth={580} 
                    cellRangeSelection={{
                        onComplete: args => {                            
                            const startRow = args.topLeft.rowIdx;
                            const endRow = args.bottomRight.rowIdx;
                            const startColumn = args.topLeft.idx;
                            const endColumn = args.bottomRight.idx;
                     
                            if(args.topLeft.idx !== -1 && args.bottomRight.idx !== -1){
                                if((startRow===endRow) && (startColumn===endColumn)){  
                                    selectData =  datalinkTableName +"."+layout[startColumn]["data"]+"."+startRow;
                                }else{
                                    selectData = datalinkTableName;
                                    selectData += ".";
                                    selectData += layout[startColumn]["data"];
                                    selectData += ":";
                                    selectData += layout[endColumn]["data"];
                                    selectData += ".";
                                    selectData += startRow;
                                    selectData += ":";
                                    selectData += endRow;
                                }
                            }                   

                        }
                    }} /> }
                    </div>
                                  
                </div>
            </div>                
        </div>        
        </MISDialog>
    );
};

export default SelectDatalinkTablePopup;
