import {rulesetConstants} from '../../constants';
import i18n from '../../language/i18n'
import moment from 'moment';
import uuid from 'uuid';

export const getOpType = (sign) => {
    let opType = "etc";
    switch(sign) {
        case "eq":
        case "rg":
        case "ia":
        case "ip":
            opType = "eq";
            break;
        case "nq":
        case "ne":
            opType = "ne";
            break;
    }

    return opType;
}

export const getConditionType = (type) => {
    type = type.replace(/([-_]\w)/g, g => g[1].toUpperCase());  // snake to camel
    
    if(rulesetConstants.CONDITION_TYPES[type]) {
        type = rulesetConstants.CONDITION_TYPES[type].sid;
    }

    const str = i18n.t(type);
    return str;
}

export const changeConditionTypeToCamel = (type) => {
    //curr_time to currTime
    
    const keys = Object.keys(rulesetConstants.CONDITION_TYPES);
    let str;

    keys.map((v,i) => {
        if(rulesetConstants.CONDITION_TYPES[v].type === type) {
            str = v;
        }
    });

    return str;    
}

export const getPatternType= (value) => {
    if(value !== undefined){
    var count = (value.match(/\*/g) || []).length;
		if(count === 1 && value.substr(value.length-1) === '*'){
			return 'sw';
		}else if(count === 2){
			return 'ct';
		}
        return 'eq';
    }
}

export const getParents = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function(s) {
                var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };
    }

    // Set up a parent array
    var parents = [];

    // Push each parent element to the array
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
        if (selector) {
            if (elem.matches(selector)) {
                parents.push(elem);
            }
            continue;
        }
        parents.push(elem);
    }

    // Return our parent array
    return parents;
}

export const checkCondition = (condition, date, time, device) => {

    const textCompare = (str1, values) => {
        if(values === undefined || values.length === 0) {
            return false;
        }

        const str2 = values[0].value;
        if(str2 === undefined || str2.length === 0) {
            return false;
        }

        if(str2.charAt(0) === "*" && str2.charAt(str2.length - 1) === "*") {
            return str1.includes(str2.substring(1, str2.length - 1));
        }

        else if(str2.charAt(str2.length - 1) === "*") {
            return str1.indexOf(str2.substring(0, str2.length - 1)) === 0;
        }

        else {
            return str1 === str2;
        }
    };

    

    const conditionType = rulesetConstants.CONDITION_TYPES;
        let result = false;
        switch(condition.type) {
            case conditionType.currTime.type:
                if(time === undefined) {
                    return true;
                }

                else {
                    const format = 'hh:mm:ss';

                    for(let i = 0; i < condition.values.length; i++) {
                        const start = condition.values[i].start;
                        const end = condition.values[i].end;
                        result = moment(time, format).isBetween(moment(start, format), moment(end, format), null, '[]');
                        if(result) {
                            break;
                        }
                    }
                }
                break;
            case conditionType.currDate.type:
                if(date === undefined) {
                    return true;
                }

                const format = 'YYYY.MM.DD';
                const dateObj = moment(date, format);

                if(condition.repeatType === "once") {
                    for(let i = 0; i < condition.values.length; i++) {
                        const start = condition.values[i].start;
                        const end = condition.values[i].end;
                        result = dateObj.isBetween(moment(start, format), moment(end, format), null, '[]');
                        if(result) {
                            break;
                        }
                    }
                }

                else if(condition.repeatType === "weekly") {
                    const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
                    for(let i = 0; i < condition.values.length; i++) {
                        if(condition.values[i].value === days[dateObj.day()]) {
                            return true;
                        }
                    }
                }

                else if(condition.repeatType === "monthly") {
                    for(let i = 0; i < condition.values.length; i++) {
                        if(condition.values[i].value === dateObj.date()) {
                            return true;
                        }
                    }
                }
                
                break;
            case conditionType.deviceNm.type:
                result = textCompare(device.deviceName, condition.values);
                break;
            case conditionType.devIp.type:
                if(condition.values === undefined || condition.values.length === 0) {
                    return false;
                }

                if(condition.sign === "eq") {
                    const ip = condition.values[0].value;
                    const ipArr = ip.split(".");
                    const devIpArr = device.ipAddress.split(".");
                    if(ipArr.length !== 4 || devIpArr.length !== 4) {
                        return false;
                    }

                    result = true;
                    for(let i = 0; i < 4; i++) {
                        if(ipArr[i] === "*") {
                            continue;
                        }

                        if(ipArr[i] !== devIpArr[i]) {
                            result = false;
                            break;
                        }
                    }
                }
                else if(condition.sign === "rg") {
                    const ip = condition.values[0];
                    const ipArr1 = ip.start.split(".");
                    const ipArr2 = ip.end.split(".");
                    const devIpArr = device.ipAddress.split(".");
                    if(ipArr1.length !== 4 || ipArr2.length !== 4 || devIpArr.length !== 4) {
                        return false;
                    }

                    for(let i = 0; i < 4; i++) {
                        if(!isNaN(ipArr1[i])) {
                            ipArr1[i] = parseInt(ipArr1[i]);
                        }
                        else {
                            return false;
                        }

                        if(!isNaN(ipArr2[i])) {
                            ipArr2[i] = parseInt(ipArr2[i]);
                        }
                        else {
                            return false;
                        }

                        if(!isNaN(devIpArr[i])) {
                            devIpArr[i] = parseInt(devIpArr[i]);
                        }
                        else {
                            return false;
                        }
                    }

                    result = true;
                    for(let i = 0; i < 4; i++) {
                        if(devIpArr[i] < ipArr1[i] || devIpArr[i] > ipArr2[i]) {
                            result = false;
                            break;
                        }
                    }
                }
                else {
                    return false;
                }
                break;
            case conditionType.devMdnm.type:
                result = textCompare(device.deviceModelName, condition.values);
                break;
            case conditionType.screenRotation.type:
                if(condition.values === undefined || condition.values.length === 0) {
                    return false;
                }
                result = device.screenRotation === condition.values[0].value;
                break;
            case conditionType.screenSize.type:
                if(condition.values === undefined || condition.values.length === 0) {
                    return false;
                }
                result = device.screenSize === condition.values[0].value;
                break;
            case conditionType.mediaTag.type:
                if(device.mediaTagValueList && device.mediaTagValueList.length > 0) {
                    let conditionValues = condition.mediaTagValues;
                    if(conditionValues === undefined) {
                        let conditionValues = new Set();
                        condition.values.map((value) => {
                            conditionValues.add(value);
                        });
                    }
                    
                    if(condition.sign === "ia") {
                        let count = 0;
                        for(let i = 0; i < device.mediaTagValueList.length; i++) {
                            if(conditionValues.has(device.mediaTagValueList[i].tagName)) {
                                count++;
                            }
                            else {
                                break;
                            }
                        }

                        result = count === device.mediaTagValueList.length;
                    }
                    else {
                        result = false;
                        for(let i = 0; i < device.mediaTagValueList.length; i++) {
                            if(conditionValues.has(device.mediaTagValueList[i].tagName)) {
                                result = true;
                                break;
                            }
                        }
                    }
                }
                else {
                    result = false;
                }
                break;
            case conditionType.datalink.type:
                return true;
            default:
                return false;
        }

        if(condition.isInvert) {
            return !result;
        }

        else {
            return result;
        }
}

export const assignUUID = (ruleset, obj) => {
    if(obj.type === "condition") {
        if(obj.id !== "default") {
            obj.id = uuid();
            obj.condition.conditionId = obj.id;
            ruleset.conditions.push(obj.condition);
        }
        else {
            obj.result = JSON.parse(JSON.stringify(obj.result));
            obj.children[0].id = uuid();
            obj.result.resultId = obj.children[0].id;
            ruleset.results.push(obj.result);
        }
    }

    else if(obj.type === "result") {
        obj.id = uuid();
        obj.result.resultId = obj.id;
        ruleset.results.push(obj.result);
    }

    if(obj.children && obj.id !== "default") {
        obj.children.map((value) => {
            assignUUID(ruleset, value);
        });
    }
}

export const generateSaveObject = (ruleset, rulesetName, group, defaultContent, description, saveAs) => {
    let r = JSON.parse(JSON.stringify(ruleset));

    const oldConditionMap = new Map();
    const oldResultMap = new Map();

    if(ruleset.conditions) {
        ruleset.conditions.map((value) => {
            oldConditionMap.set(value.conditionId, value);
        });
    }

    if(ruleset.results) {
        ruleset.results.map((value) => {
            oldResultMap.set(value.resultId, value);
        });
    }

    const conditionMap = new Map();
    const resultMap = new Map();
    const contentMap = new Map();

    const conditionAndPlay = (value) => {
        if(value.type === "condition") {
            if(value.id !== "default") {
                if(saveAs) {
                    if(!value.condition) {
                        value.condition = JSON.parse(JSON.stringify(oldConditionMap.get(value.id)));
                    }

                    value.id = uuid();
                    value.condition.conditionId = value.id;
                }

                if(!conditionMap.has(value.id)) {
                    conditionMap.set(value.id, value.condition);
                }
            }

            // default play
            else {
                if(saveAs) {
                    value.result = JSON.parse(JSON.stringify(oldResultMap.get(value.children[0].id)));
                    value.children[0].id = uuid();
                    value.result.resultId = value.children[0].id;
                }

                if(!resultMap.has(value.result.resultId)) {
                    resultMap.set(value.result.resultId, value.result);
                    if(value.result.type === "static") {
                        value.result.contentList.map((contentInfo) => {
                            contentMap.set(contentInfo.contentId, {contentsId: contentInfo.contentId, contentsType: value.result.contentsType});
                        });
                    }
                }
            }
        }

        else if(value.type === "result") {
            if(saveAs) {
                if(!value.result) {
                    value.result = JSON.parse(JSON.stringify(oldResultMap.get(value.id)));
                }
                value.id = uuid();
                value.result.resultId = value.id;
            }

            if(!resultMap.has(value.id)) {
                resultMap.set(value.id, value.result);
                if(value.result.type === "static") {
                    value.result.contentList.map((contentInfo) => {
                        contentMap.set(contentInfo.contentId, {contentsId: contentInfo.contentId, contentsType: value.result.contentsType});
                    });
                }
            }
        }

        const children = value.children;
        const id = value.id;
        const type = value.type;

        for(const prop of Object.getOwnPropertyNames(value)) {
            delete value[prop];
        }

        value.id = id;
        value.type = type;
        value.children = children;
        
        if(value.id !== "default" && value.children) {
            value.children.map((childrenValue) => {
                conditionAndPlay(childrenValue);
            });
        }
    };
    
    r.rules.map((value) => {
        value.rule_tree.map((value2) => {
            conditionAndPlay(value2);
        });
    });

    r.conditions = Array.from(conditionMap.values());
    r.results = Array.from(resultMap.values());
    r.contents = Array.from(contentMap.values());

    r.groupId = group.groupId;
    r.groupName = group.groupName;

    r.defaultPlay = defaultContent.defaultPlay;
    r.defaultPlayName = defaultContent.defaultPlayName;

    r.name = rulesetName;
    r.description = description;

    return r;
};

export const convertToTime = (time) => {
    let date = new Date();
    const times = time.split(':');
    if (times !== undefined && times.length > 2) {
        date.setHours(times[0], times[1], times[2]);
    }
    return date;
}

export const validTime = (value) => {

    const end = convertToTime(value.end);
    const start = convertToTime(value.start);

    if(start.getTime() > end.getTime()){           
        return false;
    }else{
        return true;
    }
}

export const checkDepth = (nodes, depth) => {
    if(depth > 10) {
        return false;
    }

    if(nodes === undefined || nodes.length === 0) {
        return true;
    }

    for(let i = 0; i < nodes.length; i++) {
        if(!checkDepth(nodes[i].children, depth + 1)) {
            return false;
        }
    }

    return true;
}

export const checkConditionCount = () => {
    const count = document.querySelectorAll('.ruletree-label[type="condition"]').length;
    if(window.globalConfig && window.globalConfig.ruleset) {
        if(count < window.globalConfig.ruleset.maxConditionCount) {
            return true;
        }
        else {
            return false;
        }
    }

    return true;
}