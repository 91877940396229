import {authHeader, handleBinDownloadResponse, handleResponse, objToQueryString} from '../helper';
import {fetchForDownload, fetchWithToken as fetch} from '../helper/fetchIntercept.js';

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

export const systemService = {
    fetchMenus,
    fetchMenuById,
    fetchFileDownload,
    fetchAlerts
}


function fetchMenus() {
    return fetch(`${backendHost}/restapi/v2.0/sms/system/menus`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchMenuById(id) {
    return fetch(`${backendHost}/restapi/v2.0/sms/system/menus/${id}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchFileDownload(param){
    return fetchForDownload(`${backendHost}/restapi/v2.0/sms/system/file-download${objToQueryString(param)}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleBinDownloadResponse);
}

function fetchAlerts() {
    return fetch(`${backendHost}/restapi/v2.0/sms/system/alerts`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}