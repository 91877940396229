import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import MISDialog from '../MISDialog';
import DateInput from '../../input/DateInput';
import {deviceService} from '../../../services';
import Checkbox from '../../checkbox/Checkbox';

const ExpirationDatePopup = ({deviceIds, onClose, onSave, expirationDate = '' }) => {
    const {t} = useTranslation();
    const [calDate, setCalDate] = useState( expirationDate );
    const [infinity, setInfinity] = useState(false);
    const now = new Date();

    const onChangeDate = date => {
        setCalDate(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
    };

    const onClickSave = () => {
        deviceService.updateExpirationDate({
            calDate: infinity ? 'INFINITY' : calDate,
            deviceIds
        }).then(res => {
            toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
        }).catch(error => console.log(error)).finally(() => onSave());
    };

    const toggleInfiniteDate = (e) => {
        const chk = e.target.checked;
        setInfinity(chk);
        if(chk) {
            setCalDate(''); // INFINITY
        } else {
            setCalDate(expirationDate);
        }
    }

    const isDisable = () => {
        return !infinity && calDate === ''
    }

    return (
        <MISDialog
            dialog={{
                title: t('TEXT_EXPIRED_P'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 525,
                height: 60,
                onClose
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: onClickSave,
                        disable : isDisable(),
                    },
                    {
                        id: 'MSG_CANCELED',
                        title: t('MSG_CANCELED'),
                        onClick: onClose,
                    }
                ]
            }}
        >
            <div className="detail_view mini_p mb15">
                <table>
                    <colgroup>
                        <col width="30%"/>
                        <col width=""/>
                    </colgroup>
                    <tbody>
                    <tr>
                        <th>{t('TEXT_EXPIRED_P')}</th>
                        <td>
                            <DateInput date={calDate} onChange={onChangeDate} disabled={infinity} minDate={now} />
                            <Checkbox id='infinity' name='∞' checked={infinity} onChange={toggleInfiniteDate} style={{marginLeft: '20px'}} />
                            {/* <button className="base ml20">∞</button> */}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </MISDialog>
    );
};

export default ExpirationDatePopup;