import React, {useEffect, useMemo, useState} from 'react'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import WhiteButton from "../../components/button/WhiteButton";
import {settingService} from "../../services";
import {snakeCase} from "lodash";
import {toMomentDateTimeFormat, useCheckRefWithSelectedCnt as useCheckRef, useFilter} from "../../helper";
import ReactTable from "react-table";
import Pagination from "../../components/table/Pagination";
import {commonConstants, SETTING_PAGE_SIZE_OPTIONS, settingConstants} from "../../constants";
import {popupAction} from "../../actions";
import {toastr} from 'helper/toastrIntercept';
import Checkbox from "../../components/checkbox/Checkbox";
import Moment from "moment";
import {useMISOpt} from "../../components/misopt";
import {getErrorMessage} from "../../helper/responseHandler";
import {useTrGroupProps} from "../../helper/tables";

const TagManagement = (props) => {
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

    const {t} = useTranslation();
    const dispatch = useDispatch();


    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        page: 0,
        pageSize: 30,
        keyword: '',
        organId: props.groupId !== undefined ? props.groupId : 'ALL',
        sorted: [{id: 'create_date', desc: true}],
        reload: false
    });

    if (props.groupId !== filter.organId) {
        setFilter({...filter, organId : props.groupId});
    }
    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0,
    });
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize,sorted} = filter;
    const [style, setStyle] = useState({
        height: '500px'
    });

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    useEffect(() => {
        fetchData();
    }, [filter]);

    const fetchData = () => {
        const {page, pageSize, sorted: [{id, desc}], organId} = filter;
        setData({...data, loading: true});
        settingService.fetchTags({
            startIndex: page*pageSize + 1,
            pageSize,
            groupType: 'ALL',
            organId: organId,
            sortColumn: snakeCase(id),
            sortOrder: desc === true ? 'desc' : 'asc'
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount, pages: Math.ceil(res.totalCount / pageSize)});
        });
    }

    const addTagPopup = () => {
        dispatch(popupAction.addPopup({
            type: settingConstants.ADD_TAG_POPUP,
            id: settingConstants.ADD_TAG_POPUP,
            title : t('MIS_SID_ADD_TAG'),
            onSave: addTagInfo,
            onClose: ()=>dispatch(popupAction.closePopup(settingConstants.ADD_TAG_POPUP))
        }));

    }

    const callAddTagCondition = (tagId, tagValues, index, closeLoadingPopup = false) => {
        if((tagValues.length) === index) {
            if(closeLoadingPopup) {
                dispatch(popupAction.closePopup('TAG_MANAGEMENT_LOADING_POPUP'));
                fetchData();
                toastr.success(t("COM_TEXT_SUCCESS_P"))
            }
            
            return;
        }
        settingService.addTagCondition(tagId, {tagCondition: tagValues[index]}).then(
            res => {
                if(res.status === 'Success') {
                    callAddTagCondition(res.items.tagId, tagValues, (index + 1), closeLoadingPopup);
                }
            }
        ).catch(error => {
            if(closeLoadingPopup) {
                dispatch(popupAction.closePopup('TAG_MANAGEMENT_LOADING_POPUP'));
            }
            return false;
        });
    };

    const callDeleteTagCondition = (tagId, delConditionIds, index) => {
        if((delConditionIds.length) === index) {
            return;
        }
        settingService.deleteTagCondition(tagId, delConditionIds[index]).then(
            res => {
                if(res.status === 'Success') {
                    callDeleteTagCondition(tagId, delConditionIds, (index + 1));
                }
            }
        ).catch(error => {return false;});
    };

    const addTagInfo = (tagInfo, addCondition) => {
        if(tagInfo.tagName =='') {
            toastr.error(t('MESSAGE_COMMON_INPUT_CONTENT_P'));
            return;
        }

        if(tagInfo.tagName.length >100) {
            toastr.error(t('MESSAGE_COMMON_MAX_50_P'));
            return
        }

        if(tagInfo.tagName.trim() =='') {
            toastr.error(t('MIS_MESSAGE_COMMON_CANT_TEXT_SPACE_P'));
            return;
        }

        const reg = /[\{\}\[\]\s\/?.,;:|\)*~`!^\+<>@\#$&\\\=\(\'\"%]/gi;
        if(reg.test(tagInfo.tagName)){
            toastr.error(t('MESSAGE_DEVICE_CHECK_INPUT_CHAR_P').replace(/\\/gi, ''));
            return ;
        }

        dispatch(popupAction.addPopup({id: 'TAG_MANAGEMENT_LOADING_POPUP', type : commonConstants.LOADING_POPUP}));
        settingService.addTag(tagInfo).then(
            res => {
                if(res.status === 'Success') {
                    callAddTagCondition(res.items.tagId, addCondition, 0, true);
                }
            }
        ).catch(
            err => {
                toastr.error(getErrorMessage(err));
                dispatch(popupAction.closePopup('TAG_MANAGEMENT_LOADING_POPUP'));
            }
        ).finally(() => {
            dispatch(popupAction.closePopup(settingConstants.ADD_TAG_POPUP));
        })
    }

    const editTagPopup = (tagId) => {
        dispatch(popupAction.addPopup({
            type: settingConstants.EDIT_TAG_POPUP,
            id: settingConstants.EDIT_TAG_POPUP,
            tagId: tagId,
            title : t('MIS_TEXT_TAG_MANAGEMENT_P'),
            onSave: editTagInfo,
            onClose: ()=>dispatch(popupAction.closePopup(settingConstants.EDIT_TAG_POPUP))
        }));
    }

    const editTagInfo = (tagInfo,addCondition,delCondition) => {

        if(tagInfo.tagName =='') {
            toastr.error(t('MESSAGE_COMMON_INPUT_CONTENT_P'));
            return;
        }

        if(tagInfo.tagName.length >100) {
            toastr.error(t('MESSAGE_COMMON_MAX_50_P'));
            return
        }

        if(tagInfo.tagName.trim() =='') {
            toastr.error(t('MIS_MESSAGE_COMMON_CANT_TEXT_SPACE_P'));
            return;
        }

        const reg = /[\{\}\[\]\s\/?.,;:|\)*~`!^\+<>@\#$&\\\=\(\'\"%]/gi;
        if(reg.test(tagInfo.tagName)){
            toastr.error(t('MESSAGE_DEVICE_CHECK_INPUT_CHAR_P'));
            return ;
        }
        settingService.editTag(tagInfo.tagId, tagInfo).then(
            res => {
                if (addCondition != null && addCondition.length > 0) {
                    callAddTagCondition(tagInfo.tagId, addCondition, 0);
                }

                if (delCondition != null && delCondition.length > 0) {
                    callDeleteTagCondition(tagInfo.tagId, delCondition, 0);
                }
                toastr.success(t("COM_TEXT_SUCCESS_P"));
                fetchData();
            }
        ).catch(
            err => {
                toastr.error(getErrorMessage(err));
            }
        ).finally(()=>{
            dispatch(popupAction.closePopup(settingConstants.EDIT_TAG_POPUP));
        })
    }

    const deleteTag = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_CONFIRM"),
            message: t("COM_TV_SID_DO_YOU_WANT_TO_DELETE_THE_SELECTED_ITEMS_KR_SELECTED"),
            onClickYes: handleDelete,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));

    }

    const handleDelete = () => {
        const tagIds = getCheckedId();
        settingService.deleteTag(tagIds).then(
            res => {
                if (res.items.deletedFailList.length >0) {
                    toastr.error(t("MIS_MESSAGE_COMMON_CANT_DELETE_TAG_P"))
                    fetchData();
                }else{
                    toastr.success(t("TEXT_SUCCESS_P"))
                    fetchData();
                }
            }
        ).catch(
            res => toastr.error(res)
        ).finally(
            dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
        )
    }



    const showTagMapping= () => {
        const tagIds = getCheckedId();
        dispatch(popupAction.addPopup({
            type: settingConstants.VIEW_TAG_MIND_MAP_POPUP,
            id: settingConstants.VIEW_TAG_MIND_MAP_POPUP,
            title: t('MIS_SID_MEDIA_TAG_STATUS'),
            tagId: tagIds[0],
            onClose: () => dispatch(popupAction.closePopup(settingConstants.VIEW_TAG_MIND_MAP_POPUP))
        }));

    }

    const getCheckedId = () => {
        return selected.current.map(s => items[s].tagId);
    }

    useEffect(() => {
        setStyle({height: window.innerHeight - 202});
    }, []);


    const tagTypeTitle= [
        { value:0, title:t('DID_LFD_TEXT') },
        { value:1, title:t('COM_ADMIN_CONTENT_TYPEINFO_CONTENTS_LIST_JSP_NUMBER')},
        { value:2, title:t('MIS_TEXT_TRUE_OR_FALSE_P')}
    ];
    const getTitle = (value) => {
        const resultData =  tagTypeTitle.find( option =>  option.value === value);
        return resultData.title;
    }

    const columns = useMemo(() => [
        {
            accessor: "tagId",
            show: false
        },
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'Tag_all'}
                        classname={"table"}
                        name={"check"}
                        ref={checkAll}
                        onChange={toggleSelectAll}
                    />
                )
            },
            Cell: row=> {
                return (
                    <Checkbox
                        id={items[row.index].tagId}
                        index={row.index}
                        classname={"table"}
                        name={"check"}
                        ref={setCheckBoxRefs}
                        onChange={toggleRow}
                    />
                )
            },
            width : 46,
            sortable: false,
            style : {"textAlign": "center"},
            resizable : false
        },
        {
            Header: t("MIS_TEXT_TAG_NAME_P_KR_MIS20"),
            accessor: "tagName",
            Cell: (props) => <span className={"data_name"} onClick={()=>editTagPopup(props.original.tagId)}>{props.original.tagName}</span>,
            width: 300
        },
        {
            Header: t("MIS_SID_TAG_TYPE"),
            accessor: "tagType",
            Cell : (props) => getTitle(props.original.tagType),
            width: 250
        },
        {
            Header: t("TEXT_ORGANIZATION_P"),
            accessor: "tagOrgName",
            sortable: false,
            width: 200
        },

        {
            Header: t("TEXT_DESCRIPTION_P"),
            accessor: "tagDesc",
            width: 400
        },
/*        {
            Header: t("MIS_SID_CAFEB_MAPPED_CONTENT"),
            accessor: "contentCount",
            sortable: false,
            width: 200
        },*/
        {
            Header: t("TABLE_CREATE_DATE_P"),
            accessor: "createDate",
            Cell: ({value}) => <span>{Moment(value).format(dateTimeFormat)}</span>
        }
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);

    return (
        <div style={{width: '100%' , display : (props.currContent === 'ALL_TAG' || props.currContent === 'TAG_BY_GROUP' )? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"TAG_ADD"} name={t("COM_BUTTON_ADD")} onClick={()=>addTagPopup()} />
                    <WhiteButton id={"TAG_DELETE"} name={t("COM_BUTTON_DELETE")}  disable={selectedCnt < 1} onClick={()=>deleteTag()} />
                    <WhiteButton id={"TAG_MAPPING"} name={t("COM_BUTTON_VIEW_P")}  disable={selectedCnt !== 1}onClick={()=>showTagMapping()} />
                </div>
            </div>
            <ReactTable
                noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                data={items}
                page={page}
                pages={pages}
                loading={loading}
                minRows={0}
                style={style}
                columns={columns}
                sorted={sorted}
                getTrGroupProps={getTrGroupPropsType2}
                onSortedChange={onSortedChange}
                className="-striped -highlight"
                manual
                showPagination={false}
            />
            <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={SETTING_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} />
        </div>
    );
}


export default TagManagement;