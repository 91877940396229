import React, {Component} from 'react';
import {connect} from "react-redux";
import {menuAction, popupAction, rulesetAction} from '../../../actions'
import {commonConstants} from '../../../constants';
import {contextMenu, Item, Menu} from 'react-contexify';
import {withTranslation} from "react-i18next";
import {getMixString} from "../../../language/languageUtils";
import 'react-contexify/dist/ReactContexify.min.css';
import ButtonArea from "./ButtonArea";
import uuid from "uuid";
import {assignUUID, checkConditionCount} from '../../../helper/ruleset/rulesetUtils';
import {rulesetService} from '../../../services';
import {toastr} from 'helper/toastrIntercept';
import './AddOrButtonNode.css'

class AddOrButtonNode extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showAddDefaultPlayInfo: false
        };
    }

    addOrCondition(condition) {
        const addCondition = this.props.addCondition;

        let newCondition = {
            id: condition.conditionId,
            type: "condition",
            children: []
        };

        const parentChildrenLength = this.parentElem.children.length;
        if(parentChildrenLength > 0 && this.parentElem.children[parentChildrenLength - 1].id === "default") {
            this.parentElem.children.splice(parentChildrenLength - 1, 0, newCondition);
        }

        else {
            this.parentElem.children.push(newCondition);
        }
        
        addCondition(this.currentTab, condition);
    }

    onAddOrCondition() {
        if(!checkConditionCount()) {
            const {t} = this.props;
            toastr.error(t("COM_MIV_CAN_NOT_ADD_ANY_MORE"));
            return;
        }

        const addPopup = this.props.addPopup;
        const closePopup = this.props.closePopup;

        addPopup({
            id: commonConstants.RULESET_CONDITION_POPUP,
            type: commonConstants.RULESET_CONDITION_POPUP,
            mode: "NEW",            
            save : (condition) => {
                if(condition.isPublic) {
                    this.addToConditionLibrary(condition);
                }
                condition.isPublic = false;
                this.addOrCondition(condition);
            },
            close: () => closePopup(commonConstants.RULESET_CONDITION_POPUP)
        });
    }

    addToConditionLibrary = (newCondition) => {
        const {loadContent, menu} = this.props;
        const condition = JSON.parse(JSON.stringify(newCondition));
        condition.conditionId = uuid();
        condition.isPublic = true;
        rulesetService.createCondition(condition).then(
            res => {
                menu.submenu.key++;
                loadContent(menu.currentContentId);
            }
        ).catch(error => {toastr.error(error)})
        .finally();
    }

    addToPlayLibrary = (newPlay) => {
        const {t, loadContent, menu} = this.props;
        const result = JSON.parse(JSON.stringify(newPlay));
        result.resultId = uuid();
        result.isPublic = true;
        rulesetService.createPlay(result).then(
            res => {
                menu.submenu.key++;
                loadContent(menu.currentContentId);
            }
        ).catch(error => {toastr.error(error)})
        .finally();
    }

    addNodeByDnd = (item) => {
        if(item.type === 'conditionBox') {
            if(!checkConditionCount()) {
                const {t} = this.props;
                toastr.error(t("COM_MIV_CAN_NOT_ADD_ANY_MORE"));
                return;
            }
            
            let condition = JSON.parse(JSON.stringify(item.condition));
            condition.conditionId = uuid();
            condition.isPublic = false;
            this.addOrCondition(condition);
        }
    }

    addNewDefaultPlaySave(play) {
        play.contentsIDList = [];

        play.contentList.map((value, index) => {
            value.name = value.contentName;
            play.contentsIDList.push(value.contentId);
        });

        const addPlay = this.props.addPlay;
        
        if(play.defaultDuration === undefined) {
            play.defaultDuration = 5;
        }
        play.isPublic = false;

        let newPlay = {
            id: "default",
            type: "condition",
            children: [{
                id: play.resultId,
                type: "result",
                children: []
            }]
        };

        this.parentElem.children.push(newPlay);
        addPlay(this.currentTab, play);
    }

    onNewDefaultPlayRule = () => {
        const addPopup = this.props.addPopup;
        const closePopup = this.props.closePopup;

        let device;
        if(this.currentTab === "NEW_RULESET") {
            device = {
                deviceType: this.props.rulesets.newRuleset.ruleset.deviceType,
                deviceTypeVersion: this.props.rulesets.newRuleset.ruleset.deviceTypeVersion
            };
        }
        else {
            device = {
                deviceType: this.props.rulesets.editRuleset.ruleset.deviceType,
                deviceTypeVersion: this.props.rulesets.editRuleset.ruleset.deviceTypeVersion
            };
        }

        addPopup({
            id: commonConstants.RULESET_PLAY_POPUP,
            type: commonConstants.RULESET_PLAY_POPUP,
            device : device,            
            mode : "NEW",
            save : (newPlay) => {
                if(newPlay.isPublic) {
                    this.addToPlayLibrary(newPlay);
                }
                this.addNewDefaultPlaySave(newPlay);

                // "!" 버튼 없애기 위함.
                this.setState({
                    children: this.props.elem.children
                });
            },
            close: () => closePopup(commonConstants.RULESET_PLAY_POPUP)
        });
    }

    pasteCopyData = () => {
        let ruleset;
        let copyData;
        if(this.currentTab === "NEW_RULESET") {
            ruleset = this.props.rulesets.newRuleset.ruleset;
            copyData = JSON.parse(JSON.stringify(this.props.rulesets.newRuleset.copyData));
        }
        else {
            ruleset = this.props.rulesets.editRuleset.ruleset;
            copyData = JSON.parse(JSON.stringify(this.props.rulesets.editRuleset.copyData));
        }

        const parentChildrenLength = this.parentElem.children.length;

        if(this.parentElem.children.length > 0
            && this.parentElem.children[parentChildrenLength - 1].id === "default"
            && copyData.type === "result") {
            toastr.error(getMixString(["MIS_SID_MIX_CAMAR_NOT_PASTE_EXISTS_ALREADY", "MIS_SID_DEFAULT_PLAY_RULE"]));
            return;
        }

        assignUUID(ruleset, copyData);

        // default play
        if(copyData.children.length === 0 && copyData.type === "result") {
            let newPlay = {
                id: "default",
                type: "condition",
                children: [{
                    id: copyData.id,
                    type: "result",
                    children: []
                }]
            };

            this.parentElem.children.push(newPlay);
        }

        else {
            if(parentChildrenLength > 0 && this.parentElem.children[parentChildrenLength - 1].id === "default") {
                this.parentElem.children.splice(parentChildrenLength - 1, 0, copyData);
            }
    
            else {
                this.parentElem.children.push(copyData);
            }
        }

        this.props.redraw();
    }

    onOptionMenuItemClick(id) {
        switch(id) {
            case 'ADD_OR_CONDITION':
                this.onAddOrCondition();
                break;
            case 'ADD_DEFAULT_PLAY':
                this.onNewDefaultPlayRule();
                break;
            case 'PASTE':
                this.pasteCopyData();
                break;
            default:
                break;
        }
    }

    render() {
        const {t} = this.props;
        const {showAddDefaultPlayInfo} = this.state;
        const {currentContentId} = this.props.menu;
        this.currentTab = currentContentId;

        this.elem = this.props.elem;
        this.parentElem = this.props.parentElem;
        this.ruleId = this.props.ruleId;

        this.optionMenuId = "condition_" + this.ruleId + "-" + this.elem.entryIdIndex;

        let existDefaultPlay;
        this.parentElem.children.map((value) => {
            if(value.id === "default") {
                existDefaultPlay = true;
            }
        });

        let copyData;
        if(this.currentTab === "NEW_RULESET") {
            copyData = this.props.rulesets.newRuleset.copyData;
        }
        else {
            copyData = this.props.rulesets.editRuleset.copyData;
        }

        return (
            <div className={"ruletree-label-plus-button ruletree-label ruletree-label-" + this.ruleId + "-" + this.elem.entryIdIndex}>
                <ButtonArea
                    onButtonClick={(e) => {
                        e.preventDefault();
                        contextMenu.show({
                            id: this.optionMenuId,
                            event: e,
                        });
                    }}
                    addNode={this.addNodeByDnd}
                    areaStyle={{
                        left: "-3px",
                        top: "40px",
                        width: "190px",
                        height: "85px"
                    }}
                    buttonStyle={{ left: "-2px", top: "23px"}}
                    buttonClassName="ruleset_condition_add_button condition_plus_or" />
                
                <Menu id={this.optionMenuId} style={{zIndex:20}}>
                    <Item onClick={() => this.onOptionMenuItemClick('ADD_OR_CONDITION')}>{t('MIS_SID_ADD_OR_CONDITION_RULE')}</Item>
                    <Item disabled={existDefaultPlay} onClick={() => this.onOptionMenuItemClick('ADD_DEFAULT_PLAY')}>
                        <div className="ruleset_add_or_menu">
                            <div className="quest_img_noti">
                                <span>{getMixString(['MIS_SID_MIX_ADD', 'MIS_SID_DEFAULT_PLAY_RULE'])}</span>
                                <a onMouseEnter={() => this.setState({showAddDefaultPlayInfo: true})} onMouseLeave={() => this.setState({showAddDefaultPlayInfo: false})}/>
                                <div className="opacity_notice_wrap" style={{position: 'absolute', width: 440, top: 35, left: 160, right: 100, padding: '10px 20px', zIndex: 2000, display: showAddDefaultPlayInfo ? '' : 'none'}} id="rulesetDevNotice">
                                    <table>
                                        <colgroup>
                                            <col width="119px" />
                                            <col width="" />
                                        </colgroup>
                                        <tbody>
                                            <tr><td>{t("MIS_SID_SET_CONTENT_PLAY_WHEN_CONDITIONS_ABOVE_DONOT_APPLY")}</td></tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Item>
                    <Item disabled={copyData === undefined} onClick={() => this.onOptionMenuItemClick('PASTE')}>{t('TEXT_PASTE_P')}</Item>
                </Menu>
            </div>
        )
    }
}

export default connect(
    state => ({
        menu : state.menu,
        rulesets : state.rulesets,
    }),
    dispatch => ({
        addCondition: (currentTab, condition) => dispatch(rulesetAction.addCondition(currentTab, condition)),
        addPlay: (currentTab, play) => dispatch(rulesetAction.addPlay(currentTab, play)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        redraw: () => dispatch(rulesetAction.redraw()),
        loadContent: (id) => dispatch(menuAction.loadContent(id))
    })
)(withTranslation()(AddOrButtonNode));