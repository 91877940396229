import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Checkbox from "../../../checkbox/Checkbox";
import MISDialog from "../../MISDialog";
import {userService} from "../../../../services";
import {getErrorMessage} from "../../../../helper/responseHandler";

const DataLinkOrgPopup = ({organizations, setOrganizations, onClose}) => {
    const {t} = useTranslation();
    const [allOrganizationList, setAllOrganizationList] = useState({});
    const [allChecked, setAllChecked] = useState({});
    const [checkedOrganizations, setCheckedOrganizations] = useState();

    useEffect(()=>{
        setCheckedOrganizations(organizations);
        userService.fetchUserGroups()
            .then(res => {
                if(res.items.length !== 0) {
                    let orgNameArr = [];
                    const orglist = res.items.filter(item => item.groupId !== 0);
                    for (let [key, value] of Object.entries(orglist)) {
                        for (let [key1, value1] of Object.entries(value)) {
                            if (key1 === 'groupName') {
                                orgNameArr.push(value1);
                            }
                        }
                    }
                    setAllOrganizationList(orgNameArr);
                }
            }).catch(err => {
                getErrorMessage(err);
            });
    }, []);

    useEffect(()=>{
        checkedOrganizations && allOrganizationList && setAllChecked(checkedOrganizations.length === allOrganizationList.length);
    });

    const saveOrganizations = () =>{
        setOrganizations(checkedOrganizations);
        onClose();
    };

    const onOrgChanged = (e, org) => {
        if(org){
            const i = checkedOrganizations.findIndex((a) => a == org);
            if(e.target.checked){
                if(i < 0){
                    let temp = checkedOrganizations.slice(0);
                    temp.push(org);
                    setCheckedOrganizations(temp);
                }
            } else {
                if(i >= 0){
                    let temp = checkedOrganizations.slice(0);
                    temp.splice(i, 1);
                    setCheckedOrganizations(temp);
                }    
            }
        } else { // All Checkbox checked.
            if(e.target.checked)
                setCheckedOrganizations(allOrganizationList);
            else
                setCheckedOrganizations([]);
        }
    }

    //
    return(
        <MISDialog
            dialog={{
                title:t('TABLE_ORGANIZATION_P'),
                modal:true,
                closeOnEscape: true,
                width: 330,
                //height: Object.keys(allOrganizationList)>8?39*10:39*Object.keys(allOrganizationList).length+39,
                onClose: onClose
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        disable: false,
                        onClick: ()=>saveOrganizations()
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose
                    }
                ]
            }}
        >
        <div style={{overflowY:'auto', height:'400px'}}>
            <table>
                <tbody>
                    <tr>
                        <td className={"datalink_assign_org_td"}>
                            <Checkbox
                                id={'all_org'}
                                name={t('TEXT_ALL_P')}
                                checked={allChecked}
                                onChange={(e) => onOrgChanged(e, null)}
                            />
                        </td>
                    </tr>
                {
                 allOrganizationList.length>0 && allOrganizationList.map(org => {
                        return (
                            <tr>
                                <td className={"datalink_assign_org_td"}>
                                    <Checkbox
                                        id={org}
                                        name={org}
                                        checked={checkedOrganizations.includes(org)}
                                        style={{'width': 60}}
                                        onChange={(e) => onOrgChanged(e, org)}
                                    />
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div>

        </MISDialog>
    )
};

export default DataLinkOrgPopup;
