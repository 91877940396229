import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import './EditableSelectBox.css'
import TextInput from "../input/TextInput";
import {useTranslation} from "react-i18next";
import {toastr} from 'helper/toastrIntercept';
import ReactDOM from "react-dom";

const EditableSelectBox =  ({width, defaultTitle, oldValues, setAddedValues, setDeletedValues,valueMaxLimit}) => {
    const selectBox = useRef();
    const {t} = useTranslation();
    const [status, setStatus] = useState({showList: false, showAddInput : false})

    const [allValues, setAllValues] = useState(oldValues); // exist Values + new values

    const [valueInput, setValueInput] = useState('');
    const [tempAddValues, setTempAddValues] = useState([]); // temp values array
    const [tempDelValues, setTempDelValues] = useState([]); // temp values array

    const addInputBtn = useRef();

    const handleClickOutside = (event) => {
        const domNode = ReactDOM.findDOMNode(selectBox.current);

        if(event.target.className.indexOf('addInputBtn') > -1)
            return;

        if (!domNode || !domNode.contains(event.target)) {
            setStatus({...status, showList: false});
        }
    };


    useEffect(() => {
        document.addEventListener('click', (evt) => {handleClickOutside(evt)}, true);
    },[]);

    const removeEvent = (e, value) => {
        if(value.id === undefined ) {
            // if temporarily added then has no id yet, so delete added values, and list
            setAllValues(allValues.filter(item => item.text != value.text)); //for list show
            const a = tempAddValues.filter(item => item != value.text)
            setTempAddValues(a);
            setAddedValues(a); // delete from added values

            return
        }

        tempDelValues.push(value.id);
        setDeletedValues(tempDelValues); // for Del
        setAllValues(allValues.filter(item => item.id != value.id)) //for list show
        console.log(tempDelValues);
    }

    const valueString = allValues.map((value) =>value.text);

    const openSelect = () => {
        setStatus({...status, showList: !status.showList});
    }

    const openAddInput = () => {
        setStatus({...status, showAddInput: !status.showAddInput})
        addInputBtn.current.focus = true;
    }

    const truncatedString = (string) =>{
        return string.length > 30 ? string.substring(0, 30) + '...' : string ;
    }

    const onKeyDownForNewValue = e => {
        if (e.keyCode === 13) {
            if(e.target.value === '')
                return;
            if(e.target.value.length >50) {
                toastr.error(t('MESSAGE_COMMON_MAX_50_P'));
                return
            }

            tempAddValues.push(e.target.value); // for Add
            setAddedValues(tempAddValues); // for Add

            setValueInput('')
            setStatus({...status, showAddInput: false});

            allValues.push({text: e.target.value, value: e.target.value}) //for list show
            setAllValues(allValues) //for list show
        }
    };

    return (
        <div>
            <div className={classNames("menu_select_box")} ref={selectBox}>
                <button className={classNames({'base': true, un_radius: true, select: true})} style={{width: width, display: (status.showAddInput ? 'none' : 'block')}}
                        onClick={() => openSelect()}>
                    <span>{valueString && valueString.length > 0 ? truncatedString(valueString.join(',')) : defaultTitle}<span className="arrow"></span></span>
                </button>
                <TextInput width={300} ref={addInputBtn} value={valueInput} onChange={(e) => setValueInput(e.target.value)} onKeyDown={onKeyDownForNewValue} placeholder={t('MIS_SID_ENTER_VALUES_TO_ADD')} wrapperStyle={ {display: (status.showAddInput ? 'block' : 'none')}} maxLength={valueMaxLimit!=undefined?valueMaxLimit:50}/>
                <div className="option_list" style={{'display': (status.showList ? 'block' : 'none'), width: '100%'}}>
                    <div><span></span>
                        <ul className={"lnb"} style={{height: '100px', maxHeight: '150px', overflow: 'auto'}}>
                            {
                                allValues.length > 0 ? 
                                allValues.map(
                                    (value, index) =>
                                        <li key={index}>
                                            <a href="#" style={{backgroundImage: 'none'}}> {truncatedString(value.text)}</a>
                                            <button className="message_closebox"
                                                    onClick={(e) => removeEvent(e, value)}></button>
                                        </li>
                                ) :
                                <li>{t('MIS_SID_NO_ITEMS')}</li>
                            }

                        </ul>
                    </div>
                </div>
            </div>
            <button className={status.showAddInput == true ? 'addInputBtn on' : 'addInputBtn'} onClick={()=>openAddInput()}><span/></button>

        </div>
    );
}

export default EditableSelectBox;