import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import TooltipIcon from "../../icon/TooltipIcon";
import Checkbox from "../../../components/checkbox/Checkbox";
import SelectBox from "../../selectbox/SelectBox";
import TextInput from "../../input/TextInput";
import {commonConstants} from "../../../constants";
import {deviceService, userService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import TextNumberInput from "../../input/TextNumberInput";

const AddRule = ({onSave, onClose, addPopup, closePopup, ruleId}) => {
    const {t} = useTranslation();
    const [data, setData] = useState({
        ruleName: '',
        ruleNameError: '',
        ruleInfo: '',
        ruleOrg: '',
        ruleOrgError: '',
        organizations: [{value: '', title: ''}],
        deviceGroups: [],
        elements: [],
    });

    useEffect(() => {
        let orgList = [];

        if(ruleId) {
            deviceService.fetchDeviceWarningRulesByRuleId(ruleId).then(res => {
                let elementsForEdit = [];

                res.items.thresholdList && res.items.thresholdList.map((element) => {
                    const isExistElement = res.items.warningRuleInfo.thresholds &&
                        res.items.warningRuleInfo.thresholds.findIndex(threshold => threshold.elementId === element.elementId) !== -1;

                    let elementItem = {
                        ...element,
                        checked: isExistElement,
                        elementValue: isExistElement ? parseInt(res.items.warningRuleInfo.thresholds.find(threshold => threshold.elementId === element.elementId).elementValue) : 1,
                    };

                    elementsForEdit.push(elementItem);
                });

                let deviceGroupsForEdit = [];
                res.items.warningRuleInfo.deviceGroupList && res.items.warningRuleInfo.deviceGroupList.map(group => {
                    deviceGroupsForEdit.push({groupId: group.groupId, groupName: group.groupName});
                });

                setData({
                    ruleName: res.items.warningRuleInfo.ruleName,
                    ruleOrg: {
                        orgId: res.items.warningRuleInfo.organizationId,
                        orgName: res.items.warningRuleInfo.organizationName,
                    },
                    ruleInfo: res.items.warningRuleInfo.ruleDescription,
                    deviceGroups: deviceGroupsForEdit,
                    elements: elementsForEdit,
                });
            });
        } else {
            Promise.all([
                userService.fetchOrganizations(),
                deviceService.fetchDeviceWarningRuleElements()
            ]).then(res => {
                orgList = parseOrg(res[0].items);

                let elementsForAdd = [];
                res[1].items.thresholds.map((element) => {
                    let elementItem = {...element, checked: false, elementValue: 1};

                    elementsForAdd.push(elementItem);
                });
                setData({...data, elements: elementsForAdd, organizations: orgList});
            });
        }
    }, []);

    const onChangeRuleName = (event) => {
        if(event.target.value !== undefined) {
            setData({...data, ruleName: event.target.value, ruleNameError: undefined});
        }
    };

    const onChangeRuleInfo = (event) => {
        setData({...data, ruleInfo: event.target.value});
    };

    const parseOrg = (items) => {
        let orgLIst = [];
        items.map((item) => {
            orgLIst.push({value: item.groupId, title: item.groupName});
        });

        // setData({...data, organizations: orgLIst});
        return orgLIst;
    };

    const onClickOrg = (value) => {
        setData({...data, ruleOrg: value, ruleOrgError: undefined});
    };

    const onSaveGroup = (groups) => {
        let selectedGroups = [];
        groups.map((group) => {
            selectedGroups.push({groupId: group.groupId, groupName: group.groupName})
        });

        setData({...data, deviceGroups : selectedGroups});
        closePopup(commonConstants.COMMON_GROUP_POPUP);

    };

    const onClickDeviceGroup = () => {
        addPopup({
            id: commonConstants.COMMON_GROUP_POPUP,
            type: commonConstants.COMMON_GROUP_POPUP,
            mode: 'device',
            checkbox: true,
            checkedKeys: deviceGroups.map(group => group.groupId),
            expandedKeys: deviceGroups.map(group => group.groupId),
            save: onSaveGroup,
            close: () => closePopup(commonConstants.COMMON_GROUP_POPUP),
        });
    };

    const onClickDeleteGroup = (deviceGroupId) => {
        deviceGroups.splice(deviceGroups.findIndex(group => group.groupId === deviceGroupId), 1);
        setData({...data});
    };

    const onChangeFValue = (value) => {
        return parseInt(value)*9/5 + 32;
    };

    const parseElementAndUnit = (element) => {
        let title = '';
        let unit = '';
        let message = '';

        switch (element.elementId) {
            case 'POWER':
                title = t('TABLE_POWER_P');
                unit = t('MIS_SID_20_MIX_OVER_ALARM').replace("<<A>>", "");
                message = t('MIS_SID_20_WARNING_RULE_OPERATES_FALSE_CASE_OF_INCORRECT_DEVICE_VOLTAGE_EXCEEDS_THE_SET_VALUE');
                break;
            case 'TEMPERATURE':
                title = t('DID_LFD_TEMPERATURE');
                unit = '℃(' + onChangeFValue(element.elementValue) +' ℉)' + t('MIS_SID_20_MIX_OVER_ALARM').replace("<<A>>", "");
                message = t('MIS_SID_20_WARNING_RULE_OPERATES_TEMPERATURE_OF_DEVICES_EXCEEDS_SET_VALUE');
                break;
            case 'IC':
                title = t('COM_LFD_SID_NON_IC');
                unit = t('MIS_SID_20_MIX_OVER_ALARM').replace("<<A>>", "");
                message = t('MIS_SID_WARNING_RULE_FALSE_CASE_DEVICE_MSG');
                break;
            case 'LOD':
                title = t('COM_SID_SIGNAGE_NON_LOD');
                unit = t('MIS_SID_20_MIX_OVER_ALARM').replace("<<A>>", "");
                message = t('MIS_SID_20_RULE_OPERATES_LED_OPEN_DETESCTION_MSG');
                break;
            default:
                break;
        }

        return {title: title, unit: unit, message: message};
    };

    const onClickCheckbox = (index) => {
        elements[index].checked = !elements[index].checked;

        setData({...data, elements});
    };

    const onChangeNumberInput = (event, index, value) => {
        if(event.currentTarget.className === 'number_up' && elements[index].elementValue < elements[index].maxValue) {
            elements[index].elementValue = elements[index].elementValue + 1;
        } else if(event.currentTarget.className === 'number_down' && elements[index].elementValue > 1) {
            elements[index].elementValue = elements[index].elementValue - 1;
        } else if(event.target.value !== '') {
            if(event.target.value >= elements[index].maxValue) {
                elements[index].elementValue = elements[index].maxValue;
            } else if(event.target.value <= 0) {
                elements[index].elementValue = 1;
            } else {
                elements[index].elementValue = parseInt(value);
            }
        } else if (event.target.value === "") {
            elements[index].elementValue = value;
        }

        setData({...data, elements});
    };

    const onClickSave = () => {
        if(ruleName === undefined || ruleName === '') {
            setData({...data, ruleNameError: t('MESSAGE_COMMON_ENTER_NAME_P')});
            return;
        }
        if(ruleOrg === undefined || ruleOrg === '') {
            setData({...data, ruleOrgError: t('MESSAGE_USER_SEL_ORGANIZATION_P')});
            return;
        }
        if(elements.filter(element => element.checked === true).length === 0) {
            toastr.warning(t('MESSAGE_COMMON_SELECT_ONE_MORE_CHECKBOX_P'));
            return;
        }

        let deviceGroupList = [];
        deviceGroups.map((group) => {
            deviceGroupList.push(group.groupId);
        });

        let thresholdList = [];

        elements.map((element) => {
           if(element.checked) {
               thresholdList.push({
                   elementId: element.elementId,
                   elementType: element.elementType,
                   elementValue: element.elementValue,
               })
           }
        });

        let requestBody = {
            groupIds: deviceGroupList,
            organId: ruleOrg.orgId === undefined ? ruleOrg : ruleOrg.orgId,
            ruleDesc: ruleInfo,
            ruleName: ruleName,
            thresholds: thresholdList,
        };

        if(ruleId) {
            requestBody = {...requestBody, ruleId: ruleId};
        }

        onSave(requestBody);
    };

    const {ruleName, ruleNameError, ruleInfo, ruleOrg, ruleOrgError, organizations, deviceGroups, elements} = data;

    return (
        <MISDialog 
            dialog={{
                title: ruleId === undefined ? t('MIS_SID_20_ADD_RULE') : t('MIS_SID_20_EDIT_WARNING_RULE'),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 600,
                height: 600,
                position: {x: -300, y: -370},
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_SAVE',
                        title: t('COM_BUTTON_SAVE'),
                        onClick: onClickSave,
                        // disable: !isEmpty(error)
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className='devicepop' style={{width: '549px', maxHeight: '600px', overflowY: 'auto' }}>
                <div className="add_rule_view">
                    <h3 style={{paddingBottom: '20px', borderBottom: '1px solid #f4f4f4', color: '#797979'}}>{t('MIS_SID_20_BASIC_SETTINGS')}</h3>
                    <table style={{marginTop: '20px'}}>
                        <colgroup>
                            <col width="145px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody style={{verticalAlign: 'middle', color: '#8d8d8d'}}>
                            <tr style={{height: '39px'}}>
                                <th style={{verticalAlign: 'middle'}}>{t('MIS_SID_20_WARNING_RULE_NAME')}</th>
                                <td style={{verticalAlign: 'middle'}}>
                                    <TextInput width={'260px'} maxLength={60} value={ruleName} onChange={onChangeRuleName} error={ruleNameError}/>
                                </td>
                            </tr>
                            <tr style={{height: '39px'}}>
                                <th style={{verticalAlign: 'middle'}}>{t('MIS_SID_20_WARNING_RULE_INFORMATION')}</th>
                                <td style={{verticalAlign: 'middle'}}>
                                    <TextInput width={'260px'} maxLength={60} value={ruleInfo} onChange={onChangeRuleInfo}/>
                                </td>
                            </tr>
                            <tr style={{height: '39px'}}>
                                <th style={{verticalAlign: 'middle'}}>{t('TABLE_ORGANIZATION_P')}</th>
                                <td  className='text_input_wrapper' style={{verticalAlign: 'middle'}}>
                                    {
                                        ruleOrg.orgId === undefined &&
                                        <SelectBox width={243} defaultTitle={t('MESSAGE_USER_SEL_ORGANIZATION_P')}
                                                   classname={'addRule'}
                                                   onClick={onClickOrg}
                                                   selects={organizations}
                                                   optionWidth={'100%'}
                                        />
                                    }
                                    {
                                        ruleOrg.orgId !== undefined &&
                                        <span>{ruleOrg.orgName}</span>
                                    }
                                    {
                                        ruleOrgError &&
                                            <span className="error autoline">{ruleOrgError}</span>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h3 style={{paddingTop: '20px', paddingBottom: '20px', borderBottom: '1px solid #f4f4f4', color: '#797979'}}>{t('MIS_SID_20_RULE_INFORMATION')}</h3>
                    <table>
                        <colgroup>
                            <col width="170px"/>
                        </colgroup>
                        <tbody>
                        <tr style={{height: '90px'}}>
                            <th style={{verticalAlign: 'middle'}}>{t('TEXT_DEVICE_GROUP_P')}</th>
                            <td>
                                <button id="deviceGroupBtn" className="circle_btn search float_l mr8" style={{borderRadius: "15px"}}
                                        onClick={onClickDeviceGroup}
                                />
                            </td>
                            <td style={{width: '230px', height: '50px', display: 'block', padding: '10px', border: '1px solid #e7e7e7', overflowY: 'auto'}}>
                                <div>
                                {
                                    deviceGroups && deviceGroups.map((deviceGroup) => {
                                        return (
                                            <div style={{height: '25px'}}>
                                                <span style={{display: 'inline-block'}}>{deviceGroup.groupName}</span>
                                                <button id={"deleteGroupBtn_" + deviceGroup.groupId} className="selectedDeviceDelBtn"
                                                        style={{verticalAlign: 'middle', float: 'right'}}
                                                        onClick={() => onClickDeleteGroup(deviceGroup.groupId)}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h3 style={{paddingTop: '20px', paddingBottom: '20px', borderBottom: '1px solid #f4f4f4', color: '#797979'}}>{t('MIS_SID_20_WARNING_STANDARD')}</h3>
                    <table style={{marginTop: '20px'}}>
                        <colgroup>
                            <col width="180px"/>
                            <col width=""/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                        {
                            elements && elements.map((element, index) => {
                                return (
                                    <tr style={{height: '35px'}}>
                                        <th style={{verticalAlign: 'middle'}}>
                                            <Checkbox id={element.elementId}
                                                      name={parseElementAndUnit(element).title}
                                                      index={index}
                                                      propertyNmae={element.elementId}
                                                      onClick={() => onClickCheckbox(index)}
                                                      checked={element.checked}
                                            />
                                        </th>
                                        <td className={'addRule'} style={{paddingRight: '30px', verticalAlign: 'middle'}}>
                                            <TooltipIcon
                                                message={parseElementAndUnit(element).message}/>
                                        </td>
                                        <td style={{verticalAlign: 'middle'}}>
                                            <TextNumberInput style={{width: 70}}
                                                         value={element.elementValue}
                                                         min={1} max={element.maxValue}
                                                         disabled={!element.checked}
                                                         onChange={(event, value) => onChangeNumberInput(event, index, value)}/>
                                            <span style={{verticalAlign: 'middle'}}>{parseElementAndUnit(element).unit}</span>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    );
};

export default AddRule;