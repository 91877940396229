import React from 'react';
import './ContentDetailView.css';
import {contentService} from "../../../services";
import {connect} from "react-redux";
import {popupAction} from "../../../actions";
import {withTranslation} from "react-i18next";
import MISDialog from "../MISDialog";
import SubTab from "../../tab/SubTab";
import VersionTable from "../playlist/VersionTable";
import Category from "../../category/Category";
import ContentDetailTab from "./ContentDetailTab";
import MediaTagTab from "./MediaTagTab";
import WhiteButton from "../../button/WhiteButton";
import {commonConstants, contentConstants} from "../../../constants";
import ContentThumbnail from "../../image/ContentThumbnail";
import {toastr} from 'helper/toastrIntercept';
import {validateSpecialChar} from "../../../helper";
import isEmpty from "lodash/isEmpty";

const subTabs = [
    'BUTTON_DETAIL_P',
    'TABLE_VERSION_P',
    'DID_MAIN_CATEGORY',
    'MIS_TEXT_TAG_P',
];

class ContentDetailView extends React.Component {

    constructor(props) {        
        super(props);
        this.state = {
            content : undefined,
            contentTitle: undefined,
            subTab: subTabs[0],

            contentVersions: undefined,
            selectedThumbnailId: undefined,
            selectedVersionId: undefined,
            selectedCategoryIds: undefined,

            contentTags: undefined,
            error : {
                contentName: undefined,
                streamingUrl: undefined
            }
        }
    }

    componentDidMount() {
        this.fetchContentDetail();
    }

    fetchContentDetail = () => {
        const {id, addPopup, closePopup} = this.props;
        addPopup({id: 'CONTENT_DETAIL', type: commonConstants.LOADING_POPUP});
        contentService.fetchContentById(id).then(
            res=> {
                this.setState({
                    content: res.items,
                    contentTitle: res.items.contentName,
                    contentTags: res.items.tags,
                    selectedThumbnailId: res.items.thumbFileId,
                    selectedCategoryIds: res.items.categories && res.items.categories.map(category => category.groupId),
                });
            }
        ).finally(() => {
            closePopup('CONTENT_DETAIL');
        });
        contentService.fetchContentVersionById(id).then(
            res => this.setState({
                    contentVersions:
                        res.items.map((item) => {
                            return (
                                {
                                    versionId: item.versionId,
                                    isActive: item.isActive,
                                    lastModifiedDate: item.lastModified,
                                    thumbnailId: item.thumbnailId,
                                }
                            )
                        })
                }
            )
        )
    }

    closePopup = (contentId, type) => {
        const {closePopup, onSuccess} = this.props;
        closePopup(contentId);
        this.setState({contentTags: undefined});
        if(type === 'SUCCESS') {
            if(onSuccess){
                onSuccess();
            }
        }
    };

    clickSave = () => {
        const {t} = this.props;
        const {subTab, content, contentVersions, selectedVersionId, selectedCategoryIds, contentTags} = this.state;

        switch(subTab) {
            case subTabs[0]:     //Detail
                if(!validateSpecialChar(content.contentName)){
                    this.setState({error: {contentName:t('COM_EBD_SPECIAL_CHAR_NOTALLOW_KR_2')}});
                }else if(isEmpty(content.contentName.trim())){
                    this.setState({error: {contentName:t('MESSAGE_SCHEDULE_ENTER_MESSAGE_HERE_P')}});
                }else if(content.mediaType === 'STRM' && isEmpty(content.streamingUrl.trim())){
                    this.setState({error: {streamingUrl:t('MESSAGE_SCHEDULE_ENTER_MESSAGE_HERE_P')}});
                }else {
                    const contentForUpdate = {
                        contentId: content.contentId,
                        contentName : content.contentName || '',
                        groupId : content.groupId ||  0,
                        isHidden : content.isHidden || false,
                        metaData : content.metaData || '',
                        strmAddress : content.streamingUrl || '',
                        expirationDate: content.expirationDate || '',
                        isExpirationDateActivated: content.expirationDate !== '29991231' || false,
                    };

                    contentService.updateContentById(contentForUpdate).then(
                        res => {
                            if(res.status === 'Success') {
                                toastr.success(t('MIS_TEXT_FTP_MODIFIED_P'));
                                this.closePopup(content.contentId, 'SUCCESS');
                            }
                        }
                    )
                        .catch(error => {
                            toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
                        }).finally(() => {
                            this.setState({error:undefined});
                    });
                }
                break;
            case subTabs[1]:     //Version
                contentVersions.map((version) => {
                    if(version.isActive === 'N' && version.versionId === selectedVersionId) {
                        const contentForVersionUpdate = {
                            contentId: content.contentId,
                            versionId : selectedVersionId,
                        };
                        contentService.updateContentVersionByVersionId(contentForVersionUpdate).then(
                            res => {
                                if(res.status === 'Success') {
                                    toastr.success(t('MIS_TEXT_FTP_MODIFIED_P'));
                                    this.closePopup(content.contentId, 'SUCCESS');
                                }
                            }
                        )
                            .catch(error => {
                                toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
                            });
                    } else {
                        this.closePopup(content.contentId, 'SUCCESS');
                    }
                });
                break;
            case subTabs[2]:     //Category
                const categoryForUpdate = {contentIds: [content.contentId], categoryIds: selectedCategoryIds || []};
                contentService.updateContentCategory(categoryForUpdate).then(
                    res => {
                        if(res.status === 'Success') {
                            toastr.success(t('MIS_TEXT_FTP_MODIFIED_P'));
                            this.closePopup(content.contentId, 'SUCCESS');
                        }
                    }
                )
                    .catch(error => {
                        toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
                    });
                break;
            case subTabs[3]:     //Tag
                let tagConditionList = [];
                contentTags && contentTags.map((tag) => {
                    tag.contentTagConditions ?
                        tag.contentTagConditions.map((condition) => {
                            if(tag.tagType === contentConstants.TAG_TYPE_TEXT) {
                                if(condition.checkCondition) {
                                    tagConditionList.push({
                                        tagId: condition.tagId,
                                        tagConditionId: condition.tagConditionId,
                                        tagCondition: condition.tagCondition,
                                    });
                                }
                            } else {
                                tagConditionList.push({
                                    tagId: condition.tagId,
                                    tagConditionId: condition.tagConditionId,
                                    tagCondition: condition.tagCondition,
                                });
                            }
                        })
                        :
                        tag.tagConditions && tag.tagConditions.map((condition) => {
                            tagConditionList.push({
                                tagId: tag.tagId,
                                tagConditionId: condition.conditionId,
                                tagCondition: condition.conditionValue,
                            });
                        });
                });

                const tagForUpdate = {
                    contentIds: [content.contentId],
                    tags:
                        contentTags ? contentTags.map((tag) => {
                            let tempConditionList = [];
                            tagConditionList.map(condition => {
                                if(condition.tagId === tag.tagId) {
                                    if(tag.tagType === contentConstants.TAG_TYPE_NUMBER ||
                                        tag.tagType === contentConstants.TAG_TYPE_BOOLEAN) {     // Number  or Boolean
                                        tempConditionList.push(condition.tagCondition)
                                    } else {                    // Text
                                        tempConditionList.push(condition.tagConditionId)
                                    }
                                }
                            });
                            return ({
                                tagId: tag.tagId,
                                tagType: tag.tagType,
                                tagConditions: tempConditionList,
                            })
                        })
                        :
                        [],
                };

                contentService.updateContentTag(tagForUpdate).then(
                    res => {
                        if(res.status === 'Success') {
                            toastr.success(t('MIS_TEXT_FTP_MODIFIED_P'));
                            this.closePopup(content.contentId, 'SUCCESS');
                        }
                    }
                )
                    .catch(error => {
                        toastr.error(t('COM_IDS_MSG_UNEXPEXTED_ERROR'))
                    });
                break;
            default:
                break;
        }

    };

    onClickSubTab = (id) => {
        this.setState({subTab: id});
    };

    onChangeDetail = (content) => {
        this.setState({
            content: content,
        });
    };

    openContentUpload = () => {
        const {openDetailView, onSuccess, zIndex} = this.props;
        const {content} = this.state;
        
        openDetailView({
            type: commonConstants.UPLOAD_CONTENT,
            mode: 'UPDATE',
            zIndex: zIndex,
            updateContentId: content.contentId,
            onSuccess: () => {
                this.fetchContentDetail();
                onSuccess();
            },
        });
    };

    openContentInformation = () => {
        const {addPopup, closePopup} = this.props;
        const {content} = this.state;

        addPopup({
            id: commonConstants.INFORMATION_CONTENT,
            type: commonConstants.INFORMATION_CONTENT,
            popupType: content.mediaType,
            contentId: content.contentId,
            onClose: () => closePopup(commonConstants.INFORMATION_CONTENT)
        });
    };

    onChangeVersionId = (versionId) => {
        const {contentVersions} = this.state;

        const selectedThumbnailId = contentVersions.find(version => version.versionId === versionId).thumbnailId;

        this.setState({
            selectedThumbnailId: selectedThumbnailId,
            selectedVersionId: versionId,
        })
    };

    onChangeCategoryCheck = (categoryIds) => {

        this.setState({selectedCategoryIds: categoryIds});
    };


    onChangeTagInfo = (tagList) => {
        this.setState({contentTags: tagList});
    };

    render() {
        const {t, zIndex, id} = this.props;
        const {content, contentTitle, subTab, contentVersions, selectedThumbnailId, selectedVersionId, selectedCategoryIds, contentTags, error} = this.state;

        return (
            <MISDialog
                dialog={{
                    title: contentTitle,
                    isDraggable: true,
                    closeOnEscape: true,
                    allowMinimize: true,
                    width: 876,
                    height: subTab === subTabs[0] && content && (content.mediaType === 'FTP' || content.mediaType === 'CIFS') ? 680 : 600,
                    position: {x: -400, y: -225},
                    zIndex : zIndex,
                    popupId : id,
                    onClose: () => this.closePopup(content.contentId),
                }}
                buttons={ content !== undefined && content.hasWritePermission ? {
                    rightButtons: [
                        {
                            title: t('COM_BUTTON_SAVE'),
                            onClick: () => this.clickSave(),
                        },
                        {
                            title: t('COM_BUTTON_CLOSE_P'),
                            onClick: () => this.closePopup(content.contentId),
                        }
                    ]
                } : {
                    rightButtons: [
                        {
                            title: t('COM_BUTTON_CLOSE_P'),
                            onClick: () => this.closePopup(content.contentId),
                        }
                    ]
                }}>
                {
                    content !== undefined &&
                    <div>
                        <div className="type1">
                            <div className="popup_contents clearfix" style={{display:'inline'}}>
                                <div className="playlist_imgview mt25" style={{minHeight: 450}}>
                                    <div className="preview_wrap" align="center" style={{width:370}}>
                                        <ContentThumbnail id={selectedThumbnailId} filename={content.thumbFileName} resolution ={'ORIGIN'}
                                                          width={370} height={208} textAlign={true}/>
                                    </div>
                                </div>
                                <div className="content_detail ml50 mb15" style={{width:420, float: 'left'}}>
                                    <SubTab tabs={subTabs} selected={subTab} viewSize={4} onClick={this.onClickSubTab} />
                                    { subTab === subTabs[0] && <ContentDetailTab content={content} onChangeDetail={this.onChangeDetail} error={error}/>}
                                    { subTab === subTabs[1] &&
                                    <div style={{marginTop: '15px'}}>
                                        {
                                            !content.isApprovalEnabled &&
                                            content.hasWritePermission &&
                                            content.mediaType !== 'LFT' &&
                                            content.mediaType !== 'STRM' &&
                                            content.mediaType !== 'HTML' &&
                                            content.mediaType !== 'FTP' &&
                                            content.mediaType !== 'CIFS' &&
                                            <WhiteButton className='content_detail_update_btn' name={t('MIS_SID_20_UPDATE_FILE')}
                                                         onClick={this.openContentUpload}
                                            />
                                        }
                                        {
                                            !content.isApprovalEnabled &&
                                            content.hasWritePermission &&
                                            (content.mediaType === 'FTP' || content.mediaType === 'CIFS') &&
                                            <WhiteButton className='content_detail_update_btn' name={t('COM_TEXT_INFORMATION_P')}
                                                         onClick={this.openContentInformation}
                                            />
                                        }
                                        <VersionTable versions={contentVersions}
                                                      selectedVersionId={selectedVersionId}
                                                      isChangeable={!content.isApprovalEnabled}
                                                      onChangeVersionId={this.onChangeVersionId}/>
                                    </div>
                                    }
                                    { subTab === subTabs[2] &&
                                    <Category type={'tab'}
                                              onCategoryCheck={this.onChangeCategoryCheck}
                                              checkStrictly={true}
                                              defaultExpandAll={true}
                                              defaultCheckedKeys={selectedCategoryIds}
                                    />
                                    }
                                    { subTab === subTabs[3] && <MediaTagTab hasWritePermission={content.hasWritePermission} tags={contentTags} onChangeTagInfo={this.onChangeTagInfo} zIndex={zIndex}/> }
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </MISDialog>
        )
    }

}
export default connect(
    state => ({

    }),
    dispatch => ({
        closePopup: (id)=>dispatch(popupAction.closePopup(id)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        openDetailView : (type)=> dispatch(popupAction.openDetailView(type))
    })

)(withTranslation()(ContentDetailView));