import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import '../../../components/table/react-table.css';
import MISDialog from '../MISDialog'
import isEmpty from 'lodash/isEmpty';
import {contentConstants} from "../../../constants";
import {settingService} from "../../../services";
import {dateToString} from "../../../helper";
import ContentThumbnail from "../../image/ContentThumbnail";
import {convertTagContent} from "../../../containers/playlist/playlistUtil";
import MagicInfoTable from "../../table/MagicInfoTable";
import cloneDeep from 'lodash/cloneDeep';
import TagValueSelectCell from "./TagValueSelectCell";

const getTagInformationPopup = React.forwardRef(({original, selectedTagList, tagList, onSave, onClose}, ref) => {
    const {t} = useTranslation();
    const onCloseBtn = () => {onClose()}
    let selectedTag = {...original};

    const [tagData, setTagData] = useState(cloneDeep(tagList));
    const selectedTagData = cloneDeep(selectedTagList);

    const [selTagState, setSelTagState]= useState(selectedTag);

    const onChangeCondition = (tagValues) => {
        setSelTagState({...selTagState, tagConditionTitle:tagValues[0].tagConditionTitle, tagConditions:  getFilteredContentTagConditions(tagValues[0]).map((condition)=>{
            return {
                tag_condition_id : condition.tagConditionId,
                tag_condition: condition.tagCondition,
            }
        })})
        setFilteredContentTagConditions(getFilteredContentTagConditions(tagValues[0]))
    };

    const onChangeTagListCondition = (changedTagList) => {
        setTagData(changedTagList);
    };

    const getDefaultTagCondition = (selectedTag) => {
        if (selectedTag.tagType === contentConstants.TAG_TYPE_BOOLEAN) {
            return ['-3'];
        } else {
            return [""];
        }
    };

    const onApplyBtn = () => {
        selTagState.contents = data.contentsData;
        const contentTagItem = convertTagContent(selTagState);
        onSave(contentTagItem)
    };

    const getFilteredContentTagConditions = (selTag) => {
        if (selTag.tagType === 0) {
            if (selTag.contentTagConditions) {
                return selTag.contentTagConditions.filter((condition)=>condition.checkCondition)
            }  else if (selTag.tagType === 0 && selTag.tagConditionList) {
                return selTag.tagConditionList;
            }
        } else if (selTag.tagType === 1) {
            return selTag.contentTagConditions;
        } else if (selTag.tagType === 2) {
            return selTag.contentTagConditions;
        }
    }

    const [filteredContentTagConditions, setFilteredContentTagConditions] = useState(getFilteredContentTagConditions(selectedTag));

    useEffect(()=> {
        let checkedTagConditions = [];

        switch (selectedTag.tagType) {
            case contentConstants.TAG_TYPE_NUMBER:
                checkedTagConditions = !isEmpty(filteredContentTagConditions) ? filteredContentTagConditions.map((condition)=> {return condition.tagCondition}) : getDefaultTagCondition(selectedTag);
                break;
            case contentConstants.TAG_TYPE_BOOLEAN:
                checkedTagConditions = selectedTag.contentTagConditions ? selectedTag.contentTagConditions.map((condition) =>{ return condition.tagConditionId}) : getDefaultTagCondition(selectedTag);
                break;
            case contentConstants.TAG_TYPE_TEXT:
                checkedTagConditions = !isEmpty(filteredContentTagConditions) ? filteredContentTagConditions.map((condition)=> {return condition.tagConditionId}) : getDefaultTagCondition(selectedTag);
                break;
        }

        Promise.all([
            settingService.fetchTagAndContentsWidthIdAndTagValues(selectedTag.tagId, {ids: checkedTagConditions})
        ]).then(res=> {
            const items = res[0].items ? res[0].items : [];
            setData({contentsData: items, countInfo : items.length})
        }).catch(
            error => console.log(error)
        ).finally()
    }, [filteredContentTagConditions]);

    const [data, setData] = useState({
        contentsData: [],
        countInfo: 0
    });

    const contentColumns = useMemo(() => [
        {
            Header: t("TEXT_CONTENT_P"),
            width: 100,
            accessor: 'thumbFileId',
            Cell: (props) => <div style={{margin:'0px auto'}}><ContentThumbnail id={props.original.thumb_file_id} width={95} height={56}/></div>
        },
        {
            Header: t("TEXT_CONTENT_NAME_P"),
            accessor: "contentName",
            Cell: (props) => <span style={{textAlign:'center'}} className={"data_name"}>{props.original.content_name}</span>,
            width: 180
        },
        {
            Header: <span>{t("COM_LIST_TITLE_FILE_TYPE")}</span>,
            width: 100,
            Cell: ({original})  => <span style={{textAlign:'center'}}>{original.media_type}</span>
        },
        {
            Header: <span>{t("TABLE_RESOLUTION_P")}</span>,
            width: 80,
            Cell: ({original})  => <span style={{textAlign:'center'}}>{original.resolution}</span>
        },
        {
            Header: <span>{t("COM_LIST_TITLE_VERSION")}</span>,
            width: 100,
            Cell: ({original})  => <span style={{textAlign:'center'}}>{original.version_id}</span>
        },
        {
            Header: t("TEXT_CREATOR_P"),
            accessor: "creatorId",
            width: 70,
            Cell: ({original})  => <span style={{textAlign:'center'}}>{original.creator_id}</span>
        },
        {
            Header: t("TABLE_CREATE_DATE_P"),
            accessor: "createdDate",
            Cell: props => <div><span style={{textAlign:'center'}}>{dateToString(new Date(props.original.create_date))}</span></div>
        }
    ], []);

    return (
        <MISDialog
            dialog={{
                title: t("COM_MIP_IDS_TAB_DATALINK_STT_TAG_INFO"),
                modal: false,
                isDraggable: true,
                closeOnEscape: true,
                width: 780,
                height: 'auto',
                onClose: onCloseBtn,
            }}
            buttons={{
                rightButtons: [
                    {
                        title: t('COM_BUTTON_SAVE'),
                        onClick: onApplyBtn,
                    },
                    {
                        title: t('BUTTON_OK_P'),
                        onClick: onCloseBtn,
                    }
                ]
            }}>
            <div style={{width: 'auto', height: 350, maxHeight:'none', minHeight: 0, top:-15, position: 'relative', overflowY: 'auto' }}>
                <div>
                    <div className="detail_view mini_p ml10" style={{paddingTop: '0px!important;'}}>
                        <table>
                            <colgroup>
                                <col width="116px" />
                                <col width="" />
                            </colgroup>
                            <tbody>
                            {
                                selectedTag.tagType !== contentConstants.TAG_TYPE_NUMBER &&
                                <tr>
                                    <th>{t("MIS_TEXT_TAG_NAME_P_KR_MIS20")}</th>
                                    <td>{selectedTag.tagName}</td>
                                </tr>
                            }
                            <tr>
                                {
                                    selectedTag.tagType !== contentConstants.TAG_TYPE_NUMBER &&
                                    <th>{t("MIS_TEXT_TAG_VALUE_P")}</th>
                                }
                                <TagValueSelectCell
                                    mode={'playlist'}
                                    originalTag={selectedTag}
                                    selectedTagList={selectedTagData}
                                    tagList={tagData}
                                    onChangeCondition={onChangeCondition}
                                    onChangeTagList={onChangeTagListCondition}
                                />
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={'list_wrap mt15'} style={{display:'inline-block', width: 'calc(100% - 20px)'}}>
                        <div className="content_number float_r mb10" style={{color: '#818181' ,fontSize: 12}}>
                            {t("COM_SID_ALL")} : {data.countInfo}
                        </div>
                        <MagicInfoTable
                            widthStyle={{width: '100%'}}
                            data={data.contentsData}
                            minRows={3}
                            maxRows={data.contentsData ?data.contentsData.length : 10}
                            columns={contentColumns}
                            style={{width:'100%'}}
                            className="-striped -highlight"
                            showPagination={false}
                        />
                    </div>
                </div>
            </div>

        </MISDialog>
    );
});

export default getTagInformationPopup;