import {authHeader, createParams, handleBinDownloadResponse, handleResponse, objToQueryString} from "../helper";
import {fetchWithToken as fetch} from '../helper/fetchIntercept.js';
import {fetchForDownload} from "../helper/fetchIntercept";

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;
const baseAddress = backendHost + "/restapi/v2.0/cms/rulesets";
const baseAddressDatalink = backendHost + "/restapi/v2.0/ems/settings/external-servers/datalink-servers";

export const rulesetService = {
    fetchRulesetFilter,
    fetchRulesetById,
    fetchConditionFilter,
    fetchRulesetGroups,
    fetchRulesetGroupsById,
    fetchDeleteRuleset,
    fetchRestoreRuleset,
    createRuleset,
    updateRuleset,
    moveRuleset,
    createRulesetGroup,
    renameRulesetGroup,
    deleteRulesetGroup,
    fetchPlayFilter,
    createCondition,
    editCondition,
    fetchConditionById,
    fetchPlayById,
    createPlay,
    editPlay,
    fetchConditionPreview,
    deleteConditions,
    deletePlays,
    fetchResultKeyword,
    editResultKeyword,
    deleteResultKeywords,
    createResultKeyword,
    fetchResultKeywordPreview,
    fetchContentInRuleset,
    exportRuleset,
    fetchDatalinkTable,
    fetchDatalinkServerList,
    moveRulesetGroup,
    fetchRulesetContentDownloadStatus,
    emptyRecycleBin
};

function fetchRulesetFilter(values) {
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: createParams(values)
    };

    return fetch(baseAddress + "/filter", requestOptions).then(handleResponse);
}

function fetchRulesetById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(baseAddress + "/" + id, requestOptions).then(handleResponse);
}

function fetchConditionFilter(values) {
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: createParams(values)
    };
    return fetch(baseAddress + "/conditions/filter", requestOptions).then(handleResponse);
}

function fetchRulesetGroups() {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(baseAddress + "/groups", requestOptions).then(handleResponse);
}

function fetchRulesetGroupsById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(baseAddress + "/groups/" + id + "/sub-groups", requestOptions).then(
        handleResponse
    );
}

function fetchDeleteRuleset(params) {
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: createParams(params)
    };
    return fetch(baseAddress + "/deleted-rulesets", requestOptions).then(handleResponse);
}

function fetchRestoreRuleset(params) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader("PUT"),
        body: createParams(params)
    };
    return fetch(baseAddress + "/deleted-rulesets", requestOptions).then(handleResponse);
}

function createRuleset(ruleset) {
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: createParams(ruleset)
    };

    return fetch(baseAddress, requestOptions).then(handleResponse);
}

function updateRuleset(ruleset) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader("PUT"),
        body: createParams(ruleset)
    };

    return fetch(baseAddress + "/" + ruleset.rulesetId, requestOptions).then(handleResponse);
}

function moveRuleset(rulesetIds, groupId) {
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: createParams({
            ids: rulesetIds
        })
    };

    return fetch(baseAddress + "/" + groupId + "/moved-rulesets", requestOptions).then(handleResponse);
}

function fetchPlayFilter(values) {
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: createParams(values)
    };

    return fetch(baseAddress + "/results/filter", requestOptions).then(
        handleResponse
    );
}

function createCondition(values) {
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: createParams(values)
    };

    return fetch(baseAddress + "/conditions", requestOptions).then(
        handleResponse
    );
}

function createPlay(values) {
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: createParams(values)
    };

    return fetch(baseAddress + "/results", requestOptions).then(handleResponse);
}

function fetchConditionById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(baseAddress + "/conditions/" + id, requestOptions).then(handleResponse);
}

function fetchPlayById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(baseAddress + "/results/" + id, requestOptions).then(handleResponse);
}

function editCondition(values) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader("PUT"),
        body: createParams(values)
    };

    return fetch(baseAddress + "/conditions/" + values.conditionId, requestOptions).then(
        handleResponse
    );
}

function editPlay(values) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader("PUT"),
        body: createParams(values)
    };

    return fetch(baseAddress + "/results/" + values.resultId, requestOptions).then(handleResponse);
}

function fetchConditionPreview(values) {
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: createParams(values)
    };
    return fetch(baseAddress + "/conditions/preview", requestOptions).then(handleResponse);
}

function deleteConditions({conditionIds}) 
{
    const params = conditionIds || [] ;
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: JSON.stringify(params)
    };
    return fetch(baseAddress + "/conditions/deleted-conditions", requestOptions).then(handleResponse);
}

function deletePlays({playIds}) {
    const params = playIds || [] ;
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: JSON.stringify(params)
    };
    return fetch(baseAddress + "/results/deleted-results", requestOptions).then(handleResponse);
}

function createRulesetGroup(newGroup) {
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: JSON.stringify(newGroup)
    };
    return fetch(baseAddress + "/groups", requestOptions).then(handleResponse);
}

function renameRulesetGroup(groupId, newGroupName) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader("PUT"),
        body: JSON.stringify({newGroupName: newGroupName})
    };
    return fetch(baseAddress + '/groups/' + groupId, requestOptions).then(handleResponse);
}

function deleteRulesetGroup(groupId) {
    const requestOptions ={
        method: "DELETE",
        headers: authHeader("DELETE"),       
    }
    return fetch(baseAddress + '/groups/' + groupId, requestOptions).then(handleResponse);
}


function createResultKeyword(keyword) {
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: JSON.stringify(keyword)
    };
    return fetch(baseAddress + "/results/keywords", requestOptions).then(handleResponse);
}

function deleteResultKeywords({keywordIds}) {
    const params = keywordIds || [] ;
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: JSON.stringify(params)
    };
    return fetch(baseAddress + "/results/deleted-keywords", requestOptions).then(handleResponse);
}

function editResultKeyword(keyword) {
    const requestOptions = {
        method: "PUT",
        headers: authHeader("PUT"),
        body: createParams(keyword)
    };

    return fetch(baseAddress + "/results/keywords/" + keyword.keywordId, requestOptions).then(handleResponse);
}

function fetchResultKeyword(startIndex, pageSize) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(baseAddress + "/results/keywords/"+ startIndex +"/" +pageSize, requestOptions).then(handleResponse);
}

function fetchResultKeywordPreview(values) {
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: createParams(values)
    };
    return fetch(baseAddress + "/results/keywords/preview", requestOptions).then(handleResponse);
}

function fetchContentInRuleset(rulesetId){
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(baseAddress + "/"+ rulesetId+"/contents", requestOptions).then(handleResponse);
}

function exportRuleset(type, filter) {
    const requestOptions = {
        method: "POST",
        headers: authHeader("POST"),
        body: createParams(filter)
    };
    return fetchForDownload(`${baseAddress}/filter/export?exportType=${type}`, requestOptions).then(handleBinDownloadResponse);
}

function fetchDatalinkServerList(){
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };
    return fetch(baseAddressDatalink + "/server", requestOptions).then(handleResponse);
}

function fetchDatalinkTable(filter){
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    let obj ={
        url: filter
    };
    let params = objToQueryString(obj);
    return fetch(baseAddressDatalink+"/table"+params, requestOptions).then(handleResponse);
}



function moveRulesetGroup(groupId, destinationGroupId) {
    return fetch(`${baseAddress}/groups/${groupId}/destination-groups/${destinationGroupId}`, {
        method: 'PUT',
        headers: authHeader('PUT')
    }).then(handleResponse);
}

function fetchRulesetContentDownloadStatus(rulesetId, deviceId) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(`${baseAddress}/${rulesetId}/${deviceId}`, requestOptions).then(handleResponse);
}

function emptyRecycleBin() {
    const requestOptions ={
        method: "DELETE",
        headers: authHeader("DELETE"),       
    }
    return fetch(baseAddress + "/recycle-bin", requestOptions).then(handleResponse);
}