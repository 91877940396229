import TimeField from "react-simple-timefield";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import "./CurrentTime.css";
import {toastr} from 'helper/toastrIntercept';
import {validTime} from "../../../../helper/ruleset/rulesetUtils";

const CurrentTime = ({ handleValues, orgValues }) => {
    const { t } = useTranslation();

    const [values, setValues] = useState(orgValues ? orgValues : [
        {
            start: "00:00:00",
            end: "23:59:59"
        }
    ]);

    const deleteTime = index => {
        const newValues = [...values];
        newValues.splice(index, 1);
        setValues(newValues);
        handleValues(newValues);
    };

    const addTime = () => {

        const length = values.length;
        const value = values[length-1];         

        if(!validTime(value)){
            toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
        }else{
            const value = {
                start: "00:00:00",
                end: "23:59:59"
            };
            const newValues = values.concat(value);
            setValues(newValues);
            handleValues(newValues);
        }     
    };     
    
    const timeFieldStyle = {
        width: "100px",
        textAlign: "center",
        margin: "7px"
    };

    return (
        <table className="curr_time">
            <tr id="setting">
                <th>
                    {t("TEXT_TIME_P")}
                </th>
                <td>
                    <div className="time_box">
                        <ul>
                            <li className="time_range">
                                {values.map((item, index) => (
                                    <div>
                                        <TimeField
                                            value={item.start}
                                            key={index + 'start'}
                                            onChange={(event, value) => {
                                                setValues(values =>
                                                    values.map((v, i) =>
                                                        index === i ? { ...v, start: value } : v
                                                    )
                                                );
                                                handleValues(values =>
                                                    values.map((v, i) =>
                                                        index === i ? { ...v, start: value } : v
                                                    )
                                                );
                                            }}                                            
                                            showSeconds
                                            style={timeFieldStyle}
                                        />
                                        {"-"}
                                        <TimeField
                                            value={item.end}
                                            key={index + "end"}
                                            onChange={(event, value) => {
                                                setValues(values =>
                                                    values.map((v, i) =>
                                                        index === i ? { ...v, end: value } : v
                                                    )
                                                );
                                                handleValues(values =>
                                                    values.map((v, i) =>
                                                        index === i ? { ...v, end: value } : v
                                                    )
                                                );
                                            }}   
                                            showSeconds
                                            style={timeFieldStyle}
                                        />
                                        {index > 0 && (
                                            <button
                                                className="delete"
                                                onClick={e => {
                                                    const i = index;
                                                    deleteTime(i);
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
            <tr id="add">
                <th></th>
                <td style={{ paddingTop: "0px" }}>
                    <button className="plus_btn" onClick={addTime} />
                    <span style={{ float: "left", margin: "8px" }}>
                        {t("TEXT_TIME_P")}
                    </span>
                </td>
            </tr>
        </table>
    );
};

export default CurrentTime;
