import React, {useEffect, useState} from 'react';
import {ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY} from "../../../../constants";
import Moment from "moment";
import {extendMoment} from 'moment-range';
import {Resizable} from 'react-resizable';
import {DraggableCore} from 'react-draggable';
import ContentThumbnail from "../../../../components/image/ContentThumbnail";

const getBackground = (index) => {
    const colors = ["#80cbff", "#6ee6a9", "#ff92b1", "#b22222", "#ff8c00", "#7B68EE"]
    return colors[index];
}

const EventBar = ({mode, height, timeWidth, maxWidth, tableStartTime, tableEndTime, view, slotId, openADPlaylistPopup, event, onUpdateSchedule}) => {

    const maxLimitWidth = maxWidth - 170;

    const [isDragging, setIsDragging] = useState(false)
    const [isMoving, setIsMoving] = useState(false)
    const [eventStyle, setEventStyle] = useState({})
    const [currentStatus, setCurrentStatus] = useState({})
    const [forceRender, setForceRender] = useState(false);

    const moment = extendMoment(Moment);


    useEffect(()=> {

        const start = moment(event.startDate+' '+event.startTime);
        const end = moment(event.stopDate+' '+event.stopTime);
        const range = moment.range(start, end);

        let findStartTime =  tableStartTime.clone();
        let findEndTime = view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY ? tableStartTime.clone().add(2, 'h') : tableStartTime.clone().add(1, 'd');
        let eventStartIndex = null, index= 0 , count = 0;
        while(tableEndTime.isAfter(findStartTime)) {
            if ((findStartTime.isSame(start) || findStartTime.isBefore(start)) && (findEndTime.isSame(end) || findEndTime.isAfter(end))) {
                if (eventStartIndex == null) {
                    eventStartIndex = index;
                }
                count++;
            } else if (range.contains(findStartTime) && !findStartTime.isSame(end)) {
                if (eventStartIndex == null) {
                    eventStartIndex = index;
                }
                count++;
            }
            index++;
            if (view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY) {
                findStartTime.add(2, 'hours');
                findEndTime.add(2, 'hours');
            } else {
                findStartTime.add(1, 'days');
                findEndTime.add(1, 'days');
            }
        }

        const duration = moment.duration(end.toDate() - start.toDate()).asSeconds();

        setEventStyle({
            left : timeWidth * eventStartIndex,
            width : timeWidth * count,
            height: height -4,
            background: getBackground(0),
            isUpdate: false
        })
        setCurrentStatus({
            startIndex:eventStartIndex,
            duration: duration
        });

    }, [event, forceRender, tableStartTime, tableEndTime, timeWidth])

    const [isDraggable, setIsDraggable] = useState(false);
    const [isResizable, setIsResizable] = useState(false);

    const onResize = (e, data) => {
        if (isDraggable) {
            setIsDraggable(false)
        }
        if (isResizable) {
            const {width} = data.size;
            const {layerX} = e;


            if (width < layerX) {
                if (eventStyle.width+timeWidth + eventStyle.left <= maxLimitWidth) {
                    setEventStyle({
                        ...eventStyle,
                        width: eventStyle.width+timeWidth
                    })
                }
            } else {
                if (eventStyle.width-timeWidth >= timeWidth) {
                    setEventStyle({
                        ...eventStyle,
                        width: eventStyle.width-timeWidth
                    })
                }
            }
        }
    }

    const onResizeStart = (e, data) => {
        if (isDraggable) {
            setIsDraggable(false)
        }
        setIsResizable(true);
    }

    const onResizeStop = (e, data) => {
        e.stopPropagation();
        e.preventDefault();
        if (isResizable) {
            const {width} = eventStyle;
            let widthSize = Math.round(width / timeWidth);
            const start = Moment(event.startDate + ' ' +event.startTime);
            const duration = view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY ? widthSize * 7200 : widthSize * 86400;
            const end = start.clone().add(duration, 'seconds')
            const updateEvent = {...event, stopDate: end.format('YYYY-MM-DD'), stopTime: end.format('HH:mm:ss')};
            const valid = onUpdateSchedule(slotId, updateEvent);
            if (!valid) {
                setForceRender(!forceRender);
            }
            setIsResizable(false);
        }
    }

    const onDragStart = (e, data)  => {
        if (e.target.tagName !== 'IMG') {
            setIsDraggable(true)
        }
    }

    const onDrag = (e, data) => {
        if (!isMoving) {
            setIsMoving(true)
        }
        if (isDraggable) {
            const {deltaX} = data;
            const updateLeft = eventStyle.left + deltaX;
            if ( 0 <= updateLeft && maxLimitWidth >= updateLeft+eventStyle.width) {
                setEventStyle({
                    ...eventStyle,
                    left: updateLeft
                })
            }
        }
    }

    const onDragStop = (e, data) => {
        if (!isResizable && isDraggable) {
            if (!isMoving) {
                openADPlaylistPopup(event);
            } else {
                const {left} = eventStyle;
                const {duration} = currentStatus;

                let currentIndex = 0;
                if (left > 0) {
                    currentIndex = Math.round(left / timeWidth);
                }
                const startTime  = tableStartTime.clone();
                const start = view === ADVERTISEMENT_SCHEDULE_VIEW_WEEKLY ? startTime.add(currentIndex*2, 'h') : startTime.add(currentIndex, 'd');
                const end = start.clone().add(duration,'seconds');
                setCurrentStatus({
                    startIndex : currentIndex
                });
                const updateEvent = {...event, startDate: start.format('YYYY-MM-DD'), startTime: start.format('HH:mm:ss'), stopDate: end.format('YYYY-MM-DD'), stopTime: end.format('HH:mm:ss')};
                const valid = onUpdateSchedule(slotId, updateEvent);
                if (!valid) {
                    // revert
                    setForceRender(!forceRender);
                }

                setIsMoving(false);
            }
            setIsDraggable(false)
        }

    }

    return (

        <DraggableCore
            grid={[timeWidth, height]}
            onStart={onDragStart}
            onDrag={onDrag}
            onStop={onDragStop}
        >
            <Resizable
                onResize={onResize}
                onResizeStart={onResizeStart}
                onResizeStop={onResizeStop}
                width={eventStyle.width}
                height={eventStyle.height}
                draggableOpts={{grid: [timeWidth, 0]}}
                resizeHandles={['e']}
            >
            <div className={'sc_Bar noSelect'}
                 style={eventStyle}
            >
                <span className={'head'}>
                    <span className={'adTime'}>{event.startTime+' - '+event.stopTime}</span>
                </span>
                    <span className={'text'}>{event.contentName}</span>
                    <div className={'thumbnail noSelect'}>
                        {
                            event.thumbnails !== undefined && event.thumbnails.length > 0 && event.thumbnails.map(
                                thumbnail => <ContentThumbnail id={thumbnail.thumbnailFileId} width={70} height={39}/>
                            )
                        }


                    </div>
                    <div className={'date'}>{event.startDate +' - '+ event.stopDate}</div>
                </div>
            </Resizable>
        </DraggableCore>

    )

}
export default EventBar;