import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Moment from 'moment';
import FullCalendar from '../../../components/fullcalendar/react'
import momentPlugin from '../../../components/fullcalendar/moment';
import dayGridPlugin from "../../../components/fullcalendar/daygrid";
import './ScheduleDetailViewCalendar.css';
import Tooltip from "tooltip.js";
import {CONTENT_SCHEDULE_PROGRAM_TYPE_AD} from "../../../constants";


const colors = ["#80cbff", "#6ee6a9", "#ff92b1", "#b22222", "#ff8c00", "#7B68EE"]

const ScheduleViewCalendar = ({type, program}) => {

    const {t} = useTranslation();

    const [schedule, setSchedules] = useState({
        currentChannel: undefined,
        currentEventIndex: undefined,
        currentMessageIndex: undefined,
        currentSlotIndex: undefined,
        events: []
    });

    const [tooltip, setTooltip] = useState({
        show: false
    })

    const oldMessageIndex = useRef(0);

    const isAdvertisement = useCallback(()=> {
        return program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD
    }, [program])

    const changeChannel = move => {
        if (program !== undefined && program.channels !== undefined && program.channels.length > 0) {

            const channelIndex = program.channels.findIndex(channel => channel.channelNo === schedule.currentChannel.channelNo);
            switch (move) {
                case 'first':
                    if (channelIndex !== 0) {
                        updateContentSchedules(0);
                    }
                    break;
                case 'last':
                    if (isAdvertisement()) {
                        if (channelIndex !== program.channels[0].frame.adSlots.length -1) {
                            updateContentSchedules(program.channels[0].frame.adSlots.length-1);
                        }
                    } else {
                        if (channelIndex !== program.channels.length -1) {
                            updateContentSchedules(program.channels.length-1);
                        }
                    }
                    break;
                case 'prev':
                    if (channelIndex-1 >= 0) {
                        updateContentSchedules(channelIndex-1);
                    }
                    break;
                case 'next':
                    if (isAdvertisement()) {
                        if (channelIndex + 1 < program.channels[0].frame.adSlots.length) {
                            updateContentSchedules(channelIndex+1);
                        }
                    } else {
                        if (channelIndex + 1 < program.channels.length) {
                            updateContentSchedules(channelIndex+1);
                        }
                    }

                    break;
            }
        }
    }

    const changeSlot = move => {
        if (program !== undefined && program.channels[0].frame.adSlots !== undefined && program.channels[0].frame.adSlots.length > 0) {
            const {currentSlotIndex} = schedule;
            switch (move) {
                case 'first':
                    if (currentSlotIndex !== 0) {
                        updateContentSchedules(0);
                    }
                    break;
                case 'last':
                    if (currentSlotIndex !== program.channels[0].frame.adSlots.length -1) {
                        updateContentSchedules(program.channels[0].frame.adSlots.length -1);
                    }
                    break;
                case 'prev':
                    if (currentSlotIndex-1 >= 0) {
                        updateContentSchedules(currentSlotIndex-1)
                    }
                    break;
                case 'next':
                    if (currentSlotIndex + 1 < program.channels[0].frame.adSlots.length) {
                        updateContentSchedules(currentSlotIndex + 1)
                    }
                    break;
            }
        }
    }

    const changeMessage = move => {
        if (program !== undefined && program.messageList !== undefined) {
            const {currentMessageIndex} = schedule;
            switch (move) {
                case 'first':
                    if (currentMessageIndex !== 0) {
                        setSchedules({...schedule, currentMessageIndex: 0});
                    }
                    break;
                case 'last':
                    if (currentMessageIndex !== program.messageList.length -1) {
                        setSchedules({...schedule, currentMessageIndex: program.messageList.length-1});
                    }
                    break;
                case 'prev':
                    if (currentMessageIndex-1 >= 0) {
                        setSchedules({...schedule, currentMessageIndex: currentMessageIndex-1});
                    }
                    break;
                case 'next':
                    if (currentMessageIndex + 1 < program.messageList.length) {
                        setSchedules({...schedule, currentMessageIndex: currentMessageIndex+1});
                    }
                    break;
            }
        }
    }

    const changeEvent = move => {
        if (schedule.currentEventIndex !== undefined && program.selectedEventList != undefined && program.selectedEventList.length > 0) {
            switch (move) {
                case 'prev':
                    if (schedule.currentEventIndex > 0) {
                        setSchedules({...schedule, currentEventIndex: schedule.currentEventIndex-1});
                    }
                    break;
                case 'next':
                    if (schedule.currentEventIndex < program.selectedEventList.length-1) {
                        setSchedules({...schedule, currentEventIndex: schedule.currentEventIndex+1});
                    }
                    break;
            }
        }
    }

    const getBackgroundColor = index => {
        if (index >= colors.length) {
            index = index % colors.length;
        }
        return colors[index];
    }

    const updateContentSchedules = (channelIndex) => {
        let events = [];
        if (program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD) {
            program.channels[0].frame !== undefined &&
            program.channels[0].frame.adSlots !== undefined &&
            program.channels[0].frame.adSlots[channelIndex].events !== undefined && program.channels[0].frame.adSlots[channelIndex].events.length > 0 &&
            program.channels[0].frame.adSlots[channelIndex].events.map(
                (event, index) => events.push({title: '', start: Moment(event.startDate).format('YYYY-MM-DD'), end: Moment(event.stopDate).format('YYYY-MM-DD'), backgroundColor: getBackgroundColor(index), extendedProps: {title: event.eventName}})
            )
            setSchedules({currentSlotIndex: channelIndex, events: events})
        } else {
            const channel = program.channels[channelIndex];
            channel.frame.events !== undefined && channel.frame.events.length > 0 && channel.frame.events.map(
                (schedule, index) => {
                    events.push({title: '', start: Moment(schedule.startDate).format('YYYY-MM-DD'), end: Moment(schedule.endDate).format('YYYY-MM-DD'), backgroundColor: getBackgroundColor(index), extendedProps: {title: schedule.contentName},});
                }
            )
            channel.frame.frames !==undefined && channel.frame.frames.length > 0 && channel.frame.frames.map(
                frame => {
                    if (frame.events !== undefined && frame.events.length > 0) {
                        frame.events.map(
                            (schedule, index) => {
                                events.push({title: '', start: Moment(schedule.startDate).format('YYYY-MM-DD'), end: Moment(schedule.endDate).format('YYYY-MM-DD'), backgroundColor: getBackgroundColor(index)});
                            }
                        )
                    }
                }
            )
            setSchedules({currentChannel: channel, events: events})
        }
    }



    const updateMessageSchedules = index => {
        let events = [];
        if (program !== undefined && program.messageList !== undefined && program.messageList.length > index) {
            const message = program.messageList[index];
            events.push({title: '', start: Moment(message.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'), end: Moment(message.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD'), backgroundColor: getBackgroundColor(0)})
        }
        setSchedules({...schedule, events: events, currentMessageIndex: index})
    }

    const oldEventIndex = useRef(undefined);
    const updateEventSchedules = (index) => {

        let events = [];
        events.push({title: '', start: program.selectedEventList[index].startDate, end: program.selectedEventList[index].stopDate, backgroundColor: getBackgroundColor(index), extendedProps: {title: program.selectedEventList[index].eventName}});
        oldEventIndex.current = index;

        setSchedules({...schedule, currentEventIndex: index, events: events})
    }

    useEffect(()=> {
        if (schedule.currentEventIndex !== undefined && oldEventIndex.current !== schedule.currentEventIndex) {
            updateEventSchedules(schedule.currentEventIndex)
        }
    }, [schedule])

    const eventRender = (info) => {
        const {title} = info.event.extendedProps
        const tooltip = new Tooltip(info.el, {
            title: title,
            placement: 'top',
            trigger: 'hover click',
            container: 'body'
        });

    }


    useEffect(()=>{
        if (type === 'EVENT') {
            setSchedules({currentChannel: undefined, currentEventIndex: 0, events: []})
        } else {
            setSchedules({currentChannel: undefined, events: []})
        }
        return ()=> {

        }
    },[])

    useEffect(()=> {
        if (program !== undefined) {
            if (type === 'CONTENT') {
                updateContentSchedules(0);
            } else if (type === 'MESSAGE') {
                updateMessageSchedules(0);
            }
        }
    }, [program])

    useEffect(()=> {
        if (schedule.currentMessageIndex !== undefined && type === 'MESSAGE' && oldMessageIndex.current !== schedule.currentMessageIndex) {
            oldMessageIndex.current = schedule.currentMessageIndex;
            updateMessageSchedules(schedule.currentMessageIndex);
        }
    }, [schedule])

    const channelNameNo = useCallback(()=> {
        let nameNChannel = '';
        if (schedule !== undefined && schedule.currentChannel !== undefined){
            nameNChannel = t("COM_TEXT_CHANNEL_P");
        }
        if (schedule !== undefined && schedule.currentChannel !== undefined && schedule.currentChannel.channelNo !== undefined) {
            nameNChannel += ' ' + schedule.currentChannel.channelNo;
        }
        return nameNChannel;

    }, [schedule])

    const slotName = useCallback(()=> {
        let slotName = '';
        if (schedule !== undefined && schedule.currentSlotIndex !== undefined){
            slotName = program.channels[0].frame.adSlots[schedule.currentSlotIndex].slotName
        }
        return slotName;
    }, [schedule])

    const messageName = useCallback(()=> {
        let messageName =  '';
        if (schedule !== undefined && schedule.currentMessageIndex !== undefined) {
            messageName = t("TEXT_MESSAGE_TEXT_P") + (schedule.currentMessageIndex+1);
        }
        return messageName;
    }, [schedule])

    const eventName = useCallback(()=> {
        let eventName = '';
        if (program !== undefined && program.selectedEventList !== undefined && schedule.currentEventIndex !== undefined) {
            eventName = program.selectedEventList[schedule.currentEventIndex].eventName
        }
        return eventName;
    }, [schedule])

    return (
        program !== undefined &&
        <div className="detail_View_calendar calendar_wrap_pop ml25" align="center">
            {
                type ===  'CONTENT' && program.programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                <div className="channel_wrap">
                    <div className="float_l">
                        <button className="calendar_arrow_pop prev2" onClick={()=>changeChannel('first')}>
                            <span></span></button>
                        <button className="calendar_arrow_pop prev" onClick={()=>changeChannel('prev')}>
                            <span></span></button>
                    </div>
                    <div className="float_r">
                        <button className="calendar_arrow_pop next" onClick={()=>changeChannel('next')}>
                            <span></span></button>
                        <button className="calendar_arrow_pop next2" onClick={()=>changeChannel('last')}>
                            <span></span></button>
                    </div>
                    <span id="scheduleViewChannelName">{channelNameNo()}</span>
                </div>
            }
            {
                type ===  'CONTENT' && program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                <div className="channel_wrap">
                    <div className="float_l">
                        <button className="calendar_arrow_pop prev2" onClick={()=>changeSlot('first')}>
                            <span></span></button>
                        <button className="calendar_arrow_pop prev" onClick={()=>changeSlot('prev')}>
                            <span></span></button>
                    </div>
                    <div className="float_r">
                        <button className="calendar_arrow_pop next" onClick={()=>changeSlot('next')}>
                            <span></span></button>
                        <button className="calendar_arrow_pop next2" onClick={()=>changeSlot('last')}>
                            <span></span></button>
                    </div>
                    <span id="scheduleViewChannelName">{slotName()}</span>
                </div>
            }
            {
                type ===  'EVENT' &&
                <div className="channel_wrap">
                    <div className="float_l">
                        <button className="calendar_arrow_pop prev" onClick={()=>changeEvent('prev')}>
                            <span></span></button>
                    </div>
                    <div className="float_r">
                        <button className="calendar_arrow_pop next" onClick={()=>changeEvent('next')}>
                            <span></span></button>
                    </div>
                    <span id="scheduleViewChannelName">{eventName()}</span>
                </div>
            }

            {
                type === 'MESSAGE' &&
                <div className="channel_wrap">
                    <div className="float_l">
                        <button className="calendar_arrow_pop prev2" onClick={()=>changeMessage('first')}>
                            <span></span></button>
                        <button className="calendar_arrow_pop prev" onClick={()=>changeMessage('prev')}>
                            <span></span></button>
                    </div>
                    <div className="float_r">
                        <button className="calendar_arrow_pop next" onClick={()=>changeMessage('next')}>
                            <span></span></button>
                        <button className="calendar_arrow_pop next2" onClick={()=>changeMessage('last')}>
                            <span></span></button>
                    </div>
                    <span id="scheduleViewChannelName">{messageName()}</span>
                </div>
            }


            <div className="schedule_calendar_view" style={{marginTop: (type != 'CONTENT' ? -30 : '')}}>

                {
                    tooltip.show &&
                        <div style={{display: 'absolute', width: 10, height: 10, backgroundColor: 'black'}}>{tooltip.title}</div>
                }


                <FullCalendar
                    defaultView="dayGridMonth"
                    height={350}
                    header={{
                        left: '',
                        center: 'prev, title, next',
                        right: 'today'
                    }}
                    buttonText={{
                        today: t("TEXT_TODAY_P"),
                    }}
                    titleFormat={'YYYY.MM'}
                    views={{month: {eventLimit: 3}}}
                    columnFormat={{
                        month: 'dddd',
                        week: 'D dddd',
                        day: 'MM.DD dddd'
                    }}
                    events={schedule.events}
                    Color={'yellow'}
                    textColor={'black'}
                    timezone={'local'}
                    eventConstraint={{start: '00:00', end: '24:00'}}
                    allDaySlot={false}
                    eventLimit={true}
                    plugins={[ dayGridPlugin, momentPlugin ]}
                    timeFormat={'HH:mm'}


                    eventRender={eventRender}
                />

            </div>

        </div>
    )


}
export default ScheduleViewCalendar;