import React, {useState} from "react";
import "./Edge.css";

const EdgeDropDown = ({width, height, name, display, options, onSelect, onUpdate, disabled}) => {
    const [style, setStyle] = useState('option_list edge_div_ul_hide');
    const [selectHeight, setSelectHeight] = useState(100);
    const showDropDown = () => {
        if (style === 'option_list edge_div_ul_hide') {
            setStyle('option_list edge_div_ul_show');
        } else {
            setStyle('option_list edge_div_ul_hide');
        }

        calculateAndSetSelectHeight();

    };

    const calculateAndSetSelectHeight = () => {

        let maxWidth = 0;
        // eslint-disable-next-line array-callback-return
        options.map(element => {
            let curWidth = getTextWidth(element);
            if ( maxWidth < curWidth ) {
                maxWidth = curWidth;
            }
        });

        let lineCount = height;
        if ( maxWidth > width * 0.9 ) {
            lineCount++;
        }

        setSelectHeight(lineCount > 4 ? (110) : 25 * lineCount);
    };


    const selectOption = (element) => {
        showDropDown();
        let param = {};
        param.target = {};
        param.target.name = name;
        param.target.value = element;
        onSelect(param);
        if (onUpdate) {
            onUpdate(param);
        }
    };

    const getTextWidth = (text) => {
        // re-use canvas object for better performance
        let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
        let context = canvas.getContext("2d");
        let metrics = context.measureText(text);
        return metrics.width;
    };

    return (
        <div>
            <button disabled={disabled} style={{width: width, textAlign: "left"}} className="base un_radius select"
                    onClick={showDropDown}>
                <span className="button_text">
                    {display} </span><span><span className="arrow"/></span></button>
            {options.length > 0 &&
            <div className={style}>
                <div>
                    <ul className="edge_ul" style={{height: selectHeight}}>
                        {options.map(element => (
                                <li onClick={() => {
                                    selectOption(element)
                                }}><a href="#">{element}</a></li>
                            )
                        )}
                    </ul>
                </div>
            </div>
            }
        </div>
    )
};

export default EdgeDropDown;