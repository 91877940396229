import React, {Component, Fragment} from "react";
import './DashboardPanel.css'
import './ContentSummary.css'
import {SortableHandle} from "react-sortable-hoc";
import {connect} from "react-redux";
import {menuAction} from "../../actions";
import {withTranslation} from "react-i18next";
import {MoonLoader} from "react-spinners";
import {dashboardService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from "../../helper/responseHandler";

class ContentSummary extends Component {

    state = {
        content: {},
        loading: true,
    };

    constructor(props) {
        super(props);
        this.onAllContentsClickEvent = this.onAllContentsClickEvent.bind(this);
        this.onUnapprovedContentsClickEvent = this.onUnapprovedContentsClickEvent.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
    }

    componentDidMount() {
        this.fetchGetContentInfo();
    }

    async onAllContentsClickEvent() {
        const {removeTab, moveTab, t} = this.props;
        removeTab('CONTENT');
        moveTab({id: 'CONTENT', title : t("TEXT_CONTENT_P"), active: true, close: true}, 'ALL_CONTENT');
    }

    async onUnapprovedContentsClickEvent() {
        const {removeTab, moveTab, t} = this.props;
        removeTab('CONTENT');
        moveTab({id: 'CONTENT', title: t("TEXT_CONTENT_P"), active: true, close: true}, 'UNAPPROVED_CONTENT');
    }

    fetchGetContentInfo = () => {
        this.setState({loading: true,});
        dashboardService.fetchContentInfo().then(res => {
            this.setState({
                loading: false,
                content: res.items,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally(() => {this.setState({loading: false});});
    };

    render() {
        let {t} = this.props;
        let {content, loading} = this.state;
        return (
            <div className='dashboard-widget'>
                {this.renderHeader()}
                <div className='dashboard-widget-body'>
                    {loading && <MoonLoader css={`position: absolute;top: calc(50% - 40px);left: calc(50% - 40px);`} color="#6e78c8"/>}
                    {!loading &&
                    <Fragment>
                        <div className='content-summary'>
                            <div className='content-summary-all' onClick={this.onAllContentsClickEvent}>
                                {content.allListCnt}<span>{t("COM_TEXT_TITLE_ALL_CONTENTS_P")}</span>
                            </div>
                            <div className='content-summary-running'>
                                {content.runningListCnt}<span>{t("MIS_SID_20_RUNNING_CONTENT")}</span>
                            </div>
                            { content.unApprovedCnt !== undefined &&
                            <div className='content-summary-unapproved'
                                 onClick={this.onUnapprovedContentsClickEvent}>
                                {content.unApprovedCnt}<span>{t("MIS_SID_20_UNAPPROVED_CONTENT")}</span>
                            </div>
                            }
                        </div>
                    </Fragment>
                    }
                </div>
            </div>
        )
    };

    renderHeader() {
        let {id, isEditMode, t} = this.props;
        const DragHandle = SortableHandle(() =>
            <div className='title'>{t("MIS_SID_20_CONTENT_SUMMARY")}</div>
        );
        return (
            <div className='dashboard-widget-header'>
                <DragHandle/>
                {
                    isEditMode && <div title="Close" className="remove" onClick={() => this.props.onRemoveLayoutClickEvent(id)}></div>
                }
            </div>
        );
    }
}

export default connect(
    null,
    dispatch => ({
        removeTab: (id) => dispatch(menuAction.removeTab(id)),
        moveTab: (tab, id, childrenId) => dispatch(menuAction.moveTab(tab, id, childrenId)),
    })
)(withTranslation()(ContentSummary));
