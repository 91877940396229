import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import ReactTable from "react-table";
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import {snakeCase} from 'lodash';
import fileDownload from 'js-file-download';
import Moment from "react-moment";
import {commonConstants, DEVICE_PAGE_SIZE_OPTIONS} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {deviceService, userService} from '../../services';
import {popupAction} from "../../actions";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import Pagination from '../../components/table/Pagination';
import {useMISOpt} from '../../components/misopt';
import {getPageSize, toMomentDateTimeFormat, useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import {useTrGroupProps} from '../../helper/tables';

const heightOffset = 204;
const defaultSort = {id: 'createDate', desc: true};

const SoftwareAppliedStatus = ({currContent}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange] = useFilter({
        page: 0,
        pageSize: getPageSize('device', DEVICE_PAGE_SIZE_OPTIONS[0]),
        keyword: '',
        sorted: [defaultSort],
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        deviceSecurity: false
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;

        setData({...data, loading: true});

        Promise.all([
            deviceService.fetchDeviceReservedSoftware({
                startIndex: page * pageSize + 1,
                pageSize,
                softwareType: 'APPLICATION',
                searchText: keyword,
                sortColumn: snakeCase(id === 'softwareReservationId' ? 'softwareRsvId' : id),
                sortOrder: desc ? 'desc' : 'asc',
            }),
            userService.fetchUserLoginInformation()
        ]).then(res => {
            setData({...data, loading: false, items: res[0].items, totalCount: res[0].totalCount,
                deviceSecurity: res[1].items.hasDeviceSecurity
            });
        });

    };

    const onKeywordChange = value => {
        setFilter({...filter, keyword: value, page: 0, sorted: [defaultSort]});
    };

    const onEdit = () => {
        if(selected.current.length === 0) {
            return;
        }

        const {softwareReservationId} = items[selected.current[0]];
        dispatch(popupAction.addPopup({
            id: commonConstants.EDIT_APPLIED_STATUS,
            type: commonConstants.EDIT_APPLIED_STATUS,
            rsvId: softwareReservationId,
            onSave: () => {
                dispatch(popupAction.closePopup(commonConstants.EDIT_APPLIED_STATUS));
                fetchData();
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.EDIT_APPLIED_STATUS))
        }));
    };

    const onClickSoftware = rsvId => {
        dispatch(popupAction.openDetailView({
            type: commonConstants.COMMON_DETAIL_VIEW, 
            viewType: 'DEVICE_SW_APPLIED_STATUS', 
            id: 'DEVICE_SW_APPLIED_STATUS' + rsvId,
            rsvId,
            softwareType: 'software',
            hasDeviceSecurity: data.deviceSecurity,
            onClose: () => dispatch(popupAction.closePopup('DEVICE_SW_APPLIED_STATUS' + rsvId))
        }));
    };

    const onCancel = () => {
        if(selected.current.length === 0) {
            return;
        }

        const {softwareReservationId} = items[selected.current[0]];
        deviceService.cancelSoftwareDeploy(softwareReservationId).then(res => {
            toastr.success(t('COM_TEXT_SUCCESS_P'));
            fetchData();
        }).catch(error => {
            console.log(error);
            toastr.error(t('MESSAGE_DEVICE_SOFTWARE_RESERVATION_CANCEL_FAIL_P'));
        })
    };

    const onExport = exportType => {
        const {keyword, sorted: [{id, desc}]} = filter;
        exportType = 'EXCEL';
        deviceService.exportSoftwareStatus({
            exportType,
            softwareType: 'APPLICATION',
            searchText: keyword,
            sortColumn: snakeCase(id),
            sortOrder: desc ? 'desc' : 'asc',
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(error => console.log(error));
    };

    useEffect(() => {
        setStyle({height: window.innerHeight - heightOffset});
    }, []);

    useEffect(() => {
        fetchData();
    }, [filter]);

    const searchPlaceHolder = t('COM_TEXT_SOFTWARE_NAME_P');

    const columns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllDevice_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={'AllDevice_'+row.index}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs} 
                    />
                );
            },
        },
        {
            Header: t('TABLE_RSV_ID_P'),
            accessor: 'softwareReservationId',
        },
        {
            Header: t('TABLE_DEVICE_MODEL_NAME_P'),
            accessor: 'deviceModelName',
        },
        {
            Header: t('COM_TEXT_SOFTWARE_NAME_P'),
            accessor: 'softwareName',
            Cell: ({original, value}) => <span className='link_cell' onClick={() => onClickSoftware(original.softwareReservationId)}>{value}</span>
        },
        {
            Header: t('TABLE_SOFTWARE_VERSION_P'),
            accessor: 'softwareVersion',
        },
        {
            Header: t('TABLE_TYPE_P'),
            accessor: 'deployType',
        },
        {
            Header: t('TABLE_SUBSCRIBER_P'),
            accessor: 'subscriberId',
        },
        {
            Header: t('TABLE_RESERVATION_DATE_P'),
            accessor: 'reservationDate',
            Cell: data => {
                return data.value ?
                    <Moment format={dateTimeFormat}>{data.value}</Moment> : <span>{'-'}</span>
            }
        },
        {
            Header: t('TEXT_STATUS_P'),
            accessor: 'downloadStatus',
            Cell: ({value, original}) => <span>{t(value)} ( {original.countOfFinishedState}/{original.countOfAllState} )</span> ,
            sortable: false
        },
        {
            Header: t('DID_ADMIN_DEVICE_REGDATE'),
            accessor: 'createDate',
            Cell: data => <Moment format={dateTimeFormat}>{data.value}</Moment>
        },
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'device_tr_group');

    return (
        <div style={{width: '100%',display: currContent === 'SOFTWARE_APPLIED_STATUS' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id='COM_BUTTON_EDIT' name={t('COM_BUTTON_EDIT')} disable={selectedCnt !== 1} onClick={onEdit} />
                    <WhiteButton id='BUTTON_CANCEL_P' name={t('BUTTON_CANCEL_P')} disable={selectedCnt !== 1} onClick={onCancel} />
                    <WhiteButton id={"DEVICE_EXPORT"} name={t('BUTTON_EXPORT_P')} onClick={onExport} style={{borderColor: '#5e5e5e'}} />
                </div>
                <div className="rightButton">
                    <SearchBar id="deviceSearch" placeholder={searchPlaceHolder} onClickSearch={onKeywordChange} keyword={filter.keyword} />
                </div>
            </div>
            <div className='device_list_view_wrap'>
                <ReactTable
                    data={items}
                    loading={loading}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns= {columns}
                    showPagination={false}
                    className="-striped -highlight"
                    style={style}
                    manual
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} divide={'device'} />
            </div>
        </div>
    );
};

export default SoftwareAppliedStatus;
