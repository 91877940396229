import React from 'react';
import {
    AllRuleset,
    RulesetRecycleBin,
    AllConditionLibrary,
    AllPlayLibrary,
    RulesetManager
} from './ruleset';
import {
    AllContent,
    ContentRecycleBin,
    ContentTemplate,
    UnApprovedContent
} from './content';

import {
    AllPlaylist,
    DeletedPlaylist,
    PlaylistManager
} from "./playlist";

import {
    AllContentSchedule,
    ScheduleManager,
    ContentScheduleRecycleBin,
    AllMessageSchedule,
    MessageScheduleRecycleBin,
    AllEventSchedule,
    EventScheduleRecycleBin,
    AllEventScheduleManager,
    MessageManager,
    EventConditionManager,
    EventManager
} from './schedule';

import {
    AllDevice,
    UnapprovedDevice,
    DeviceHealth,
    ContentDownloadIncomplete,
    ScheduleNotPublished,
    SchedulesToExpire,
    PlaylistToExpire,
    TimezoneNotSet,
    InsufficientCapacity,
    SoftwareRegistered,
    SoftwareAppliedStatus,
    CustomizeRegistered,
    CustomizeAppliedStatus,
    WarningRule,
    RemoteJob,
    Preset,
    LedCabinet,
} from "./device";

import {
    Summary,
    ConnectionStatus,
    ApprovedDevice,
    ConnectionHistory,
    ContentType,
    PlayFrequency,
    EventTriggerReport,
    PopFileHistory,
    ContentDetailReport,
    ContentStorageSpace,
} from "./statistics";

import {
    AllUser,
    WithdrawnUsers,
    Role,
    OrganizationGroup,
    Unapproved,
    LDAPOrganization
} from './user';

import {
    Dashboard
} from './dashboard';

import {
    MyInformation,
    WithdrawMember,
    DataLinkServers,
    EdgeServers,
    RemoteServers,
    Licenses,
    TagManagement,
    CategoryManagement,
    LogManagement,
    AlarmMailHistory,
    SystemInformation,
    DeviceSummary,
    ServiceHistory,
    MyInformationManager,
    ServerSettingsManager
} from './setting';

import {
    Insight
} from './insight';

import {
    RuleManager
} from './rulemanager';


import Category from '../components/category/Category'
import {scheduleConstants} from "../constants/ScheduleConstants";
import {useSelector} from "react-redux";
import AdvertisementManager from "./schedule/advertisement/AdvertisementManager";


const Container = ({content, ...other}) => {

    const {submenuWidth, submenu, reloadKey} = useSelector(state => state.menu);
    const others = {...other, key : reloadKey, currContent: content, };
    return (
        <div className="contents height100p noSelect" style={{marginLeft: submenu.hasSubmenu && submenu.showSubmenu ? submenuWidth : 0}}>
            {(
                () => {
                    switch (content) {
                        /* dashboard */
                        case 'DASHBOARD':
                            return <Dashboard {...others}/>
                        /* ruleset */
                        case 'ALL_RULESET':
                        case 'RULESET_BY_GROUP':
                            return <AllRuleset {...others}/>
                        case 'NEW_RULESET':
                            return <RulesetManager mode={"NEW"} {...others} />
                        case 'EDIT_RULESET':
                            return <RulesetManager mode={"EDIT"} {...others} />
                        case "LIBRARY_MANAGER_CONDITION":
                            return <AllConditionLibrary {...others} />
                        case "LIBRARY_MANAGER_PLAY":
                            return <AllPlayLibrary {...others} />
                        case 'RULESET_RECYCLE_BIN':
                            return <RulesetRecycleBin {...others} />
                        /* contents */
                        case 'ALL_CONTENT':
                        case 'MY_CONTENT':
                        case 'SHARED_CONTENT':
                        case 'BY_USER':
                            return <AllContent {...others}/>
                        case 'CONTENT_RECYCLE_BIN':
                            return <ContentRecycleBin {...others}/>
                        case 'CONTENT_TEMPLATE_BY_FOLDER':
                            return <ContentTemplate {...others}/>
                        case 'UNAPPROVED_CONTENT':
                            return <UnApprovedContent {...others}/>
                        /* playlist */
                        case 'ALL_PLAYLIST':
                        case 'MY_PLAYLIST':
                        case 'PLAYLIST_BY_USER':
                            return <AllPlaylist {...others}/>
                        case 'PLAYLIST_RECYCLE_BIN':
                            return <DeletedPlaylist {...others}/>
                        case 'NEW_PLAYLIST':
                            return <PlaylistManager mode={"NEW"} {...others} />
                        case 'EDIT_PLAYLIST':
                            return <PlaylistManager mode={"EDIT"} {...others} />
                        /* schedule */
                        case 'SIMPLE_SCHEDULE':
                        case 'ALL_CONTENT_SCHEDULE':
                        case 'CONTENT_SCHEDULE_BY_GROUP':
                            return <AllContentSchedule {...others}/>

                        case 'CONTENT_SCHEDULE_RECYCLE_BIN':
                            return <ContentScheduleRecycleBin {...others}/>
                        case 'ALL_MESSAGE_SCHEDULE':
                        case 'MESSAGE_SCHEDULE_BY_GROUP':
                            return <AllMessageSchedule {...others}/>
                        case 'MESSAGE_SCHEDULE_RECYCLE_BIN':
                            return <MessageScheduleRecycleBin {...others} />
                        case 'ALL_EVENT_SCHEDULE':
                        case 'EVENT_SCHEDULE_BY_GROUP':
                            return <AllEventSchedule {...others} />
                        case 'EVENT_SCHEDULE_RECYCLE_BIN':
                            return <EventScheduleRecycleBin {...others} />
                        case 'EVENT_SCHEDULE_MANAGER':
                            return <AllEventScheduleManager {...others}/>
                        case 'NEW_SCHEDULE':
                            return <ScheduleManager mode={scheduleConstants.CONTENT_SCHEDULE_NEW_MODE} />
                        case 'EDIT_SCHEDULE':
                            return <ScheduleManager mode={scheduleConstants.CONTENT_SCHEDULE_EDIT_MODE} />
                        case 'NEW_MESSAGE':
                            return <MessageManager mode={"new"} />
                        case 'EDIT_MESSAGE':
                            return <MessageManager mode={"edit"} />
                        case 'EDIT_EVENT_CONDITION':
                            return <EventConditionManager mode={"edit"} />
                        case 'NEW_EVENT_CONDITION':
                            return <EventConditionManager mode={"new"} />
                        case 'NEW_EVENT':
                            return <EventManager mode={"new"} />
                        case 'EDIT_EVENT':
                            return <EventManager mode={"edit"} />
                        case 'NEW_ADVERTISEMENT':
                            return <AdvertisementManager mode={'new'}/>
                        case 'EDIT_ADVERTISEMENT':
                            return <AdvertisementManager mode={'edit'}/>

                        /* device */
                        case 'ALL_DEVICE':
                        case 'DEVICE_BY_GROUP':
                            return <AllDevice {...others}/>
                        case 'UNAPPROVED_DEVICE':
                            return <UnapprovedDevice {...others} />
                        case 'DEVICE_HEALTH_ERROR':
                            return <DeviceHealth type='error' {...others} />
                        case 'DEVICE_HEALTH_WARNING':
                            return <DeviceHealth type='warning' {...others} />
                        case 'DEVICE_HEALTH_RESOLVED':
                            return <DeviceHealth type='resolved' {...others} />
                        case 'CONTENT_DOWNLOAD_INCOMPLETE':
                            return <ContentDownloadIncomplete {...others} />
                        case 'SCHEDULE_NOT_PUBLISHED':
                            return <ScheduleNotPublished {...others} />
                        case 'SCHEDULES_TO_EXPIRE':
                            return <SchedulesToExpire {...others} />
                        case 'PLAYLIST_TO_EXPIRE':
                            return <PlaylistToExpire {...others} />
                        case 'TIMEZONE_NOT_SET':
                            return <TimezoneNotSet {...others} />
                        case 'INSUFFICIENT_CAPACITY':
                            return <InsufficientCapacity {...others} />
                        case 'SOFTWARE_REGISTER_PUBLISH':
                            return <SoftwareRegistered {...others} />
                        case 'SOFTWARE_APPLIED_STATUS':
                            return <SoftwareAppliedStatus {...others} />
                        case 'PRESET_MANAGEMENT':
                            return <Preset {...others} />
                        case 'CUSTOMIZE_REGISTER_PUBLISH':
                            return <CustomizeRegistered {...others} />
                        case 'CUSTOMIZE_APPLIED_STATUS':
                            return <CustomizeAppliedStatus {...others} />
                        case 'LEDBOX_WARNING_RULE':
                            return <WarningRule {...others} />
                        case 'I_PLAYER_REMOTE_JOB':
                            return <RemoteJob {...others} />
                        case 'LED_CABINET':
                            return <LedCabinet {...others} />

                        /* statistics */
                        case 'SUMMARY':
                            return <Summary {...others}/>
                        case 'CONNECTION_STATUS':
                            return <ConnectionStatus {...others}/>
                        case 'APPROVED_DEVICE':
                            return <ApprovedDevice {...others}/>
                        case 'CONNECTION_HISTORY':
                            return <ConnectionHistory {...others}/>
                        case 'CONTENT_TYPE':
                            return <ContentType {...others}/>
                        case 'PLAY_FREQUENCY':
                            return <PlayFrequency {...others}/>
                        case 'PROOF_OF_PLAY_FILE_HISTORY':
                            return <PopFileHistory {...others}/>
                        case 'EVENT_TRIGGER_DETAIL_REPORTS':
                            return <EventTriggerReport {...others}/>
                        case 'DETAIL_REPORTS':
                            return <ContentDetailReport {...others}/>
                        case 'STORAGE_SPACE_USAGE':
                            return <ContentStorageSpace {...others}/>

                        /* user */
                        case 'ALL_USER':
                            return <AllUser mode={"ALL"} {...others}/>
                        case 'USER_BY_GROUP':
                            return <AllUser mode={"GROUPED"} {...others}/>
                        case 'UNAPPROVED':
                            return <Unapproved {...others}/>
                        case 'WITHDRAWN_USERS':
                            return <WithdrawnUsers {...others}/>
                        case 'ROLE':
                            return <Role {...others}/>
                        case 'ORGANIZATION_GROUP':
                            return <OrganizationGroup {...others}/>
                        case 'SYNC_LDAP_ORGANIZATION':
                            return <LDAPOrganization {...others}/>

                        /* setting */
                        case 'MY_INFORMATION':
                            return <MyInformationManager {...others}/>
                        case 'WITHDRAW_MEMBERSHIP':
                            return <WithdrawMember {...others}/>
                        case 'SERVER_SETTINGS':
                            return <ServerSettingsManager {...others}/>
                        case 'DEVICE_SUMMARY':
                            return <DeviceSummary {...others}/>
                        case 'SYSTEM_INFO':
                            return <SystemInformation {...others}/>
                        case 'SERVICE_HISTORY':
                            return <ServiceHistory {...others}/>
                        case 'DATALINK_SERVER':
                            return <DataLinkServers {...others} />
                        case 'EDGE_SERVER':
                            return <EdgeServers {...others} />
                        case 'REMOTE_CONTROL_SERVER':
                            return <RemoteServers {...others} />
                        case 'LICENSE_INFO':
                            return <Licenses {...others}/>
                        case 'E2E_LICENSE_INFO':
                            return <Licenses {...others}/>
                        case 'ALL_TAG':
                        case 'TAG_BY_GROUP':
                            return <TagManagement {...others}/>
                        case 'CATEGORY_MANAGEMENT':
                            return <CategoryManagement {...others}/>
                        case 'LOG':
                            return <LogManagement {...others}/>
                        case 'ALARM_MAIL_HISTORY':
                            return <AlarmMailHistory {...others}/>
                        /* insight, rule manager */
                        case 'INSIGHT':
                            return <Insight {...others}/>
                        case 'RULE_MANAGER':
                            return <RuleManager {...others}/>
                        default:
                            return '';
                    }
                }
            )()}

        </div>
    )
}
export default Container;
