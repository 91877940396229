import {useCallback} from "react";

export const setButton = (ref, disable) => {
    if(disable) {
        ref.current.disabled = true;
        ref.current.className = 'whiteButton disabled';
    } else {
        ref.current.disabled = false;
        ref.current.className = 'whiteButton';
    }
};

export const getTrGroupProps = (className) => (state, rowInfo) => {
    if(rowInfo) {
        return {className};
    }
    return {};
};


export const useTrGroupProps = (items, checkBoxRefs, toggleRow, className )=> {

    const getTrGroupPropsType1 = useCallback((state, rowInfo) => {
        if(rowInfo&& rowInfo.original) {
            return {className};
        }
        return {};
    },[items]);

    const getTrGroupPropsType2 = useCallback((state, rowInfo) => {
        if(rowInfo && rowInfo.original) {
            return {
                onClick: (e) => {
                    const idx = rowInfo.index;
                    if(
                        e.target.classList.contains('data_name') ||
                        e.target.classList.contains('rt-expander') ||
                        e.target.classList.contains('status_wrap') ||
                        e.target.nodeName === 'A'||
                        e.target.tagName === 'LABEL' ||
                        e.target.tagName === 'INPUT') {
                        return;
                    }
                    checkBoxRefs.current[idx].checked = !checkBoxRefs.current[idx].checked;
                    toggleRow({target: checkBoxRefs.current[idx]});
                },
                className : className
            };
        }else {
            return {};
        }
    },[items]);



    return [getTrGroupPropsType1,getTrGroupPropsType2];
};