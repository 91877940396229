import React from 'react';
import DroppableSpace from "./DroppableSpace";
import {useTranslation} from "react-i18next";
import TagContent from "./TagContent";
import {TAG_ITEM} from "../../constants";

const TagPlaylistEditor = React.forwardRef(({tags, playlistType, deviceType, onItemClick, moveItem, updateItem,
    onDelete, onSettingPopup, onDrop, tagCheckList}, ref) => {

    const {t} = useTranslation();

    return (
        <div>
            <div className={"animated"} style={{overflowY: 'auto', height: '48vh', width: '100%', display: 'flex'}}>
                <ul className="clearfix" style={{width:'100%', display: 'flex', flexFlow: 'wrap', alignContent: 'flex-start'}}>
                    {
                        tags != null && tags.length > 0 ?
                            tags.map(
                                (tag, index) => {
                                    return (
                                        <div style={{display: 'flex', height:'fit-content'}}>
                                            <DroppableSpace index={index} onDrop={onDrop} position={"prev"} moveItem={moveItem} />
                                            <TagContent
                                                tag={tag}
                                                index={index}
                                                deleteButtonClick={()=> onDelete(tag, TAG_ITEM)}
                                                settingButtonClick={(item) => onSettingPopup(item, index) }
                                                itemClick={(content, i)=> {onItemClick(content, i)}}
                                                updateItem={(item) => updateItem(item, null, TAG_ITEM)}
                                                playlistType={playlistType}
                                                deviceType={deviceType}
                                                tagCheckList={tagCheckList}
                                            />
                                            <DroppableSpace index={index} onDrop={onDrop} position={"post"} moveItem={moveItem}/>
                                        </div>
                                    )}
                            ) : ''
                    }
                </ul>
            </div>
        </div>
    );
})

export default TagPlaylistEditor;