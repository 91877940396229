import React from 'react';
import {useTranslation} from "react-i18next";
import Checkbox from '../checkbox/Checkbox';

const functionOption = [
    {label:"COM_DID_RMS_DEVICE_VIDEO_WALL", value:"is_videowall"},
];

const FunctionFilter = ({functions = [], onChange}) => {
    const {t} = useTranslation();

    return (
        <div className='dev_filter_type_checkbox'>
            <h5><span className="device_type" style={{background:'#93bde8'}}></span>{t("TEXT_FUNCTION_P")}</h5>
            <div style={{overflowY:'auto'}}>
                <ul style={{overflowY: 'auto'}}>
                    {
                        functionOption.map((option, i) => {
                            return (
                                <li key={option.value} className={functions.includes(option) ? "on" : ""}>
                                    <Checkbox id={"function_filter_"+option.value}
                                        name={t(option.label)}
                                        checked={functions.includes(option)}
                                        onChange={e => onChange(e.target.checked, option)}
                                    />
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        </div>
    );
};

export default FunctionFilter;