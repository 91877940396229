import React, {memo, useEffect, useMemo, useState} from 'react';
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import {contentService} from '../../services';
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import SupportedDeviceIcon from "../../components/icon/SupportedDeviceIcon";
import Size from "../../components/utils/Size";
import {connect} from "react-redux";
import {menuAction, popupAction} from "../../actions";
import {APPROVAL_STATUS_LABEL, commonConstants, contentConstants} from "../../constants";
import {useTranslation} from 'react-i18next';
import {snakeCase} from 'lodash';
import './Content.css'
import ContentThumbnail from "../../components/image/ContentThumbnail"
import Pagination from "../../components/table/Pagination";
import DeviceRelativeTimeCell from "../../components/device/DeviceRelativeTimeCell";
import {getPageSize, useCheckRefForContent, useFilter} from "../../helper";
import {useMISOpt} from "../../components/misopt";
import {toastr} from 'helper/toastrIntercept';
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../helper/tables";

const UnApprovedContent = (props) => {

    const {t} = useTranslation();

    const {getAuthority} = useMISOpt();
    const [authority, setAuthority] = useState({});

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        force: false,
        page: 0,
        pageSize: getPageSize('content_unapproved'),
        keyword: '',
        groupId: props.groupId !== undefined ? props.groupId : '',
        sorted: [{id: 'lastModifiedDate', desc: true}],
        reload: false,
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0
    });

    const [style, setStyle] = useState({
        height: '500px'
    });

    const [didMount, setMount] = useState(false);

    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted} = filter;
    const {addPopup, closePopup, updateSubMenuCounter} = props;
    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt, initData, toggleSelectAllForCardView, toggleCard, toggleRowWithIndex] = useCheckRefForContent(items);

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;
        setData({...data, loading: true});
        contentService.fetchContentFilter({
            isThumbnail: true,
            startIndex: (page * pageSize) + 1,
            pageSize: pageSize,
            searchText: keyword,
            approvalFilter: '',
            groupType: 'SUBMITTED',
            sortColumn: snakeCase(id).toUpperCase(),
            sortOrder: desc === true ? 'DESC' : 'ASC',
            mainPage: true
        })
            .then(res => {
                setData({
                    ...data,
                    loading: false,
                    items: res.items,
                    totalCount: res.totalCount
                });
            });
    };

    const getCheckedContentId = () => {
        return selected.current.map(key => items[key].contentId);
    };

    const approvalContentByRowButton = (index, contentId) => {
        if(contentId !== null) {
            toggleRowWithIndex(index);
            approvalContentPopup("approvalContentPopup", contentId, contentConstants.APPROVED);
        }
    };

    const approvalContentPopup = (id, contentId, action) => {
        addPopup({
            type: commonConstants.CONTENT_APPROVE,
            id: id,
            data : {id: id, action: action, contentId: contentId, comment:''},
            onSubmit: (approveData) => approvalContent(approveData),
            onClose: ()=> closePopup(id)
        });
    };

    const approvalContent = (approveData) => {
        const {id, action, contentId, comment} = approveData;
        let targetContentIds = [];
        let approvals = [];
        if(contentId !== null){
            approvals.push({
                contentId: contentId,
                status: action,
                opinion: comment
            });
        }else {
            selected.current.forEach(key => {
                approvals.push({
                    contentId: items[key].contentId,
                    status: action,
                    opinion: comment
                });
            });
        }

        if(approvals.length === 0){
            return;
        }
        contentService.approveContents({
            approvals: approvals
        })
            .then(res => {
                updateSubMenuCounter("CONTENT");
                fetchData();
            }).catch(error => {
                let errorMessage = error.errorMessage;
                switch(error.errorCode) {
                    case '403001':
                        errorMessage = t('ERROR_NO_ROLE');
                        break;
                    default:
                        errorMessage = t('COM_IDS_MSG_UNEXPEXTED_ERROR');
                        break;
                }
                toastr.error(errorMessage);
            }).finally(() => {
                    closePopup(id);
        });
    };

    const deleteContentPopup = (id) => {
        addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: id,
            title: t("COM_BUTTON_DELETE"),
            message: t('MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P'),
            onClickYes: ()=> deleteContent(id),
            onClose: ()=> closePopup(id)
        });
    };

    const deleteContent = (id) => {
        const targetContentIds = getCheckedContentId();
        if(targetContentIds.length === 0){
            return;
        }

        const requestBody = {
            contentIds: targetContentIds,
            deleteMethod: contentConstants.GO_TO_RECYCLEBIN
        };

        contentService.deleteContentById(requestBody)
            .then(res => {
                updateSubMenuCounter("CONTENT");
                fetchData();
            }).catch(error => {
                let errorMessage = error.errorMessage;
                switch(error.errorCode) {
                    case '403001':
                        errorMessage = t('ERROR_NO_ROLE');
                        break;
                    default:
                        errorMessage = t('COM_IDS_MSG_UNEXPEXTED_ERROR');
                        break;
                }
                toastr.error(errorMessage);
            }).finally(() => {
                closePopup(id);
        });
    }

    const renderModifiedDate = (lastModifiedDate) => {
        return <DeviceRelativeTimeCell value={lastModifiedDate} />
    };

    const getApprovalStatusLabel = (status) => {
        if(status !== undefined){
            return t(APPROVAL_STATUS_LABEL.find(item => item.status === status).label);
        }else {
            return "";
        }
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'Unapproved_All'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox
                        id={items[row.index].contentId}
                        index={row.index}
                        classname="table"
                        name="check"
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                )
            },
            width : 40,
            sortable: false,
            resizable: false
        },
        {
            Header: t("DID_MAIN_CONTENT"),
            width: 130,
            accessor: 'thumbFileId',
            sortable: false,
            Cell: (props) => <div><ContentThumbnail id={props.original.thumbFileId} width={100} height={56} textAlign={true}/></div>
        },
        {
            Header: t("TEXT_CONTENT_NAME_P"),
            accessor: "contentName",
            width: 400,
            Cell: (props) => <span title={props.original.contentName}>{props.original.contentName}</span>
        },
        {
            Header: t("COM_TV_SID_DETAILS"),
            width: 130,
            sortable: false,
            Cell: props => <div><span>{props.original.mediaType}</span><Size value={", "} size={props.original.totalSize} /></div>
        },
        {
            Header: t("COM_MAPP_SID_SUPPORTED_DEVICES"),
            accessor: "deviceType",
            width: 230,
            sortable: false,
            Cell: props => <SupportedDeviceIcon
                deviceType={props.original.deviceType}
                deviceTypeVersion={props.original.deviceTypeVersion}
                mediaType={props.original.mediaType}
                fileType={props.original.mainFileExtension}
            />
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "lastModifiedDate",
            width: 180,
            Cell: props => renderModifiedDate(props.original.lastModifiedDate)
        },
        {
            Header: t("TEXT_CREATOR_P"),
            accessor: "creatorId"
        },
        {
            Header: t("MESSAGE_STATISTICS_SUMMARY_APPROVE_TITLE_P"),
            accessor: "approvalStatus",
            Cell: props => getApprovalStatusLabel(props.original.approvalStatus)
        },
        {
            Header: t("MIS_SID_CONTENT_APPROVAL"),
            sortable: false,
            Cell: (row) => <WhiteButton id={"Approve_Btn_"+row.index} name={t("COM_BUTTON_APPROVAL")} onClick={()=>approvalContentByRowButton(row.index, items[row.index].contentId)}/>
        }
    ], [items]);

    useEffect(() => {
        setStyle({height: window.innerHeight - 198});
        setAuthority(getAuthority('CONTENT_PLAYLIST_RULESET'));
        fetchData();
        setMount(true);
    }, []);

    useEffect(() => {
        if(didMount){
            fetchData();
        }
    }, [filter]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow,'content_tr_group');

    return (
        <div style={{width: '100%',display: props.currContent === 'UNAPPROVED_CONTENT' ? 'block':'none'}}>
            <div className="contents_buttonWrap overflow_hidden">
                <div className="leftButton">
                    <WhiteButton id={"CONTENT_APPROVAL"} name={t("MIS_SID_CONTENT_APPROVAL")} disable={selectedCnt < 1} onClick={()=>approvalContentPopup("approvalContentPopup", null, contentConstants.APPROVED)} authority={authority.MANAGE}/>
                    <WhiteButton id={"CONTENT_REJECT"} name={t("BUTTON_REJECT_P")} disable={selectedCnt < 1} onClick={()=>approvalContentPopup("rejectContentPopup", null, contentConstants.REJECTED)} authority={authority.MANAGE}/>
                    <WhiteButton id={"CONTENT_DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} onClick={()=>deleteContentPopup("deleteContentPopup")} authority={authority.MANAGE}/>
                </div>
                <div className="rightButton">
                    <SearchBar id="contentSearch" placeholder={t("TEXT_CONTENT_NAME_P")} onClickSearch={onKeywordChange} />
                </div>
            </div>
            <div className='content_list_view'>
                <MagicInfoTable
                    data={items}
                    page={page}
                    pages={pages}
                    loading={loading}
                    minRows={0}
                    sorted={sorted}
                    getTrGroupProps={getTrGroupPropsType2}
                    columns={columns}
                    showPagination={false}
                    onSortedChange={onSortedChange}
                    className="-striped -highlight"
                    style={style}
                    manual
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize}
                            pageSizeOptions={contentConstants.PAGE_SIZE_OPTIONS} onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange} divide={'content_unapproved'}/>
            </div>
        </div>
    );
};

export default connect(
    null,
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        updateSubMenuCounter: (submenuId) => dispatch(menuAction.updateSubMenuCounter(submenuId)),
    })
)(memo(UnApprovedContent));
