import TRANSITION1 from "../images/img/transition_img_1.png"
import FallLeft from "../images/transition/78x78/Ext_Eff_3D/FallLeft.png"
import FallRight from "../images/transition/78x78/Ext_Eff_3D/FallRight.png"
import FallAndFadeLeft from "../images/transition/78x78/Ext_Eff_3D/FallAndFadeLeft.png"
import FallAndFadeRight from "../images/transition/78x78/Ext_Eff_3D/FallAndFadeRight.png"
import BouncingFallLeft from "../images/transition/78x78/Ext_Eff_3D/BouncingFallLeft.png"
import BouncingFallRight from "../images/transition/78x78/Ext_Eff_3D/BouncingFallRight.png"
import LeftSpinX from "../images/transition/78x78/Ext_Eff_3D/LeftSpinX.png"
import LeftSpinY from "../images/transition/78x78/Ext_Eff_3D/LeftSpinY.png"
import LeftSpinZ from "../images/transition/78x78/Ext_Eff_3D/LeftSpinZ.png"
import RightSpinX from "../images/transition/78x78/Ext_Eff_3D/RightSpinX.png"
import RightSpinY from "../images/transition/78x78/Ext_Eff_3D/RightSpinY.png"
import RightSpinZ from "../images/transition/78x78/Ext_Eff_3D/RightSpinZ.png"
import RaiseSpinX from "../images/transition/78x78/Ext_Eff_3D/RaiseSpinX.png"
import RaiseSpinY from "../images/transition/78x78/Ext_Eff_3D/RaiseSpinY.png"
import RaiseSpinZ from "../images/transition/78x78/Ext_Eff_3D/RaiseSpinZ.png"
import FallSpinX from "../images/transition/78x78/Ext_Eff_3D/FallSpinX.png"
import FallSpinY from "../images/transition/78x78/Ext_Eff_3D/FallSpinY.png"
import FallSpinZ from "../images/transition/78x78/Ext_Eff_3D/FallSpinZ.png"
import FlyLeftSpinFly from "../images/transition/78x78/Ext_Eff_3D/FlyLeftSpinFly.png"
import FlyRightSpinFly from "../images/transition/78x78/Ext_Eff_3D/FlyRightSpinFly.png"
import RaiseSpinRaise from "../images/transition/78x78/Ext_Eff_3D/RaiseSpinRaise.png"
import FallSpinFall from "../images/transition/78x78/Ext_Eff_3D/FallSpinFall.png"
import SpinAtEndLeft from "../images/transition/78x78/Ext_Eff_3D/SpinAtEndLeft.png"
import SpinAtEndRight from "../images/transition/78x78/Ext_Eff_3D/SpinAtEndRight.png"
import RicochetX from "../images/transition/78x78/Ext_Eff_3D/RicochetX.png"
import RicochetY from "../images/transition/78x78/Ext_Eff_3D/RicochetY.png"
import InLeftSpinXUp from "../images/transition/78x78/Ext_Eff_3D/InLeftSpinXUp.png"
import InRightSpinXUp from "../images/transition/78x78/Ext_Eff_3D/InRightSpinXUp.png"
import InLeftSpinXDown from "../images/transition/78x78/Ext_Eff_3D/InLeftSpinXDown.png"
import InRightSpinXDown from "../images/transition/78x78/Ext_Eff_3D/InRightSpinXDown.png"
import InLeftRotateUp from "../images/transition/78x78/Ext_Eff_3D/InLeftRotateUp.png"
import InRightRotateUp from "../images/transition/78x78/Ext_Eff_3D/InRightRotateUp.png"
import InLeftRotateDown from "../images/transition/78x78/Ext_Eff_3D/InLeftRotateDown.png"
import InRightRotateDown from "../images/transition/78x78/Ext_Eff_3D/InRightRotateDown.png"
import InLeftSpinYUp from "../images/transition/78x78/Ext_Eff_3D/InLeftSpinYUp.png"
import InRightSpinYUp from "../images/transition/78x78/Ext_Eff_3D/InRightSpinYUp.png"
import InLeftSpinYDown from "../images/transition/78x78/Ext_Eff_3D/InLeftSpinYDown.png"
import InRightSpinYDown from "../images/transition/78x78/Ext_Eff_3D/InRightSpinYDown.png"
import AMTumbleZoom from "../images/transition/78x78/Ext_Eff_3D/AMTumbleZoom.png"
import AMTumbleSmooth from "../images/transition/78x78/Ext_Eff_3D/AMTumbleSmooth.png"
import TumblePlus from "../images/transition/78x78/Ext_Eff_3D/TumblePlus.png"
import Uncoveratend from "../images/transition/78x78/Ext_Eff_3D/Uncoveratend.png"
import Spin from "../images/transition/78x78/Ext_Eff_3D/Spin.png"
import NewsFlash from "../images/transition/78x78/Ext_Eff_3D/NewsFlash.png"
import Tumble from "../images/transition/78x78/Ext_Eff_3D/Tumble.png"
import TumbleInLeft from "../images/transition/78x78/Ext_Eff_3D/TumbleInLeft.png"
import TumbleInRight from "../images/transition/78x78/Ext_Eff_3D/TumbleInRight.png"
import InUpSpinRight from "../images/transition/78x78/Ext_Eff_3D/InUpSpinRight.png"
import InDownSpinLeft from "../images/transition/78x78/Ext_Eff_3D/InDownSpinLeft.png"
import RushInRight from "../images/transition/78x78/Ext_Eff_3D/RushInRight.png"
import RushInLeft from "../images/transition/78x78/Ext_Eff_3D/RushInLeft.png"
import Worm from "../images/transition/78x78/Ext_Eff_3D/Worm.png"
import FallingRubber from "../images/transition/78x78/Ext_Eff_3D/FallingRubber.png"
import KnightDownLeft from "../images/transition/78x78/Ext_Eff_3D/KnightDownLeft.png"
import KnightLeftDown from "../images/transition/78x78/Ext_Eff_3D/KnightLeftDown.png"
import KnightLeftUp from "../images/transition/78x78/Ext_Eff_3D/KnightLeftUp.png"
import KnightRightDown from "../images/transition/78x78/Ext_Eff_3D/KnightRightDown.png"
import KnightRightUp from "../images/transition/78x78/Ext_Eff_3D/KnightRightUp.png"
import KnightUpRight from "../images/transition/78x78/Ext_Eff_3D/KnightUpRight.png"
import KnightZoomDownLeft from "../images/transition/78x78/Ext_Eff_3D/KnightZoomDownLeft.png"
import KnightZoomDownRight from "../images/transition/78x78/Ext_Eff_3D/KnightZoomDownRight.png"
import KnightZoomLeftDown from "../images/transition/78x78/Ext_Eff_3D/KnightZoomLeftDown.png"
import KnightZoomLeftUp from "../images/transition/78x78/Ext_Eff_3D/KnightZoomLeftUp.png"
import KnightZoomRightDown from "../images/transition/78x78/Ext_Eff_3D/KnightZoomRightDown.png"
import KnightZoomRightUp from "../images/transition/78x78/Ext_Eff_3D/KnightZoomRightUp.png"
import KnightZoomUpLeft from "../images/transition/78x78/Ext_Eff_3D/KnightZoomUpLeft.png"
import KnightZoomUpRight from "../images/transition/78x78/Ext_Eff_3D/KnightZoomUpRight.png"
import fourSpin from "../images/transition/78x78/Ext_Eff_3D/4Spin.png"
import AMSpinFade from "../images/transition/78x78/Ext_Eff_3D/AMSpinFade.png"
import AMTumbleSpinLeft from "../images/transition/78x78/Ext_Eff_3D/AMTumbleSpinLeft.png"
import AMTumbleSpinRight from "../images/transition/78x78/Ext_Eff_3D/AMTumbleSpinRight.png"
import ArcInLeftSpinX from "../images/transition/78x78/Ext_Eff_3D/ArcInLeftSpinX.png"
import ArcInRightSpinX from "../images/transition/78x78/Ext_Eff_3D/ArcInRightSpinX.png"
import BlindFold from "../images/transition/78x78/Ext_Eff_3D/BlindFold.png"
import BlindFold2 from "../images/transition/78x78/Ext_Eff_3D/BlindFold2.png"
import BlindFold3 from "../images/transition/78x78/Ext_Eff_3D/BlindFold3.png"
import BottomEdgeSpin from "../images/transition/78x78/Ext_Eff_3D/BottomEdgeSpin.png"
import Careen from "../images/transition/78x78/Ext_Eff_3D/Careen.png"
import CloseGarage from "../images/transition/78x78/Ext_Eff_3D/CloseGarage.png"
import ClosingRamps from "../images/transition/78x78/Ext_Eff_3D/ClosingRamps.png"
import CueCard from "../images/transition/78x78/Ext_Eff_3D/CueCard.png"
import Disorder from "../images/transition/78x78/Ext_Eff_3D/Disorder.png"
import DoubleDoors from "../images/transition/78x78/Ext_Eff_3D/DoubleDoors.png"
import DoubleDoors2 from "../images/transition/78x78/Ext_Eff_3D/DoubleDoors2.png"
import DoubleSpin from "../images/transition/78x78/Ext_Eff_3D/DoubleSpin.png"
import Float from "../images/transition/78x78/Ext_Eff_3D/Float.png"
import Folder from "../images/transition/78x78/Ext_Eff_3D/Folder.png"
import HeyYou1 from "../images/transition/78x78/Ext_Eff_3D/HeyYou1.png"
import HeyYou2 from "../images/transition/78x78/Ext_Eff_3D/HeyYou2.png"
import HookEastSpin from "../images/transition/78x78/Ext_Eff_3D/HookEastSpin.png"
import HookWestSpin from "../images/transition/78x78/Ext_Eff_3D/HookWestSpin.png"
import JumpInLeft from "../images/transition/78x78/Ext_Eff_3D/JumpInLeft.png"
import JumpInRight from "../images/transition/78x78/Ext_Eff_3D/JumpInRight.png"
import LeftEdgeSpin from "../images/transition/78x78/Ext_Eff_3D/LeftEdgeSpin.png"
import NewZoomNorth from "../images/transition/78x78/Ext_Eff_3D/NewZoomNorth.png"
import NewZoomSouth from "../images/transition/78x78/Ext_Eff_3D/NewZoomSouth.png"
import PivotIn from "../images/transition/78x78/Ext_Eff_3D/PivotIn.png"
import QuadSpin from "../images/transition/78x78/Ext_Eff_3D/QuadSpin.png"
import RightEdgeSpin from "../images/transition/78x78/Ext_Eff_3D/RightEdgeSpin.png"
import RockIn from "../images/transition/78x78/Ext_Eff_3D/RockIn.png"
import SatelliteDoubleSmall from "../images/transition/78x78/Ext_Eff_3D/SatelliteDoubleSmall.png"
import SatelliteMedium from "../images/transition/78x78/Ext_Eff_3D/SatelliteMedium.png"
import SmoothArcEastY from "../images/transition/78x78/Ext_Eff_3D/SmoothArcEastY.png"
import SmoothArcNorth from "../images/transition/78x78/Ext_Eff_3D/SmoothArcNorth.png"
import SmoothArcSouth from "../images/transition/78x78/Ext_Eff_3D/SmoothArcSouth.png"
import SmoothArcWestY from "../images/transition/78x78/Ext_Eff_3D/SmoothArcWestY.png"
import SpinHookEast from "../images/transition/78x78/Ext_Eff_3D/SpinHookEast.png"
import SpinHookWest from "../images/transition/78x78/Ext_Eff_3D/SpinHookWest.png"
import TiltFly from "../images/transition/78x78/Ext_Eff_3D/TiltFly.png"
import TopEdgeSpin from "../images/transition/78x78/Ext_Eff_3D/TopEdgeSpin.png"
import TwistIn from "../images/transition/78x78/Ext_Eff_3D/TwistIn.png"
import Uncover from "../images/transition/78x78/Ext_Eff_3D/Uncover.png"
import TheBlob from "../images/transition/78x78/Ext_Eff_Alpha/TheBlob.png"
import MandelBrot from "../images/transition/78x78/Ext_Eff_Alpha/MandelBrot.png"
import Erosion from "../images/transition/78x78/Ext_Eff_Alpha/Erosion.png"
import Vortex from "../images/transition/78x78/Ext_Eff_Alpha/Vortex.png"
import Fractured from "../images/transition/78x78/Ext_Eff_Alpha/Fractured.png"
import RippleWash from "../images/transition/78x78/Ext_Eff_Alpha/RippleWash.png"
import WetWiper from "../images/transition/78x78/Ext_Eff_Alpha/WetWiper.png"
import Canvas from "../images/transition/78x78/Ext_Eff_Alpha/Canvas.png"
import WarmFronts from "../images/transition/78x78/Ext_Eff_Alpha/WarmFronts.png"
import ArcWiper from "../images/transition/78x78/Ext_Eff_Alpha/ArcWiper.png"
import Smokescreen from "../images/transition/78x78/Ext_Eff_Alpha/Smokescreen.png"
import Floodlights from "../images/transition/78x78/Ext_Eff_Alpha/Floodlights.png"
import SlidingStar from "../images/transition/78x78/Ext_Eff_Alpha/SlidingStar.png"
import CutWhiteFade from "../images/transition/78x78/Ext_Eff_Fade/CutWhiteFade.png"
import CutGrayFade from "../images/transition/78x78/Ext_Eff_Fade/CutGrayFade.png"
import CutBlackFade from "../images/transition/78x78/Ext_Eff_Fade/CutBlackFade.png"
import CutRedFade from "../images/transition/78x78/Ext_Eff_Fade/CutRedFade.png"
import CutGreenFade from "../images/transition/78x78/Ext_Eff_Fade/CutGreenFade.png"
import CutBlueFade from "../images/transition/78x78/Ext_Eff_Fade/CutBlueFade.png"
import CutOrangeFade from "../images/transition/78x78/Ext_Eff_Fade/CutOrangeFade.png"
import CutYellowFade from "../images/transition/78x78/Ext_Eff_Fade/CutYellowFade.png"
import CutPurpleFade from "../images/transition/78x78/Ext_Eff_Fade/CutPurpleFade.png"
import Straight from "../images/transition/78x78/Ext_Eff_FlyOn/Straight.png"
import Sine from "../images/transition/78x78/Ext_Eff_FlyOn/Sine.png"
import Wiggle from "../images/transition/78x78/Ext_Eff_FlyOn/Wiggle.png"
import DropWave from "../images/transition/78x78/Ext_Eff_FlyOn/DropWave.png"
import GlideWave from "../images/transition/78x78/Ext_Eff_FlyOn/GlideWave.png"
import Crescent from "../images/transition/78x78/Ext_Eff_FlyOn/Crescent.png"
import Loop from "../images/transition/78x78/Ext_Eff_FlyOn/Loop.png"
import Boomerang from "../images/transition/78x78/Ext_Eff_FlyOn/Boomerang.png"
import Damped from "../images/transition/78x78/Ext_Eff_FlyOn/Damped.png"
import FadeWave from "../images/transition/78x78/Ext_Eff_FlyOn/FadeWave.png"
import FadeLoop from "../images/transition/78x78/Ext_Eff_FlyOn/FadeLoop.png"
import FlyFade from "../images/transition/78x78/Ext_Eff_FlyOn/FlyFade.png"
import ShortFadeNorth from "../images/transition/78x78/Ext_Eff_FlyOn/ShortFadeNorth.png"
import ShortFadeEast from "../images/transition/78x78/Ext_Eff_FlyOn/ShortFadeEast.png"
import ShortFadeSouth from "../images/transition/78x78/Ext_Eff_FlyOn/ShortFadeSouth.png"
import ShortFadeWest from "../images/transition/78x78/Ext_Eff_FlyOn/ShortFadeWest.png"
import DampedFade from "../images/transition/78x78/Ext_Eff_FlyOn/DampedFade.png"
import KnightUp from "../images/transition/78x78/Ext_Eff_FlyOn/KnightUp.png"
import KnightDown from "../images/transition/78x78/Ext_Eff_FlyOn/KnightDown.png"
import FlyZoom from "../images/transition/78x78/Ext_Eff_FlyOn/FlyZoom.png"
import FlyZoomWave from "../images/transition/78x78/Ext_Eff_FlyOn/FlyZoomWave.png"
import FlyZoomX from "../images/transition/78x78/Ext_Eff_FlyOn/FlyZoomX.png"
import FlyZoomY from "../images/transition/78x78/Ext_Eff_FlyOn/FlyZoomY.png"
import Dissolve from "../images/transition/78x78/Ext_Eff_FlyOn/Dissolve.png"
import FadeThrough from "../images/transition/78x78/Ext_Eff_PassThru/FadeThrough.png"
import FadeThroughFastIn from "../images/transition/78x78/Ext_Eff_PassThru/FadeThroughFastIn.png"
import FadeThroughFastOut from "../images/transition/78x78/Ext_Eff_PassThru/FadeThroughFastOut.png"
import FlareFadeThrough from "../images/transition/78x78/Ext_Eff_PassThru/FlareFadeThrough.png"
import GrowFadeThrough from "../images/transition/78x78/Ext_Eff_PassThru/GrowFadeThrough.png"
import PeekabooEast from "../images/transition/78x78/Ext_Eff_PassThru/Peekaboo-East.png"
import PeekabooNorth from "../images/transition/78x78/Ext_Eff_PassThru/Peekaboo-North.png"
import PeekabooNorthEast from "../images/transition/78x78/Ext_Eff_PassThru/Peekaboo-NorthEast.png"
import PeekabooNorthWest from "../images/transition/78x78/Ext_Eff_PassThru/Peekaboo-NorthWest.png"
import PeekabooSouth from "../images/transition/78x78/Ext_Eff_PassThru/Peekaboo-South.png"
import PeekabooSouthEast from "../images/transition/78x78/Ext_Eff_PassThru/Peekaboo-SouthEast.png"
import PeekabooSouthWest from "../images/transition/78x78/Ext_Eff_PassThru/Peekaboo-SouthWest.png"
import PeekabooWest from "../images/transition/78x78/Ext_Eff_PassThru/Peekaboo-West.png"
import ShrinkFadeThrough from "../images/transition/78x78/Ext_Eff_PassThru/ShrinkFadeThrough.png"
import StellarPlane from "../images/transition/78x78/Ext_Eff_PassThru/StellarPlane.png"
import StellarReceding from "../images/transition/78x78/Ext_Eff_PassThru/StellarReceding.png"
import StraightThrough from "../images/transition/78x78/Ext_Eff_PassThru/StraightThrough.png"
import StraightFadeThroughEast from "../images/transition/78x78/Ext_Eff_PassThru/StraightFadeThrough-East.png"
import StraightFadeThroughNorth from "../images/transition/78x78/Ext_Eff_PassThru/StraightFadeThrough-North.png"
import StraightFadeThroughNorthEast from "../images/transition/78x78/Ext_Eff_PassThru/StraightFadeThrough-NorthEast.png"
import StraightFadeThroughNorthWest from "../images/transition/78x78/Ext_Eff_PassThru/StraightFadeThrough-NorthWest.png"
import StraightFadeThroughSouth from "../images/transition/78x78/Ext_Eff_PassThru/StraightFadeThrough-South.png"
import StraightFadeThroughSouthEast from "../images/transition/78x78/Ext_Eff_PassThru/StraightFadeThrough-SouthEast.png"
import StraightFadeThroughSouthWest from "../images/transition/78x78/Ext_Eff_PassThru/StraightFadeThrough-SouthWest.png"
import StraightFadeThroughWest from "../images/transition/78x78/Ext_Eff_PassThru/StraightFadeThrough-West.png"
import TallFadeThrough from "../images/transition/78x78/Ext_Eff_PassThru/TallFadeThrough.png"
import Trailer from "../images/transition/78x78/Ext_Eff_PassThru/Trailer.png"
import TurnFadeThrough from "../images/transition/78x78/Ext_Eff_PassThru/TurnFadeThrough.png"
import WideFadeThrough from "../images/transition/78x78/Ext_Eff_PassThru/WideFadeThrough.png"
import ScrollClosed from "../images/transition/78x78/Ext_Eff_Push/ScrollClosed.png"
import SlatsCrash from "../images/transition/78x78/Ext_Eff_Push/SlatsCrash.png"
import RollOn from "../images/transition/78x78/Ext_Eff_Push/RollOn.png"
import DoubleRollOn from "../images/transition/78x78/Ext_Eff_Push/DoubleRollOn.png"
import UnrollStripts from "../images/transition/78x78/Ext_Eff_Push/UnrollStripts.png"
import Clasp from "../images/transition/78x78/Ext_Eff_Push/Clasp.png"
import DoubleSlam from "../images/transition/78x78/Ext_Eff_Push/DoubleSlam.png"
import DoubleVision from "../images/transition/78x78/Ext_Eff_Push/DoubleVision.png"
import PinWheel from "../images/transition/78x78/Ext_Eff_Push/PinWheel.png"
import PushMe from "../images/transition/78x78/Ext_Eff_Push/PushMe.png"
import PushMoreStrips from "../images/transition/78x78/Ext_Eff_Push/PushMoreStrips.png"
import PushStrips from "../images/transition/78x78/Ext_Eff_Push/PushStrips.png"
import PushMoreWave from "../images/transition/78x78/Ext_Eff_Push/PushMoreWave.png"
import QuadrantIn from "../images/transition/78x78/Ext_Eff_Push/QuadrantIn.png"
import ScaleIn from "../images/transition/78x78/Ext_Eff_Push/ScaleIn.png"
import ScrollDivide from "../images/transition/78x78/Ext_Eff_Push/ScrollDivide.png"
import ScrollIn from "../images/transition/78x78/Ext_Eff_Push/ScrollIn.png"
import SlatsTogether2 from "../images/transition/78x78/Ext_Eff_Push/SlatsTogether2.png"
import SlatsTogether4 from "../images/transition/78x78/Ext_Eff_Push/SlatsTogether4.png"
import SlatsClosed8 from "../images/transition/78x78/Ext_Eff_Push/SlatsClosed8.png"
import Slam from "../images/transition/78x78/Ext_Eff_Push/Slam.png"
import Slicer from "../images/transition/78x78/Ext_Eff_Push/Slicer.png"
import SweepSlicer from "../images/transition/78x78/Ext_Eff_Push/SweepSlicer.png"
import Trislide from "../images/transition/78x78/Ext_Eff_Push/Trislide.png"
import TrislideLR from "../images/transition/78x78/Ext_Eff_Push/TrislideLR.png"
import Next from "../images/transition/78x78/Ext_Eff_Special/Next.png"
import RandomAlways from "../images/transition/78x78/Ext_Eff_Special/RandomAlways.png"
import RandomOnce from "../images/transition/78x78/Ext_Eff_Special/RandomOnce.png"
import Wipe from "../images/transition/78x78/Ext_Eff_Reveal/Wipe.png"
import Center from "../images/transition/78x78/Ext_Eff_Reveal/Center.png"
import Split from "../images/transition/78x78/Ext_Eff_Reveal/Split.png"
import Quadrant from "../images/transition/78x78/Ext_Eff_Reveal/Quadrant.png"
import Cornor from "../images/transition/78x78/Ext_Eff_Reveal/Cornor.png"
import SmallBlinds from "../images/transition/78x78/Ext_Eff_Reveal/SmallBlinds.png"
import Striper from "../images/transition/78x78/Ext_Eff_Reveal/Striper.png"
import BasketWeave from "../images/transition/78x78/Ext_Eff_Reveal/BasketWeave.png"
import BlindsFantasy1 from "../images/transition/78x78/Ext_Eff_Reveal/BlindsFantasy1.png"
import BlindsFantasy2 from "../images/transition/78x78/Ext_Eff_Reveal/BlindsFantasy2.png"
import BlindsFantasy3 from "../images/transition/78x78/Ext_Eff_Reveal/BlindsFantasy3.png"
import Blocks from "../images/transition/78x78/Ext_Eff_Reveal/Blocks.png"
import ClosingBlinds from "../images/transition/78x78/Ext_Eff_Reveal/ClosingBlinds.png"
import Closure from "../images/transition/78x78/Ext_Eff_Reveal/Closure.png"
import CornerSlice from "../images/transition/78x78/Ext_Eff_Reveal/CornerSlice.png"
import Curtain from "../images/transition/78x78/Ext_Eff_Reveal/Curtain.png"
import DiagonalBlocks from "../images/transition/78x78/Ext_Eff_Reveal/DiagonalBlocks.png"
import DiagonalStrips from "../images/transition/78x78/Ext_Eff_Reveal/DiagonalStrips.png"
import Enigma from "../images/transition/78x78/Ext_Eff_Reveal/Enigma.png"
import EnigmaReverse from "../images/transition/78x78/Ext_Eff_Reveal/EnigmaReverse.png"
import MiniQuadrant from "../images/transition/78x78/Ext_Eff_Reveal/MiniQuadrant.png"
import PaintDrip from "../images/transition/78x78/Ext_Eff_Reveal/PaintDrip.png"
import Peel from "../images/transition/78x78/Ext_Eff_Reveal/Peel.png"
import PremiereReveal from "../images/transition/78x78/Ext_Eff_Reveal/PremiereReveal.png"
import RandomBlocks from "../images/transition/78x78/Ext_Eff_Reveal/RandomBlocks.png"
import ScaleTheWall from "../images/transition/78x78/Ext_Eff_Reveal/ScaleTheWall.png"
import SmoothWall from "../images/transition/78x78/Ext_Eff_Reveal/SmoothWall.png"
import SpinningFan from "../images/transition/78x78/Ext_Eff_Reveal/SpinningFan.png"
import SpiralBlock from "../images/transition/78x78/Ext_Eff_Reveal/SpiralBlock.png"
import SpiralIn from "../images/transition/78x78/Ext_Eff_Reveal/SpiralIn.png"
import SplitBlinds from "../images/transition/78x78/Ext_Eff_Reveal/SplitBlinds.png"
import SquareDance from "../images/transition/78x78/Ext_Eff_Reveal/SquareDance.png"
import Stacker from "../images/transition/78x78/Ext_Eff_Reveal/Stacker.png"
import Stripes from "../images/transition/78x78/Ext_Eff_Reveal/Stripes.png"
import SuperStripper from "../images/transition/78x78/Ext_Eff_Reveal/SuperStripper.png"
import Swiss from "../images/transition/78x78/Ext_Eff_Reveal/Swiss.png"
import TimeSlice from "../images/transition/78x78/Ext_Eff_Reveal/TimeSlice.png"
import Turnaround from "../images/transition/78x78/Ext_Eff_Reveal/Turnaround.png"
import WideBlinds from "../images/transition/78x78/Ext_Eff_Reveal/WideBlinds.png"
import XmasTree from "../images/transition/78x78/Ext_Eff_Reveal/XmasTree.png"
import ZoomSpiral from "../images/transition/78x78/Ext_Eff_Reveal/ZoomSpiral.png"
import Ccccut from "../images/transition/78x78/Ext_Eff_Special/Ccccut.png"
import Cut from "../images/transition/78x78/Ext_Eff_Special/Cut.png"
import Fluorescent from "../images/transition/78x78/Ext_Eff_Special/Fluorescent.png"
import Quake from "../images/transition/78x78/Ext_Eff_Special/Quake.png"
import Rub from "../images/transition/78x78/Ext_Eff_Special/Rub.png"
import ArcIn from "../images/transition/78x78/Ext_Eff_Zoom/ArcIn.png"
import BouncingBlinds from "../images/transition/78x78/Ext_Eff_Zoom/BouncingBlinds.png"
import BouncingIris from "../images/transition/78x78/Ext_Eff_Zoom/BouncingIris.png"
import CenterZoom from "../images/transition/78x78/Ext_Eff_Zoom/CenterZoom.png"
import Contraction from "../images/transition/78x78/Ext_Eff_Zoom/Contraction.png"
import CornerFlyOn from "../images/transition/78x78/Ext_Eff_Zoom/CornerFlyOn.png"
import CornerStretch from "../images/transition/78x78/Ext_Eff_Zoom/CornerStretch.png"
import CornerZoom from "../images/transition/78x78/Ext_Eff_Zoom/CornerZoom.png"
import DiagonalZooms from "../images/transition/78x78/Ext_Eff_Zoom/DiagonalZooms.png"
import DualZoom from "../images/transition/78x78/Ext_Eff_Zoom/DualZoom.png"
import FlipFour from "../images/transition/78x78/Ext_Eff_Zoom/FlipFour.png"
import Implosion from "../images/transition/78x78/Ext_Eff_Zoom/Implosion.png"
import InYoFace from "../images/transition/78x78/Ext_Eff_Zoom/InYoFace.png"
import Kyoto from "../images/transition/78x78/Ext_Eff_Zoom/Kyoto.png"
import Memory from "../images/transition/78x78/Ext_Eff_Zoom/Memory.png"
import MiddleStretch from "../images/transition/78x78/Ext_Eff_Zoom/MiddleStretch.png"
import MirrorMaze from "../images/transition/78x78/Ext_Eff_Zoom/MirrorMaze.png"
import MirrorMazeRing from "../images/transition/78x78/Ext_Eff_Zoom/MirrorMazeRing.png"
import NewImproved from "../images/transition/78x78/Ext_Eff_Zoom/NewImproved.png"
import OurSponsor from "../images/transition/78x78/Ext_Eff_Zoom/OurSponsor.png"
import Premiere from "../images/transition/78x78/Ext_Eff_Zoom/Premiere.png"
import PremiereEdges from "../images/transition/78x78/Ext_Eff_Zoom/PremiereEdges.png"
import Presenting from "../images/transition/78x78/Ext_Eff_Zoom/Presenting.png"
import PsychoStretch from "../images/transition/78x78/Ext_Eff_Zoom/PsychoStretch.png"
import QuarterBounceOn from "../images/transition/78x78/Ext_Eff_Zoom/QuarterBounceOn.png"
import QuarterFlyOn from "../images/transition/78x78/Ext_Eff_Zoom/QuarterFlyOn.png"
import QuatroFlipCoin from "../images/transition/78x78/Ext_Eff_Zoom/QuatroFlipCoin.png"
import RandomGrowth from "../images/transition/78x78/Ext_Eff_Zoom/RandomGrowth.png"
import Recollection from "../images/transition/78x78/Ext_Eff_Zoom/Recollection.png"
import SlideStretch from "../images/transition/78x78/Ext_Eff_Zoom/SlideStretch.png"
import SlimZoom from "../images/transition/78x78/Ext_Eff_Zoom/SlimZoom.png"
import Stretch from "../images/transition/78x78/Ext_Eff_Zoom/Stretch.png"
import StripFoldOut from "../images/transition/78x78/Ext_Eff_Zoom/StripFoldOut.png"
import SwimIn from "../images/transition/78x78/Ext_Eff_Zoom/SwimIn.png"
import TotalRecall from "../images/transition/78x78/Ext_Eff_Zoom/TotalRecall.png"
import Troika from "../images/transition/78x78/Ext_Eff_Zoom/Troika.png"
import WhiteHole from "../images/transition/78x78/Ext_Eff_Zoom/WhiteHole.png"
import ZoomFade from "../images/transition/78x78/Ext_Eff_Zoom/ZoomFade.png"
import ZoomUp from "../images/transition/78x78/Ext_Eff_Zoom/ZoomUp.png"

export const playlistConstants = {

    PAGE_SIZE_OPTIONS: [10, 25, 50, 100],

    PLAYLISTTYPES:
        [
            {id: "0", value: 'general', name: 'COM_TEXT_GENERAL_P'},
            {id: "2", value: 'isVwl', name: 'TEXT_TITLE_VIDEOWALL_P'},
            {id: "3", value: 'sync', name: 'MIS_SID_SYNC_PLAY'},
            {id: "4", value: 'ad', name: 'COM_DID_LFD_ADVERTISEMENT'},
            {id: "5", value: 'tag', name: 'MIS_TEXT_TAG_P'},
            {id: "6", value: 'nested', name: 'MIS_SID_NESTED'},
        ],

    INIT_NEW_PLAYLIST: 'INIT_NEW_PLAYLIST',
    INIT_TAG_PLAYLIST: 'INIT_TAG_PLAYLIST',
    ADD_PLAYLIST: 'ADD_PLAYLIST',
    INIT_EDIT_PLAYLIST: 'INIT_EDIT_PLAYLIST',
    LOAD_PLAYLIST: 'LOAD_PLAYLIST',
    REMOVE_PLAYLIST: 'REMOVE_PLAYLIST',
    UPDATE_PLAYLIST: 'UPDATE_PLAYLIST',
    DESTROY_PLAYLIST: 'DESTROY_PLAYLIST',
    LOAD_PLAYLIST_EFFECT: 'LOAD_PLAYLIST_EFFECT',
    LOAD_TAG_INFORMATION: 'LOAD_TAG_INFORMATION',
    INCLUDE_EXPIRED_CONTENT: 'INCLUDE_EXPIRED_CONTENT',
    LOAD_TRASH_BASKET: 'LOAD_TRASH_BASKET',
    DESTROY_LAST_PLAYLIST: 'DESTROY_LAST_PLAYLIST',

    PLAYLIST_GROUP_TYPE: [
        {menuId: "ALL_PLAYLIST", groupType: 'ALL'},
        {menuId: "MY_PLAYLIST", groupType: 'GROUPED'},
        {menuId: "PLAYLIST_BY_USER", groupType: 'USER'},
        {menuId: "PLAYLIST_RECYCLE_BIN", groupType: 'DELETED'},
    ],

    MY_PLAYLIST_GROUP: 'MY_PLAYLIST_GROUP'
};

export const defaultPlaylist = {
    playlistType: 0,
    deviceTypeVersion: 2,
    deviceType: 'IPLAYER',
    playlistId: '',
    view: 'IMAGE',
    effectListMode: false,
    shareFlag: 1,
    shuffleFlag: 'N',
    ignoreTag: 1,
    evennessPlayback: 1,
    contents: [],
    contentList: undefined,
    init: true,
};

export const defaultTagPlaylist = {
    playlistType: 5,
    deviceTypeVersion: 2,
    deviceType: 'IPLAYER',
    playlistId: '',
    view: 'LIST',
    effectListMode: false,
    shareFlag: 1,
    shuffleFlag: 'N',
    ignoreTag: 0,
    evennessPlayback: 0,
    tags: [],
    defaultContentDuration: 5,
    init: true,
};

export const RANDOM = 'random';
export const GENERAL = 'general';
export const ADD = 'add';
export const UPDATE = 'update';
export const DELETE = 'delete';
export const SYNC_ITEM = 'syncItem';
export const TAG_ITEM = 'tagItem';

export const playlistEffectList = {
    TYPE1: [
        {id: "", imgSrc: TRANSITION1, effectName: 'none'},
        {id: "Ext_Eff_3D::Fall Left", imgSrc: FallLeft, effectName: '3D:Fall Left'},
        {
            id: "Ext_Eff_3D::Fall Right",
            imgSrc: FallRight,
            effectName: '3D:Fall Right'
        },
        {
            id: "Ext_Eff_3D::FallAndFadeLeft",
            imgSrc: FallAndFadeLeft,
            effectName: '3D:FallAndFadeLeft'
        },
        {
            id: "Ext_Eff_3D::FallAndFadeRight",
            imgSrc: FallAndFadeRight,
            effectName: '3D:FallAndFadeRight'
        },
        {
            id: "Ext_Eff_3D::BouncingFallLeft",
            imgSrc: BouncingFallLeft,
            effectName: '3D:BouncingFallLeft'
        },
        {
            id: "Ext_Eff_3D::BouncingFallRight",
            imgSrc: BouncingFallRight,
            effectName: '3D:BouncingFallRight'
        },
        {
            id: "Ext_Eff_3D::LeftSpinX",
            imgSrc: LeftSpinX,
            effectName: '3D:LeftSpinX'
        },
        {
            id: "Ext_Eff_3D::LeftSpinY",
            imgSrc: LeftSpinY,
            effectName: '3D:LeftSpinY'
        },
        {
            id: "Ext_Eff_3D::LeftSpinZ",
            imgSrc: LeftSpinZ,
            effectName: '3D:LeftSpinZ'
        },
        {
            id: "Ext_Eff_3D::RightSpinX",
            imgSrc: RightSpinX,
            effectName: '3D:RightSpinX'
        },
        {
            id: "Ext_Eff_3D::RightSpinY",
            imgSrc: RightSpinY,
            effectName: '3D:RightSpinY'
        },
        {
            id: "Ext_Eff_3D::RightSpinZ",
            imgSrc: RightSpinZ,
            effectName: '3D:RightSpinZ'
        },
        {
            id: "Ext_Eff_3D::RaiseSpinX",
            imgSrc: RaiseSpinX,
            effectName: '3D:RaiseSpinX'
        },
        {
            id: "Ext_Eff_3D::RaiseSpinY",
            imgSrc: RaiseSpinY,
            effectName: '3D:RaiseSpinY'
        },
        {
            id: "Ext_Eff_3D::RaiseSpinZ",
            imgSrc: RaiseSpinZ,
            effectName: '3D:RaiseSpinZ'
        },
        {
            id: "Ext_Eff_3D::FallSpinX",
            imgSrc: FallSpinX,
            effectName: '3D:FallSpinX'
        },
        {
            id: "Ext_Eff_3D::FallSpinY",
            imgSrc: FallSpinY,
            effectName: '3D:FallSpinY'
        },
        {
            id: "Ext_Eff_3D::FallSpinZ",
            imgSrc: FallSpinZ,
            effectName: '3D:FallSpinZ'
        },
        {
            id: "Ext_Eff_3D::FlyLeftSpinFly",
            imgSrc: FlyLeftSpinFly,
            effectName: '3D:FlyLeftSpinFly'
        },
        {
            id: "Ext_Eff_3D::FlyRightSpinFly",
            imgSrc: FlyRightSpinFly,
            effectName: '3D:FlyRightSpinFly'
        },
        {
            id: "Ext_Eff_3D::RaiseSpinRaise",
            imgSrc: RaiseSpinRaise,
            effectName: '3D:RaiseSpinRaise'
        },
        {
            id: "Ext_Eff_3D::FallSpinFall",
            imgSrc: FallSpinFall,
            effectName: '3D:FallSpinFall'
        },
        {
            id: "Ext_Eff_3D::SpinAtEndLeft",
            imgSrc: SpinAtEndLeft,
            effectName: '3D:SpinAtEndLeft'
        },
        {
            id: "Ext_Eff_3D::SpinAtEndRight",
            imgSrc: SpinAtEndRight,
            effectName: '3D:SpinAtEndRight'
        },
        {
            id: "Ext_Eff_3D::Ricochet X",
            imgSrc: RicochetX,
            effectName: '3D:Ricochet X'
        },
        {
            id: "Ext_Eff_3D::Ricochet Y",
            imgSrc: RicochetY,
            effectName: '3D:Ricochet Y'
        },
        {
            id: "Ext_Eff_3D::InLeftSpinXUp",
            imgSrc: InLeftSpinXUp,
            effectName: '3D:InLeftSpinXUp'
        },
        {
            id: "Ext_Eff_3D::InRightSpinXUp",
            imgSrc: InRightSpinXUp,
            effectName: '3D:InRightSpinXUp'
        },
        {
            id: "Ext_Eff_3D::InLeftSpinXDown",
            imgSrc: InLeftSpinXDown,
            effectName: '3D:InLeftSpinXDown'
        },
        {
            id: "Ext_Eff_3D::InRightSpinXDown",
            imgSrc: InRightSpinXDown,
            effectName: '3D:InRightSpinXDown'
        },
        {
            id: "Ext_Eff_3D::InLeftRotateUp",
            imgSrc: InLeftRotateUp,
            effectName: '3D:InLeftRotateUp'
        },
        {
            id: "Ext_Eff_3D::InRightRotateUp",
            imgSrc: InRightRotateUp,
            effectName: '3D:InRightRotateUp'
        },
        {
            id: "Ext_Eff_3D::InLeftRotateDown",
            imgSrc: InLeftRotateDown,
            effectName: '3D:InLeftRotateDown'
        },
        {
            id: "Ext_Eff_3D::InRightRotateDown",
            imgSrc: InRightRotateDown,
            effectName: '3D:InRightRotateDown'
        },
        {
            id: "Ext_Eff_3D::InLeftSpinYUp",
            imgSrc: InLeftSpinYUp,
            effectName: '3D:InLeftSpinYUp'
        },
        {
            id: "Ext_Eff_3D::InRightSpinYUp",
            imgSrc: InRightSpinYUp,
            effectName: '3D:InRightSpinYUp'
        },
        {
            id: "Ext_Eff_3D::InLeftSpinYDown",
            imgSrc: InLeftSpinYDown,
            effectName: '3D:InLeftSpinYDown'
        },
        {
            id: "Ext_Eff_3D::InRightSpinYDown",
            imgSrc: InRightSpinYDown,
            effectName: '3D:InRightSpinYDown'
        },
        {
            id: "Ext_Eff_3D::AM TumbleZoom",
            imgSrc: AMTumbleZoom,
            effectName: '3D:AM TumbleZoom'
        },
        {
            id: "Ext_Eff_3D::AM TumbleSmooth",
            imgSrc: AMTumbleSmooth,
            effectName: '3D:AM TumbleSmooth'
        },
        {
            id: "Ext_Eff_3D::Tumble Plus",
            imgSrc: TumblePlus,
            effectName: '3D:Tumble Plus'
        },
        {
            id: "Ext_Eff_3D::Uncover at end",
            imgSrc: Uncoveratend,
            effectName: '3D:Uncover at end'
        },
        {id: "Ext_Eff_3D::Spin", imgSrc: Spin, effectName: '3D:Spin'},
        {
            id: "Ext_Eff_3D::NewsFlash",
            imgSrc: NewsFlash,
            effectName: '3D:NewsFlash'
        },
        {id: "Ext_Eff_3D::Tumble", imgSrc: Tumble, effectName: '3D:Tumble'},
        {
            id: "Ext_Eff_3D::TumbleIn Left",
            imgSrc: TumbleInLeft,
            effectName: '3D:TumbleIn Left'
        },
        {
            id: "Ext_Eff_3D::TumbleIn Right",
            imgSrc: TumbleInRight,
            effectName: '3D:TumbleIn Right'
        },
        {
            id: "Ext_Eff_3D::InUpSpin Right",
            imgSrc: InUpSpinRight,
            effectName: '3D:InUpSpin Right'
        },
        {
            id: "Ext_Eff_3D::InDownSpin Left",
            imgSrc: InDownSpinLeft,
            effectName: '3D:InDownSpin Left'
        },
        {
            id: "Ext_Eff_3D::RushInRight",
            imgSrc: RushInRight,
            effectName: '3D:RushInRight'
        },
        {
            id: "Ext_Eff_3D::RushInLeft",
            imgSrc: RushInLeft,
            effectName: '3D:RushInLeft'
        },
        {id: "Ext_Eff_3D::Worm", imgSrc: Worm, effectName: '3D:Worm'},
        {
            id: "Ext_Eff_3D::Falling Rubber",
            imgSrc: FallingRubber,
            effectName: '3D:Falling Rubber'
        },
        {
            id: "Ext_Eff_3D::KnightDownLeft",
            imgSrc: KnightDownLeft,
            effectName: '3D:KnightDownLeft'
        },
        {
            id: "Ext_Eff_3D::KnightLeftDown",
            imgSrc: KnightLeftDown,
            effectName: '3D:KnightLeftDown'
        },
        {
            id: "Ext_Eff_3D::KnightLeftUp",
            imgSrc: KnightLeftUp,
            effectName: '3D:KnightLeftUp'
        },
        {
            id: "Ext_Eff_3D::KnightRightDown",
            imgSrc: KnightRightDown,
            effectName: '3D:KnightRightDown'
        },
        {
            id: "Ext_Eff_3D::KnightRightUp",
            imgSrc: KnightRightUp,
            effectName: '3D:KnightRightUp'
        },
        {
            id: "Ext_Eff_3D::KnightUpRight",
            imgSrc: KnightUpRight,
            effectName: '3D:KnightUpRight'
        },
        {
            id: "Ext_Eff_3D::KnightZoomDownLeft",
            imgSrc: KnightZoomDownLeft,
            effectName: '3D:KnightZoomDownLeft'
        },
        {
            id: "Ext_Eff_3D::KnightZoomDownRight",
            imgSrc: KnightZoomDownRight,
            effectName: '3D:KnightZoomDownRight'
        },
        {
            id: "Ext_Eff_3D::KnightZoomLeftDown",
            imgSrc: KnightZoomLeftDown,
            effectName: '3D:KnightZoomLeftDown'
        },
        {
            id: "Ext_Eff_3D::KnightZoomLeftUp",
            imgSrc: KnightZoomLeftUp,
            effectName: '3D:KnightZoomLeftUp'
        },
        {
            id: "Ext_Eff_3D::KnightZoomRightDown",
            imgSrc: KnightZoomRightDown,
            effectName: '3D:KnightZoomRightDown'
        },
        {
            id: "Ext_Eff_3D::KnightZoomRightUp",
            imgSrc: KnightZoomRightUp,
            effectName: '3D:KnightZoomRightUp'
        },
        {
            id: "Ext_Eff_3D::KnightZoomUpLeft",
            imgSrc: KnightZoomUpLeft,
            effectName: '3D:KnightZoomUpLeft'
        },
        {
            id: "Ext_Eff_3D::KnightZoomUpRight",
            imgSrc: KnightZoomUpRight,
            effectName: '3D:KnightZoomUpRight'
        },
        {id: "Ext_Eff_3D2::4Spin", imgSrc: fourSpin, effectName: '3D:4Spin'},
        {
            id: "Ext_Eff_3D2::AMSpinFade",
            imgSrc: AMSpinFade,
            effectName: '3D:AMSpinFade'
        },
        {
            id: "Ext_Eff_3D2::AMTumble & SpinLeft",
            imgSrc: AMTumbleSpinLeft,
            effectName: '3D:AMTumble&SpinLeft'
        },
        {
            id: "Ext_Eff_3D2::AMTumble & SpinRight",
            imgSrc: AMTumbleSpinRight,
            effectName: '3D:AMTumble&SpinRight'
        },
        {
            id: "Ext_Eff_3D2::ArcInLeftSpinX",
            imgSrc: ArcInLeftSpinX,
            effectName: '3D:ArcInLeftSpinX'
        },
        {
            id: "Ext_Eff_3D2::ArcInRightSpinX",
            imgSrc: ArcInRightSpinX,
            effectName: '3D:ArcInRightSpinX'
        },
        {
            id: "Ext_Eff_3D2::BlindFold",
            imgSrc: BlindFold,
            effectName: '3D:BlindFold'
        },
        {
            id: "Ext_Eff_3D2::BlindFold2",
            imgSrc: BlindFold2,
            effectName: '3D:BlindFold2'
        },
        {
            id: "Ext_Eff_3D2::BlindFold3",
            imgSrc: BlindFold3,
            effectName: '3D:BlindFold3'
        },
        {
            id: "Ext_Eff_3D2::BottomEdgeSpin",
            imgSrc: BottomEdgeSpin,
            effectName: '3D:BottomEdgeSpin'
        },
        {
            id: "Ext_Eff_3D2::Careen",
            imgSrc: Careen,
            effectName: '3D:Careen'
        },
        {
            id: "Ext_Eff_3D2::CloseGarage",
            imgSrc: CloseGarage,
            effectName: '3D:CloseGarage'
        },
        {
            id: "Ext_Eff_3D2::ClosingRamps",
            imgSrc: ClosingRamps,
            effectName: '3D:ClosingRamps'
        },
        {
            id: "Ext_Eff_3D2::CueCard",
            imgSrc: CueCard,
            effectName: '3D:CueCard'
        },
        {
            id: "Ext_Eff_3D2::Disorder",
            imgSrc: Disorder,
            effectName: '3D:Disorder'
        },
        {
            id: "Ext_Eff_3D2::DoubleDoors",
            imgSrc: DoubleDoors,
            effectName: '3D:DoubleDoors'
        },
        {
            id: "Ext_Eff_3D2::DoubleDoors2",
            imgSrc: DoubleDoors2,
            effectName: '3D:DoubleDoors2'
        },
        {
            id: "Ext_Eff_3D2::DoubleSpin",
            imgSrc: DoubleSpin,
            effectName: '3D:DoubleSpin'
        },
        {id: "Ext_Eff_3D2::Float", imgSrc: Float, effectName: '3D:Float'},
        {
            id: "Ext_Eff_3D2::Folder",
            imgSrc: Folder,
            effectName: '3D:Folder'
        },
        {
            id: "Ext_Eff_3D2::HeyYou1",
            imgSrc: HeyYou1,
            effectName: '3D:HeyYou1'
        },
        {
            id: "Ext_Eff_3D2::HeyYou2",
            imgSrc: HeyYou2,
            effectName: '3D:HeyYou2'
        },
        {
            id: "Ext_Eff_3D2::HookEastSpin",
            imgSrc: HookEastSpin,
            effectName: '3D:HookEastSpin'
        },
        {
            id: "Ext_Eff_3D2::HookWestSpin",
            imgSrc: HookWestSpin,
            effectName: '3D:HookWestSpin'
        },
        {
            id: "Ext_Eff_3D2::JumpInLeft",
            imgSrc: JumpInLeft,
            effectName: '3D:JumpInLeft'
        },
        {
            id: "Ext_Eff_3D2::JumpInRight",
            imgSrc: JumpInRight,
            effectName: '3D:JumpInRight'
        },
        {
            id: "Ext_Eff_3D2::LeftEdgeSpin",
            imgSrc: LeftEdgeSpin,
            effectName: '3D:LeftEdgeSpin'
        },
        {
            id: "Ext_Eff_3D2::NewZoomNorth",
            imgSrc: NewZoomNorth,
            effectName: '3D:NewZoomNorth'
        },
        {
            id: "Ext_Eff_3D2::NewZoomSouth",
            imgSrc: NewZoomSouth,
            effectName: '3D:NewZoomSouth'
        },
        {
            id: "Ext_Eff_3D2::PivotIn",
            imgSrc: PivotIn,
            effectName: '3D:PivotIn'
        },
        {
            id: "Ext_Eff_3D2::QuadSpin",
            imgSrc: QuadSpin,
            effectName: '3D:QuadSpin'
        },
        {
            id: "Ext_Eff_3D2::RightEdgeSpin",
            imgSrc: RightEdgeSpin,
            effectName: '3D:RightEdgeSpin'
        },
        {
            id: "Ext_Eff_3D2::RockIn",
            imgSrc: RockIn,
            effectName: '3D:RockIn'
        },
        {
            id: "Ext_Eff_3D2::SatelliteDoubleSmall",
            imgSrc: SatelliteDoubleSmall,
            effectName: '3D:SatelliteDoubleSmall'
        },
        {
            id: "Ext_Eff_3D2::SatelliteMedium",
            imgSrc: SatelliteMedium,
            effectName: '3D:SatelliteMedium'
        },
        {
            id: "Ext_Eff_3D2::SmoothArcEastY",
            imgSrc: SmoothArcEastY,
            effectName: '3D:SmoothArcEastY'
        },
        {
            id: "Ext_Eff_3D2::SmoothArcNorth",
            imgSrc: SmoothArcNorth,
            effectName: '3D:SmoothArcNorth'
        },
        {
            id: "Ext_Eff_3D2::SmoothArcSouth",
            imgSrc: SmoothArcSouth,
            effectName: '3D:SmoothArcSouth'
        },
        {
            id: "Ext_Eff_3D2::SmoothArcWestY",
            imgSrc: SmoothArcWestY,
            effectName: '3D:SmoothArcWestY'
        },
        {
            id: "Ext_Eff_3D2::SpinHookEast",
            imgSrc: SpinHookEast,
            effectName: '3D:SpinHookEast'
        },
        {
            id: "Ext_Eff_3D2::SpinHookWest",
            imgSrc: SpinHookWest,
            effectName: '3D:SpinHookWest'
        },
        {
            id: "Ext_Eff_3D2::TiltFly",
            imgSrc: TiltFly,
            effectName: '3D:TiltFly'
        },
        {
            id: "Ext_Eff_3D2::TopEdgeSpin",
            imgSrc: TopEdgeSpin,
            effectName: '3D:TopEdgeSpin'
        },
        {
            id: "Ext_Eff_3D2::TwistIn",
            imgSrc: TwistIn,
            effectName: '3D:TwistIn'
        },
        {
            id: "Ext_Eff_3D2::Uncover",
            imgSrc: Uncover,
            effectName: '3D:Uncover'
        },
        {
            id: "Ext_Eff_Alpha::TheBlob",
            imgSrc: TheBlob,
            effectName: 'Alpha:TheBlob'
        },
        {
            id: "Ext_Eff_Alpha::MandelBrot",
            imgSrc: MandelBrot,
            effectName: 'Alpha:MandelBrot'
        },
        {
            id: "Ext_Eff_Alpha::Erosion",
            imgSrc: Erosion,
            effectName: 'Alpha:Erosion'
        },
        {
            id: "Ext_Eff_Alpha::Vortex",
            imgSrc: Vortex,
            effectName: 'Alpha:Vortex'
        },
        {
            id: "Ext_Eff_Alpha::Fractured",
            imgSrc: Fractured,
            effectName: 'Alpha:Fractured'
        },
        {
            id: "Ext_Eff_Alpha::RippleWash",
            imgSrc: RippleWash,
            effectName: 'Alpha:RippleWash'
        },
        {
            id: "Ext_Eff_Alpha::WetWiper",
            imgSrc: WetWiper,
            effectName: 'Alpha:WetWiper'
        },
        {
            id: "Ext_Eff_Alpha::Canvas",
            imgSrc: Canvas,
            effectName: 'Alpha:Canvas'
        },
        {
            id: "Ext_Eff_Alpha::WarmFronts",
            imgSrc: WarmFronts,
            effectName: 'Alpha:WarmFronts'
        },
        {
            id: "Ext_Eff_Alpha::ArcWiper",
            imgSrc: ArcWiper,
            effectName: 'Alpha:ArcWiper'
        },
        {
            id: "Ext_Eff_Alpha::Smokescreen",
            imgSrc: Smokescreen,
            effectName: 'Alpha:Smokescreen'
        },
        {
            id: "Ext_Eff_Alpha::Floodlights",
            imgSrc: Floodlights,
            effectName: 'Alpha:Floodlights'
        },
        {
            id: "Ext_Eff_Alpha::SlidingStar",
            imgSrc: SlidingStar,
            effectName: 'Alpha:SlidingStar'
        },
        {
            id: "Ext_Eff_Fade::CutWhiteFade",
            imgSrc: CutWhiteFade,
            effectName: 'Fade:CutWhiteFade'
        },
        {
            id: "Ext_Eff_Fade::CutGrayFade",
            imgSrc: CutGrayFade,
            effectName: 'Fade:CutGrayFade'
        },
        {
            id: "Ext_Eff_Fade::CutBlackFade",
            imgSrc: CutBlackFade,
            effectName: 'Fade:CutBlackFade'
        },
        {
            id: "Ext_Eff_Fade::CutRedFade",
            imgSrc: CutRedFade,
            effectName: 'Fade:CutRedFade'
        },
        {
            id: "Ext_Eff_Fade::CutGreenFade",
            imgSrc: CutGreenFade,
            effectName: 'Fade:CutGreenFade'
        },
        {
            id: "Ext_Eff_Fade::CutBlueFade",
            imgSrc: CutBlueFade,
            effectName: 'Fade:CutBlueFade'
        },
        {
            id: "Ext_Eff_Fade::CutOrangeFade",
            imgSrc: CutOrangeFade,
            effectName: 'Fade:CutOrangeFade'
        },
        {
            id: "Ext_Eff_Fade::CutYellowFade",
            imgSrc: CutYellowFade,
            effectName: 'Fade:CutYellowFade'
        },
        {
            id: "Ext_Eff_Fade::CutPurpleFade",
            imgSrc: CutPurpleFade,
            effectName: 'Fade:CutPurpleFade'
        },
        {
            id: "Ext_Eff_FlyOn::Straight",
            imgSrc: Straight,
            effectName: 'FlyOn:Straight'
        },
        {
            id: "Ext_Eff_FlyOn::Sine",
            imgSrc: Sine,
            effectName: 'FlyOn:Sine'
        },
        {
            id: "Ext_Eff_FlyOn::Wiggle",
            imgSrc: Wiggle,
            effectName: 'FlyOn:Wiggle'
        },
        {
            id: "Ext_Eff_FlyOn::DropWave",
            imgSrc: DropWave,
            effectName: 'FlyOn:DropWave'
        },
        {
            id: "Ext_Eff_FlyOn::GlideWave",
            imgSrc: GlideWave,
            effectName: 'FlyOn:GlideWave'
        },
        {
            id: "Ext_Eff_FlyOn::Crescent",
            imgSrc: Crescent,
            effectName: 'FlyOn:Crescent'
        },
        {
            id: "Ext_Eff_FlyOn::Loop",
            imgSrc: Loop,
            effectName: 'FlyOn:Loop'
        },
        {
            id: "Ext_Eff_FlyOn::Boomerang",
            imgSrc: Boomerang,
            effectName: 'FlyOn:Boomerang'
        },
        {
            id: "Ext_Eff_FlyOn::Damped",
            imgSrc: Damped,
            effectName: 'FlyOn:Damped'
        },
        {
            id: "Ext_Eff_FlyOn::FadeWave",
            imgSrc: FadeWave,
            effectName: 'FlyOn:FadeWave'
        },
        {
            id: "Ext_Eff_FlyOn::FadeLoop",
            imgSrc: FadeLoop,
            effectName: 'FlyOn:FadeLoop'
        },
        {
            id: "Ext_Eff_FlyOn::FlyFade",
            imgSrc: FlyFade,
            effectName: 'FlyOn:FlyFade'
        },
        {
            id: "Ext_Eff_FlyOn::ShortFadeNorth",
            imgSrc: ShortFadeNorth,
            effectName: 'FlyOn:ShortFadeNorth'
        },
        {
            id: "Ext_Eff_FlyOn::ShortFadeEast",
            imgSrc: ShortFadeEast,
            effectName: 'FlyOn:ShortFadeEast'
        },
        {
            id: "Ext_Eff_FlyOn::ShortFadeSouth",
            imgSrc: ShortFadeSouth,
            effectName: 'FlyOn:ShortFadeSouth'
        },
        {
            id: "Ext_Eff_FlyOn::ShortFadeWest",
            imgSrc: ShortFadeWest,
            effectName: 'FlyOn:ShortFadeWest'
        },
        {
            id: "Ext_Eff_FlyOn::DampedFade",
            imgSrc: DampedFade,
            effectName: 'FlyOn:DampedFade'
        },
        {
            id: "Ext_Eff_FlyOn::KnightUp",
            imgSrc: KnightUp,
            effectName: 'FlyOn:KnightUp'
        },
        {
            id: "Ext_Eff_FlyOn::KnightDown",
            imgSrc: KnightDown,
            effectName: 'FlyOn:KnightDown'
        },
        {
            id: "Ext_Eff_FlyOn::FlyZoom",
            imgSrc: FlyZoom,
            effectName: 'FlyOn:FlyZoom'
        },
        {
            id: "Ext_Eff_FlyOn::FlyZoomWave",
            imgSrc: FlyZoomWave,
            effectName: 'FlyOn:FlyZoomWave'
        },
        {
            id: "Ext_Eff_FlyOn::FlyZoomX",
            imgSrc: FlyZoomX,
            effectName: 'FlyOn:FlyZoomX'
        },
        {
            id: "Ext_Eff_FlyOn::FlyZoomY",
            imgSrc: FlyZoomY,
            effectName: 'FlyOn:FlyZoomY'
        },
        {
            id: "Ext_Eff_FlyOn::Dissolve",
            imgSrc: Dissolve,
            effectName: 'FlyOn:Dissolve'
        },
        {
            id: "Ext_Eff_PassThru::FadeThrough",
            imgSrc: FadeThrough,
            effectName: 'PassThru:FadeThrough'
        },
        {
            id: "Ext_Eff_PassThru::FadeThroughFastIn",
            imgSrc: FadeThroughFastIn,
            effectName: 'PassThru:FadeThroughFastIn'
        },
        {
            id: "Ext_Eff_PassThru::FadeThroughFastOut",
            imgSrc: FadeThroughFastOut,
            effectName: 'PassThru:FadeThroughFastOut'
        },
        {
            id: "Ext_Eff_PassThru::FlareFadeThrough",
            imgSrc: FlareFadeThrough,
            effectName: 'PassThru:FlareFadeThrough'
        },
        {
            id: "Ext_Eff_PassThru::GrowFadeThrough",
            imgSrc: GrowFadeThrough,
            effectName: 'PassThru:GrowFadeThrough'
        },
        {
            id: "Ext_Eff_PassThru::Peekaboo-East",
            imgSrc: PeekabooEast,
            effectName: 'PassThru:Peekaboo-East'
        },
        {
            id: "Ext_Eff_PassThru::Peekaboo-North",
            imgSrc: PeekabooNorth,
            effectName: 'PassThru:Peekaboo-North'
        },
        {
            id: "Ext_Eff_PassThru::Peekaboo-NorthEast",
            imgSrc: PeekabooNorthEast,
            effectName: 'PassThru:Peekaboo-NorthEast'
        },
        {
            id: "Ext_Eff_PassThru::Peekaboo-NorthWest",
            imgSrc: PeekabooNorthWest,
            effectName: 'PassThru:Peekaboo-NorthWest'
        },
        {
            id: "Ext_Eff_PassThru::Peekaboo-South",
            imgSrc: PeekabooSouth,
            effectName: 'PassThru:Peekaboo-South'
        },
        {
            id: "Ext_Eff_PassThru::Peekaboo-SouthEast",
            imgSrc: PeekabooSouthEast,
            effectName: 'PassThru:Peekaboo-SouthEast'
        },
        {
            id: "Ext_Eff_PassThru::Peekaboo-SouthWest",
            imgSrc: PeekabooSouthWest,
            effectName: 'PassThru:Peekaboo-SouthWest'
        },
        {
            id: "Ext_Eff_PassThru::Peekaboo-West",
            imgSrc: PeekabooWest,
            effectName: 'PassThru:Peekaboo-West'
        },
        {
            id: "Ext_Eff_PassThru::ShrinkFadeThrough",
            imgSrc: ShrinkFadeThrough,
            effectName: 'PassThru:ShrinkFadeThrough'
        },
        {
            id: "Ext_Eff_PassThru::StellarPlane",
            imgSrc: StellarPlane,
            effectName: 'PassThru:StellarPlane'
        },
        {
            id: "Ext_Eff_PassThru::StellarReceding",
            imgSrc: StellarReceding,
            effectName: 'PassThru:StellarReceding'
        },
        {
            id: "Ext_Eff_PassThru::StraightThrough",
            imgSrc: StraightThrough,
            effectName: 'PassThru:StraightThrough'
        },
        {
            id: "Ext_Eff_PassThru::StraightFadeThrough-East",
            imgSrc: StraightFadeThroughEast,
            effectName: 'StraightFadeThrough-East'
        },
        {
            id: "Ext_Eff_PassThru::StraightFadeThrough-North",
            imgSrc: StraightFadeThroughNorth,
            effectName: 'StraightFadeThrough-North'
        },
        {
            id: "Ext_Eff_PassThru::StraightFadeThrough-NorthEast",
            imgSrc: StraightFadeThroughNorthEast,
            effectName: 'StraightFadeThrough-NorthEast'
        },
        {
            id: "Ext_Eff_PassThru::StraightFadeThrough-NorthWest",
            imgSrc: StraightFadeThroughNorthWest,
            effectName: 'StraightFadeThrough-NorthWest'
        },
        {
            id: "Ext_Eff_PassThru::StraightFadeThrough-South",
            imgSrc: StraightFadeThroughSouth,
            effectName: 'StraightFadeThrough-South'
        },
        {
            id: "Ext_Eff_PassThru::StraightFadeThrough-SouthEast",
            imgSrc: StraightFadeThroughSouthEast,
            effectName: 'StraightFadeThrough-SouthEast'
        },
        {
            id: "Ext_Eff_PassThru::StraightFadeThrough-SouthWest",
            imgSrc: StraightFadeThroughSouthWest,
            effectName: 'StraightFadeThrough-SouthWest'
        },
        {
            id: "Ext_Eff_PassThru::StraightFadeThrough-West",
            imgSrc: StraightFadeThroughWest,
            effectName: 'StraightFadeThrough-West'
        },
        {
            id: "Ext_Eff_PassThru::TallFadeThrough",
            imgSrc: TallFadeThrough,
            effectName: 'PassThru:TallFadeThrough'
        },
        {
            id: "Ext_Eff_PassThru::Trailer",
            imgSrc: Trailer,
            effectName: 'PassThru:Trailer'
        },
        {
            id: "Ext_Eff_PassThru::TurnFadeThrough",
            imgSrc: TurnFadeThrough,
            effectName: 'PassThru:TurnFadeThrough'
        },
        {
            id: "Ext_Eff_PassThru::WideFadeThrough",
            imgSrc: WideFadeThrough,
            effectName: 'PassThru:WideFadeThrough'
        },
        {
            id: "Ext_Eff_Push::ScrollClosed",
            imgSrc: ScrollClosed,
            effectName: 'Push:ScrollClosed'
        },
        {
            id: "Ext_Eff_Push::SlatsCrash",
            imgSrc: SlatsCrash,
            effectName: 'Push:SlatsCrash'
        },
        {
            id: "Ext_Eff_Push::RollOn",
            imgSrc: RollOn,
            effectName: 'Push:RollOn'
        },
        {
            id: "Ext_Eff_Push::DoubleRollOn",
            imgSrc: DoubleRollOn,
            effectName: 'Push:DoubleRollOn'
        },
        {
            id: "Ext_Eff_Push::UnrollStripts",
            imgSrc: UnrollStripts,
            effectName: 'Push:UnrollStripts'
        },
        {
            id: "Ext_Eff_Push2::Clasp",
            imgSrc: Clasp,
            effectName: 'Push:Clasp'
        },
        {
            id: "Ext_Eff_Push2::DoubleSlam",
            imgSrc: DoubleSlam,
            effectName: 'Push:DoubleSlam'
        },
        {
            id: "Ext_Eff_Push2::DoubleVision",
            imgSrc: DoubleVision,
            effectName: 'Push:DoubleVision'
        },
        {
            id: "Ext_Eff_Push2::PinWheel",
            imgSrc: PinWheel,
            effectName: 'Push:PinWheel'
        },
        {
            id: "Ext_Eff_Push2::PushMe",
            imgSrc: PushMe,
            effectName: 'Push:PushMe'
        },
        {
            id: "Ext_Eff_Push2::PushMoreStrips",
            imgSrc: PushMoreStrips,
            effectName: 'Push:PushMoreStrips'
        },
        {
            id: "Ext_Eff_Push2::PushStrips",
            imgSrc: PushStrips,
            effectName: 'Push:PushStrips'
        },
        {
            id: "Ext_Eff_Push2::PushMoreWave",
            imgSrc: PushMoreWave,
            effectName: 'Push:PushMoreWave'
        },
        {
            id: "Ext_Eff_Push2::QuadrantIn",
            imgSrc: QuadrantIn,
            effectName: 'Push:QuadrantIn'
        },
        {
            id: "Ext_Eff_Push2::ScaleIn",
            imgSrc: ScaleIn,
            effectName: 'Push:ScaleIn'
        },
        {
            id: "Ext_Eff_Push2::ScrollDivide",
            imgSrc: ScrollDivide,
            effectName: 'Push:ScrollDivide'
        },
        {
            id: "Ext_Eff_Push2::ScrollIn",
            imgSrc: ScrollIn,
            effectName: 'Push:ScrollIn'
        },
        {
            id: "Ext_Eff_Push2::SlatsTogether2",
            imgSrc: SlatsTogether2,
            effectName: 'Push:SlatsTogether2'
        },
        {
            id: "Ext_Eff_Push2::SlatsTogether4",
            imgSrc: SlatsTogether4,
            effectName: 'Push:SlatsTogether4'
        },
        {
            id: "Ext_Eff_Push2::SlatsClosed8",
            imgSrc: SlatsClosed8,
            effectName: 'Push:SlatsClosed8'
        },
        {
            id: "Ext_Eff_Push2::Slam",
            imgSrc: Slam,
            effectName: 'Push:Slam'
        },
        {
            id: "Ext_Eff_Push2::Slicer",
            imgSrc: Slicer,
            effectName: 'Push:Slicer'
        },
        {
            id: "Ext_Eff_Push2::SweepSlicer",
            imgSrc: SweepSlicer,
            effectName: 'Push:SweepSlicer'
        },
        {
            id: "Ext_Eff_Push2::Trislide",
            imgSrc: Trislide,
            effectName: 'Push:Trislide'
        },
        {
            id: "Ext_Eff_Push2::TrislideLR",
            imgSrc: TrislideLR,
            effectName: 'Push:TrislideLR'
        },
        {
            id: "Ext_Eff_Random::Next",
            imgSrc: Next,
            effectName: 'Special:Next'
        },
        {
            id: "Ext_Eff_Random::Random Always",
            imgSrc: RandomAlways,
            effectName: 'Special:Random Always'
        },
        {
            id: "Ext_Eff_Random::Random Once",
            imgSrc: RandomOnce,
            effectName: 'Special:Random Once'
        },
        {
            id: "Ext_Eff_Reveal::Wipe",
            imgSrc: Wipe,
            effectName: 'Reveal:Wipe'
        },
        {
            id: "Ext_Eff_Reveal::Center",
            imgSrc: Center,
            effectName: 'Reveal:Center'
        },
        {
            id: "Ext_Eff_Reveal::Split",
            imgSrc: Split,
            effectName: 'Reveal:Split'
        },
        {
            id: "Ext_Eff_Reveal::Quadrant",
            imgSrc: Quadrant,
            effectName: 'Reveal:Quadrant'
        },
        {
            id: "Ext_Eff_Reveal::Cornor",
            imgSrc: Cornor,
            effectName: 'Reveal:Cornor'
        },
        {
            id: "Ext_Eff_Reveal::SmallBlinds",
            imgSrc: SmallBlinds,
            effectName: 'Reveal:SmallBlinds'
        },
        {
            id: "Ext_Eff_Reveal::Striper",
            imgSrc: Striper,
            effectName: 'Reveal:Striper'
        },
        {
            id: "Ext_Eff_Reveal2::BasketWeave",
            imgSrc: BasketWeave,
            effectName: 'Reveal:BasketWeave'
        },
        {
            id: "Ext_Eff_Reveal2::BlindsFantasy1",
            imgSrc: BlindsFantasy1,
            effectName: 'Reveal:BlindsFantasy1'
        },
        {
            id: "Ext_Eff_Reveal2::BlindsFantasy2",
            imgSrc: BlindsFantasy2,
            effectName: 'Reveal:BlindsFantasy2'
        },
        {
            id: "Ext_Eff_Reveal2::BlindsFantasy3",
            imgSrc: BlindsFantasy3,
            effectName: 'Reveal:BlindsFantasy3'
        },
        {
            id: "Ext_Eff_Reveal2::Blocks",
            imgSrc: Blocks,
            effectName: 'Reveal:Blocks'
        },
        {
            id: "Ext_Eff_Reveal2::ClosingBlinds",
            imgSrc: ClosingBlinds,
            effectName: 'Reveal:ClosingBlinds'
        },
        {
            id: "Ext_Eff_Reveal2::Closure",
            imgSrc: Closure,
            effectName: 'Reveal:Closure'
        },
        {
            id: "Ext_Eff_Reveal2::CornerSlice",
            imgSrc: CornerSlice,
            effectName: 'Reveal:CornerSlice'
        },
        {
            id: "Ext_Eff_Reveal2::Curtain",
            imgSrc: Curtain,
            effectName: 'Reveal:Curtain'
        },
        {
            id: "Ext_Eff_Reveal2::DiagonalBlocks",
            imgSrc: DiagonalBlocks,
            effectName: 'Reveal:DiagonalBlocks'
        },
        {
            id: "Ext_Eff_Reveal2::DiagonalStrips",
            imgSrc: DiagonalStrips,
            effectName: 'Reveal:DiagonalStrips'
        },
        {
            id: "Ext_Eff_Reveal2::Enigma",
            imgSrc: Enigma,
            effectName: 'Reveal:Enigma'
        },
        {
            id: "Ext_Eff_Reveal2::EnigmaReverse",
            imgSrc: EnigmaReverse,
            effectName: 'Reveal:EnigmaReverse'
        },
        {
            id: "Ext_Eff_Reveal2::MiniQuadrant",
            imgSrc: MiniQuadrant,
            effectName: 'Reveal:MiniQuadrant'
        },
        {
            id: "Ext_Eff_Reveal2::PaintDrip",
            imgSrc: PaintDrip,
            effectName: 'Reveal:PaintDrip'
        },
        {
            id: "Ext_Eff_Reveal2::Peel",
            imgSrc: Peel,
            effectName: 'Reveal:Peel'
        },
        {
            id: "Ext_Eff_Reveal2::PremiereReveal",
            imgSrc: PremiereReveal,
            effectName: 'Reveal:PremiereReveal'
        },
        {
            id: "Ext_Eff_Reveal2::RandomBlocks",
            imgSrc: RandomBlocks,
            effectName: 'Reveal:RandomBlocks'
        },
        {
            id: "Ext_Eff_Reveal2::ScaleTheWall",
            imgSrc: ScaleTheWall,
            effectName: 'Reveal:ScaleTheWall'
        },
        {
            id: "Ext_Eff_Reveal2::SmoothWall",
            imgSrc: SmoothWall,
            effectName: 'Reveal:SmoothWall'
        },
        {
            id: "Ext_Eff_Reveal2::SpinningFan",
            imgSrc: SpinningFan,
            effectName: 'Reveal:SpinningFan'
        },
        {
            id: "Ext_Eff_Reveal2::SpiralBlock",
            imgSrc: SpiralBlock,
            effectName: 'Reveal:SpiralBlock'
        },
        {
            id: "Ext_Eff_Reveal2::SpiralIn",
            imgSrc: SpiralIn,
            effectName: 'Reveal:SpiralIn'
        },
        {
            id: "Ext_Eff_Reveal2::SplitBlinds",
            imgSrc: SplitBlinds,
            effectName: 'Reveal:SplitBlinds'
        },
        {
            id: "Ext_Eff_Reveal2::SquareDance",
            imgSrc: SquareDance,
            effectName: 'Reveal:SquareDance'
        },
        {
            id: "Ext_Eff_Reveal2::Stacker",
            imgSrc: Stacker,
            effectName: 'Reveal:Stacker'
        },
        {
            id: "Ext_Eff_Reveal2::Stripes",
            imgSrc: Stripes,
            effectName: 'Reveal:Stripes'
        },
        {
            id: "Ext_Eff_Reveal2::SuperStripper",
            imgSrc: SuperStripper,
            effectName: 'Reveal:SuperStripper'
        },
        {
            id: "Ext_Eff_Reveal2::Swiss",
            imgSrc: Swiss,
            effectName: 'Reveal:Swiss'
        },
        {
            id: "Ext_Eff_Reveal2::TimeSlice",
            imgSrc: TimeSlice,
            effectName: 'Reveal:TimeSlice'
        },
        {
            id: "Ext_Eff_Reveal2::Turnaround",
            imgSrc: Turnaround,
            effectName: 'Reveal:Turnaround'
        },
        {
            id: "Ext_Eff_Reveal2::WideBlinds",
            imgSrc: WideBlinds,
            effectName: 'Reveal:WideBlinds'
        },
        {
            id: "Ext_Eff_Reveal2::XmasTree",
            imgSrc: XmasTree,
            effectName: 'Reveal:XmasTree'
        },
        {
            id: "Ext_Eff_Reveal2::ZoomSpiral",
            imgSrc: ZoomSpiral,
            effectName: 'Reveal:ZoomSpiral'
        },
        {
            id: "Ext_Eff_Special2::Ccccut",
            imgSrc: Ccccut,
            effectName: 'Special:Ccccut'
        },
        {
            id: "Ext_Eff_Special2::Cut",
            imgSrc: Cut,
            effectName: 'Special:Cut'
        },
        {
            id: "Ext_Eff_Special2::Fluorescent",
            imgSrc: Fluorescent,
            effectName: 'Special:Fluorescent'
        },
        {
            id: "Ext_Eff_Special2::Quake",
            imgSrc: Quake,
            effectName: 'Special:Quake'
        },
        {
            id: "Ext_Eff_Special2::Rub",
            imgSrc: Rub,
            effectName: 'Special:Rub'
        },
        {
            id: "Ext_Eff_Zoom::ArcIn",
            imgSrc: ArcIn,
            effectName: 'Zoom:ArcIn'
        },
        {
            id: "Ext_Eff_Zoom::BouncingBlinds",
            imgSrc: BouncingBlinds,
            effectName: 'Zoom:BouncingBlinds'
        },
        {
            id: "Ext_Eff_Zoom::BouncingIris",
            imgSrc: BouncingIris,
            effectName: 'Zoom:BouncingIris'
        },
        {
            id: "Ext_Eff_Zoom::CenterZoom",
            imgSrc: CenterZoom,
            effectName: 'Zoom:CenterZoom'
        },
        {
            id: "Ext_Eff_Zoom::Contraction",
            imgSrc: Contraction,
            effectName: 'Zoom:Contraction'
        },
        {
            id: "Ext_Eff_Zoom::CornerFlyOn",
            imgSrc: CornerFlyOn,
            effectName: 'Zoom:CornerFlyOn'
        },
        {
            id: "Ext_Eff_Zoom::CornerStretch",
            imgSrc: CornerStretch,
            effectName: 'Zoom:CornerStretch'
        },
        {
            id: "Ext_Eff_Zoom::CornerZoom",
            imgSrc: CornerZoom,
            effectName: 'Zoom:CornerZoom'
        },
        {
            id: "Ext_Eff_Zoom::DiagonalZooms",
            imgSrc: DiagonalZooms,
            effectName: 'Zoom:DiagonalZooms'
        },
        {
            id: "Ext_Eff_Zoom::DualZoom",
            imgSrc: DualZoom,
            effectName: 'Zoom:DualZoom'
        },
        {
            id: "Ext_Eff_Zoom::FlipFour",
            imgSrc: FlipFour,
            effectName: 'Zoom:FlipFour'
        },
        {
            id: "Ext_Eff_Zoom::Implosion",
            imgSrc: Implosion,
            effectName: 'Zoom:Implosion'
        },
        {
            id: "Ext_Eff_Zoom::InYoFace",
            imgSrc: InYoFace,
            effectName: 'Zoom:InYoFace'
        },
        {
            id: "Ext_Eff_Zoom::Kyoto",
            imgSrc: Kyoto,
            effectName: 'Zoom:Kyoto'
        },
        {
            id: "Ext_Eff_Zoom::Memory",
            imgSrc: Memory,
            effectName: 'Zoom:Memory'
        },
        {
            id: "Ext_Eff_Zoom::MiddleStretch",
            imgSrc: MiddleStretch,
            effectName: 'Zoom:MiddleStretch'
        },
        {
            id: "Ext_Eff_Zoom::MirrorMaze",
            imgSrc: MirrorMaze,
            effectName: 'Zoom:MirrorMaze'
        },
        {
            id: "Ext_Eff_Zoom::MirrorMazeRing",
            imgSrc: MirrorMazeRing,
            effectName: 'Zoom:MirrorMazeRing'
        },
        {
            id: "Ext_Eff_Zoom::NewImproved",
            imgSrc: NewImproved,
            effectName: 'Zoom:NewImproved'
        },
        {
            id: "Ext_Eff_Zoom::OurSponsor",
            imgSrc: OurSponsor,
            effectName: 'Zoom:OurSponsor'
        },
        {
            id: "Ext_Eff_Zoom::Premiere",
            imgSrc: Premiere,
            effectName: 'Zoom:Premiere'
        },
        {
            id: "Ext_Eff_Zoom::PremiereEdges",
            imgSrc: PremiereEdges,
            effectName: 'Zoom:PremiereEdges'
        },
        {
            id: "Ext_Eff_Zoom::Presenting",
            imgSrc: Presenting,
            effectName: 'Zoom:Presenting'
        },
        {
            id: "Ext_Eff_Zoom::PsychoStretch",
            imgSrc: PsychoStretch,
            effectName: 'Zoom:PsychoStretch'
        },
        {
            id: "Ext_Eff_Zoom::QuarterBounceOn",
            imgSrc: QuarterBounceOn,
            effectName: 'Zoom:QuarterBounceOn'
        },
        {
            id: "Ext_Eff_Zoom::QuarterFlyOn",
            imgSrc: QuarterFlyOn,
            effectName: 'Zoom:QuarterFlyOn'
        },
        {
            id: "Ext_Eff_Zoom::QuatroFlipCoin",
            imgSrc: QuatroFlipCoin,
            effectName: 'Zoom:QuatroFlipCoin'
        },
        {
            id: "Ext_Eff_Zoom::RandomGrowth",
            imgSrc: RandomGrowth,
            effectName: 'Zoom:RandomGrowth'
        },
        {
            id: "Ext_Eff_Zoom::Recollection",
            imgSrc: Recollection,
            effectName: 'Zoom:Recollection'
        },
        {
            id: "Ext_Eff_Zoom::SlideStretch",
            imgSrc: SlideStretch,
            effectName: 'Zoom:SlideStretch'
        },
        {
            id: "Ext_Eff_Zoom::SlimZoom",
            imgSrc: SlimZoom,
            effectName: 'Zoom:SlimZoom'
        },
        {
            id: "Ext_Eff_Zoom::Stretch",
            imgSrc: Stretch,
            effectName: 'Zoom:Stretch'
        },
        {
            id: "Ext_Eff_Zoom::StripFoldOut",
            imgSrc: StripFoldOut,
            effectName: 'Zoom:StripFoldOut'
        },
        {
            id: "Ext_Eff_Zoom::SwimIn",
            imgSrc: SwimIn,
            effectName: 'Zoom:SwimIn'
        },
        {
            id: "Ext_Eff_Zoom::TotalRecall",
            imgSrc: TotalRecall,
            effectName: 'Zoom:TotalRecall'
        },
        {
            id: "Ext_Eff_Zoom::Troika",
            imgSrc: Troika,
            effectName: 'Zoom:Troika'
        },
        {
            id: "Ext_Eff_Zoom::WhiteHole",
            imgSrc: WhiteHole,
            effectName: 'Zoom:WhiteHole'
        },
        {
            id: "Ext_Eff_Zoom::ZoomFade",
            imgSrc: ZoomFade,
            effectName: 'Zoom:ZoomFade'
        },
        {
            id: "Ext_Eff_Zoom::ZoomUp",
            imgSrc: ZoomUp,
            effectName: 'Zoom:ZoomUp'
        },
    ],
    TYPE2: [
        {id: "", imgSrc: TRANSITION1, effectName: 'none'},
        {
            id: "Ext_Eff_3D2::AMSpinFade",
            imgSrc: AMSpinFade,
            effectName: "3D:AMSpinFade"
        },
        {
            id: "Ext_Eff_3D2::CloseGarage",
            imgSrc: CloseGarage,
            effectName: "3D:CloseGarage"
        },
        {
            id: "Ext_Eff_3D2::Uncover",
            imgSrc: Uncover,
            effectName: "3D:Uncover"
        },
        {
            id: "Ext_Eff_3D2::Folder",
            imgSrc: Folder,
            effectName: "3D:Folder"
        },
        {
            id: "Ext_Eff_3D2::DoubleDoors",
            imgSrc: DoubleDoors,
            effectName: "3D:DoubleDoors"
        },
        {
            id: "Ext_Eff_3D2::DoubleDoors2",
            imgSrc: DoubleDoors2,
            effectName: "3D:DoubleDoors2"
        },
        {id: "Ext_Eff_3D2::Float", imgSrc: Float, effectName: "3D:Float"},
        {
            id: "Ext_Eff_3D2::JumpInLeft",
            imgSrc: JumpInLeft,
            effectName: "3D:JumpInLeft"
        },
        {
            id: "Ext_Eff_3D2::JumpInRight",
            imgSrc: JumpInRight,
            effectName: "3D:JumpInRight"
        },
        {
            id: "Ext_Eff_Fade::CutBlackFade",
            imgSrc: CutBlackFade,
            effectName: "Fade:CutBlackFade"
        },
        {
            id: "Ext_Eff_FlyOn::ShortFadeNorth",
            imgSrc: ShortFadeNorth,
            effectName: "FlyOn:ShortFadeNorth"
        },
        {
            id: "Ext_Eff_FlyOn::ShortFadeEast",
            imgSrc: ShortFadeEast,
            effectName: "FlyOn:ShortFadeEast"
        },
        {
            id: "Ext_Eff_FlyOn::ShortFadeSouth",
            imgSrc: ShortFadeSouth,
            effectName: "FlyOn:ShortFadeSouth"
        },
        {
            id: "Ext_Eff_FlyOn::ShortFadeWest",
            imgSrc: ShortFadeWest,
            effectName: "FlyOn:ShortFadeWest"
        },
        {
            id: "Ext_Eff_FlyOn::DampedFade",
            imgSrc: DampedFade,
            effectName: "FlyOn:DampedFade"
        },
        {
            id: "Ext_Eff_FlyOn::KnightUp",
            imgSrc: KnightUp,
            effectName: "FlyOn:KnightUp"
        },
        {
            id: "Ext_Eff_FlyOn::KnightDown",
            imgSrc: KnightDown,
            effectName: "FlyOn:KnightDown"
        },
        {
            id: "Ext_Eff_FlyOn::Damped",
            imgSrc: Damped,
            effectName: "FlyOn:Damped"
        },
        {
            id: "Ext_Eff_Push::RollOn",
            imgSrc: RollOn,
            effectName: "Push:RollOn"
        },
        {
            id: "Ext_Eff_Push2::DoubleVision",
            imgSrc: DoubleVision,
            effectName: "Push:DoubleVision"
        },
        {
            id: "Ext_Eff_Push2::PushMe",
            imgSrc: PushMe,
            effectName: "Push:PushMe"
        },
        {
            id: "Ext_Eff_Push2::PushMoreStrips",
            imgSrc: PushMoreStrips,
            effectName: "Push:PushMoreStrips"
        },
        {
            id: "Ext_Eff_Push2::TrislideLR",
            imgSrc: TrislideLR,
            effectName: "Push:TrislideLR"
        },
        {
            id: "Ext_Eff_Reveal2::Curtain",
            imgSrc: Curtain,
            effectName: "Reveal:Curtain"
        },
        {
            id: "Ext_Eff_Reveal::Wipe",
            imgSrc: Wipe,
            effectName: "Reveal:Wipe"
        },
        {
            id: "Ext_Eff_Reveal::Cornor",
            imgSrc: Cornor,
            effectName: "Reveal:Cornor"
        },
        {
            id: "Ext_Eff_Reveal::Quadrant",
            imgSrc: Quadrant,
            effectName: "Reveal:Quadrant"
        },
        {
            id: "Ext_Eff_Reveal2::Swiss",
            imgSrc: Swiss,
            effectName: "Reveal:Swiss"
        },
        {
            id: "Ext_Eff_Zoom::CenterZoom",
            imgSrc: CenterZoom,
            effectName: "Zoom:CenterZoom"
        },
        {
            id: "Ext_Eff_Zoom::ZoomUp",
            imgSrc: ZoomUp,
            effectName: "Zoom:ZoomUp"
        },
        {
            id: "Ext_Eff_Zoom::FlipFour",
            imgSrc: FlipFour,
            effectName: "Zoom:FlipFour"
        },
        {
            id: "Ext_Eff_Zoom::QuarterFlyOn",
            imgSrc: QuarterFlyOn,
            effectName: "Zoom:QuarterFlyOn"
        },
        {
            id: "Ext_Eff_Zoom::TotalRecall",
            imgSrc: TotalRecall,
            effectName: "Zoom:TotalRecall"
        }
    ],
    TYPE3: [
        {id: "", imgSrc: TRANSITION1, effectName: 'none'},
        {
            id: "Ext_Eff_3D2::AMSpinFade",
            imgSrc: AMSpinFade,
            effectName: "3D:AMSpinFade"
        },
        {
            id: "Ext_Eff_FlyOn::Dissolve",
            imgSrc: Dissolve,
            effectName: "FlyOn:Dissolve"
        },
        {
            id: "Ext_Eff_Push2::PushMe",
            imgSrc: PushMe.png,
            effectName: "Push:PushMe"
        },
        {
            id: "Ext_Eff_Reveal2::BlindsFantasy3",
            imgSrc: BlindsFantasy3,
            effectName: "Reveal:BlindsFantasy3"
        },
        {
            id: "Ext_Eff_Reveal2::Blocks",
            imgSrc: Blocks,
            effectName: "Reveal:Blocks"
        }],
    TYPE4: [
        {id: "", imgSrc: TRANSITION1, effectName: 'none'},
        {id: "FADE_1", imgSrc: ZoomFade, effectName: "FADE_1"},
        {id: "FADE_2", imgSrc: ZoomFade, effectName: "FADE_2"},
        {id: "BLIND", imgSrc: TimeSlice, effectName: "BLIND"},
        {id: "SPIRAL", imgSrc: SpiralIn, effectName: "SPIRAL"},
        {id: "CHECKER", imgSrc: Enigma, effectName: "CHECKER"},
        {id: "LINEAR", imgSrc: SplitBlinds, effectName: "LINEAR"},
        {id: "STAIRS", imgSrc: Peel, effectName: "STAIRS"},
        {id: "WIPE", imgSrc: MiddleStretch, effectName: "WIPE"},
        {id: "RANDOM", imgSrc: RandomAlways, effectName: "RANDOM"}
    ],

}