import React from 'react'
import {useTranslation} from "react-i18next";

const ContentFileSizeFilterStatus = ({selectedFileSizes, fileSizeList}) => {
    const {t} = useTranslation();
    return (
        <span id="filter_box_fileFilter">
            {
                selectedFileSizes && (fileSizeList.every(size => selectedFileSizes.indexOf(size) > -1)) ?
                    <span className="filter_status_size_filter">
                    <span>{t("COM_SID_ALL")}</span>
                </span>
                    : selectedFileSizes.map(item => (
                        <span className="filter_status_size_filter">
                        <span>{item}</span>
                    </span>
                    ))
            }
        </span>
    )
}
export default ContentFileSizeFilterStatus