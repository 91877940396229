import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toastr} from 'helper/toastrIntercept';
import ReactTable from 'react-table';
import '../../../table/react-table.css';
import MISDialog from '../../MISDialog';
import {deviceService} from '../../../../services';
import './ErrorReportPopup.css';
import {useTrGroupProps} from '../../../../helper/tables';
import Moment from "react-moment";
import {toMomentDateTimeFormat} from '../../../../helper';
import {useMISOpt} from '../../../misopt';

const tableStyle = {width: 210, height: 550};

const ErrorHistoryPopup = ({deviceId, filter, onClose}) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

    const [data, setData] = useState([]);
    const {listType, warningType, code, startId, endId} = filter;

    useEffect(() => {
        console.log("ErrorHistoryPopup > useEffect > filter : " + filter.listType);

        deviceService.fetchDeviceWarningErrorHistory(deviceId, {
            type: listType,
            warningType: warningType,
            errorCode: code,
            startId,
            endId,
            startIndex: 0,
            pageSize: 30
        }).then(res => {
            setData(res.items);
        }).catch(error => {
            console.log(error);
            toastr.error(t('MESSAGE_SCHEDULE_DATA_LOAD_FAILED_P'));
        });
    }, [deviceId, filter]);


    const columns = [
        {
            Header: t('MIS_SID_OCCURRED'),
            accessor: 'clientTime',
            Cell: (data) => {
                return data.original.clientTime ? <Moment format={dateTimeFormat}>{data.original.clientTime}</Moment> : '';
            }
        }
    ];
    const [getTrGroupPropsType1] = useTrGroupProps('device_tr_group');
    return (
        <MISDialog 
            dialog={{
                title: `${t('MIS_SID_OCCURRED')} (${code})`,
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 225,
                height: 550,
                position : {x:539,y:-359},
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_CLOSE_P',
                        title: t('COM_BUTTON_CLOSE_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className='error_report_list_wrap'>
                <ReactTable 
                    data={data}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType1}
                    columns={columns}
                    className="-striped -highlight"
                    showPagination={false}
                    style={tableStyle}
                    manual
                />
            </div>
        </MISDialog>
    );
};

export default ErrorHistoryPopup;