import React, {memo, useEffect, useMemo, useState} from "react";
import WhiteButton from "../../components/button/WhiteButton";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import "../../components/table/react-table.css";
import {commonConstants, RULESET_PAGE_SIZE_OPTIONS} from "../../constants";
import {useTranslation} from "react-i18next";
import {rulesetService} from '../../services/ruleset.service';
import {popupAction} from "../../actions";
import {changeConditionTypeToCamel, getConditionType} from '../../helper/ruleset/rulesetUtils';
import {toastr} from 'helper/toastrIntercept';
import {useDispatch} from "react-redux";
import {getMixString} from '../../language/languageUtils';
import {useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../helper';
import {getErrorMessage} from '../../helper/responseHandler';
import {useMISOpt} from "../../components/misopt";
import {snakeCase} from "lodash";
import DeviceRelativeTimeCell from '../../components/device/DeviceRelativeTimeCell';
import MagicInfoTable from '../../components/table/MagicInfoTable';
import {useTrGroupProps} from "../../helper/tables";

const pageSizeOptions = [10, 25, 50, 100];

const AllConditionLibrary = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {getAuthority} = useMISOpt();
    const [authority, setAuthority] = useState({});
    
    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        page: 0,
        pageSize: localStorage.getItem(`ruleset_condition_library_pageSize_${JSON.parse(localStorage.getItem('user')).id}`) || 25,
        keyword: "",
        groupId: props.groupId !== undefined ? props.groupId : "",
        sorted: [{ id: "modifyDate", desc: true }]
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0
    });

    const [style, setStyle] = useState({ height: "500px" });
    const { items = [], loading = false, totalCount = 0, pages = 0 } = data;
    const { page, pageSize, sorted } = filter;

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {
            page,
            pageSize,
            keyword,
            sorted: [{ id, desc }],
            groupId
        } = filter;
        setData({ ...data, loading: true });
        rulesetService
            .fetchConditionFilter({
                isPublic: true,
                startIndex: page * pageSize + 1,
                pageSize,
                searchText: keyword,
                groupId: groupId,
                orderBy: snakeCase(id).toUpperCase(),
                sortDirection: desc ? "DESC" : "ASC"
            })
            .then(res => {
                setData({ ...data, loading: false, items: res.items, totalCount: res.totalCount, pages: Math.ceil(res.totalCount / pageSize) });
            })
            .catch(e => {
                toastr.error(getErrorMessage(e));
                setData({ ...data, loading: false });
            });
    };

    const saveCondition = condition => {
        condition.isPublic = true;
        rulesetService
            .createCondition(condition)
            .then(res => {
                toastr.success(t("ALERT_SUCCESS_CREATED"));
                fetchData();
            })
            .catch(error => {
                toastr.error(getErrorMessage(error));
            })
            .finally();
    };

    const updateCondition = condition => {
        condition.isPublic = true;

        rulesetService
            .editCondition(condition)
            .then(res => {
                toastr.success(t("MESSAGE_SCHEDULE_SUCCESS_EDIT_P"));
                fetchData();
            })
            .catch(error => {
                toastr.error(getErrorMessage(error));
            })
            .finally();
    };

    const createConditionPopup = () => {
        dispatch(
            popupAction.addPopup({
                type: commonConstants.RULESET_CONDITION_POPUP,
                id: commonConstants.RULESET_CONDITION_POPUP,
                isLibrary: true,
                mode: "NEW",
                save: condition => {
                    saveCondition(condition);
                },
                close: () => dispatch(popupAction.closePopup(commonConstants.RULESET_CONDITION_POPUP))
            })
        );
    };

    const editConditionPopup = conditionId => {
        if (conditionId === undefined) {
            const conditionIds = getCheckedId();
            if (conditionIds.length > 1 || conditionIds.length === 0) {
                toastr.error(t("MESSAGE_COMMON_SELECT_ONE_CHECKBOX_P"));
            } else {
                const conditionId = conditionIds[0];
                rulesetService
                    .fetchConditionById(conditionId)
                    .then(res => {
                        if (res.items) {
                            dispatch(
                                popupAction.addPopup({
                                    type: commonConstants.RULESET_CONDITION_POPUP,
                                    id: commonConstants.RULESET_CONDITION_POPUP,
                                    isLibrary: true,
                                    mode: "EDIT",
                                    condition: res.items,
                                    save: (condition, saveAs) => {
                                        if (saveAs !== undefined) {
                                            saveCondition(condition);
                                        } else {
                                            updateCondition(condition);
                                        }
                                    },
                                    close: () => dispatch(popupAction.closePopup(commonConstants.RULESET_CONDITION_POPUP))
                                })
                            );
                        }
                    })
                    .catch(e => toastr.error(getErrorMessage(e)));
            }
        } else {
            rulesetService
                .fetchConditionById(conditionId)
                .then(res => {
                    if (res.items) {
                        dispatch(
                            popupAction.addPopup({
                                type: commonConstants.RULESET_CONDITION_POPUP,
                                id: commonConstants.RULESET_CONDITION_POPUP,
                                isLibrary: true,
                                mode: "EDIT",
                                condition: res.items,
                                save: (condition, saveAs) => {
                                    if (saveAs !== undefined) {
                                        saveCondition(condition);
                                    } else {
                                        updateCondition(condition);
                                    }
                                },
                                close: () => dispatch(popupAction.closePopup(commonConstants.RULESET_CONDITION_POPUP))
                            })
                        );
                    }
                })
                .catch(e => toastr.error(getErrorMessage(e)));
        }
    };

    const deleteConditionPopup = () => {
        dispatch(
            popupAction.addPopup({
                id: commonConstants.COMMON_CONFIRM_POPUP,
                type: commonConstants.COMMON_CONFIRM_POPUP,
                title: t("COM_BUTTON_DELETE"),
                message: t("MESSAGE_CONTENT_CONFIRM_DELETE_CONTENT_P"),
                onClickYes: handleDelete,
                onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP))
            })
        );
    };

    const handleDelete = () => {
        const conditionIds = getCheckedId();
        const params = {
            conditionIds: conditionIds
        };
        rulesetService
            .deleteConditions(params)
            .then(res => {
                toastr.success(t("ALERT_SUCCESS_DELETE"));
                fetchData();
            })
            .catch(error => toastr.error(getErrorMessage(error)))
            .finally(dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)));
    };

    useEffect(() => {
        setStyle({ height: window.innerHeight - 204 });
        setAuthority(getAuthority("CONTENT_PLAYLIST_RULESET"));
    }, []);

    useEffect(() => {
        fetchData();
    }, [filter]);

    if (props.groupId !== filter.groupId) {
        setFilter({ ...filter, groupId: props.groupId });
    }

    const getOperator = operator => {
        let returnValue = "";
        switch (operator) {
            case "eq":
                returnValue = t("MIS_SID_EQUAL");
                break;
            case "rg":
                returnValue = t("MIS_SID_WEBAUTHOR_RANGE");
                break;
            case "sw":
                returnValue = t("MIS_SID_START_WITH");
                break;
            case "ct":
                returnValue = t("MIS_SID_CONTAINS");
                break;
            case "ia":
                returnValue = t("MIS_SID_CAFEB_FULL_MATCH");
                break;
            case "ip":
                returnValue = t("MIS_SID_CAFEB_PARTIAL_MATCH");
                break;
            case "le":
                returnValue = t("MIS_SID_LESS_THAN_OR_EQUAL_TO");
                break;
            case "lt":
                returnValue = t("MIS_SID_LESS_THAN");
                break;
            case "ge":
                returnValue = t("MIS_SID_GREATER_THAN_OR_EQUAL_TO");
                break;
            case "gt":
                returnValue = t("MIS_SID_GREATER_THAN");
                break;
            case "ne":
                returnValue = t("MIS_SID_NOT_EQUAL");
                break;
        }

        return returnValue;
    };

    const getValues = values => {
        let returnValue = "";
        let returnHTML = "";

        if (values !== null && values !== undefined) {
            values.forEach(item => {
                returnValue += ",";
                if (item.start) {
                    returnValue += item.start + "-" + item.end;
                } else if (item.value) {
                    returnValue += item.value;
                }
            });
        }
        returnValue = returnValue.replace(",", "");
        const valueList = returnValue.split(",");
        returnHTML = valueList.map((v, i) => {
            return "\n" + v;
        });

        return <span title={returnHTML}>{returnValue}</span>;
    };

    const getCheckedId = () => {
        return selected.current.map(s => items[s].conditionId);
    };

    const columns = useMemo(
        () => [
            {
                accessor: "conditionId",
                show: false
            },
            {
                id: "checkbox",
                width: 40,
                sortable: false,
                Header: () => {
                    return <Checkbox id={"AllCondition_all"} classname={"table"} name={"check"} onChange={toggleSelectAll} ref={checkAll} />;
                },
                Cell: row => {
                    return <Checkbox id={items[row.index].conditionId} index={row.index} classname={"table"} name={"check"} onChange={toggleRow} ref={setCheckBoxRefs} />;
                },
                resizable: false
            },
            {
                Header: t("LIST_TITLE_NAME"),
                accessor: "conditionName",
                Cell: props => (
                    <span className={"data_name"} onClick={() => editConditionPopup(props.original.conditionId)}>
                        {props.original.conditionName}
                    </span>
                ),
                width: 400
            },
            {
                Header: t("TABLE_TYPE_P"),
                accessor: "type",
                Cell: ({ value }) => {
                    return getConditionType(changeConditionTypeToCamel(value));
                },
                width: 150,
                sortable:false
            },
            {
                Header: t("MIS_SID_OPERATOR"),
                accessor: "sign",
                Cell: ({ value }) => {
                    return getOperator(value);
                },
                width: 150,
                sortable:false
            },
            {
                Header: t("COM_LFD_VALUE"),
                accessor: "values",
                sortable: false,
                Cell: props => getValues(props.original.values),
                width: 350                
            },           
            {
                Header: t("COM_TEXT_MODIFY_DATE_P"),
                accessor: "modifyDate",
                width: 200,
                Cell: ({value}) => <DeviceRelativeTimeCell value={value} />
            },
            {
                Header: t("TEXT_CREATOR_P"),
                accessor: "creator"                
            }
        ],
        [items]
    );

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);

    return (
        <div style={{ width: "100%" ,display: props.currContent === 'LIBRARY_MANAGER_CONDITION' ? 'block':'none'}} className={"ruleset_table_wrap"}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id="createConditionButton" name={t("TEXT_TITLE_NEW_P")} authority={authority.CREATE || authority.MANAGE} onClick={() => createConditionPopup()} />
                    <WhiteButton id="editConditionButton" name={t("COM_BUTTON_EDIT")} disable={selectedCnt !== 1} authority={authority.CREATE || authority.MANAGE} onClick={() => editConditionPopup()} />
                    <WhiteButton id="deleteConditionButton" name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} authority={authority.CREATE || authority.MANAGE} onClick={() => deleteConditionPopup()} />
                </div>
                <div className="rightButton">
                    <SearchBar id="conditionSearch" placeholder={getMixString(["MIS_SID_MIX_NAME", "MIS_SID_CONDITION_RULE"])} onClickSearch={onKeywordChange} enableDetail={false} />
                </div>
            </div>
            <MagicInfoTable
                data={items}
                loading={loading}
                sorted={sorted}
                onSortedChange={onSortedChange}
                style={style}
                columns={columns}
                getTrGroupProps={getTrGroupPropsType2}
                usePagination={true}
                paginationOptions={{
                    totalCount: totalCount,
                    page: page,
                    pageSize: pageSize,
                    pageSizeOptions: RULESET_PAGE_SIZE_OPTIONS,
                    onPageChange: onPageChange,
                    onPageSizeChange: onPageSizeChange,
                    divide: 'ruleset_condition_library'
                }}
            />
        </div>
    );
};

export default memo(AllConditionLibrary);
