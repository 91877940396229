import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import RadioGroup from "../../../radio/RadioGroup";
import "../ConditionPopup.css";
import {commonConstants, rulesetConstants} from "../../../../constants";
import {useDispatch} from "react-redux";
import {popupAction} from "../../../../actions";
import TextInput from "../../../input/TextInput";
import NumberInput from '../../../input/NumberInput';
import {toastr} from 'helper/toastrIntercept';
import Select from "../../../selectbox/Select";
import {getMixString} from "../../../../language/languageUtils";

const Datalink = ({ datalinkConfig, handleDatalinkConfig, orgValueType, orgValues, handleValues, orgSign, handleSign, handleValueType }) => {
    const sizeOperatorList = [];
    const { t } = useTranslation();
    Object.entries(rulesetConstants.CONDITION_SCREENSIZE_OPERATOR).forEach(
      ([key, value]) => {
        sizeOperatorList.push({
          value: value.sign,
          title: getMixString([value.sid,''])
        });
      }
    );

    const [config, setConfig] = useState(datalinkConfig || {
        serverAddress : "", 
        tagMatchColumn : "", 
        tags: [], 
        valueLocationText : "", 
        valueLocationView : "v",
        tagMatchType : "and",
        pollingInterval : 30
    });
    
    const [valueType, setValueType] = useState(orgValueType || "text");
    const [sign, setSign] = useState(orgSign || "eq");
    const [data, setData] = useState(orgValues.length?orgValues[0].value:""); 
    const [selectedUrl, setSelectedUrl] = useState(datalinkConfig?datalinkConfig.serverAddress.substr(0,datalinkConfig.serverAddress.lastIndexOf("/")):'');


    const dispatch = useDispatch();    
    const selectedTags = [];

    const onSelectDatalinkTable = () =>{
        dispatch(
            popupAction.addPopup({
                id: commonConstants.RULESET_SELECT_DATALINK_TABLE_POPUP,
                type: commonConstants.RULESET_SELECT_DATALINK_TABLE_POPUP,               
                onApply: (onDatalinkTableApply),
                onClose: () => dispatch(popupAction.closePopup(commonConstants.RULESET_SELECT_DATALINK_TABLE_POPUP))
            })
        );
    }

    const onSelectTag = () => {
        dispatch(
            popupAction.addPopup({
                id: commonConstants.TAG_SETTING,
                type: commonConstants.TAG_SETTING,
                tagType: "media",
                showDevice: false,
                showTagValue: false,
                selectedTags: selectedTags,
                onApply: onTagSettingPopupApply,
                onClose: () => dispatch(popupAction.closePopup(commonConstants.TAG_SETTING))
            })
        );
    };

    const onSelectDatalinkTag = () => {
        if(config.valueLocationText !== ""){
            dispatch(
                popupAction.addPopup({
                    id: commonConstants.RULESET_SELECT_DATALINK_TAG_POPUP,
                    type: commonConstants.RULESET_SELECT_DATALINK_TAG_POPUP,     
                    url : selectedUrl,
                    onApply: (onDatalinkTagApply),
                    onClose: () => dispatch(popupAction.closePopup(commonConstants.RULESET_SELECT_DATALINK_TAG_POPUP))
                })
            );
        }else{
            toastr.error(t("MIS_MESSAGE_COMMON_SELECT_DATALINK_TABLE_P"));
        }        
    }
    
    const onDatalinkTableApply = (selected, selectedUrl, serverAddress) => {
        setSelectedUrl(selectedUrl);
        setConfig({...config, serverAddress : serverAddress, valueLocationText: selected});        
        handleDatalinkConfig({...config, valueLocationText: selected});
        dispatch(popupAction.closePopup(commonConstants.RULESET_SELECT_DATALINK_TABLE_POPUP));
    };

    const onDatalinkTagApply = selected => {
        setConfig({...config, tagMatchColumn : selected});
        handleDatalinkConfig({...config, tagMatchColumn : selected});        
        dispatch(popupAction.closePopup(commonConstants.RULESET_SELECT_DATALINK_TAG_POPUP));
    };
    
    const onTagSettingPopupApply = selected => {
        const newTags = []; 
        selected.forEach((v) => {            
            newTags.push(v.tagName);
        });
        setConfig({...config, tags : newTags});
        handleDatalinkConfig({...config, tags : newTags});       
        dispatch(popupAction.closePopup(commonConstants.TAG_SETTING));
    };

    const deleteTag = tagName => {
        
        const newTags = config.tags.filter(i => i !== tagName);        
        setConfig({...config, tags : newTags});
        handleDatalinkConfig({...config, tags : newTags});        
    };

    const deleteDatalinkTag = () => {
        setConfig({...config, tagMatchColumn : ""});
        handleDatalinkConfig({...config, tagMatchColumn : ""});
        
    };

    const onChangeOperator = (e,value) => {
        setSign(value);        
        handleSign(value);
    }
    
    const onChangePollingInterval = (e,value) => {
        setConfig({...config, pollingInterval : value});
        handleDatalinkConfig({...config, pollingInterval : value});        
    }

    const onChangeMatchType = (e,value) => {
        setConfig({...config, tagMatchType : value});
        handleDatalinkConfig({...config, tagMatchType : value});
    }

    const onChangeValueType = (e,value) => {
        setValueType(value);        
        handleValueType(value);
        setData("");
        const dataValue = [{value : ""}];       
        handleValues(dataValue);
        setSign('eq');
        handleSign('eq')
    }

    const onChangeData = e => {
        if(valueType === "number"){            
            setData(e.target.value.replace(/[^0-9]/g, ''));                   
            const value = [{value : e.target.value.replace(/[^0-9]/g, '')}];  
            handleValues(value);
        }else{
            setData(e.target.value);        
            const value = [{value : e.target.value}];        
            handleValues(value);
        }        
    }

    const onChangeSign = (e, value) => {
        setSign(value);
        handleSign(value);
      };

    return (
        <table className="datalink">
            <tbody>
                <tr>
                    <th>
                        {t("MIS_SID_LED_DATALINK_TABLE")}
                    </th>
                    <td>
                    <div style={{width:"300px"}}>                    
                        <input type="text" style={{width:"243px"}} value={config.valueLocationText} readOnly/>
					    <input type="hidden" value={config.serverAddress} readOnly/>
                        <button className="base searchBtn" id="selectDatalinkBtn" style={{"float":"right"}} onClick={onSelectDatalinkTable}></button>
                    </div></td>
                </tr>
                <tr>
                    <th>{t("MIS_TEXT_TAG_P")}</th>
                    <td>
                    <button className="base searchBtn" id="selectTagBtn" style={{"float":"left"}} onClick={onSelectTag}></button>
                        <div className="selectedTags" style={{float:"left", paddingLeft:"5px", maxHeight:'100px', overflowY:'auto'}}>
                            {config.tags.length>0&&config.tags.map((tag, index) => (
                                <div className="ruleset_tag_input float_l mr8" style={{width:"70px", height:"30px"}}>
                                <span className="ellipsis2" id={tag} value={tag} title={tag}>  {tag} </span>
                                <button style={{ float: "right" }} className="selectedDeviceDelBtn" onClick={() => { deleteTag(tag);  }}></button></div>
                            ))}
                        </div>
                    </td>
                </tr>
                <tr> 
                    <th>{t("MIS_SID_DATALINK_TAG")}</th>
                    <td>
						<button className="base searchBtn" id="selectDatalinkTagBtn" style={{float:"left"}} onClick={onSelectDatalinkTag}></button>
                        {config.tagMatchColumn!==""&&
						<div id="selectedDatalinkTags" style={{float:"left",paddingLeft:"5px"}}>                                
						<div className="ruleset_tag_input float_l mr8" style={{width:"120px", height:"30px"}}>                       
					    <span className="ellipsis2" id={config.tagMatchColumn} value={config.tagMatchColumn} title={config.tagMatchColumn}>
					    {config.tagMatchColumn}</span>
                        <button style={{ float: "right" }} className="selectedDeviceDelBtn" onClick={() => { deleteDatalinkTag();  }}></button></div>
						</div>}
					</td>
                </tr>
                    <tr><th>{t("MIS_SID_LED_TAG_LOGIC")}</th>
					<td>
                        <RadioGroup
                            propertyName={"tagLogic"}
                            selects={[
                                { title: t("MIS_TEXT_TAG_MATCH_TYPE_AND_P"), value: "and" },
                                { title: t("MIS_TEXT_TAG_MATCH_TYPE_OR_P"), value: "or" }
                            ]}
                            value={config.tagMatchType}
                            onChange={onChangeMatchType}
                            multiLang={true}
                        />					
					</td>
				</tr>
				<tr id="value_type"><th>{t("MIS_SID_DATA_TYPE")}</th>
					<td>
                    <RadioGroup
                            propertyName={"dataType"}
                            selects={[
                                { title: t("DID_LFD_TEXT"), value: "text" },
                                { title: t("COM_ADMIN_CONTENT_TYPEINFO_CONTENTS_LIST_JSP_NUMBER"), value: "number" }
                            ]}
                            value={valueType}
                            onChange={onChangeValueType}
                            multiLang={true}
                        />	
                        </td>
                </tr>	
                { valueType === "text" &&				
				<tr id="operator"><th>{t("MIS_SID_OPERATOR")}</th>               
					<td>
                    <RadioGroup
                            propertyName={"operator"}
                            selects={[
                                { title: t("MIS_SID_EQUAL"), value: "eq" },
                                { title: t("MIS_SID_NOT_EQUAL"), value: "ne" }
                            ]}
                            value={sign}
                            onChange={onChangeOperator}
                            multiLang={true}
                        />	
                    </td>                				
				</tr>}
				<tr id="polling_interval"><th>{t("MIS_TEXT_FTP_POLLING_INTERVAL_P")}</th>
					<td>
						<div className="input_number float_l mr8">
                            <NumberInput
                                propertyName="pollingInterval"
                                value={config.pollingInterval}
                                onChange={onChangePollingInterval}
                                min = {10}
                            />
						</div>
					</td>
				</tr>
				<tr><th>{t("COM_IDS_DLG_DATA")}</th>  
                <td>
                {valueType === "number" &&                
                    <><Select
                    width={204}
                    value={sign}
                    selects={sizeOperatorList}
                    onChange={onChangeSign}
                    />
                    <span style={{marginRight:'5px'}}></span></>
                }
                <span style={{display : 'inline-block'}}>
                    <TextInput
                        width={243}
                        value={data}                      
                        onChange={e=>onChangeData(e)}                        
                        placeholder={t("MIS_SID_ENTER_DATA")}
                        onKeyDown={(e) => {return e.keyCode !== 69}} 
                    ></TextInput> </span>
                </td>               
				</tr>
            </tbody>
        </table>
    );
};

export default Datalink;
