import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import './CreateWebUrl.css';
import {useDispatch, useSelector} from "react-redux";
import {contentAction, popupAction} from "../../../actions";
import TextInput from "../../input/TextInput";
import {contentService, settingService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import withMISOpt from "../../misopt";
import SupportedDeviceIcon from "../../icon/SupportedDeviceIcon";
import TimeInput from "../../input/TimeInput";
import {isValidUrl, validateSpecialChar} from "../../../helper/contentValidator";
import {contentConstants} from "../../../constants";

const CreateWebUrl = (props) => {
    const {t} = useTranslation();
    const myContentGroupId = useSelector(({content}) => content.myContentGroupId);
    const {id, misopt:{misAcceptFileTypeObj}, editMode = false, content, menu} = props;
    const dispatch = useDispatch();
    const [webUrl, setWebUrl] = useState({
        urlContentName: editMode ? content.urlContentName : '',
        urlAddress: editMode ? content.urlAddress : '',
        refreshInterval: editMode ? content.refreshInterval : '00:01:00',
        type: contentConstants.CONTENT_TYPE_URL,
        groupId : myContentGroupId === "" ? 0 : myContentGroupId
    });

    const [deviceTypeInfo, setDeviceTypeInfo] = useState({
        deviceType: editMode ? content.deviceType : misAcceptFileTypeObj('url').deviceType,
        deviceTypeVersion: editMode ? content.deviceTypeVersion : misAcceptFileTypeObj('url').deviceTypeVersion
    });

    const [showTag, setShowTag] = useState(false);

    const [mediaTags, setMediaTags] = useState([]);

    const {deviceType, deviceTypeVersion} = deviceTypeInfo;
    const {urlContentName, urlAddress, refreshInterval} = webUrl;

    const remoteLoginRetryOption = [
        {title:1, value:1},
        {title:2, value:2},
        {title:3, value:3},
        {title:4, value:4},
        {title:5, value:5},
        {title:6, value:6}
    ];

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const saveWebUrlContent = () => {
        if(urlContentName.trim() === '') {
            toastr.error(t('MESSAGE_COMMON_ENTER_NECCESSARY_ITEM_P'));
        }else if(!validateSpecialChar(urlContentName)){
            toastr.error(t('COM_EBD_SPECIAL_CHAR_NOTALLOW_KR_2'));
        }else if(urlAddress.trim() === '') {
            toastr.error(t('MIS_SID_SERVER_ENTER_THE_WEBSITE_URL'));
        }else if(!isValidUrl(urlAddress)){
            toastr.error(t('MIS_MESSAGE_EXTERNAL_LINK_URL_CHECK_P'));
        }else {
            let promise;

            if(editMode){
                promise = contentService.editUrlContent(content.contentId, webUrl);
            }else {
                promise = contentService.createUrlContent(webUrl);
            }
            if(promise){
                promise.then(res => {
                    if(res.status === 'Success'){
                        toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                        closePopup(id);
                        dispatch(contentAction.reloadContent(true));
                    }
                }).catch(error => {
                    let errorMessage = error.errorMessage;
                    switch(error.errorCode) {
                        case '403001':
                            errorMessage = t('ERROR_NO_ROLE');
                            break;
                    }
                    toastr.error(errorMessage);
                });
            }
        }
    };

    const onChangeTimePicker = (value) => {
        setWebUrl({...webUrl, refreshInterval: value});
    };

    const fetchTags = () => {
        settingService.fetchTags({
            startIndex: 1,
            pageSize: 100,
            type: 'media',
            sortColumn: 'tag_value',
            sortOrder: 'asc',
        }).then(res => {
            setMediaTags(res.items);
        });
    };

    const onClickTag = (value) => {
        const result = urlAddress + '(' + value + ')';
        setWebUrl({...webUrl, urlAddress: result});
        setShowTag(false);
    };

    useEffect(() => {
        fetchTags();
    }, [])

    return (
        <div className='upload_web_url_content_popup_wrap'>
            <MISDialog dialog={{
                title: editMode ? t('MIS_SID_SERVER_ADD_WEB_URL') : t('MIS_SID_SERVER_ADD_WEB_URL'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 800,
                height: 156,
                position: {x: -150, y: -200},
                onClose: () => closePopup(id),
            }}
                       buttons={{
                           rightButtons: [
                               {
                                   id: 'SAVE',
                                   title: t('COM_BUTTON_SAVE'),
                                   onClick: () => saveWebUrlContent(),
                               },
                               {
                                   id: 'CANCEL',
                                   title: t('BUTTON_CANCEL_P'),
                                   onClick: () => closePopup(id),
                               }
                           ],
                       }}>
                <div className="upload_web_url_content_popup">
                    <table>
                        <colgroup>
                            <col width="139px"/>
                            <col/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>{t('TEXT_CONTENT_NAME_P')}</th>
                            <td>
                                <TextInput width={300} maxLength={60} value={urlContentName} onChange={(e) => setWebUrl({...webUrl, urlContentName: e.target.value})} />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_MAPP_SID_SUPPORTED_DEVICES')}</th>
                            <td className="icon">
                                <SupportedDeviceIcon deviceType={deviceType} deviceTypeVersion={deviceTypeVersion} mediaType={'URL'}/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_MIS_TEXT_ADDRESS_P')}</th>
                            <td>
                                <div className='option_list_wrap'>
                                    <TextInput width={580} value={urlAddress} placeholder={"http://samsung.com"} maxLength={200}
                                               onChange={(e) => setWebUrl({...webUrl, urlAddress: e.target.value})}
                                               onKeyDown={(e) => (e.keyCode === 52 && e.shiftKey) ? setShowTag(true) : undefined}/>
                                    { showTag && mediaTags && mediaTags.length > 0 &&
                                    <div className="option_list" >
                                        <div>
                                            <span></span>
                                        <ul className='taglist_dropdown'>
                                            {
                                                mediaTags.map(tag => {
                                                    return (
                                                        <li key={tag.tagName} onClick={(e) => onClickTag(tag.tagName)}>
                                                            {tag.tagName}
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                        </div>
                                    </div>}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('COM_LFD_SID_REFRESH_INTERVAL')}</th>
                            <td>
                                <TimeInput width={162} value={refreshInterval} onChange={(e, value)=> onChangeTimePicker(value)} style={{margin:0}}/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </MISDialog>
        </div>
    );
};

export default withMISOpt(CreateWebUrl);