import React, { useState } from 'react';
import {useTranslation} from "react-i18next";
import classNames from 'classnames';
import './Select.css';

const Select = React.forwardRef(({width, propertyName, selects, value, error, optionStyle, multiLang, onChange, defaultTitle, disabled, buttonStyle, span1Style, ulStyle, useEllipsis = false}, ref) => {
    const {t} = useTranslation();
    const [showOptions, setShowOptions] = useState(false);

    const getTitle = () => {
        const found = selects.find(e => e.value == value);
        if (found !== undefined) {
            return found.title;
        } else if (found === undefined && defaultTitle) {
            return defaultTitle;
        } else {
            return '';
        }
    }

    const title = getTitle();


    const onSelect = (e, selectedValue) => {
        // const selectedValue = e.target.getAttribute('data-value');
        if(selectedValue !== value) {
            onChange(e, selectedValue);
        }
    };

    const onClick = () => {
        if(showOptions) {
            setShowOptions(false);
        } else {
            setShowOptions(true);
        }
    };

    const onBlur = e => {
        setShowOptions(false);
    };

    return (
        <div className="select_input" style={{width}}>
            <button style={buttonStyle} ref={ref} data-value={value} className={classNames('base', 'un_radius', 'select', {'on': showOptions}, { "error" : error !== undefined})} onClick={onClick} onBlur={onBlur} disabled={disabled}>
                <span style={span1Style} className={useEllipsis ? 'text_ellipsis' : ''} title={multiLang ? t(title) : title}>{multiLang ? t(title) : title}<span className="arrow"></span></span>
            </button>
            {
                error &&
                <span className="errorTxt autoline">{error}</span>
            }
            {
                showOptions && selects && selects.length > 0 &&
                <div className="option_list" style={optionStyle}>
                    <ul style={ulStyle}>
                    {
                        selects.map(e => {
                            if(e.hide) {
                                return null;
                            }
                            return (
                                <li key={e.value} data-name={propertyName} data-value={e.value} onMouseDown={evt => onSelect(evt, e.value)} title={multiLang ? t(e.title) : e.title}>
                                    {
                                        e.multiLang ? t(e.title) : (multiLang ? t(e.title) : e.title)
                                    }
                                </li>
                            );
                        })
                    }
                    </ul>
                </div>
            }
        </div>
    );
});

Select.defaultProps = {
    width: 450,
    selects: [],
    multiLang: true,
    onChange: () => {},
    defaultTitle: undefined,
    disabled: false
};

export default Select;