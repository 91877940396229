import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
//import ReactTable from "react-table";
import ReactTable from "../../components/react-table";
import "../../components/table/react-table.css";
import Pagination from "./Pagination";

const MagicInfoTable = ({data = [], loading = false, noDataText, minRows = 0, sorted= [], multiSort = false, getTbodyProps, getTrGroupProps, getTdProps,
    onSortedChange, columns, className, style, widthStyle,
    usePagination, paginationOptions = {}, expanded = undefined, onExpandedChange = undefined, SubComponent = undefined}) => {

    const {t} = useTranslation();
    const tableRef = useRef();

    useEffect(()=>{
        let thead = tableRef.current.getElementsByClassName("rt-thead")[0];
        let tbody = tableRef.current.getElementsByClassName("rt-tbody")[0];
        
        tbody.addEventListener("scroll", () => {
            thead.style.transform = `translate(-${tbody.scrollLeft}px, 0)`;
         });
    }, []);

    noDataText = noDataText || t('MESSAGE_COMMON_NO_DATA_P');
    return (
        <div className='magicinfo_table_wrapper' ref={tableRef} style={widthStyle}>
            <ReactTable
                data={data}
                loading={loading}
                noDataText={loading ? '' : noDataText}
                minRows={minRows}
                sorted={sorted}
                multiSort={multiSort}
                getTbodyProps={getTbodyProps}
                getTrGroupProps={getTrGroupProps}
                getTdProps={getTdProps}
                onSortedChange={onSortedChange}
                columns={columns}
                className={className}
                style={style}
                showPagination={false}
                manual

                expanded={expanded}
                onExpandedChange={onExpandedChange}
                SubComponent={SubComponent}
            />
            {
                usePagination &&
                <Pagination totalCount={paginationOptions.totalCount} page={paginationOptions.page} 
                    pageSize={paginationOptions.pageSize} pageSizeOptions={paginationOptions.pageSizeOptions} 
                    onPageChange={paginationOptions.onPageChange} onPageSizeChange={paginationOptions.onPageSizeChange} 
                    divide={paginationOptions.divide}/>
            }
        </div>
    );
};

MagicInfoTable.defaultProps = {
    onSortedChange: () => {},
    getTrGroupProps: () => {},
    className: '-striped -highlight',
    usePagination: false,
    paginationOptions: {
        totalCount: 0,
        page: 0,
        pageSize: 30,
        pageSizeOptions: [30],
        onPageChange: () => {},
        onPageSizeChange: () => {},
        divide: ''
    },
    expanded: undefined,
    onExpandedChange: undefined,
    SubComponent: undefined
}

export default MagicInfoTable;