import {authHeader, createParams, handleResponse} from "../helper";
import {fetchWithToken as fetch} from '../helper/fetchIntercept.js';

const backendHost = window.globalConfig.backendHost || process.env.REACT_APP_BACKEND_URL;

export const dashboardService = {
    fetchMyDashboardList,
    fetchAllDashboardList,
    deleteMyDashboard,
    addMyDashboard,
    sortMyDashboard,
    fetchMyInfo,
    fetchNoticeList,
    fetchNoticeDetail,
    createNotice,
    updateNotice,
    deleteNotice,
    fetchStorageInfo,
    fetchContentInfo,
    fetchScheduleInfo,
    fetchPlaylistInfo,
    fetchCurrentDeviceConnectionInfo,
    fetchDeviceHealthInfo,
    fetchUserInfo,
    fetchEdgeServerInfo,
    fetchRemoteServerInfo,
    fetchDatalinkServerInfo,
    fetchDeviceConnectionHistory,
    fetchSoftwareUpdate,
};

function fetchMyDashboardList() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/activated-cards`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchAllDashboardList() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/all-cards`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}


function deleteMyDashboard(dashboardName) {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/${dashboardName}`, {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    }).then(handleResponse);
}

function addMyDashboard(dashboardName) {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard?dashboardName=${dashboardName}`, {
        method: 'POST',
        headers: authHeader('POST'),
    }).then(handleResponse);
}

function sortMyDashboard(dashboards) {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/cards/priorities`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        // body: JSON.stringify({dashboards}),
        body: createParams(dashboards),
    }).then(handleResponse);
}

function fetchMyInfo() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/loggedin-user`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchNoticeList() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/notices`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchNoticeDetail(id) {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/notices/${id}`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function createNotice(notice) {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/notices`, {
        method: 'POST',
        headers: authHeader('POST'),
        body: createParams(notice)
    }).then(handleResponse);
}

function updateNotice(id, notice) {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/notices/${id}`, {
        method: 'PUT',
        headers: authHeader('PUT'),
        body: createParams(notice)
    }).then(handleResponse);
}

function deleteNotice(id) {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/notices/${id}`, {
        method: 'DELETE',
        headers: authHeader('DELETE'),
    }).then(handleResponse);
}

function fetchStorageInfo() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/storages`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchContentInfo() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/content`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchScheduleInfo() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/schedule`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchPlaylistInfo() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/playlist`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchCurrentDeviceConnectionInfo() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/device`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDeviceHealthInfo() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/devices/health`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchUserInfo() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/user`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}
function fetchEdgeServerInfo() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/edge-server`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchRemoteServerInfo() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/remote-server`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDatalinkServerInfo() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/datalink-server`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchDeviceConnectionHistory() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/devices/connection-history`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}

function fetchSoftwareUpdate() {
    return fetch(`${backendHost}/restapi/v2.0/ems/dashboard/software-update-status`, {
        method: 'GET',
        headers: authHeader(),
    }).then(handleResponse);
}