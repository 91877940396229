import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import './ResultNode.css';
import {contextMenu, Item, Menu} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import {menuAction, popupAction, rulesetAction} from '../../../actions';
import {commonConstants} from '../../../constants';
import {getParents} from '../../../helper/ruleset/rulesetUtils';
import VerificationCountingBadge from './VerificationCountingBadge';
import uuid from "uuid";
import {toastr} from 'helper/toastrIntercept';
import {rulesetService} from '../../../services';
import Size from '../../utils/Size';

class ResultNode extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            style : {
                border: "4px solid #727272"
            }
        };
    }

    onOptionButtonClick(e) {
        e.preventDefault();
        contextMenu.show({
            id: this.optionMenuId,
            event: e,
        });
    }

    onOptionMenuItemClick(id) {
        switch(id) {
            case 'ADD_TO_LIBRARY':
                this.addToLibrary(this.elem.result);
                break;
            case 'COPY':
                this.copyPlayNode();
                break;
            case 'DELETE':
                this.deleteNode();
                break;
            default:
                break;
        }
    }

    onMouseMove = (e) => {
        if(this.playRuleContentInfoEl) {
            this.playRuleContentInfoEl.children[0].style.left = (e.pageX + 20) + "px";
            this.playRuleContentInfoEl.children[0].style.top = (e.pageY + 20) + "px";
        }
    }

    onMouseEnter = (e) => {
        const {t, menu, rulesets, setHighlightPath} = this.props;
        const ruleId = this.props.ruleId;
        const verificationMode = menu.currentContentId === 'NEW_RULESET' ? rulesets.newRuleset.verificationMode.enabled : rulesets.editRuleset.verificationMode.enabled;

        if(verificationMode) {
            let elem = document.querySelector('div[rule_id="' + ruleId + '"]').querySelector('div[data-entry-id="' + this.elem.entryIdIndex + '"]')
            let parentResults = getParents(elem, ".ruletree-entry");
            let a = [];

            for(let i = parentResults.length - 1; i >= 0; i--) {
                a.push(parseInt(parentResults[i].getAttribute('data-entry-id')));
            }
            
            setHighlightPath(ruleId, a);

            const result = this.props.result;
            
            if(this.playRuleContentInfoEl) {
                this.playRuleContentInfoEl.remove();
                this.playRuleContentInfoEl = undefined;
            }

            this.playRuleContentInfoEl = document.createElement('div');
            this.playRuleContentInfoEl.id = 'play-rule-content-info';
            document.body.appendChild(this.playRuleContentInfoEl);
            ReactDOM.render(
                <div
                    className="opacity_notice_wrap"
                    style={{top: e.pageY + 20, left: e.pageX + 20}}>
                    <table>
                        <colgroup>
                            <col width="119px" />
                            <col width="" />
                        </colgroup>
                        <tbody>
                            <tr><td>{result.resultName}</td></tr>
                            <tr><td>{t("MIS_SID_CONTENT_TYPE") + ": " + result.mediaTypes}</td></tr>
                            <tr><td>{t("MIS_SID_TOTAL_SIZE") + ": "}<Size size={result.totalSize}/></td></tr>
                        </tbody>
                    </table>
                </div>, this.playRuleContentInfoEl);
        }
    }
    
    onMouseLeave = (e) => {
        const {menu, rulesets, setHighlightPath} = this.props;
        const verificationMode = menu.currentContentId === 'NEW_RULESET' ? rulesets.newRuleset.verificationMode.enabled : rulesets.editRuleset.verificationMode.enabled;

        if(verificationMode) {
            setHighlightPath(0, []);
            this.setState({showPlayRuleContentInfo: false});
        }

        if(this.playRuleContentInfoEl) {
            this.playRuleContentInfoEl.remove();
            this.playRuleContentInfoEl = undefined;
        }
    }

    onClick = (e) => {
        e.preventDefault();

        if(e.target.className === 'description name' || e.target.className === 'option_button' || e.target.className === 'verification-mode-result-count') {
            return;
        }
        
        const {menu, rulesets, fixedHighlightPath, setFixedHighlightPath} = this.props;
        const ruleId = this.props.ruleId;
        const verificationMode = menu.currentContentId === 'NEW_RULESET' ? rulesets.newRuleset.verificationMode.enabled : rulesets.editRuleset.verificationMode.enabled;

        if(verificationMode) {
            if(fixedHighlightPath && fixedHighlightPath.ruleId === ruleId
                && fixedHighlightPath.path && fixedHighlightPath.path[fixedHighlightPath.path.length - 1] === this.elem.entryIdIndex) {
                    setFixedHighlightPath(0, []);
            }

            else {
                let elem = document.querySelector('div[rule_id="' + ruleId + '"]').querySelector('div[data-entry-id="' + this.elem.entryIdIndex + '"]')
                let result = getParents(elem, ".ruletree-entry");
                let a = [];

                for(let i = result.length - 1; i >= 0; i--) {
                    a.push(parseInt(result[i].getAttribute('data-entry-id')));
                }
                
                setFixedHighlightPath(ruleId, a);
            }
        }
    }

    deleteNode() {
        const {setHighlightPath, setFixedHighlightPath, ruleId} = this.props;
        
        if(this.elem.id && this.elem.id === "default") {
            this.parentElem.children.splice(this.parentElem.children.length - 1, 1);
        }

        else {
            this.parentElem.children.length = 0;
        }

        if(this.playRuleContentInfoEl) {
            this.playRuleContentInfoEl.remove();
            this.playRuleContentInfoEl = undefined;
        }

        setHighlightPath(0, []);
        setFixedHighlightPath(ruleId, []);
    }

    openEditPlayRulePopup = () => {
        const editPlay = this.props.editPlay;
        const addPopup = this.props.addPopup;
        const closePopup = this.props.closePopup;
        
        const {menu, result} = this.props;
        
        addPopup({
            id: commonConstants.RULESET_PLAY_POPUP,
            type: commonConstants.RULESET_PLAY_POPUP,
            mode: "EDIT",
            device: { deviceType: result.deviceType, deviceTypeVersion: result.deviceTypeVersion },
            play: this.props.result,
            save : (playRule) => {
                if(playRule.isPublic) {
                    this.addToLibrary(playRule);
                }
                playRule.isPublic = false;
                editPlay(menu.currentContentId, playRule);
            },
            close: () => closePopup(commonConstants.RULESET_PLAY_POPUP)
        });
    }

    copyPlayNode = () => {
        const {copy, menu} = this.props;
        const copyData = JSON.parse(JSON.stringify(this.elem));

        if(copyData.type === "condition" && copyData.id === "default") {
            copyData.type = "result";
            copyData.id = copyData.result.resultId;
            copyData.children = [];
        }

        copy(menu.currentContentId, copyData);
    }

    addToLibrary = (newPlay) => {
        const {t, loadContent, menu} = this.props;
        const result = JSON.parse(JSON.stringify(newPlay));
        result.resultId = uuid();
        result.isPublic = true;
        rulesetService.createPlay(result).then(
            res => {
                menu.submenu.key++;
                menu.submenu.mode = "PLAY";
                loadContent(menu.currentContentId);
            }
        ).catch(error => {toastr.error(error)})
        .finally();
    }

    componentWillUnmount() {
        if(this.playRuleContentInfoEl) {
            this.playRuleContentInfoEl.remove();
            this.playRuleContentInfoEl = undefined;
        }
    }

    render() {
        const {t, menu, rulesets} = this.props;
        const isDefault = this.props.isDefault;
        const result = this.props.result;
        const entryIdIndex = this.props.entryIdIndex;
        const ruleId = this.props.ruleId;
        const {style, showPlayRuleContentInfo, playRuleContentInfoStyle} = this.state;

        this.elem = this.props.elem;
        this.parentElem = this.props.parentElem;

        let contentTypeSID = "";
        if(result.contentsType.toUpperCase() === "CONTENT") {
            contentTypeSID = "TEXT_CONTENT_P";
        }
        else if(result.contentsType.toUpperCase() === "PLAYLIST") {
            contentTypeSID = "TEXT_TITLE_PLAYLIST_P";
        }

        let contentsName = "";
        
        if(result.type === "dynamic") {
            contentsName = result.filter.expression;
        }

        else {
            if(result.contentList && result.contentList.length > 0) {
                result.contentList.map((value, index) => {
                    if(index > 0) {
                        contentsName += ", ";
                    }
    
                    contentsName += value.contentName;
                });
            }
        }

        this.optionMenuId = "result_" + ruleId + "-" + entryIdIndex;

        const verificationMode = menu.currentContentId === 'NEW_RULESET' ? rulesets.newRuleset.verificationMode.enabled : rulesets.editRuleset.verificationMode.enabled;
        
        const highlightPath = this.props.highlightPath;
        const fixedHighlightPath = this.props.fixedHighlightPath;
        let className = "ruletree-label ruletree-label-" + ruleId + "-" + entryIdIndex;
        if(ruleId === highlightPath.ruleId
            && highlightPath.path.indexOf(entryIdIndex) >= 0) {
            className = className + " highlight";
        }
        else if(ruleId === fixedHighlightPath.ruleId
            && fixedHighlightPath.path.indexOf(entryIdIndex) >= 0) {
            className = className + " fixed-highlight";
        }
        
        return(
            <>
                <div
                    className={className}
                    id={entryIdIndex + "_" + result.resultId}
                    type={isDefault ? "default" : "result"}
                    style={style}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                    onMouseMove={this.onMouseMove}
                    onClick={this.onClick}
                    >

                    <button className="option_button" onClick={(e) => this.onOptionButtonClick(e)} />
                    
                    <div>
                        <div className="description name" onClick={(e) => {
                            e.preventDefault();
                            this.openEditPlayRulePopup();
                        }}>
                            {result.resultName}
                        </div>
                        <div className="description">{t(contentTypeSID)}</div>
                        <div className="description">{contentsName}</div>
                    </div>

                    { verificationMode && this.elem.verificationData && this.elem.verificationData.deviceIds &&
                        <VerificationCountingBadge id={result.resultId} deviceIds={this.elem.verificationData.deviceIds}/>
                    }
                </div>
                <Menu id={this.optionMenuId} style={{zIndex:20}}>
                        <Item onClick={() => this.onOptionMenuItemClick('ADD_TO_LIBRARY')}>{t('MIS_SID_ADD_TO_LIBRARY')}</Item>
                        <Item onClick={() => this.onOptionMenuItemClick('COPY')}>{t('COM_IDS_EDIT_COPY')}</Item>
                        <Item onClick={() => this.onOptionMenuItemClick('DELETE')}>{t('COM_BUTTON_DELETE')}</Item>
                </Menu>
            </>
        )
    }
}

export default connect(
    state => ({
        menu: state.menu,
        rulesets: state.rulesets,
        highlightPath: state.rulesets.highlightPath,
        fixedHighlightPath: state.rulesets.fixedHighlightPath,
    }),
    dispatch => ({
        setHighlightPath: (ruleId, path) => dispatch(rulesetAction.setHighlightPath(ruleId, path)),
        setFixedHighlightPath: (ruleId, path) => dispatch(rulesetAction.setFixedHighlightPath(ruleId, path)),
        redraw: () => dispatch(rulesetAction.redraw()),
        editPlay: (currentTab, play) => dispatch(rulesetAction.editPlay(currentTab, play)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        copy: (currentTab, copyData) => dispatch(rulesetAction.copy(currentTab, copyData)),
        loadContent: (id) => dispatch(menuAction.loadContent(id))
    })
)(withTranslation()(ResultNode));