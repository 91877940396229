import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {settingService} from "../../../../services";
import './Edge.css';
import EdgeDropDown from "./EdgeDropDown";
import {toastr} from 'helper/toastrIntercept'
import RadioGroup from "../../../radio/RadioGroup";
import TextInput from "../../../input/TextInput";
import WhiteButton from "../../../button/WhiteButton";
import {validateSpecialCharForExternalServerName, validateWhiteSpace} from "../../../../helper";
import {isEmpty} from "lodash";

const EdgeInformation = ({edgeInfo,setEdgeInfo,overwriteInfo, nameChecked, setNameChecked, originalHostName}) => {
    const {t} = useTranslation();
    const [currentEdgeInfo, setCurrentEdgeInfo] = useState({...edgeInfo});
    const [hostNameChecked, setHostNameChecked] = useState(true);
    const [hostNameEditErrorText, setHostNameEditErrorText] = useState('');
    const [hostNameAvailableText, setHostNameAvailableText] = useState('');
    const [masterList, setMasterList] = useState([]);

    const {
        hostName,
        uuid,
        ipAddress,
        httpPort,
        httpsPort,
        syncCondition,
        initialSyncTime,
        deleteCondition,
        storageLimitByGB,
        totalStorage,
        usedStorage,
        deleteNotUsedInDays,
        status,
        ftpServerStatusControl,
        storageManagePolicy,
        syncPeriod,
        master,
        connectedMasterUuid,
        contentDir
    } = currentEdgeInfo;

    useEffect(() => {
        fetchAllMasterEdgeServers()
    }, []);

    const fetchAllMasterEdgeServers = () => {
        console.log("fetchMasterEdgeServers");
        settingService.fetchMasterEdgeServers({
            startIndex: 1,
            pageSize: 100000,
            sortColumn : 'host_name',
            sortOrder : 'ASC',
        }).then(res => {
            setMasterList(res.items.filter(element=>{return element.uuid !== currentEdgeInfo.uuid}));
        }).catch()
    };

    const setInfo = (key, value) => {
        setCurrentEdgeInfo({
            ...currentEdgeInfo,
            [key]:value
        });
        setEdgeInfo(key, value);
    };

    const onMasterSlaveSelect = (e, value) => {
        setCurrentEdgeInfo({
            ...currentEdgeInfo,
            "master" : value
        });
        setEdgeInfo("master", value);
    };

    const updateToMasterEdgeInfo = (e)=>{

        let hostName =  e.target.value.split(' ( ');
        hostName =  hostName[0].split(' )');
        settingService.fetchOneEdge(hostName[0])
            .then(res=>{
                setCurrentEdgeInfo({
                    ...currentEdgeInfo,
                    "storageLimitByGB" : res.items.storageLimitByGB,
                    "syncCondition" : res.items.syncCondition,
                    "syncPeriod" : res.items.syncPeriod,
                    "deleteCondition" : res.items.deleteCondition,
                    "storageManagePolicy" : res.items.storageManagePolicy,
                    "connectedMasterUuid" : res.items.connectedMasterUuid
                });
                overwriteInfo({
                    ...currentEdgeInfo,
                    "storageLimitByGB" : res.items.storageLimitByGB,
                    "syncCondition" : res.items.syncCondition,
                    "syncPeriod" : res.items.syncPeriod,
                    "deleteCondition" : res.items.deleteCondition,
                    "storageManagePolicy" : res.items.storageManagePolicy,
                    "connectedMasterUuid" : res.items.connectedMasterUuid
                });
            })
            .catch(err=>{
                toastr.error(t('ALERT_FAIL'));
            });
    };

    const updateEdgeInfo = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        if(key === ''){
            key = e.target.getAttribute('data-name');
        }

        (key === 'hostName') && setNameChecked(false);
        (key === 'hostName') && setHostNameAvailableText('');
        (key === 'hostName') && setHostNameEditErrorText('');

        setCurrentEdgeInfo({
            ...currentEdgeInfo,
            [key] : value
        });
        setEdgeInfo(key,value);
    };

    const checkEdgeServerName = () => {

        if(isEmpty(currentEdgeInfo.hostName)) {
            setHostNameAvailableText('');
            setHostNameEditErrorText('');
            setNameChecked(false);
            toastr.error(t('MIS_MESSAGE_SETUP_ENTER_SERVERNAME_P'));
            return;
        }

        if(currentEdgeInfo.hostName === '-'){
            setHostNameAvailableText('');
            setHostNameEditErrorText(t('COM_TV_SID_THE_NAME_YOU_ENTERED_IS_NOT_VALID'));
            setNameChecked(false);
            return;
        }

        if(validateWhiteSpace(currentEdgeInfo.hostName)){
            setHostNameAvailableText('');
            setHostNameEditErrorText(t('MIS_SID_BLANK_SPACE_NOT_ALLOW_MSG'));
            setNameChecked(false);
            return;
        }

        if(!validateSpecialCharForExternalServerName(currentEdgeInfo.hostName)){
            setHostNameAvailableText('');
            setHostNameEditErrorText(t('MIS_SID_ONLY_ALPHANUMERIC_CHARACTERS_AND_FOLLOWING_ALLOWED') + " ( ( ) - )");
            setNameChecked(false);
            return;
        }

        if(currentEdgeInfo.hostName.length > 60){
            setHostNameAvailableText('');
            setHostNameEditErrorText(t('COM_SID_SCHEDULE_MIX_MAX_LENGTH').replace("<<A>>",60));
            setNameChecked(false);
            return;
        }

        if(originalHostName === currentEdgeInfo.hostName){
            setHostNameAvailableText(t('MIS_TEXT_SETUP_CHECK_SERVERNAME_P'));
            setHostNameEditErrorText('');
            setNameChecked(true);
            return;
        }

        settingService.checkEdgeName(currentEdgeInfo.hostName)
            .then(res => {
                if(res.items === true){
                    setHostNameAvailableText(t('MIS_TEXT_SETUP_CHECK_SERVERNAME_P'));
                    setHostNameEditErrorText('');
                } else {
                    setHostNameAvailableText('');
                    setHostNameEditErrorText(t('COM_BDP_SID_NAME_EXIST_HELP'));
                }
                setNameChecked(res.items);
                setHostNameChecked(res.items);
            });
    };


    const renderSslCondition = () => {
        if(httpPort !== 0 && httpsPort !== 0){
            return "both";
        }
        if(httpPort !== 0 && httpsPort === 0){
            return "http";
        }
        if(httpPort === 0 && httpsPort !== 0){
            return "https";
        }
        return "none";
    };

    const masterServerNameAndIP = (uuid) => {
        let edgeServers = masterList.filter(e=>{return e.uuid===uuid});
        if (edgeServers.length === 0 )
            return '';
        else {
            let master = edgeServers[0];
            return master.hostName + " ( " + master.ipAddress + " )";
        }
    }


    return(
        <div className='detail_view'>
            <table className='edge_table'>
                <colgroup>
                    <col width='143px'></col>
                </colgroup>
                <tbody className='tbody'>
                <tr>
                    <th>{t('TABLE_SERVER_NAME_P')}</th>
                    <td>
                        <TextInput
                            error={hostNameEditErrorText}
                            success={hostNameAvailableText}
                            errorWidth={460}
                            errorMarginTop={3}
                            propertyName = {'hostName'}
                            width={260}
                            onChange={e=>{updateEdgeInfo(e)}}
                            value={hostName?hostName:''}
                        />
                        <span className="checkbox">
                                <WhiteButton
                                    name={t('MIS_TEXT_CHECK_P')}
                                    onClick={()=>checkEdgeServerName()}
                                />
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>IP</th>
                    <td>{currentEdgeInfo.ipAddress}</td>
                </tr>
                <tr>
                    <th>{renderSslCondition()==="both"?"HTTP/HTTPS":renderSslCondition()==="http"?"HTTP":"HTTPS"}</th>
                    <td>{renderSslCondition()==="both"? httpPort +'/'+ httpsPort:renderSslCondition()==="http"?httpPort:httpsPort}</td>
                </tr>
                <tr>
                    <th>{renderSslCondition()==="both"?"FTP/FTPS":renderSslCondition()==="http"?"FTP":"FTPS"}</th>
                    <td>{renderSslCondition()==="both"? 21 +'/'+ 990:renderSslCondition()==="http"?21:990}</td>
                </tr>
                <tr>
                    <th>FTP Server</th>
                    <td>Running</td>
                </tr>
                <tr>
                    <th>{t('MIS_SID_MASTER_SLASH_SLAVE')}</th>
                    <td>
                        <RadioGroup
                            selects={[
                                {title: "COM_LFD_SID_MASTER", value:true},
                                {title: "MIS_SID_SLAVE", value:false}
                            ]}
                            value={currentEdgeInfo.master}
                            onChange={onMasterSlaveSelect}
                            multiLang={true}/>
                    </td>
                </tr>
                {!currentEdgeInfo.master &&
                <tr>
                    <th>{t('COM_LFD_SID_MASTER')}</th>
                    <td>
                        <EdgeDropDown
                            width={260}
                            height={masterList.length}
                            name="connectedMasterUuid"
                            display={
                                masterServerNameAndIP(edgeInfo.connectedMasterUuid)}
                            setInfo={setInfo}
                            options={masterList.map(e=> ( e.hostName + " ( " + e.ipAddress + " )"))}
                            onSelect={updateEdgeInfo}
                            onUpdate={updateToMasterEdgeInfo}
                        />
                    </td>
                </tr>}
                </tbody>
            </table>
        </div>
    )
};

export default EdgeInformation;