import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import MISDialog from '../../MISDialog';
import ReactTable from 'react-table';
import '../../../table/react-table.css';
import {deviceService} from '../../../../services';
import './ErrorReportPopup.css';
import {popupAction} from '../../../../actions';
import {commonConstants} from '../../../../constants';
import Pagination from "../../../table/Pagination";
import {useTrGroupProps} from '../../../../helper/tables';
import Moment from "react-moment";
import {toMomentDateTimeFormat} from '../../../../helper';
import {useMISOpt} from '../../../misopt';


const webManualUrl = 'http://displaysolutions.samsung.com/docs/pages/viewpage.action?pageId=15859974';

const ErrorReportPopup = ({listType, warningType, deviceId, onClose, deviceName}) => {
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

    const dispatch = useDispatch();
    const [filter, setFilter] = useState({
        page: 0,
        pageSize: 30,
        startIndex: 1,
        type: listType.toUpperCase(),
        warningType: warningType,
    });

    const onPageChange = page => {
        setFilter({...filter, page, startIndex: page * filter.pageSize + 1});
    };

    const [data, setData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const onClickHistory = (listType, warningType, code, startId, endId) => e => {
        dispatch(popupAction.addPopup({
            id: commonConstants.ERROR_HISTORY,
            type: commonConstants.ERROR_HISTORY,
            deviceId,
            filter: {
                listType,
                warningType,
                code,
                startId,
                endId,
            },
            onClose: () => dispatch(popupAction.closePopup(commonConstants.ERROR_HISTORY)),
        }));
    };

    const makeTitle = () => {
        const type = listType === 'error' ? t('COM_TEXT_TITLE_ERROR_P') : (listType === 'resolved' ?t('MIS_SID_RESOLVED') : t('TEXT_TITLE_WARNING_P'))
        return t('MIS_SID_MIX_REPORT_FOR').replace('<<A>>', type).replace('<<B>>', deviceName);
    }

    useEffect(() => {
        console.log("ErrorReportPopup > useEffect > filter : " + filter.type);

        deviceService.fetchDeviceWarningErrorInfo(deviceId, filter).then(res => {
            setData(res.items);
            setTotalCount(res.totalCount);
        }).catch(error => console.log(error));
    }, [deviceId, filter]);

    const columns = [
        {
            Header: t('ADMIN_DEVICEINFO_DEVICEMODEL_CONTENTS_CODE'),
            accessor: 'code',
            sortable: false,
            width: 137,
            Cell: ({original, value}) => {
                if(original.lastFault.level === 'E' || original.lastFault.level === 'W') {
                    return <a href={webManualUrl} target="mis_web_manual">{value}</a>;
                } else {
                    return value;
                }
            }
        },
        {
            Header: t('TEXT_DESCRIPTION_P'),
            accessor: 'lastFault.errorScript',
            sortable: false,
            // width: 475,
            Cell: ({original, value}) => {
                let highlight = false;
                let bodyFormat = original.lastFault.bodyFormat;

                if(original.lastFault.code === '25001') {
                    // bodyFormat = $.misopt.inputsource_name[$.misopt.inputsource_code.indexOf(data.last_fault.bodyFormat+'')] || '';
                }

                if(bodyFormat && bodyFormat.length > 0) {
                    bodyFormat = `(${bodyFormat})`;
                }

                if(original.lastFault.category && original.lastFault.category.indexOf(':H') >= 0){
					highlight = true;
				}

                if(original.lastFault.level === 'E' || original.lastFault.level === 'W') {
                    if(highlight) {
                        return <a className='health_description_cell' href={webManualUrl} target="mis_web_manual" style={{color: 'red'}}>{`${original.lastFault.errorScript} ${bodyFormat}`}</a>;
                    }
                    return <a className='health_description_cell' href={webManualUrl} target="mis_web_manual">{`${original.lastFault.errorScript} ${bodyFormat}`}</a>;
                } else {
                    return `${original.lastFault.errorScript} ${bodyFormat}`;
                }
            }
        },
        {
            Header: listType === 'resolved' ? t('MIS_SID_RESOLVED') : t('MIS_SID_OCCURRED'),
            accessor: 'firstReported',
            sortable: false,
            width: 160,
            Cell: (data) => {
                return data.original.firstReported ? <Moment format={dateTimeFormat}>{data.original.firstReported}</Moment> : '';
            }
        },
        {
            Header: t('MIS_SID_RECENTLY_REPORTED'),
            accessor: 'lastReported',
            sortable: false,
            width: 160,
            Cell: (data) => {
                return data.original.lastReported ? <Moment format={dateTimeFormat}>{data.original.lastReported}</Moment> : '';
            }
        },
        // {
        //     Header: t('COM_ADMIN_ENGINEER_AS_MANAGE_CONTENTS_STATUS'),
        //     accessor: 'recovered',
        //     width: 137,
        //     show: listType !== 'resolved',
        //     Cell: ({original, value}) => {
        //         return (
        //             <span className="thumbview_wrapper" style={{cursor:'pointer'}} onClick={onClickHistory(listType, original.code, original.firstFaultId, original.lastFaultId)}>
        //                 {
        //                     value ?
        //                     <span style={{color:'#00B050'}}>{t('MIS_SID_RESOLVED')}</span> :
        //                     <span style={{color:'#C00000'}}>{t('COM_MSG_PROCESSING')}</span>
        //                 }
        //             </span>
        //         );
        //     }
        // },
        {
            Header: t('TABLE_TYPE_P'),
            show: listType === 'resolved',
            accessor: 'lastFault.level',
            sortable: false,
            width: 137,
            Cell: ({original, value}) => {
                return (
                    <span className="thumbview_wrapper" style={{cursor:'pointer'}} onClick={onClickHistory(listType, value, original.code, original.firstFaultId, original.lastFaultId, )}>
                        {
                            value === 'E' ? <span style={{color:'#C00000'}}>{t('COM_TEXT_TITLE_ERROR_P')}</span> :
                            value === 'SE' ? <span style={{color:'#C00000'}}>{`${t('COM_TEXT_TITLE_ERROR_P')} (SW)`}</span> :
                            value === 'W' ? t('TEXT_TITLE_WARNING_P') :
                            value === 'SW' ? `${t('TEXT_TITLE_WARNING_P')} (SW)` : ''
                        }
                    </span>
                );
            }
        }
    ];

    const [getTrGroupPropsType1] = useTrGroupProps('device_tr_group');

    return (
        <MISDialog 
            dialog={{
                title: makeTitle(),
                modal: true,
                isDraggable: false,
                closeOnEscape: true,
                width: 1030,
                height: 550,
                onClose: onClose,
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'COM_BUTTON_CLOSE_P',
                        title: t('COM_BUTTON_CLOSE_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className='error_report_list_wrap'>
                <ReactTable 
                    data={data}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType1}
                    columns={columns}
                    className="-striped -highlight"
                    showPagination={false}
                    style={{width: 1020, height: totalCount > 30 ? 520 : 550}}
                    manual
                />
                {
                    totalCount > 30 &&
                    <Pagination
                    style={{top: 590, width: 1010, borderTop: 0}}
                    totalCount={totalCount} page={filter.page} pageSize={filter.pageSize} onPageChange={onPageChange} />
                }
            </div>
        </MISDialog>
    );
};

export default ErrorReportPopup;