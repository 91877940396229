import React from 'react';
import Checkbox from "../checkbox/Checkbox";
import {useTranslation} from "react-i18next";

const MediaTag = ({id, type, mediaTags, toggleTag, mediaTagCheckBox}) => {

    const {t} = useTranslation();
    return (
        <div className="dev_filter_type_checkbox" id="filter_list_tagFilter">
            <h5>
                <span className="tagFilter" style={{background:'#c997e1'}}></span>
                {
                    type === 'device' || type === 'content_schedule' || type === 'content_popup' ? t('MIS_TEXT_TAG_P') : t("MIS_SID_MEDIA_TAG")
                }
            </h5>
            <div id="tag_list" style={{height:236,overflow:'auto'}}>
                <ul className="type_checklist">
                    {
                        mediaTags !==undefined && mediaTags.length > 0 ?
                            mediaTags.map(
                                tag => (
                                    <li key={'filter_tag_'+tag.tagId}>
                                        <Checkbox id={id+'_filter_tag_'+tag.tagId} name={tag.tagName} ref={ref => mediaTagCheckBox[tag.tagId] = ref} onChange={() => toggleTag(tag)}/>
                                    </li>
                                )
                            ) : 'loading'
                    }
                </ul>
            </div>
        </div>
    )
}
export default MediaTag;