import React, {createRef} from 'react';
import {withTranslation} from "react-i18next";
import ReactDOM from "react-dom";
import Checkbox from "../checkbox/Checkbox";
import './MultiSelectButton.css';
import _isEqual from "lodash/isEqual";

class MultiSelectButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selected : [],
            selectAll: true,
            height: '',
        };

        this.checkAll = createRef();
        this.checkBox = [];
    }

    checkSelectAll = () => {
        const {data} = this.props;
        const selected = [undefined];
        if ( typeof data === 'undefined' || data.length === 0 ){
            return;
        }
        let cnt = 0;
        for ( let i = 0 ; i < data.length; i++){
            if(data[i].isChecked){
                cnt++;
                selected.push(data[i].id);
            }
        }
        if( cnt !== data.length ){
            this.setState({selectAll: false, selected : selected});
        } else {
            this.setState({selectAll: true, selected : selected});
        }
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        this.props.itemAll && this.checkSelectAll();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {data} = nextProps;
        if (data && (!_isEqual(data, prevState.data))) {
            return {data: nextProps.data};
        }
        return null;
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = ReactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                show: false
            });
        }
    };

    toggleView = () => {
        const _show = !this.state.show;
        this.setState({
            show: _show
        })
    };


    toggleSelectAll = (e) => {
        const {data} = this.props;
        if(e.target.checked){
            for ( let i = 0 ; i < data.length; i++){
                this.checkBox[data[i].id].checked = true;
            }
            this.setState({selectAll: true,
                selected : this.props.data.map(el => el.id).concat(undefined)
            }, this.onChange);
        } else {
            for ( let i = 0 ; i < data.length; i++){
                this.checkBox[data[i].id].checked = false;
            }
            this.setState({selectAll: false,
                selected : [undefined]
            }, this.onChange);
        }
    };

    onSelect = (id) => {
        const {selected} = this.state;
        const {data} = this.props;

        if(this.checkBox[id].checked){
            if(selected.length === data.length){
                this.checkAll.current.checked = true;
            }
            this.setState({
                selectAll: true,
                selected : selected.concat(id)
            }, this.onChange);
        } else {
            if(selected.length  === data.length + 1){
                this.checkAll.current.checked = false;
            }
            this.setState({
                selectAll: false,
                selected : selected.filter( i => i != id)
            }, this.onChange);
        }
    };

    onChange = () => {
        const {selected} = this.state;
        this.props.onChange(selected);
    };

    visibleSelectAll = () => {
        const {t} = this.props;
        return (
            <li key={'multiSelectKey'}>
                <Checkbox id={'multiSelect_all'}
                          name={t("TEXT_ALL_P")}
                          defaultChecked={this.state.selectAll}
                          onChange={this.toggleSelectAll}
                          ref={this.checkAll}
                />
            </li>
        );
    };


    render() {
        const {t} = this.props;
        const {title, style, width, height} = this.props;
        const {data} = this.props;
        const timestamp = new Date().getTime();

        return (
            <div className={"multiSelect float_l mr8"} style={{marginRight: 10, width: width? width : 'auto',}}>
                <button className="base select multiSelect_button" id="multiSelectButton" style={style} onClick={this.toggleView}>
                    <span>{title}<span className="arrow"></span></span>
                </button>
                <div className="option_list" id="multiSelectWrap" style={{'display': this.state.show ? '' : 'none'}}>
                    <div>
                        <span></span>
                    </div>
                    <ul style={{ height: height ? height : 'auto', overflow: 'hidden auto', padding: '10px 0px',}}>
                        {
                            this.props.itemAll && this.visibleSelectAll()
                        }
                        {
                            data.length > 0 &&
                                data.map((el, i) => (
                                    <li key={i} style={{display: 'flex'}}>
                                        <Checkbox id={"multiSelect_" +  el.id + "_"+timestamp }
                                                  name={el.name}
                                                  onChange={()=>this.onSelect(el.id)}
                                                  ref={ref => this.checkBox[el.id] = ref}
                                                  defaultChecked={el.isChecked}
                                        />
                                    </li>
                                ))
                        }
                    </ul>
                </div>
            </div>
        )
    }
}

MultiSelectButton.defaultProps = {
    itemAll: true
}

export default withTranslation()(MultiSelectButton);