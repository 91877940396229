import React from 'react';
import {useTranslation} from "react-i18next";
import DateToStr from "../../../utils/DateToStr";


const EventConditionDetail = ({event, index}) => {

    const {t} = useTranslation();

    return(
        <div className={"detail_view mt35"}>
            <table>
                <colgroup>
                    <col width="116px"/>
                    <col width=""/>
                </colgroup>
                <tbody>
                    <tr>
                        <th>{t("COM_ADMIN_DEVICEEVENT_ALARMSEARCH_CONTENTS_EVENT_NAME")}</th>
                        <td>{event !== undefined && event.eventName}</td>
                    </tr>
                    <tr>
                        <th>{t("TEXT_DESCRIPTION_P")}</th>
                        <td style={{whiteSpace:'normal', wordBreak:'break-all'}}>{event !== undefined && event.eventDescription}</td>
                    </tr>
                    <tr>
                        <th>{t("COM_TEXT_EVENT_TYPE_P")}</th>
                        <td>{event !== undefined && event.eventType}</td>
                    </tr>
                    <tr>
                        <th>{t("COM_TEXT_MODIFY_DATE_P")}</th>
                        <td><DateToStr date={event !== undefined && event.modifiedDate} /></td>
                    </tr>
                    <tr>
                        <th>{t("TEXT_MESSAGE_P")}</th>
                        <td>
                            {
                                event !== undefined && event.eventConditions !== undefined && event.eventConditions.length >= index && 
                                <div style={{maxWidth:'250px', overflow: 'hidden', textOverflow: 'ellipsis'}} title={event.eventConditions[index].messageText}>
                                    {event.eventConditions[index].messageText}
                                </div>
                            }
                        </td>
                    </tr>
                    {
                        event.eventType !== 'BOOL' &&
                        <tr>
                            <th>{t("MIS_TEXT_EVENT_CONDITION_P")}</th>
                            <td>{event !== undefined && event.eventConditions !== undefined && event.eventConditions.length >= index && event.eventConditions[index].compString}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )

}
export default EventConditionDetail;