import React, {useEffect, useMemo, useRef, useState} from 'react';
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import Moment from "react-moment";
import {Trans, useTranslation} from 'react-i18next';
import {userService} from "../../services";
import Pagination from "../../components/table/Pagination";
import {commonConstants, contentConstants, userConstants} from "../../constants";
import {connect} from "react-redux";
import {menuAction, popupAction} from "../../actions";
import {snakeCase} from "lodash";
import {toastr} from 'helper/toastrIntercept';
import "./User.css"
import {checkButtonDisplayWithAuthority, convertSortColumnId} from "../../components/popup/user/common/userCommons";
import {useMISOpt} from "../../components/misopt";
import {getPageSize, useCheckRefWithSelectedCnt as useCheckRef, useFilter} from "../../helper";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../helper/tables";

const WithdrawnUsers = (props) => {
    const {t} = useTranslation();

    const [style, setStyle] = useState({
        height: '762px'
    });

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        page: 0,
        pageSize: getPageSize('user', 100),
        sorted: [{id: 'userInfo.userId', asc: true}],
        keyword: ''
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0
    });

    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted, keyword} = filter;
    const {addPopup, closePopup,updateSubMenu} = props;
    const isServerAdmin = localStorage.getItem('user') === null ? false : JSON.parse(localStorage.getItem('user')).serverAdmin;
    const {getAuthority} = useMISOpt();
    const userAuthority = getAuthority("USER");
    const logInGroupId = localStorage.getItem('user') === null ? -1 : JSON.parse(localStorage.getItem('user')).groupId;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [filter]);

    const updateDimensions = () => {
        let height = window.innerHeight - 204;
        setStyle({
            ...style,
            height: height
        })
    };

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;

        setData({...data, loading: true});

        userService.fetchUserFilter({
            startIndex: page === 0 ? page + 1 : page * pageSize + 1,
            pageSize: pageSize,
            userStatus: 'WITHDRAWN',
            searchText: keyword,
            sortColumn: snakeCase(convertSortColumnId(id)),
            sortOrder: desc === true ? 'DESC' : 'ASC'
        }).then(res => {
            res.items.map(item => {
                if (item.groupName === userConstants.ORGAN_NAME_ADMINISTRATORS) {
                    item.groupName = t('TEXT_ROOT_GROUP_NAME_P');
                }
            });
            setData({
                ...data,
                loading: false,
                items: res.items,
                totalCount: res.totalCount,
                pages: Math.ceil(res.totalCount / res.pageSize)
            })
        });
    };

    const handleDelete = () => {
        const userIds = selected.current.map(s => items[s].userInfo.userId);

        addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP});

        userService.deleteWithdrawanUsers({ids : userIds}).then(res => {
            toastr.success(t('TEXT_SUCCESS_P'));
        }).catch(error => {
            toastr.error(error);
        }).finally(() => {
            setFilter({...filter});
            closePopup(commonConstants.LOADING_POPUP);
            closePopup(commonConstants.COMMON_CONFIRM_POPUP);
            updateSubMenu();
        });
    };

    const deleteWithdrawalPopup = (e, id) => {
        addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: id,
            title: t("TEXT_TITLE_WARNING_P"),
            useMessageLine: false,
            message: (
                <div style={{height: '90px', textAlign:"center", lineHeight: '90px'}}>{t('MESSAGE_USER_DEL_USER_P')}</div>
            ),
            yesTitle: t('BUTTON_OK_P'),
            noTitle: t('BUTTON_CANCEL_P'),
            width: 320,
            height: 90,
            onClickYes: () => {
                handleDelete();
                closePopup(id);
            },
            onClose: () => closePopup(id),
        });
    };

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'ALL'}
                        classname={"table"}
                        name={"check"}
                        ref={checkAll}
                        onChange={toggleSelectAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox id={items[row.index].userInfo.userId}
                              classname="table"
                              name="check"
                              index={row.index}
                              onChange={toggleRow}
                              ref={setCheckBoxRefs}
                    />
                )
            },
            width: 46,
            sortable: false,
            resizable: false
        },
        {
            Header: t("COM_DID_ADMIN_USER_USERID"),
            accessor: "userInfo.userId",
            width: 300
        },

        {
            Header: t("COM_TEXT_USER_NAME_P"),
            accessor: 'userInfo.userName',
            width: 300

        },
        {
            Header: t("TABLE_WITHDRAWAL_DATE_P"),
            accessor: "withdrawalDate",
            Cell: ({value}) => <Moment format="YYYY-MM-DD HH:mm:ss">{value}</Moment>

        }
    ], [items]);

    const buttonDisplayStatus = checkButtonDisplayWithAuthority(userAuthority, logInGroupId, isServerAdmin);
    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'withdrawn_tr_group');
    return (
        <div style={{width: '100%', height: '100%', display: props.currContent === 'WITHDRAWN_USERS' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"REMOVE_USER"} name={t("COM_BUTTON_DELETE")}
                                 onClick={() => deleteWithdrawalPopup('REMOVE_USER')}
                                 authority={buttonDisplayStatus[userConstants.BUTTON_KEY_REMOVE_USER]}
                                 disable={selectedCnt === 0}/>
                </div>
                <div className="rightButton">
                    <SearchBar id="userSearch"
                               placeholder={`${t('COM_DID_ADMIN_USER_USERID')}, ${t('COM_TEXT_USER_NAME_P')}`}
                               keyword={keyword}
                               onClickSearch={onKeywordChange}/>
                </div>
            </div>

            <div className='user_list_view'>
                <MagicInfoTable
                    data={items}
                    minRows={0}
                    page={page}
                    pages={pages}
                    loading={loading}
                    sorted={sorted}
                    showPagination={false}
                    columns={columns}
                    onSortedChange={onSortedChange}
                    className="-striped -highlight"
                    style={style}
                    getTrGroupProps={getTrGroupPropsType2}
                />
                <Pagination totalCount={totalCount}
                            page={page}
                            pageSize={pageSize}
                            pageSizeOptions={contentConstants.PAGE_SIZE_OPTIONS}
                            onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            divide={'user'}/>
                </div>
        </div>
    );
};

export default connect(
    state => ({
        menu: state.menu
    }),
    dispatch => ({
        openDetailView: (type) => dispatch(popupAction.openDetailView(type)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id)),
        updateSubMenu : () => dispatch(menuAction.updateSubMenuCounter('USER'))
    })
)(WithdrawnUsers);

