import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import './DataLink.css';
import Checkbox from '../../../checkbox/Checkbox';
import DataLinkOrgPopup from "./DataLinkOrgPopup";

const DataLinkTableInformation = ({organizations, setOrganizations, datalinkTables, setTableToSave, tableToSave})=> {
    const {t} = useTranslation();
    const tableRefs = useRef([]);
    const tableBodyRef = useRef();
    const [showOrgTable, setShowOrgTable] = useState(false);
    const [scrollTop, setScrollTop] = useState(false);
    const [scrollLeft, setScrollLeft] = useState(false);

    const onTableScroll = e => {
        const left = tableBodyRef.current && tableBodyRef.current.scrollLeft;
        const top = tableBodyRef.current && tableBodyRef.current.scrollTop;

        setScrollLeft(left || 0);
        setScrollTop(top || 0);
    }
    
    const onTableChange = (e) =>{
        const ref = tableRefs.current[e];
        if(ref.getAttribute('class') === 'on'){
            setTableToSave({
                'serverName':ref.getAttribute('data-server'),
                'serviceName':ref.getAttribute('data-service'),
                'dynaName':ref.getAttribute('data-dyna'),
                'tableName':ref.getAttribute('data-table'),
                'organizationName':ref.getAttribute('data-org')
            }, false)
        }else{
            setTableToSave({
                'serverName':ref.getAttribute('data-server'),
                'serviceName':ref.getAttribute('data-service'),
                'dynaName':ref.getAttribute('data-dyna'),
                'tableName':ref.getAttribute('data-table'),
                'organizationName':ref.getAttribute('data-org')
            }, true)
        }
    };
    const toggleShowOrgTable = () => {
        setShowOrgTable(!showOrgTable);
    };

    const onChangeChecked = e => {
        const type = e.target.id.split('_');
        let obj = [];
        if(type[0] === 'org'){
            datalinkTables.forEach(dlktable => {
                obj.push({...dlktable, 'organizationName':type.slice(1).join('_')});
            });
        } else if(type[0] === 'table'){
            const index = parseInt(type[1]);
            organizations.forEach(org => {
                obj.push({...datalinkTables[index], 'organizationName':org});
            });
        }
        setTableToSave(obj, e.target.checked);
    }

    return(
        <div className={"assign_table_list"} style={{height:'500px', overflow:'hidden'}}>
            <div className={"org_list"}>
                <div className={"assign_table_header"} style={{width:'201px', backgroundColor:'#eeeeef'}}>
                    <div style={{lineHeight:'99px', paddingLeft:'10px'}}>
                        <div style={{float:'left', marginRight:'20px'}}>{t('TABLE_ORGANIZATION_P')}</div>
                        <div style={{float:'left'}}>
                            <button className={"base_datalink"}>
                                <span
                                    className={"filterByIcon"} style={{width: '16px'}}
                                    onClick={()=>toggleShowOrgTable()}
                                />
                            </button>
                        </div>
                    </div>
                </div>
                <div className={"assign_table_body"} style={{overflow:'hidden'}}>
                    <div style={{width:'202px', overflow:'hidden', transform: `translate3d(0px, ${scrollTop*-1}px, 0px)`, height:`${organizations.length*39}px`}}>
                        <table>
                            <tbody>
                            { 
                                organizations && organizations.map((org, i) =>
                                    <tr>
                                        <td>
                                            <Checkbox id={`org_${org}`} name={org} onChange={onChangeChecked}/>
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className={"data_table_list"}>
                <div className={"assign_table_header"} style={{position:'relative', transform: `translate3d(${scrollLeft*-1}px, 0px, 0px)`, border:'0'}}>
                    <table style={{tableLayout:'fixed', width:`${(datalinkTables.length * 130)}px`}}>
                        <tr>
                            <th colspan={datalinkTables.length}><span>{datalinkTables[0].serverName}</span></th>
                        </tr>
                        <tr>
                        {
                            datalinkTables && datalinkTables.map(tableObj => 
                                <th className={"serviceName"}><span>{tableObj.serviceName}</span></th>
                            )
                        }
                        </tr>
                        <tr style={{height:'37px'}}>
                        {
                            datalinkTables && datalinkTables.map((tableObj, i) => 
                                <th className={"dataName"} style={{lineHeight:'17px'}}>
                                    <span title={tableObj.tableName}><Checkbox id={`table_${i}`} name={tableObj.tableName} onChange={onChangeChecked} /></span>
                                </th>
                            )
                        }
                        </tr>
                    </table>
                </div>
                <div className={"assign_table_body"} style={{overflow:'auto', marginTop:'1px'}} ref={tableBodyRef} onScroll={onTableScroll}> 
                    <table id={"assign_table"} style={{tableLayout:'fixed', width:`${(datalinkTables.length * 130)}px`}}>
                        {
                            organizations && organizations.map((org)=> {
                                let boxToCheck = tableToSave.filter((element) => 
                                    (element.organizationName === org)
                                );
                                return (
                                    <tr style={{height:'39px'}}>
                                        {
                                            datalinkTables && datalinkTables.map((tableObj) => {
                                                let checked = [];
                                                let isSelected = false;
                                                    
                                                if(boxToCheck !== null && boxToCheck.length > 0) {
                                                    checked = boxToCheck.filter(checkedTable => (
                                                        checkedTable.dynaName === tableObj.dynaName &&
                                                        checkedTable.serviceName === tableObj.serviceName &&
                                                        checkedTable.tableName === tableObj.tableName &&
                                                        checkedTable.serverName === tableObj.serverName
                                                    ))
                                                }
                                                if(checked.length > 0){
                                                    isSelected = true;
                                                }

                                                return (
                                                    <td className={isSelected ? "on":""}
                                                        onClick={()=>onTableChange(org+'/'+tableObj.serverName+'/'+tableObj.dynaName)}
                                                        ref={ref=>tableRefs.current[org+'/'+tableObj.serverName+'/'+tableObj.dynaName] = ref}
                                                        data-org={org}
                                                        data-dyna={tableObj.dynaName}
                                                        data-server={tableObj.serverName}
                                                        data-table={tableObj.tableName}
                                                        data-service={tableObj.serviceName}                                                    
                                                        >
                                                        <span className={"assignCheck"}></span>
                                                        <input type={"checkBox"} checked={checked.length > 0}/>                                                    
                                                    </td>
                                                )
                                            })
                                        }              
                                    </tr>
                                )
                            })
                        }
                    </table>
                </div>
                { showOrgTable &&
                    <DataLinkOrgPopup
                        organizations={organizations}
                        setOrganizations={setOrganizations}
                        onClose={toggleShowOrgTable}
                    />
                }
            </div>
        </div>
    );
};

export default DataLinkTableInformation;