import React, {forwardRef, useEffect, useState} from 'react';
import Dialog from '../../components/react-dialog';
import classNames from 'classnames';
import './MISDialog.css';
import WhiteButton from "../button/WhiteButton";


const MISDialog = ({classname, buttons, dialog, children, width, height, scroll, scrollWidth}, ref) => {

    const position = {};
    const dialogWidth = width !== undefined ? width : dialog.width;
    const dialogHeight = height !== undefined ? height : dialog.height;

    if (dialog.modal !== undefined && (dialog.modal && !dialog.isDraggable)) {
        position.x = '-50%';
        position.y = '-50%';
    } else {
        position.x = -dialogWidth/2;
        position.y = -(dialogHeight+200)/2;

    }
    return (
        <div className={classNames('mis_dialog', {'modal': dialog.modal && !dialog.isDraggable}, classname !== undefined ? classname : '')} >
        <Dialog
            {...dialog}
            width='auto'
            height='auto'
            position={position}
            ref={ref}
        >
            <div className={"mis_dialog_body_wrap"} style={{display: 'inline-block', width: dialogWidth, height: dialogHeight}}>
                {children}
            </div>
            <div className={"mis_dialog_buttons_wrap"} style={{height: 50, lineHeight: '50px', display: buttons !== undefined && (buttons.leftButtons !== undefined || buttons.rightButtons !== undefined) ? '' : 'none'}}>
                <div className={"float_l"}>
                    {
                        buttons != null && buttons.leftButtons != null && buttons.leftButtons.length > 0 &&
                        buttons.leftButtons.map(
                            button =>
                                button.hide ? null : React.isValidElement(button) ? button : <WhiteButton key={button.id} name={button.title} id={button.id} onClick={button.onClick} disable={button.disable} authority={button.authority}/>
                        )
                    }
                </div>
                <div className={"float_r"}>
                    {
                        buttons != null && buttons.rightButtons != null && buttons.rightButtons.length > 0 &&
                        buttons.rightButtons.map(
                            button =>
                                button.hide ? null : <WhiteButton key={button.id} name={button.title} id={button.id} onClick={button.onClick} disable={button.disable} authority={button.authority}/>
                        )
                    }
                </div>
            </div>
        </Dialog>
        </div>
    )
}
export default forwardRef(MISDialog);