import React,{forwardRef} from 'react';
import './NumberInput.css';
import {checkNumberValidation} from "../../helper";

const TextNumberInput = ({width = 80, propertyName, value, min = 0, onChange, onKeyChange, disabled, max = 100, step = 1}, ref) => {
    const onClickUp = e => {
        if(onChange && value < max) {
            onChange(e, value + step);
        }
    };

    const onClickDown = e => {
        if(onChange && value > min) {
            onChange(e, value - step);
        }
    };

    const onChangeNumber = e => {
        onChange(e, checkNumberValidation(e.target.value, min, max));
    };

    const onBlur = e => {
        onChange(e,checkNumberValidation(e.target.value, min, max))
    }

    return (
        <div className="input_number_wrap float_l mr8" style={{width}}>
            <input className="numeric" type="text"
                value={value}
                data-name={propertyName} onBlur={onBlur}
                ref={ref} disabled={disabled} onChange={onChangeNumber}
            />
            <button className="number_up" data-name={propertyName} onMouseDown={onClickUp} disabled={disabled} ></button>
            <button className="number_down" data-name={propertyName} onMouseDown={onClickDown} disabled={disabled} ></button>
        </div>
    );
};

export default forwardRef(TextNumberInput);