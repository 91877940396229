import React from 'react';

const UserFilterStatus = ({selectedUsers}) => {

    return (
        <span id="filter_box_userFilter">
            {
                selectedUsers && selectedUsers.map(item => (
                    <span className="filter_status_user_filter">
                        <span>{item}</span>
                    </span>
                ))
            }
        </span>
    )
}
export default UserFilterStatus;