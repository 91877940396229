import React from 'react';
import {useTranslation} from "react-i18next";

const PlaylistTypeFilterStatus = ({playlistTypeChanged, selected, playlistTypes}) => {

    const {t} = useTranslation();

    return (
        <>


            {
                selected.playlistTypes && (selected.playlistTypes.length === playlistTypes.length) ?
                    <span>
                        <span className="filter_status_playlist_type"><span>{t("COM_SID_ALL")}</span></span>
                    </span>
                    :
                    selected.playlistTypes.map(
                        playlistId => (
                            <span>
                                <span className="filter_status_playlist_type"><span>{t(playlistTypes !== undefined && playlistTypes.find(p => p.id === playlistId).name)}</span></span>
                            </span>
                    ))
            }



        </>
    )
}
export default PlaylistTypeFilterStatus;