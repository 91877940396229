import React from 'react';
import SupportedDeviceIcon from "../../icon/SupportedDeviceIcon";
import {withTranslation} from "react-i18next";
import DatePicker from "react-datepicker";
import Checkbox from "../../checkbox/Checkbox";
import Moment from "react-moment";
import {APPROVAL_STATUS_LABEL, commonConstants, contentConstants} from "../../../constants";
import {popupAction} from "../../../actions";
import {connect} from "react-redux";
import WhiteButton from "../../button/WhiteButton";
import {isNil} from "lodash";

class ContentDetailTab extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            focus: false,
            content: props.content,
            changedGroupName: props.content.groupName,
            changedExpirationDate: new Date(props.content.expirationDate.substring(0,4), parseInt(props.content.expirationDate.substring(4,6)) - 1, props.content.expirationDate.substring(6,8)),
            isExpirationDateActivated: props.content.isExpirationDateActivated || false,
        };

        this.onChangeDetail = this.props.onChangeDetail;
    }

    handleChange = (e) => {
        const {content} = this.state;
        const { name, value } = e.target;

        this.setState({
            content: {...content, [name]: value }
        });

        this.onChangeDetail({...content, [name]: value});
    };

    onClickFileList = () => {

        const {content} = this.state;
        this.props.addPopup({
            type: commonConstants.POLLING_HISTORY,
            id: 'POLLING_HISTORY',
            contentPollingHistories: content.contentPollingHistories,
            close: () => this.props.closePopup('POLLING_HISTORY')
        });
    };

    onClickFolder = () => {
        const params = {
            groupType : contentConstants.MY_CONTENT_GROUP
        };
        this.props.addPopup({
            type: commonConstants.COMMON_GROUP_POPUP,
            id: 'CONTENT_DETAIL',
            mode: 'content',
            save: this.changeFolder,
            params: params,
            close: () => this.props.closePopup('CONTENT_DETAIL')
        });
    };

    changeFolder = (groups) => {
        this.setState({changedGroupName: groups[0].groupName});
        this.handleChange({target: {name: 'groupId', value: groups[0].groupId}});
        this.props.closePopup('CONTENT_DETAIL');
    };

    onPrivateClick = (value) => {
        this.handleChange({target: {name: 'isHidden', value: value}});
    };

    onExpirationDateClick = () => {
        const {isExpirationDateActivated} = this.state;

        let selectedDateStr = '';
        if(!isExpirationDateActivated) {
            selectedDateStr = "" + new Date().getFullYear() + ("0" + (new Date().getMonth() + 1)).slice(-2) + ("0" + new Date().getDate()).slice(-2);
            this.setState({isExpirationDateActivated: !isExpirationDateActivated, changedExpirationDate: new Date()});
        } else {
            selectedDateStr = '29991231';
            this.setState({isExpirationDateActivated: !isExpirationDateActivated, changedExpirationDate: new Date('2999-12-31')});
        }

        this.handleChange({target: {name: 'expirationDate', value: selectedDateStr}});
    };

    changeExpirationDate = (selectDate) => {
        this.setState({changedExpirationDate: selectDate});
        const selectDateStr = "" + new Date(selectDate).getFullYear() + ("0" + (new Date(selectDate).getMonth() + 1)).slice(-2) + ("0" + new Date(selectDate).getDate()).slice(-2);
        this.handleChange({target: {name: 'expirationDate', value: selectDateStr ? selectDateStr : ''}});
        this.component.setOpen(false);
        this.setState({focus: false});
    };

    onClickCalendar = () => {
        this.component.setOpen(this.state.focus);
        this.setState({focus: !this.state.focus});
    };

    getApprovalStatusLabel = (status) => {
        const {t} = this.props;
        if(status !== undefined){
            return t(APPROVAL_STATUS_LABEL.find(item => item.status === status).label);
        }else {
            return "";
        }
    };

    onClickReject = (reason) => {
        this.props.addPopup({
            type: commonConstants.CONTENT_APPROVE,
            id: 'REJECT_REASON',
            data : {id: null, action: contentConstants.REJECTED, contentId: null, comment:reason},
            isRejectReason : true,
            onClose: ()=> this.props.closePopup('REJECT_REASON')
        });
    };

    render() {
        const {t, error} = this.props;
        const {content, changedGroupName, changedExpirationDate, isExpirationDateActivated} = this.state;

        return (
            <div className="detail_view" id="detailsDiv">
                <div style={{overflowY: 'auto', maxHeight: '550px'}}>
                    <div style={{overflowX:'hidden'}}>
                        <table style={{borderSpacing: 0}}>
                            <colgroup>
                                <col width="100px" />
                                <col width="" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <th><span className="popupTh">{t('TEXT_CONTENT_NAME_P')}</span></th>
                                <td>
                                    <input type="text" id="contentNameInput" maxLength={60} name="contentName" value={content.contentName} style={{width:225}}
                                           onChange={this.handleChange}/>
                                    <div><span className="error_txt2" id="contentNameErrorMsg">
                                        {error.contentName &&
                                            <div className='error_text'>
                                                {error.contentName}
                                            </div>
                                        }
                                    </span></div>
                                </td>
                            </tr>
                            <tr>
                                <th><span className="popupTh">{t('MESSAGE_STATISTICS_LEFT_MENU_SUB_CONTENT_TYPE_P')}</span></th>
                                <td>{content.mediaType}</td>
                            </tr>
                            <tr>
                                <th><span className="popupTh">{t('COM_MAPP_SID_SUPPORTED_DEVICES')}</span></th>
                                <td className="icon">
                                    <SupportedDeviceIcon
                                        deviceType={content.deviceType}
                                        deviceTypeVersion={content.deviceTypeVersion}
                                        mediaType={content.mediaType}
                                        fileType={content.mainFileExtension}
                                    />
                                </td>
                            </tr>
                            {
                                content.mediaType === 'STRM' &&
                                <tr>
                                    <th><span className="popupTh">{t('COM_MIS_TEXT_ADDRESS_P')}</span></th>
                                    <input type="text" id="streamingUrlInput" name="streamingUrl" value={content.streamingUrl} style={{width:225}}
                                           onChange={this.handleChange}/>
                                    <div><span className="error_txt2" id="contentNameErrorMsg">
                                        {error.streamingUrl &&
                                        <div className='error_text'>
                                            {error.streamingUrl}
                                        </div>
                                        }
                                    </span></div>
                                </tr>
                            }
                            {
                                (content.mediaType === 'FTP' || content.mediaType === 'CIFS') &&
                                <>
                                    <tr>
                                        <th><span className="popupTh">{t('TEXT_FILE_COUNT_P')}</span></th>
                                        <td>
                                            <span style={{overflow: 'hidden',
                                                textOverflow:'ellipsis',
                                                width: '120px',
                                                whiteSpace: 'pre-wrap',
                                                height: '100%',
                                                display: 'inline-flex',
                                                alignItems: 'center'}}
                                            >
                                                {content.ftpCifsFiles !== undefined ? content.ftpCifsFiles.length : 0}
                                            </span>
                                            <WhiteButton style={{verticalAlign: 'middle', display: 'inline', float: 'right'}}
                                                         id='FILE_LIST' name={t('TEXT_FILE_LIST_P')} width={102} onClick={this.onClickFileList}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><span className="popupTh">{t('MIS_TEXT_FTP_POLLING_INTERVAL_P')}</span></th>
                                        <td>{content.refreshInterval + ' ' + t('COM_SID_MIN')}</td>
                                    </tr>
                                </>
                            }
                            {
                                (content.mediaType !== 'FTP' && content.mediaType !== 'CIFS' && content.mediaType !== 'STRM') &&
                                <>
                                    <tr>
                                        <th><span className="popupTh">{t('TABLE_RESOLUTION_P')}</span></th>
                                        <td>{content.resolution}</td>
                                    </tr>
                                    <tr>
                                        <th><span className="popupTh">{t('TEXT_PLAY_TIME_P')}</span></th>
                                        <td>{content.playTimeInString}</td>
                                    </tr>
                                </>
                            }
                            <tr>
                                <th><span className="popupTh">{t('COM_TEXT_MODIFY_DATE_P')}</span></th>
                                <td><Moment format={"YYYY-MM-DD HH:mm:ss"}>{content.lastModifiedDate}</Moment></td>
                            </tr>
                            <tr>
                                <th><span className="popupTh">{t('TABLE_VERSION_P')}</span></th>
                                <td>{content.versionId}</td>
                            </tr>
                            <tr>
                                <th><span className="popupTh">{t('TEXT_CREATOR_P')}</span></th>
                                <td>{content.creatorId}</td>
                            </tr>
                            {
                                !isNil(content.streamingUrl) && content.mediaType === 'STRM' &&
                                <tr>
                                    <th><span className="popupTh">{t('MIS_SID_WEBAUTHOR_WEB_URL')}</span></th>
                                    <td title={content.streamingUrl}>{content.streamingUrl}</td>
                                </tr>
                            }
                            {
                                !isNil(content.urlAddress) && content.mediaType === 'URL' &&
                                <tr>
                                    <th><span className="popupTh">{t('MIS_SID_WEBAUTHOR_WEB_URL')}</span></th>
                                    <td title={content.urlAddress}>{content.urlAddress}</td>
                                </tr>
                            }
                            {
                                !isNil(content.refreshInterval) && content.canRefresh !== false &&
                                <tr>
                                    <th><span className="popupTh">{t('COM_LFD_SID_REFRESH_INTERVAL')}</span></th>
                                    <td>{content.refreshInterval}</td>
                                </tr>
                            }
                            {
                                !isNil(content.startPage) &&
                                <tr>
                                    <th><span className="popupTh">{t('COM_MIS_SID_AUTHOR_START_PAGE')}</span></th>
                                    <td>{content.startPage}</td>
                                </tr>
                            }
                            {
                                !isNil(content.approvalStatus) && content.isApprovalEnabled &&
                                <tr>
                                    <th><span className="popupTh">{t('MESSAGE_STATISTICS_SUMMARY_APPROVE_TITLE_P')}</span></th>
                                    <td className={content.approvalStatus === 'REJECTED' ? 'data_name' : ''} style={{display:'table-cell'}}onClick={content.approvalStatus === 'REJECTED' ? () => this.onClickReject(content.approvalOpinion) : undefined}>{this.getApprovalStatusLabel(content.approvalStatus)}</td>
                                </tr>
                            }
                            <tr>
                                <th><span className="popupTh">{t('TEXT_FOLDER_P')}</span></th>
                                <td>
                                    <div className="menu_select_box">
                                        <input type="hidden" id="contentGroupId" />
                                        <button className="base un_radius select" style={{width:245, borderRadius: '5px', display:'inline-flex'}} id="contentViewGroupBtn" onClick={this.onClickFolder}>
                                            <span style={{overflow: 'hidden',
                                                textOverflow:'ellipsis',
                                                width: '240px',
                                                whiteSpace: 'nowrap'}} title={changedGroupName}>{changedGroupName}</span>
                                            <span className="arrow"></span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr className="bullet_line">
                                <th>
                                    <span>{t("COM_SID_PRIVATE")}</span>
                                </th>
                                <td className='radio_group_wrap'>
                                    <input type="radio" checked={content.isHidden ? "checked" : ""} value={true}/>
                                    <label className="mr29" onClick={()=>{this.onPrivateClick(true)}}>
                                        {t("BUTTON_YES_P")}
                                    </label>
                                    <input type="radio"  checked={content.isHidden? "" : "checked"} value={false}/>
                                    <label onClick={()=>this.onPrivateClick(false)}>
                                        {t("BUTTON_NO_P")}
                                    </label>
                                </td>
                            </tr>
                            {
                                content.mediaType !== 'LFT' &&
                                <tr>
                                    <th><span className="popupTh">{t('COM_EXPIRATION_DATE_KR_DATE')}</span></th>
                                    <td>
                                        <div className="content_expiration_date" style={{display: 'inline-flex'}}>
                                            <DatePicker
                                                ref={(r) => {
                                                    if(r !== null) {
                                                        this.component = r;
                                                        this.component.setOpen(this.state.focus);
                                                    }
                                                }}
                                                autoFocus
                                                dateFormat="yyyy-MM-dd"
                                                selected={new Date(changedExpirationDate)}
                                                onSelect={(selectDate) => this.changeExpirationDate(selectDate)}
                                                disabled={isExpirationDateActivated === undefined || isExpirationDateActivated === false}
                                                onClick={() => this.onClickCalendar()}
                                            />
                                            <button className="search_detail_calendar" type="button"
                                                    disabled={isExpirationDateActivated === undefined || isExpirationDateActivated === false}
                                                    onClick={() => this.onClickCalendar()}
                                            />
                                        </div>
                                        <span style={{marginLeft: 10}} onClick={this.onExpirationDateClick}>
                                        <Checkbox name={t('TEXT_ENABLE2_P')}
                                                  checked={isExpirationDateActivated}
                                        />
                                    </span>
                                        <div className='error_text' style={{width:280}}>{
                                            t('MIS_SID_SERVER_EXPIRED_CONTENT_REMOVED_PLAYLIST')}
                                        </div>
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    };
}
export default connect(
    state => ({
    }),
    dispatch => ({
        closePopup: (id)=>dispatch(popupAction.closePopup(id)),
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
    })

)(withTranslation()(ContentDetailTab));