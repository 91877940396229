import React, {memo, useEffect, useMemo, useState} from 'react';
import WhiteButton from "../../../components/button/WhiteButton";
import "../../../components/table/react-table.css";
import {scheduleService} from '../../../services';
import SearchBar from "../../../components/search/SearchBar";
import Checkbox from "../../../components/checkbox/Checkbox";
import SupportedDeviceIcon from "../../../components/icon/SupportedDeviceIcon";
import {useDispatch} from "react-redux";
import {menuAction, popupAction, scheduleAction} from "../../../actions";
import {
    commonConstants,
    CONTENT_SCHEDULE_PROGRAM_TYPE_AD,
    CONTENT_SCHEDULE_PROGRAM_TYPE_LFD,
    CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC,
    CONTENT_SCHEDULE_PROGRAM_TYPE_VWL,
    SCHEDULE_PAGE_SIZE_OPTIONS,
    scheduleConstants
} from "../../../constants";
import {useTranslation} from 'react-i18next';
import {getPageSize, useCheckRefWithSelectedCnt as useCheckRef, useFilter} from '../../../helper';
import {snakeCase} from "lodash";
import {ContentScheduleSubTable} from "./ContentScheduleSubTable";
import {toastr} from 'helper/toastrIntercept';
import MultiSelectButton from "../../../components/button/MultiSelectButton";
import TimeIcon from '../../../images/icon/icon_time.png';
import DateToStr from "../../../components/utils/DateToStr";
import {convertContentScheduleFromServer, DeviceGroups} from "../../../helper/schedule/scheduleUtils";
import {useMISOpt} from "../../../components/misopt";
import {getErrorMessage} from "../../../helper/responseHandler";
import Moment from "moment";
import MagicInfoTable from "../../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../../helper/tables";

const ReserveIcon = ({deployTime}) => {
    return (
        <span>
            {
                deployTime !== undefined && deployTime !== null && deployTime !== '' &&
                <span style={{width:30, display: "inline-block", textAlign: 'center'}}><img src={TimeIcon} /></span>
            }
        </span>
    )
}

const defaultContentScheduleFilter = {
    force: false,
    page: 0,
    pageSize: getPageSize('content_schedule'),
    searchText: '',
    groupId: undefined,
    sorted: [{id: 'modifyDate', desc: true}],
    reload: false,
    scheduleTypes: ['LFD', 'VWL', 'SYNC', 'ADV'],
    devGroupIds: undefined,
    startModifiedDate: undefined,
    endModifiedDate: undefined
}

const AllContentSchedule = (props) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {getAuthority, misOption} = useMISOpt();

    const [expanded, setExpanded] = useState({})

    const [authority, setAuthority] = useState({});

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        ...defaultContentScheduleFilter,
        groupId: props.groupId
    });

    const [scheduleTypes, setScheduleTypes] = useState([
        {id: 'LFD', value: 'LFD', name: t("COM_TEXT_GENERAL_P"), isChecked: true},
        {id: 'VWL', value: 'VWL', name: t("COM_DID_RMS_DEVICE_VIDEO_WALL"), isChecked: true},
        {id: 'SYNC', value: 'SYNC', name: t("MIS_SID_SYNC_PLAY"), isChecked: true},
        {id: 'ADV', value: 'ADV', name: t("COM_DID_LFD_ADVERTISEMENT"), isChecked: true}
    ])

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0,
    });

    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted} = filter;

    const [checkAll, checkBoxes, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, sorted: [{id, desc}], groupId, searchText, startModifiedDate, endModifiedDate, devGroupIds, contentIds} = filter;
        setData({...data, loading: true});
        scheduleService.fetchContentScheduleFilter({
            startIndex: (page * pageSize) + 1,
            pageSize,
            groupType: 'ALL',
            groupId: groupId,
            sortColumn: snakeCase(id).toUpperCase(),
            sortOrder: desc === true ? 'DESC' : 'ASC',
            scheduleTypes: filter.scheduleTypes,
            searchText: searchText,
            devGroupIds: devGroupIds,
            contentIds: contentIds,
            startModifiedDate: startModifiedDate,
            endModifiedDate: endModifiedDate,
        }).then(res => {
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount});
        }).then(
            setExpanded({})
        )

    }

    const onExpandedChange = (newExpended, index, event)=> {
        if (newExpended === undefined) {
            setExpanded({ ...expanded, [index]: false });
        } else {
            if (expanded[index] === undefined || expanded[index] === false) {
                dispatch(scheduleAction.addSimpleSchedule(data.items[index].programId, data.items[index].scheduleTypes))
            }
            setExpanded({ [index]: newExpended[index] !== false });
        }
    }

    const openPopup = programId => {
        dispatch(popupAction.openDetailView({type: commonConstants.COMMON_DETAIL_VIEW, viewType: 'SCHEDULE_CONTENT', id: programId}));
    }

    const openProgressPopup = rowIndex => {
        const program = data.items[rowIndex];
        dispatch(popupAction.openDetailView({type: commonConstants.CONTENT_SCHEDULE_DETAIL_PROGRESS_POPUP, id: program.programId, program: program}));
    }

    const getPublishStatus = status => {
        switch (status) {
            case 'published':
                return t('COM_MIA_SID_STATUS_PUBLISHED');
            case 'waiting':
                return t("COM_CRM_WAITING_KR_BLANK");
            case 'failed':
                return t("ALERT_FAIL");

        }
    }

    const editProgram = () => {
        const programIds = getCheckedId();

        const newTabForEditSchedule = (program) => {
            const {deviceType, deviceTypeVersion, programType} = program;
            const tab = {id: program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? 'EDIT_ADVERTISEMENT' : 'EDIT_SCHEDULE', title: program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? t('COM_DID_LFD_ADVERTISEMENT') : t("TEXT_EDIT_SCHEDULE_P"), active: true, close: true};
            new Promise(()=> {
                if (program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD) {
                    dispatch(scheduleAction.initADSchedule(scheduleConstants.CONTENT_SCHEDULE_EDIT_MODE, {deviceType: deviceType, deviceTypeVersion: deviceTypeVersion}, programType, program))
                } else {
                    dispatch(scheduleAction.initContentSchedule(scheduleConstants.CONTENT_SCHEDULE_EDIT_MODE, {deviceType: deviceType, deviceTypeVersion: deviceTypeVersion}, programType, program));
                }
            }).then(
                dispatch(menuAction.addTab(tab))
            ).then(
                program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? dispatch(menuAction.loadContent('EDIT_ADVERTISEMENT')) : dispatch(menuAction.loadContent('EDIT_SCHEDULE'))
            )
        }

        if (programIds.length === 1) {
            scheduleService.fetchContentScheduleById(programIds[0]).then(
                res => {
                    const program = convertContentScheduleFromServer(res.items);
                    const {programType, deviceGroups} = program;
                    if ((programType === CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC || programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL) && (deviceGroups === undefined || deviceGroups.length === 0)) {
                        const {deviceType, deviceTypeVersion} = program;
                        dispatch(popupAction.addPopup({
                            id: commonConstants.DEVICE_GROUP_SELECTION,
                            type: commonConstants.DEVICE_GROUP_SELECTION,
                            allowSelectOrganization: false,
                            allEnabled: false,
                            checkbox: true,
                            priority : getPriority(deviceType, deviceTypeVersion),
                            isVwl : programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL,
                            isSync : programType === CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC,
                            save: (groups)=> {
                                const deviceGroups = [];
                                groups.map(
                                    (group) => {
                                        deviceGroups.push({groupId: group.groupId, groupName: group.groupNameText !== undefined ? group.groupNameText : group.groupName})
                                    }
                                )
                                program.deviceGroups = deviceGroups;
                                newTabForEditSchedule(program);
                                dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
                            },
                            close: ()=> {
                                dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
                            }
                        }));
                    } else {
                        newTabForEditSchedule(program)
                    }
                }
            ).catch(
                e=> toastr.error(getErrorMessage(e))
            )
        } else {
            toastr.error(t("COM_ALERT_CHECK_ONE"));
        }
    }

    const deleteProgram = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("TEXT_SCHEDULE_DELETE_P"),
            message: t("MESSAGE_SCHEDULE_DELETE_QUE_P"),
            onClickYes: handleDelete,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    }

    const handleDelete = () => {
        const programIds = getCheckedId();
        scheduleService.deleteContentScheduleByIds(programIds, true)
            .then(
                res=> {
                    if (res) {
                        toastr.success(t("TEXT_SUCCESS_P"))
                        dispatch(menuAction.reloadGroup('CONTENT_SCHEDULE_BY_GROUP'));
                        let expandedList = expanded;
                        selected.current.map((s, index) => {
                            if (expanded[index] !== undefined && expanded[index] !== false) {
                                expandedList = {...expandedList, [index] : false}
                            }
                        })
                        setExpanded(expandedList);
                    }
                }
            )
            .catch(res=> toastr.error(getErrorMessage(res)))
            .finally(()=>{
                dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
                dispatch(menuAction.updateSubMenuCounter('SCHEDULE'));
                setFilter({...filter, force: !filter.force});
            })
    }

    const moveGroup = () => {
        const programId = getCheckedId();
        dispatch(popupAction.addPopup({
            type: commonConstants.COMMON_GROUP_POPUP,
            id: commonConstants.COMMON_GROUP_POPUP,
            mode: 'content_schedule',
            save: (groups)=>moveProgramGroup(groups),
            close: ()=>dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP))
        }));
    }

    const saveAsProgram = () => {
        const programIds = getCheckedId();
        if (programIds.length === 1) {
            const programId = programIds[0];
            scheduleService.fetchContentScheduleById(programId).then(
                res => {
                    const program = res.items;

                    program.programName = '';
                    program.programGroupId = '';
                    program.programGroupName = '';
                    program.deviceGroups = [];
                    program.description = '';
                    program.deployReservation = {};

                    const popup = {
                        type: commonConstants.SAVE_CONTENT_SCHEDULE,
                        mode: 'copy',
                        id: programId,
                        stateProgram: program,
                        closePopup: (refresh) => {
                            dispatch(popupAction.closePopup(programId));
                            if(refresh) {
                                fetchData();
                            }
                        }
                    }
                    dispatch(popupAction.addPopup(popup));
                })
        } else {
            toastr.error(t("COM_ALERT_CHECK_ONE"));
        }
    }

    const getPriority = (deviceType, deviceTypeVersion) => {
        const device = misOption(deviceType, deviceTypeVersion);
        return device !== undefined ? device.priority : 999999;
    }

    const publishSchedule = () => {
        const programIds = getCheckedId();
        if (programIds !== undefined && programIds.length === 1) {
            const programId = programIds[0];
            const program = items.find(
                program => program.programId === programId
            );
            if (program !== undefined) {
                const {deviceType, deviceTypeVersion} = program;
                const priority = getPriority(deviceType, deviceTypeVersion);
                dispatch(popupAction.addPopup({
                    id: commonConstants.DEVICE_GROUP_SELECTION,
                    type: commonConstants.DEVICE_GROUP_SELECTION,
                    allowSelectOrganization: false,
                    programId: programId,
                    allEnabled: false,
                    checkbox: true,
                    priority: priority,
                    searchKey: 'CONTENT_SCHEDULE_ID',
                    save: (groups)=>publishProgramGroup(groups),
                    close: ()=>dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
                }));
            }
        }
    }

    const moveProgramGroup = (groups) => {
        if (groups !==undefined && groups.length > 0) {
            const {groupId, parentGroupId} = groups[0];
            if (parentGroupId > 0) {
                const programId = getCheckedId();
                scheduleService.moveContentSchedule(programId, groupId).then(
                    res => {
                        if (res) {
                            toastr.success(t("BUTTON_EDIT_GROUP_P"));
                            dispatch(menuAction.reloadGroup('CONTENT_SCHEDULE_BY_GROUP'));
                        }
                    }
                ).catch(
                    err => {
                        toastr.error(t("MIS_MESSAGE_COMMON_MOVE_INCORRECT_P"));
                        toastr.error(getErrorMessage(err));
                    }
                ).finally(()=>{
                    dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
                    setFilter({...filter, force: !filter.force});
                })
            }

        }
    }

    const publishProgramGroup = (groups) => {
        const programId = getCheckedId();
        const groupIds = [];
        groups.map(group => groupIds.push(group.groupId))
        scheduleService.updateDeviceGroups(programId, groupIds).then(
            res => {
                toastr.success(t("MIS_TEXT_SUCCESS_SENT_P"));
            }
        ).catch(
            err => {
                toastr.error(getErrorMessage(err));
            }
        ).finally(()=>{
            dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
            setFilter({...filter, force: !filter.force});
        })
    }

    useEffect(() => {
        setStyle({height: window.innerHeight - 205});
    }, []);

    useEffect(() => {
        fetchData();
    }, [filter]);

    useEffect(() => {
        setAuthority(getAuthority('SCHEDULE'));
    }, [])

    const getCheckedId = () => {
        return selected.current.map(s => items[s].programId);
    }

    const getProgramType =(scheduleType) => {
        switch (scheduleType) {
            case 'LFD':
                return t("COM_TEXT_GENERAL_P");
            case 'SYNC':
                return t("MIS_SID_SYNC_PLAY");
            case 'VWL':
                return t("COM_DID_RMS_DEVICE_VIDEO_WALL");
            case 'ADV':
                return t("COM_DID_LFD_ADVERTISEMENT");
        }
    }

    const onChangeScheduleType = Ids => {
        setFilter({...filter, scheduleTypes: Ids.filter(id=>id !== undefined), page: 0})
    };

    const onChangeScheduleName = value => {
        setFilter({...filter, searchText: value, page: 0})
    }

    const onClickDetailSearch = (searchDetail) => {
        const groupIds = [], contentIds = [];
        if (searchDetail.deviceGroups !== undefined && searchDetail.deviceGroups.length > 0) {
            searchDetail.deviceGroups.map(group=>groupIds.push(group.groupId));
        }
        if (searchDetail.contents !== undefined && searchDetail.contents.length > 0) {
            searchDetail.contents.map(content => contentIds.push(content.selectedId));
        }

        setFilter({
            ...filter,
            page: 0,
            devGroupIds: groupIds,
            contentIds: contentIds,
            startModifiedDate: searchDetail.date.startDate !== undefined && searchDetail.date.startDate !== '' ? Moment(searchDetail.date.startDate).format('YYYY-MM-DD') : undefined,
            endModifiedDate: searchDetail.date.endDate !== undefined && searchDetail.date.endDate !== '' ? Moment(searchDetail.date.endDate).format('YYYY-MM-DD') : undefined
        });
    };

    useEffect(()=> {
        if (props !== undefined) {
            if (props.groupId != filter.groupId) {
                setFilter({...defaultContentScheduleFilter, groupId : props.groupId});
            }
        }
    }, [props])


    const getCountForChannelOrSlots = (program) => {

        const {programType, channelCount, slotCount} = program;
        if (programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD) {
            return slotCount;
        } else {
            return channelCount;
        }
    }

    const columns = useMemo(()=>[
        {
            accessor: "programId",
            show: false
        },
        {
            accessor: "scheduleTypes",
            show: false
        },
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllContent_Schedule_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={items[row.index].programId}
                        index={row.index}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                );
            },
        },
        {
            expander: authority.CREATE || authority.MANAGE,
            show: authority.CREATE || authority.MANAGE,
        },
        {
            Header: t("TEXT_SCHEDULE_NAME_P"),
            accessor: "programName",
            Cell: (props) => <span className={"data_name"} onClick={()=>openPopup(props.original.programId)}><ReserveIcon deployTime={props.original.deployTime} />{props.original.programName}</span>,
            width: 500
        },
        {
            Header: t("BUTTON_DETAIL_P"),
            accessor: "channelCount",
            Cell: (props) => <div>{props.original.scheduleTypes !== CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? <div>{getCountForChannelOrSlots(props.original)}</div> : ''}<div>{getProgramType(props.original.scheduleTypes)}</div></div>,
            sortable: false,
            width: 100
        },
        {
            Header: t("COM_MIS_TEXT_SUPPORTED_DEVICE_TYPE_P"),
            Cell: props => <SupportedDeviceIcon deviceType={props.original.deviceType} deviceTypeVersion={props.original.deviceTypeVersion}/>,
            sortable: false,
            width: 150
        },
        {
            Header: t("TEXT_DEVICE_GROUP_P"),
            Cell: (props) =>
                props.original.deviceGroups !== null && props.original.deviceGroups !== undefined && props.original.deviceGroups.length > 0 ? <DeviceGroups key={props.original.programId} deviceGroups={props.original.deviceGroups} /> : ''
            ,
            sortable: false,
            width: 150
        },
        {
            Header: t("MIS_TEXT_TAG_MAP_DEVICE_COUNT_P"),
            sortable: false,
            accessor: "deviceCount",
            width: 120
        },
        {
            Header: t("MIS_SID_PUBLISH_STATUS"),
            accessor: "publishStatus",
            Cell: (props) => {
                let {publishStatus, publishTotalCount} = props.original;
                if (publishStatus !== 'published' && publishStatus !== 'waiting' && publishTotalCount > 0) {
                    let {publishCurrentCount} = props.original;
                    if (publishTotalCount > 0) {
                        publishTotalCount = publishTotalCount;
                    }
                    if (publishCurrentCount > 0) {
                        publishCurrentCount = publishCurrentCount;
                    }
                    const percent = publishCurrentCount/publishTotalCount*100;
                    return (
                        <div className={'status_wrap'} >
                            <a href="#" id={"publish_status_" + props.original.programId} onClick={()=>openProgressPopup(props.index)}>
                                <span className="status_wrap"><span style={{width:`${percent}%`,background:'#66ca84'}}></span></span>
                                {`${publishCurrentCount}/${publishTotalCount} `+t('TEXT_DEVICE_P')}
                            </a>
                        </div>
                    )
                } else {
                    return (<span className={"data_name"} onClick={()=>openProgressPopup(props.index)}>{getPublishStatus(props.original.publishStatus)}</span>)
                }
            },
            sortable: false,
            width: 200
        },
        {
            Header: t("COM_TEXT_MODIFY_DATE_P"),
            accessor: "modifyDate",
            Cell: ({value}) => <DateToStr date={value}/>

        }
    ], [items])

    const handleExport = (docType) => {
        const copiedFilter = JSON.parse(JSON.stringify(filter));
        copiedFilter.pageSize = data.totalCount;
        scheduleService.exportContentSchedule(copiedFilter, docType ).then(
            res=> {

            }
        )
    };

    const [isAbleRePublish, setIsAbleRePublish] = useState(false);
    const [isAbleCopy, setIsAbleCopy] = useState(false);

    useEffect(()=> {

        const programIds = getCheckedId();
        if (programIds !== undefined && programIds.length === 1) {
            const programId = programIds[0];
            const program = items.find(
                program => program.programId === programId
            );
            if (program !== undefined) {
                const {scheduleTypes} = program;
                setIsAbleRePublish(scheduleTypes ===  CONTENT_SCHEDULE_PROGRAM_TYPE_LFD || scheduleTypes === CONTENT_SCHEDULE_PROGRAM_TYPE_AD)
                setIsAbleCopy(scheduleTypes ===  CONTENT_SCHEDULE_PROGRAM_TYPE_LFD || scheduleTypes === CONTENT_SCHEDULE_PROGRAM_TYPE_AD)
            } else {
                setIsAbleRePublish(false);
                setIsAbleCopy(false);
            }
        } else {
            setIsAbleRePublish(false);
            setIsAbleCopy(false);
        }

    }, [selectedCnt])

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxes, toggleRow);

    return (
        <div style={{width: '100%', display:(props.currContent === 'ALL_CONTENT_SCHEDULE' || props.currContent === 'CONTENT_SCHEDULE_BY_GROUP' || props.currContent === 'SIMPLE_SCHEDULE') ? 'block':'none'}} className={'content_schedule_table_warp'}>
            <div className="contents_buttonWrap">
                <div className="leftButton">

                    <WhiteButton id={"CONTENT_SCHEDULE_EDIT"} name={t("COM_BUTTON_EDIT")} disable={selectedCnt !== 1} onClick={editProgram} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"CONTENT_SCHEDULE_DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} onClick={deleteProgram} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"CONTENT_SCHEDULE_MOVE"} name={t("BUTTON_MOVE_P")} disable={selectedCnt < 1} onClick={moveGroup} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"CONTENT_SCHEDULE_COPY"} name={t("COM_IDS_EDIT_COPY")} disable={!isAbleCopy} onClick={saveAsProgram} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"CONTENT_SCHEDULE_REPUBLISH"} name={t("MIS_SID_20_REPUBLISH")} disable={!isAbleRePublish} onClick={publishSchedule} authority={authority.CREATE || authority.MANAGE}/>
                    <WhiteButton id={"CONTENT_SCHEDULE_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick={()=>handleExport('EXCEL')} />
                </div>
                <div className="rightButton">
                    <MultiSelectButton itemAll={true} title={t("MIS_SID_20_SCHEDULE_TYPE")} data={scheduleTypes} onChange={onChangeScheduleType}/>
                    <SearchBar enableDetail id="scheduleSearch" placeholder={t("TEXT_SCHEDULE_NAME_P")} onClickSearch={onChangeScheduleName} width={210}
                               keyword={filter.searchText}
                               onClickDetailSearch={onClickDetailSearch}
                               searchDetails={[
                                   {title: 'contents', type: 'popup'},
                                   {title: 'deviceGroup', type: 'popup'},
                                   {title: 'date', type: 'date'}
                               ]}/>
                </div>
            </div>
            <div className='content_schedule_list_view_wrap' style={{width: '100%'}}>
                {
                    authority.CREATE || authority.MANAGE ?
                        <MagicInfoTable
                            data={items}
                            loading={loading}
                            sorted={sorted}
                            onSortedChange={onSortedChange}
                            columns={columns}
                            style={style}
                            getTrGroupProps={getTrGroupPropsType2}
                            usePagination={true}
                            paginationOptions={{
                                totalCount: totalCount,
                                page: page,
                                pageSize: pageSize,
                                pageSizeOptions: SCHEDULE_PAGE_SIZE_OPTIONS,
                                onPageChange: onPageChange,
                                onPageSizeChange: onPageSizeChange,
                                divide: "content_schedule"
                            }}

                            expanded={expanded}
                            onExpandedChange={onExpandedChange}
                            SubComponent={row =>{
                                const programId = row.original.programId;
                                return <ContentScheduleSubTable programId={programId} onExpandedChange={()=>onExpandedChange(undefined, row.index)}/>
                            }}
                        />
                        :
                        <MagicInfoTable
                            data={items}
                            loading={loading}
                            sorted={sorted}
                            onSortedChange={onSortedChange}
                            columns={columns}
                            style={style}
                            getTrGroupProps={(state, rowInfo) => {

                                if (rowInfo && rowInfo.original) {
                                    return {
                                        onClick: (e) => {
                                            const idx = rowInfo.index;

                                            if(e.target.classList.contains('data_name') || e.target.classList.contains('rt-expander') || e.target.classList.contains('status_wrap') || e.target.nodeName === 'A' || e.target.tagName === 'LABEL' || e.target.tagName === 'INPUT') {
                                                return;
                                            }

                                            checkBoxes.current[idx].checked = !checkBoxes.current[idx].checked;
                                            toggleRow({target: checkBoxes.current[idx]});
                                        },
                                    }
                                }else{
                                    return {}
                                }
                            }}
                            usePagination={true}
                            paginationOptions={{
                                totalCount: totalCount,
                                page: page,
                                pageSize: pageSize,
                                pageSizeOptions: SCHEDULE_PAGE_SIZE_OPTIONS,
                                onPageChange: onPageChange,
                                onPageSizeChange: onPageSizeChange,
                                divide: "content_schedule"
                            }}
                        />
                }
            </div>
        </div>
    )

}
export default memo(AllContentSchedule);