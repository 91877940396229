import React, {Component, Fragment} from 'react';
import ReactTable from "react-table";
import "../../components/table/react-table.css";
import {Trans, withTranslation} from 'react-i18next';
import {statisticsService, userService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import './ContentStorageSpace.css'
import MultiSelectButton from "../../components/button/MultiSelectButton";
import fileDownload from "js-file-download";
import {cloneDeep} from "lodash";
import {bytesToSize} from "../../helper";
import {getMixString} from '../../language/languageUtils';
import WhiteButton from "../../components/button/WhiteButton";
import {LoadingBar} from "../../components/loading/LoadingBar";
import {getErrorMessage} from "../../helper/responseHandler";

const storageBackgroundColors = ['#70A7E6','#70C3CE','#80D5AF','#E898D4','#C5EB62','#84D0D4','#849FD3','#EAD389', '#EEAFAF','#F1B48A', '#70C6E6','#70C3CB', '#70C3D8','#70C3EC',];

class ContentStorageSpace extends Component {
    state = {
        organizations: [],
        chartData: {
            totalSize: {value: '0',},
            availableSize: {value: '0',},
            systemSize: {percent: '0', value: '0',},
            orgUsageDataList: [],
        },
        loading: false,
        loadingbar: false,
        items: [],
        totalSize: 0,
        sorted: [{id: 'storageUsage', desc: false}],
        request: {
            organizations: undefined,
        }
    };

    constructor(props) {
        super(props);
        this.selectedOrganizations = [];
    }

    componentDidMount() {
        this.fetchOrganizationGroupOrganizations();
    }

    onClickExport = (docType) => {
        this.setState({loadingbar: true});
        const {organizations} = this.state.request;
        statisticsService.fetchStatisticsContentsFileDownload(docType, {
            data: "storageUsages",
            selectedOrganizationIds: organizations,
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {this.setState({loadingbar: false});});
    }

    onChangedOrganization = (ids) => {
        this.selectedOrganizations = [];
        if (ids && ids.length > 0) {
            ids.forEach(id => {
                let selectedOrganization = this.state.organizations.find(organization => organization && id == organization.id);
                if(selectedOrganization) {
                    this.selectedOrganizations.push(selectedOrganization.id);
                }
            });
        } else {
            this.selectedOrganizations = [];
        }
        this.fetchStorageSpaceUsage();
    };

    onSortedChange = (newSorted, column, additive) => {
        const [{id, desc}] = newSorted;
        const {items} = this.state;
        this.sort(items, id, desc);
        this.setState({items: items, sorted: [{id, desc}]});
    };

    sort = (items, id, desc) => {
        items.sort(function (a, b) {
            let valueA;
            let valueB;
            if(id === 'storageUsage') {
                valueA = Number(a[id].replace('MB', '').trim()) * 1024 * 1024;
                valueB = Number(b[id].replace('MB', '').trim()) * 1024 * 1024;
            } else {
                valueA = a[id].toUpperCase();
                valueB = b[id].toUpperCase();
            }
            if(desc === true) {
                if (valueA < valueB) {
                    return -1;
                }
                if (valueA > valueB) {
                    return 1;
                }
            } else {
                if (valueA < valueB) {
                    return 1;
                }
                if (valueA > valueB) {
                    return -1;
                }
            }
            return 0;
        });
    };

    fetchOrganizationGroupOrganizations = () => {
        userService.fetchOrganizationGroupOrganizations().then(res => {
            let newOrganizations = [];
            res.items.forEach((organization, index) => {
                newOrganizations.push({id: organization.organizationId, name: organization.groupName, isChecked: true});
                this.selectedOrganizations.push(organization.organizationId);
            });
            this.setState({
                organizations: newOrganizations,
            }, () => {
                this.fetchStorageSpaceUsage();
                this.fetchStorageChart();
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    fetchStorageChart = () => {
        statisticsService.fetchStatisticsContents({
            data: "storageUsageAll",
            format: "typeChart",
            selectedOrganizationIds: ['AllOrg'],
        }).then(res => {
            if(res.items && res.items.length > 0) {
                let chartData = {};
                const chart = res.items[0];
                chartData.totalSize = chart.totalSize;
                chartData.systemSize = chart.systemSize;
                chartData.availableSize = chart.availableSize;
                chartData.orgUsageDataList = chart.orgUsageDataList;
                this.setState({
                    chartData: cloneDeep(chartData),
                });
            }
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    }

    fetchStorageSpaceUsage = () => {
        this.setState({loading: true});
        statisticsService.fetchStatisticsContents({
            data: "storageUsageByOrganization",
            format: "typeTable",
            selectedOrganizationIds: this.selectedOrganizations
        }).then(res => {
            let totalSize = 0;
            res.items.forEach(item => {
                totalSize += Number(item.storageUsage.replace('MB', '').trim()) * 1024 * 1024;
            })
            this.sort(res.items, this.state.sorted[0].id, this.state.sorted[0].desc);
            this.setState({
                items: cloneDeep(res.items),
                totalSize: bytesToSize(totalSize),
                request: {
                    organizations: cloneDeep(this.selectedOrganizations),
                }
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {this.setState({loading: false});});
    }

    noDataComponent = () => {
        let {t} = this.props;
        return <div style={{position: 'absolute', left: '50%', top: '50%', color: '#59616b'}}>{t('MESSAGE_COMMON_NO_DATA_P')}</div>
    };

    render() {
        let {t, currContent} = this.props;
        let {organizations, loadingbar} = this.state;
        return (
            <div className='storage_space_view' style={{display: currContent === 'STORAGE_SPACE_USAGE' ? 'block':'none'}}>
                <div className="contents_buttonWrap">
                    <div className="leftButton">
                        {organizations.length > 0 &&
                        <Fragment>
                            <WhiteButton id={"CONTENT_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick={() => this.onClickExport('EXCEL')}/>
                            {organizations && organizations.length > 1 && <MultiSelectButton data={organizations} onChange={this.onChangedOrganization} title={t("TABLE_ORGANIZATION_P")} itemAll={true} height={252} width={200}/>}
                        </Fragment>
                        }
                    </div>
                </div>
                {this.renderStorageChart()}
                {this.renderStorageTable()}
                {loadingbar && <LoadingBar />}
            </div>
        );
    }

    renderStorageChart = () => {
        let {t} = this.props;
        const {totalSize, systemSize, availableSize, orgUsageDataList} = this.state.chartData;
        if(!totalSize) {
            return (null);
        }
        return (
            <div className='storage_space_chart'>
                <h4 className='storage_space_chart_title'>{t('MIS_SID_STORAGE_SPACE_USAGE')}</h4>
                <div className='storage_space_chart_size'>{getMixString(['MIS_SID_MIX_FREE_OF', bytesToSize(Number(availableSize.value)), bytesToSize(Number(totalSize.value))])}</div>
                <div className='storage_space_chart_bar'>
                    <div className='storage_space_chart_bar_volume' style={{width: `${systemSize.percent}%`, backgroundColor: '#BDBDBD'}} title={t('MIS_TEXT_SYSTEM_USAGE_P') + ": " + bytesToSize(Number(systemSize.value))}/>
                    {
                        orgUsageDataList.map((org, index) => {
                            return <div key={index} className='storage_space_chart_bar_volume' style={{width: `${org.percent}%`, backgroundColor: `${storageBackgroundColors[index % storageBackgroundColors.length]}`}}
                                 title={t('TABLE_ORGANIZATION_P') + ": " + org.name + " " + t('MIS_SID_STORAGE_SPACE_USAGE') + ": " + bytesToSize(Number(org.value))}/>
                        })
                    }
                    <div className='storage_space_chart_bar_volume' style={{width: `${availableSize.percent}%`, backgroundColor: '#F2F2F2'}} title={t('COM_TABLE_FREE_USABLE_MEMORY_P') + ": " + availableSize.value}/>
                </div>
            </div>
        );
    }

    renderStorageTable = () => {
        let {t} = this.props;
        let {items, totalSize, sorted, loading} = this.state;
        return (
            <div className='storage_space_list_view'>
                <div className='storage_space_list_table'>
                    <ReactTable
                        data={items}
                        loading={loading}
                        minRows={0}
                        sorted={sorted}
                        onSortedChange={this.onSortedChange}
                        showPagination={false}
                        NoDataComponent={this.noDataComponent}
                        columns={[
                            {
                                Header: <Trans i18nKey={"TABLE_ORGANIZATION_P"}/>,
                                accessor: "organizationName",
                                Cell: (props) => <span className='storage_space_list_view_column' title={props.original.organizationName}>{props.original.organizationName}</span>,
                                width: '20%',
                            },
                            {
                                Header: <Trans i18nKey={"COM_TABLE_GROUP_NAME_P"}/>,
                                accessor: 'groupName',
                                Cell: (props) => <span className='storage_space_list_view_column' title={props.original.groupName}>{props.original.groupName}</span>,
                                width: '20%',
                            },
                            {
                                Header: <Trans i18nKey={"COM_DID_ADMIN_USER_USERID"}/>,
                                accessor: 'userId',
                                Cell: (props) => <span className='storage_space_list_view_column' title={props.original.userId}>{props.original.userId}</span>,
                                width: '20%',
                            },
                            {
                                Header: <Trans i18nKey={"COM_TEXT_USER_NAME_P"}/>,
                                accessor: 'userName',
                                Cell: (props) => <span className='storage_space_list_view_column' title={props.original.userName}>{props.original.userName}</span>,
                                width: '20%',
                            },
                            {
                                Header: <Trans i18nKey={"MIS_SID_STORAGE_SPACE_USAGE"}/>,
                                accessor: 'storageUsage',
                                Cell: (props) => <span className='storage_space_list_view_column' title={props.original.storageUsage}>{props.original.storageUsage}</span>,
                                width: '20%',
                            }
                        ]}
                        pageSize={items.length}
                        className="-striped -highlight"
                        manual
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        getTrGroupProps={(state, rowInfo) => {
                            return {
                                className: 'storage_space_tr_group',
                            };
                        }}
                        getTdProps={() => {
                            return {
                                className: 'storage_space_td_props'
                            };
                        }}/>
                </div>
                <div style={{height: '4px'}}/>
                <div className='storage_space_total'>
                    <div className='storage_space_total_title'>{t('LIST_TITLE_TOTAL')}</div>
                    <div className='storage_space_total_size'>{totalSize}</div>
                </div>
            </div>
        );
    }
};

export default withTranslation()(ContentStorageSpace);

