import React from 'react';
import {useTranslation} from "react-i18next";

const ContentExpiration = ({expirationStatus, expirationStatusOption, onClickExpirationStatus}) => {
    const {t} = useTranslation();

    return (
        <div className="dev_filter_type_radio" id="filter_list_expirationStatus">
            <h5><span className="expirationStatus" style={{background: '#e27be8'}}></span>{t("MIS_SID_EXPRIATION_STAUTS")}</h5>
            <div>
                <ul className="type_checklist" style={{overflowY: 'auto'}}>
                    <li rel="All"
                        className={expirationStatus.value === expirationStatusOption[0].value ? "on" : ""}
                        value="device_status_view_all">
                        <a href="#"
                           onClick={onClickExpirationStatus(expirationStatusOption[0])}>{t("TEXT_ALL_P")}</a>
                    </li>
                    <li rel="Expiration Status"
                        className={expirationStatus.value === expirationStatusOption[1].value ? "on" : ""}
                        value="expired_content">
                        <a href="#"
                           onClick={onClickExpirationStatus(expirationStatusOption[1])}>{t("MIS_SID_EXPRIATION_STAUTS")}</a>
                    </li>
                    <li rel="Not Expired"
                        className={expirationStatus.value === expirationStatusOption[2].value ? "on" : ""}
                        value="valid_content">
                        <a href="#"
                           onClick={onClickExpirationStatus(expirationStatusOption[2])}>{t("MIS_SID_NOT_EXPIRED")}</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default ContentExpiration;