import React, {useRef, useState} from 'react'
import {useDispatch} from "react-redux";
import {menuAction, popupAction, scheduleAction} from "../../../../actions";
import {useTranslation} from "react-i18next";
import './CreateContentSchedulePopup.css';
import SelectSupportedDevice from "../../../utils/SelectSupportedDevice";
import MISDialog from "../../MISDialog";
import NumberInput from "../../../input/NumberInput";
import WhiteButton from "../../../button/WhiteButton";
import SelectedDevices from "../../../input/SelectedDevices";
import {toastr} from 'helper/toastrIntercept';
import {
    commonConstants,
    CONTENT_SCHEDULE_PROGRAM_TYPE_AD,
    CONTENT_SCHEDULE_PROGRAM_TYPE_LFD,
    CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC,
    CONTENT_SCHEDULE_PROGRAM_TYPE_VWL,
    scheduleConstants
} from "../../../../constants";
import Select from "../../../selectbox/Select";
import {deviceService} from "../../../../services/device.service";
import {getErrorMessage} from "../../../../helper/responseHandler";
import {isNil} from "lodash";


const CreateContentSchedulePopup  = ({id}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const dialog = useRef();

    const [schedule, setSchedule] = useState(()=> ({
        programType: CONTENT_SCHEDULE_PROGRAM_TYPE_LFD,
        deviceGroupIds: [],
        deviceGroupNames: [],
        deviceGroupMode: '',
    }));

    const [assignTagPopup, setAssignTagPopup] = useState(false);

    const [selectedSupportDevice, setSelectedSupportDevice] = useState({
        devices: [],
        selected: []
    })

    const [showInfo, setShowInfo] = useState(false);

    const [scheduleTypes, setScheduleTypes] = useState([
        {value: CONTENT_SCHEDULE_PROGRAM_TYPE_LFD, title: "COM_TEXT_GENERAL_P"}
    ]);

    const [adScheduleInfo, setAdScheduleInfo] = useState({
        numberOfSlot : 1,
        slotDuration: 10
    })

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    }

    const selectedDevice = (devices, selected) => {
        setSelectedSupportDevice({devices: devices, selected: selected});
    }

    const createContentSchedule = () => {
        const {devices, selected} = selectedSupportDevice;
        const initSchedule = (device, deviceTags) => {
            const tab = {id: programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? 'NEW_ADVERTISEMENT' : 'NEW_SCHEDULE', title: t("LAYERTITLE_NEW_SCHEDULE"), active: true, close: true};
            new Promise((resolve) => {
                if (programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD) {
                    dispatch(scheduleAction.initADSchedule(scheduleConstants.CONTENT_SCHEDULE_NEW_MODE, device, schedule.programType, schedule, adScheduleInfo))
                } else {
                    dispatch(scheduleAction.initContentSchedule(scheduleConstants.CONTENT_SCHEDULE_NEW_MODE, device, schedule.programType, schedule, deviceTags !== undefined ? deviceTags : []))
                }
                resolve()
            }).then(
                dispatch(menuAction.addTab(tab))
            ).then(
                () => {
                    if (programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD) {
                        dispatch(menuAction.loadContent('NEW_ADVERTISEMENT'))
                    } else {
                        dispatch(menuAction.loadContent('NEW_SCHEDULE'))
                    }
                }
            ).then(
                dispatch(popupAction.closePopup(id))
            );
        }

        const {programType} = schedule;

        if (programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL || programType === CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC) {
            if (schedule.deviceGroups === undefined || schedule.deviceGroups.length === 0) {
                toastr.error(t("MESSAGE_DEVICE_SELECT_DEVICE_GROUP_P"));
                return false;
            }
        }

        if ((devices !== undefined && devices.length > 0) && (selected !== undefined && selected.length > 0)) {
            const maxPriorityIndex = Math.max(...selected);
            const device = Object.assign({}, devices[maxPriorityIndex]);
            if (device.deviceType === 'IPLAYER') {
                device.deviceType = 'iPLAYER';
            }
            if (programType === CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC) {
                const groupIds = schedule.deviceGroups.map(group => group.groupId)
                deviceService.fetchTagsByDeviceGroups(groupIds)
                    .then(res=> {
                        if (res) {
                            const deviceTags = res.items;
                            initSchedule(device, deviceTags);
                        }
                    }).catch((e)=> toastr.error(getErrorMessage(e)))
            } else {
                initSchedule(device);
            }
        } else {
            toastr.error(t("MESSAGE_DEVICE_SELECT_DEVICE_GROUP_P"))
        }
    }

    const onChangeDevice = (device) => {
        if (device !== undefined) {
            const supportScheduleType = [
                {value: CONTENT_SCHEDULE_PROGRAM_TYPE_LFD, title: "COM_TEXT_GENERAL_P"}
            ]
            if (device.scheduleInfo  !== undefined && device.scheduleInfo.contentSchedule  !== undefined && device.scheduleInfo.contentSchedule.supportVwl === true) {
                supportScheduleType.push({value: CONTENT_SCHEDULE_PROGRAM_TYPE_VWL, title: "COM_DID_RMS_DEVICE_VIDEO_WALL"});
            }
            if (device.scheduleInfo !== undefined && device.scheduleInfo.contentSchedule  !== undefined && device.scheduleInfo.contentSchedule.supportSync === true) {
                supportScheduleType.push({value: CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC, title: "MIS_SID_SYNC_PLAY"});
            }
            if (device.scheduleInfo !== undefined && device.scheduleInfo.contentSchedule  !== undefined && device.scheduleInfo.contentSchedule.supportAd === true) {
                supportScheduleType.push({value: CONTENT_SCHEDULE_PROGRAM_TYPE_AD, title: "COM_DID_LFD_ADVERTISEMENT"});
            }
            setSchedule({...schedule, programType: CONTENT_SCHEDULE_PROGRAM_TYPE_LFD, priority: device.priority})
            setScheduleTypes(supportScheduleType);
        }

    }

    const showDeviceGroupPopup = () => {
        //setSchedule({...schedule, showDeviceGroupPopup: !schedule.showDeviceGroupPopup});
        dispatch(popupAction.addPopup({
            id: commonConstants.DEVICE_GROUP_SELECTION,
            type: commonConstants.DEVICE_GROUP_SELECTION,
            allowSelectOrganization: false,
            allEnabled: false,
            checkbox: true,
            priority : schedule.priority,
            isVwl : schedule.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL,
            isSync : schedule.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC,
            save: setDeviceGroups,
            close: ()=>dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
        }));

    }

    const showAssignTagPopup = () => {
        dispatch(popupAction.addPopup(
            {
                id: commonConstants.TAG_SETTING,
                type: commonConstants.TAG_SETTING,
                tagListType:'media',
                showTagValue: true,
                showDevice: true,
                selectedTags: [],
                devices: [],
                onApply: (tags, tagDevices) => {
                    deviceService.updateDeviceTags({
                        deviceIds: tagDevices.map(d => d.deviceId),
                        tagIds: tags.map(t => t.tagId),
                        tagConditionIds: tags.filter(t => !isNil(t.tagConditionId)).map(t => t.tagConditionId),
                        tagType: 'MEDIA'
                    }).then(res => {
                        toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                        dispatch(popupAction.closePopup(commonConstants.TAG_SETTING))
                    }).catch(error => toastr.error(getErrorMessage(error)));
                },
                onClose: () => dispatch(popupAction.closePopup(commonConstants.TAG_SETTING)),
            }
        ))

    }

    const removeDeviceGroup = id => {
        const {deviceGroups} = schedule;
        setSchedule({...schedule, deviceGroups: deviceGroups.filter(group=> group.groupId !== id)});
    }

    const handleProgramType = (e, type) => {
        setSchedule({...schedule, programType: type})
    }

    const setDeviceGroups = (groups) => {
        if (groups !== undefined && groups.length > 0) {
            const deviceGroups = [];
            groups.map(
                (group) => {
                    deviceGroups.push({groupId: group.groupId, groupName: group.groupNameText !== undefined ? group.groupNameText : group.groupName})
                }
            )
            setSchedule({...schedule, deviceGroups: deviceGroups});
            dispatch(popupAction.closePopup(commonConstants.DEVICE_GROUP_SELECTION))
        }
    }

    const handleADSlot = (e, value) => {
        setAdScheduleInfo({...adScheduleInfo, numberOfSlot: value});
    }

    const handleADDuration = (e, value) => {
        setAdScheduleInfo({...adScheduleInfo, slotDuration: value});
    }

    const dialogProps ={title: t("TEXT_CREATE_SCHEDULE_P"), closeOnEscape : true, modal:true, onClose:()=> closePopup()};

    return(
        <MISDialog
            ref={dialog}
            dialog = {dialogProps}
            width={780}
            height={260}
            buttons = {{
                rightButtons: [
                    {title : t("COM_TEXT_CREATE_P"), id: 'createContentScheduleBtn', onClick: ()=> createContentSchedule()},
                    {title : t("BUTTON_CANCEL_P"), id: 'closeCreateContentSchedulePopup', onClick: ()=> closePopup()}
                ]
            }}
        >

            <div className={"create_content_Schedule_popup_wrap"}>
                <div className="quest_img_noti">
                    {t("COM_MAPP_SID_SUPPORTED_DEVICES")}
                    <a href="#" id="scheduleDevNoticeBtn" onMouseEnter={()=>setShowInfo(true)} onMouseLeave={()=>setShowInfo(false)}></a>
                    <div className="opacity_notice_wrap" style={{width: 420,top: 100,padding: '10px 20px', left: 170, zIndex:1, display: showInfo ? '' : 'none'}} id="scheduleDevNotice">
                        <table>
                            <colgroup>
                                <col width="119px" />
                                <col width="" />
                            </colgroup>
                            <tbody>
                            <tr>
                                <td>- I, S8, S7, S6, S5, S4 – {t("COM_TEXT_GENERAL_P")} / {t("COM_DID_RMS_DEVICE_VIDEO_WALL")} / {t("MIS_SID_SYNC_PLAY")} / {t("COM_DID_LFD_ADVERTISEMENT")}</td>
                            </tr>
                            <tr>
                                <td>- S3 – {t("COM_TEXT_GENERAL_P")} / {t("COM_DID_RMS_DEVICE_VIDEO_WALL")} / {t("MIS_SID_SYNC_PLAY")}</td>
                            </tr>
                            <tr>
                                <td>- S2 – {t("COM_TEXT_GENERAL_P")} / {t("COM_DID_RMS_DEVICE_VIDEO_WALL")}</td>
                            </tr>
                            <tr>
                                <td>- W – {t("COM_TEXT_GENERAL_P")} / {t("MIS_SID_SYNC_PLAY")}</td>
                            </tr>
                            <tr>
                                <td>- S – {t("COM_TEXT_GENERAL_P")}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="device_type_btn mt15" style={{display:'inline-block'}}>
                    <SelectSupportedDevice type={"schedule"} onChange={onChangeDevice} selectedDevice={selectedDevice}/>
                </div>
                <span className="error_txt2" id="supportedDeviceErrorMsg"></span>

                <div className="quest_img_noti mt17">{t("MIS_SID_20_SCHEDULE_TYPE")}</div>
                <div className="mt15 mb20">
                    <Select value={schedule.programType} onChange={handleProgramType} id={"CREATE_CONTENT_SCHEDULE_TYPE"} width={200} selects={scheduleTypes} disabled={scheduleTypes.length <= 1}/>
                </div>

                <div className={"schedule_option_wrap"}>
                    {
                        schedule.programType !== undefined && schedule.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL &&
                        <div style={{width:420}}>
                            <div style={{display:'flex', lineHeight: '30px'}}>
                                <div style={{width: 100}}>{t("MIS_SID_20_PUBLISH_TO")}</div>
                                <div style={{width: 100}}>
                                    <WhiteButton id={"content_schedule_vwl_group"} name={t("TEXT_SELECT_P")} onClick={showDeviceGroupPopup}/>
                                </div>
                            </div>
                            <div>
                                <SelectedDevices groups={schedule.deviceGroups} removeGroup={(groupId) => removeDeviceGroup(groupId)}/>
                            </div>
                        </div>
                    }

                    {
                        schedule.programType !== undefined && schedule.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_SYNC &&
                        <div style={{width:420}}>
                            <div style={{display:'flex', lineHeight: '30px'}}>
                                <div style={{width: 100}}>{t("MIS_SID_20_PUBLISH_TO")}</div>
                                <div style={{width: 100}}>
                                    <WhiteButton id={"content_schedule_sync_group"} name={t("TEXT_SELECT_P")} onClick={showDeviceGroupPopup}/>
                                </div>
                                <div style={{width: 100}}>
                                    <WhiteButton id={"content_schedule_sync_assign_tag"} name={t("MIS_SID_20_ASSIGN_TAG")} onClick={showAssignTagPopup}/>
                                </div>
                            </div>
                            <div>
                                {t("MIS_SID_TAGS_ASSIGNED_DEVICES_GROUP_SYNC_PLAYLIST")}
                            </div>
                            <div>
                                <SelectedDevices groups={schedule.deviceGroups} removeGroup={(groupId) => removeDeviceGroup(groupId)}/>
                            </div>
                        </div>
                    }

                    {
                        schedule.programType !== undefined && schedule.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                        <div style={{width:420}}>
                            <div style={{display:'flex', lineHeight: '30px'}}>
                                <div style={{width: 220}}>{t("MIS_SID_20_NUMBER_OF_SLOTS")}</div>
                                <div>
                                    <NumberInput width={100} min={1} max={12} value={adScheduleInfo.numberOfSlot} onChange={handleADSlot} onKeyChange/>
                                </div>
                            </div>
                            <div style={{display:'flex', lineHeight: '30px'}}>
                                <div style={{width: 220}}>{t("MIS_SID_20_SLOT_DURATION")}</div>
                                <div>
                                    <NumberInput width={100} min={5} max={3600} value={adScheduleInfo.slotDuration} onChange={handleADDuration} onKeyChange/>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </MISDialog>
    )


}
export default CreateContentSchedulePopup;
