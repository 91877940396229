import React, {useCallback, useEffect, useRef, useState} from 'react';
import 'rc-time-picker/assets/index.css';
import Checkbox from "../../../checkbox/Checkbox";
import Moment from 'moment';
import {useTranslation} from "react-i18next";
import MISDialog from "../../MISDialog";
import TimeInput from "../../../input/TimeInput";
import ContentPopup from "../ContentPopup";
import MonthlyContainer from "../../../../containers/schedule/content/MonthlyContainer";
import Select from "../../../selectbox/Select";
import {toastr} from 'helper/toastrIntercept';
import WeeklyCheckbox from "../../../checkbox/WeeklyCheckbox";
import {isNil, isUndefined} from "lodash";
import DateInput from "../../../input/DateInput";
import {popupAction} from "../../../../actions";
import {commonConstants, CONTENT_SCHEDULE_PROGRAM_TYPE_VWL} from "../../../../constants";
import {useDispatch} from "react-redux";
import RadioGroup from "../../../radio/RadioGroup";
import TextInput from "../../../input/TextInput";
import {useMISOpt} from "../../../misopt";

const SelectContentsPlaylistsPopup = ({scheduleInfo, closePopup, updateSchedule, removeSchedule, mode, deviceType, deviceTypeVersion, programType}) => {

    const {misOptionSchedule} = useMISOpt();
    const dispatch = useDispatch();
    const [schedule, setSchedule] =  useState(scheduleInfo);
    const [contentPopup, setContentPopup] = useState(false);
    const [style, setStyle] = useState({
        width: 680,
        height: 200
    })

    const isVwl = useCallback(()=> {
        return programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL
    }, [])

    const ref = useRef();
    const {t} = useTranslation();

    const getScheduleInfo = useCallback(()=> {
        return misOptionSchedule(deviceType.toUpperCase(), deviceTypeVersion).contentSchedule;
    }, [])

    const {contents}  = getScheduleInfo();

    const selectContent = (contentId, contentName, thumbnail, filename, fileSize, contentType, playlistType) => {
        
        if (contentType === 'HW_IS' && contents !== undefined && contents.isHW !== undefined && contents.isHW) {
            let updateSchedule = Object.assign({}, initScheduleForDaily(schedule, 'WEEKLY', true));
            updateSchedule = {...updateSchedule, contentId: contentId, contentName: contentName, title: contentName, contentType: contentType, thumbnailFileId: thumbnail, fileSize: fileSize, playlistType: playlistType};
            setSchedule({...schedule, ...updateSchedule});
        } else {
            setSchedule({...schedule, contentId: contentId, contentName: contentName, title: contentName, contentType: contentType, thumbnailFileId: thumbnail, fileSize: fileSize, playlistType: playlistType});
        }

        openContentPopup();
    }

    const openContentPopup = () => {
        setContentPopup(!contentPopup);
    }

    const getScheduleTime = (type) => {
        if (type === 'start') {
            if (schedule.repeatType === 'ONCE') {
                return Moment(schedule.start).format('HH:mm:ss');
            } else {
                return schedule.startTime;
            }
        } else if (type === 'end') {
            if (schedule.repeatType === 'ONCE') {
                return Moment(schedule.end).format('HH:mm:ss');
            } else {
                return schedule.endTime;
            }
        }
    }

    const onRemoveSchedule = () => {
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_CONFIRM_POPUP,
            type: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("COM_BUTTON_CONFIRM"),
            message: t("MESSAGE_SCHEDULE_DELETE_QUE_P"),
            onClickYes: ()=>remove(),
            onClose: () => dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP)),
        }));
    }

    const remove = () => {
        removeSchedule(scheduleInfo.scheduleId);
        dispatch(popupAction.closePopup(commonConstants.COMMON_CONFIRM_POPUP));
    }

    const getScheduleDate = (type) => {
        if (type === 'start') {
            if (schedule.repeatType === 'ONCE') {
                return schedule.start;
            } else {
                return schedule.ranges.start.toDate();
            }
        } else if (type === 'end') {
            if (schedule.repeatType === 'ONCE') {
                return schedule.end;
            } else {
                return schedule.ranges.end.toDate();
            }
        }
    }

    const handleDate = (date, type) => {
        if (schedule.repeatType === 'ONCE') {
            if (type === 'start') {
                const end = schedule.end;
                end.setDate(1); // 31일 경우, 31일이 없는 달로 설정하면 달이 바뀜.
                                // ex) 3월 31일 에 setMonth를 4월로 설정하면 4월 31일이 없기때문에 5월로 됨.
                                // 이를 방지하기 위해 1일을 기본 설정으로 둠.
                end.setFullYear(date.getFullYear());
                end.setMonth(date.getMonth());
                end.setDate(date.getDate());
                setSchedule({...schedule, start: date, end: end});
            } else if (type === 'end') {
                const start = schedule.start;
                start.setDate(1);
                start.setFullYear(date.getFullYear());
                start.setMonth(date.getMonth());
                start.setDate(date.getDate());
                setSchedule({...schedule, start: start, end: date});
            }
        } else {
            if (type === 'start') {
                if (date.getTime() > schedule.ranges.end.toDate().getTime()) {
                    const end = Moment(schedule.ranges.end).toDate();
                    end.setFullYear(date.getFullYear());
                    end.setMonth(date.getMonth());
                    end.setDate(date.getDate());
                    setSchedule({...schedule, startRecur: Moment(date).format('YYYY-MM-DD'), endRecur: Moment(date).add(1, 'days').format('YYYY-MM-DD'),  ranges: {start: Moment(date), end: Moment(end)}})
                } else {
                    setSchedule({...schedule, startRecur: Moment(date).format('YYYY-MM-DD'), ranges: {...schedule.ranges, start: Moment(date)}})
                }
            } else if (type === 'end') {
                if (date.getTime() < schedule.ranges.start.toDate().getTime()) {
                    const start = Moment(schedule.ranges.start).toDate();
                    start.setFullYear(date.getFullYear());
                    start.setMonth(date.getMonth());
                    start.setDate(date.getDate());
                    setSchedule({...schedule, startRecur: Moment(date).format('YYYY-MM-DD'), endRecur: Moment(date).add(1, 'days').format('YYYY-MM-DD'), ranges: {start: Moment(start), end: Moment(date)}})
                } else {
                    setSchedule({...schedule, endRecur: Moment(date).add(1, 'days').format('YYYY-MM-DD'), ranges: {...schedule.ranges, end: Moment(date)}})
                }
            }
        }
    }

    const handleTime  = (event, time, type) => {
        let date;
        const {repeatType} = schedule;
        if (repeatType === 'ONCE') {
            if (type ==='start') {
                date = schedule.start;
            } else if (type === 'end') {
                date = schedule.end;
            }
            const times = time.split(':');
            if (times !== undefined && times.length > 2) {
                date.setHours(times[0], times[1], times[2]);
                if (type === 'start') {
                    if (date.getTime() > schedule.end.getTime()) {
                        toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                        return false;
                    } else {
                        setSchedule({...schedule, start: date});
                    }
                } else if (type === 'end') {
                    if (date.getTime() < schedule.start.getTime()) {
                        toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                        return false;
                    } else {
                        setSchedule({...schedule, end: date});
                    }
                }
            }
        } else {
            const date = new Date(Moment().format('YYYY-MM-DD')+' '+time);
            if (type === 'start') {
                const end = new Date(Moment().format('YYYY-MM-DD')+' '+schedule.endTime);
                if (date.getTime() > end.getTime()) {
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                    return false;
                } else {
                    setSchedule({...schedule, startTime: time});
                }
            } else {
                const start = new Date(Moment().format('YYYY-MM-DD')+' '+schedule.startTime);
                if (start.getTime() > date.getTime()) {
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                    return false;
                } else {
                    setSchedule({...schedule, endTime: time});
                }
            }
        }
    }

    const handleNeverExpired = e => {

        let {repeatType} = schedule;
        const updateSchedule = schedule;
        if (!schedule.isInfinitePlay) {
            if (!schedule.isAllDayPlay) {
                if (repeatType === 'ONCE') {
                    updateSchedule.startTime = Moment(schedule.start).format('HH:mm:ss');
                    updateSchedule.endTime = Moment(schedule.end).format('HH:mm:ss');
                } else {
                    updateSchedule.startTime = Moment(schedule.ranges.start).format('HH:mm:ss');
                    //updateSchedule.endTime = Moment(schedule.ranges.end).format('HH:mm:ss');
                }
            } else {
                //24 hour
                if (repeatType === 'ONCE') {
                    updateSchedule.startTime = '00:00:00';
                    updateSchedule.endTime = '23:59:59';
                }
                //updateSchedule.ranges = {start: Moment(schedule.start), end: Moment('2999-12-31 23:59:59', 'YYYY-MM-DD HH:mm:ss')};
            }
            updateSchedule.startRecur = Moment(schedule.start).format('YYYY-MM-DD');
            updateSchedule.ranges = {start: Moment(schedule.start), end: Moment('2999-12-31 '+schedule.endTime, 'YYYY-MM-DD HH:mm:ss')};
            updateSchedule.endRecur = '3000-01-01';
            updateSchedule.groupId = schedule.scheduleId;
            updateSchedule.daysOfWeek = [0,1,2,3,4,5,6];
        } else {
            //const now = Moment();
            updateSchedule.endRecur = schedule.ranges.start.clone().add(1, 'days').format('YYYY-MM-DD');
            updateSchedule.ranges = {...updateSchedule.ranges, end: Moment(schedule.start)};
        }
        updateSchedule.repeatType = schedule.repeatType === 'ONCE' ? 'DAILY' : schedule.repeatType;
        updateSchedule.isInfinitePlay = !schedule.isInfinitePlay;

        setSchedule({...schedule, ...updateSchedule});
    }


    const check24Hours = e => {
        if (!schedule.isAllDayPlay) {
            if (schedule.repeatType === 'ONCE') {
                const tempStart = schedule.start;
                const start = new Date(tempStart.setHours(0, 0, 0));
                const tempEnd = schedule.end;
                const end = new Date(tempEnd.setHours(23,59,59));
                setSchedule({...schedule, isAllDayPlay: true, start: start, end: end});
            } else {
                setSchedule({...schedule, isAllDayPlay: true, startTime: '00:00:00', endTime:  '23:59:59'});
            }

        } else {
            setSchedule({...schedule, isAllDayPlay: false});
        }

    }

    const getDayNumber = value => {
        switch (value) {
            case 'MON':
                return 1;
            case 'TUE':
                return 2;
            case 'WED':
                return 3;
            case 'THU':
                return 4;
            case 'FRI':
                return 5;
            case 'SAT':
                return 6;
            case 'SUN':
                return 0;
        }
    }
    const onChangeReserveWeekly = e => {
        const value = e.target.getAttribute('data-name');
        if (e.target.checked) {
            setSchedule({...schedule,repeatedDayOfWeekList: schedule.repeatedDayOfWeekList.concat(value)})
        } else {
            setSchedule({...schedule,repeatedDayOfWeekList: schedule.repeatedDayOfWeekList.filter(day => day !== value)})
        }
    }

    const onChangeReserveMonthly = e => {
        const value = e.target.getAttribute('data-value');
        if (schedule.repeatedDateOfMonthList.find(day=> day === parseInt(value)) !== undefined) {
            setSchedule({...schedule, repeatedDateOfMonthList: schedule.repeatedDateOfMonthList.filter(day => day !== parseInt(value))})
        } else {
            setSchedule({...schedule, repeatedDateOfMonthList: schedule.repeatedDateOfMonthList.concat(parseInt(value))})
        }
    }

    const initScheduleForDaily = (scheduleInfo, repeatType, isHW = false) => {

        let {isInfinitePlay, isAllDayPlay} = schedule;
        if (isHW) {
            isInfinitePlay = true;
            scheduleInfo.endRecur = '3000-01-01';
            if (scheduleInfo.ranges) {
                scheduleInfo.ranges.end = Moment('2999-12-31 '+schedule.endTime, 'YYYY-MM-DD HH:mm:ss');
            }
        }

        let start, end;

        if (scheduleInfo.repeatType === 'ONCE') {
            start = Moment(scheduleInfo.start);
            end = Moment(scheduleInfo.end);
            scheduleInfo.ranges = {start: start.clone(), end: end.clone()}
        } else {
            start = Moment(scheduleInfo.ranges.start);
            end = Moment(scheduleInfo.ranges.end);
        }
        
        if(isAllDayPlay) {
            start.set({hours: 0, minutes: 0, seconds: 0});
            end.set({hours: 23, minutes: 59, seconds: 59});
        }
        
        scheduleInfo.groupId = schedule.scheduleId;
        scheduleInfo.startTime = start.format('HH:mm:ss');
        scheduleInfo.endTime = end.format('HH:mm:ss');
        scheduleInfo.startRecur = start.format('YYYY-MM-DD');

        if (isInfinitePlay) {
            scheduleInfo.endRecur = scheduleInfo.repeatType === 'ONCE' ? '3000-01-01' : scheduleInfo.endRecur;
        } else {
            scheduleInfo.endRecur = scheduleInfo.repeatType === 'ONCE' ? end.clone().add(1, 'day').format('YYYY-MM-DD') : scheduleInfo.endRecur;
        }

        scheduleInfo.repeatedDateOfMonthList = [];
        scheduleInfo.repeatedDayOfWeekList = [];
        scheduleInfo.daysOfWeek = [0,1,2,3,4,5,6];
        if (repeatType === 'WEEKLY') {
            scheduleInfo.repeatedDayOfWeekList = ['MON','TUE','WED','THU','FRI','SAT','SUN'];
        }

        scheduleInfo.repeatType = repeatType;

        return scheduleInfo;
    }

    const handleRepeatType = (value) => {
        let height = 200;
        let updateSchedule = {}
        if (value === 'DAILY') {
            updateSchedule = Object.assign({}, initScheduleForDaily(schedule, value));
        } else if (value === 'WEEKLY') {
            height = 230;
            if (schedule.contentType === 'HW_IS') {
                schedule.isInfinitePlay = true;
            }
            updateSchedule = Object.assign({}, initScheduleForDaily(schedule, value));
        } else if (value === 'MONTHLY') {
            height = 340;
            updateSchedule = Object.assign({}, initScheduleForDaily(schedule, value));
        } else if (value === 'ONCE') {
            updateSchedule = Object.assign({}, schedule);
            if (updateSchedule.isInfinitePlay) {
                updateSchedule.isInfinitePlay = false;
            }
            if (updateSchedule.repeatType !== 'ONCE') {
                updateSchedule.start = updateSchedule.ranges.start.toDate();
                updateSchedule.end = Moment(updateSchedule.ranges.start.clone().format('YYYY-MM-DD')+' '+updateSchedule.ranges.end.format('HH:mm:ss')).toDate();
            } else {
                if (updateSchedule.isInfinitePlay) {
                    updateSchedule.isInfinitePlay = false;
                }
            }
            updateSchedule.ranges = undefined;

            if (updateSchedule.startRecur !== undefined)  {
                updateSchedule.startRecur = undefined;
            }
            if (updateSchedule.endRecur !== undefined) {
                updateSchedule.endRecur = undefined;
            }
            if (updateSchedule.startTime !== undefined) {
                updateSchedule.startTime = undefined
            }
            if (updateSchedule.startTime !== undefined) {
                updateSchedule.startTime = undefined;
            }
            if (updateSchedule.endTime !== undefined) {
                updateSchedule.endTime = undefined;
            }
            if (updateSchedule.daysOfWeek !== undefined) {
                updateSchedule.daysOfWeek = undefined;
            }
            if (updateSchedule.groupId !== undefined) {
                updateSchedule.groupId = undefined;
            }
            updateSchedule.repeatType = value;
        }

        setStyle({...style, height: height});
        setSchedule({...schedule, ...updateSchedule});
    }

    const handleSafetyLock = e => {
        setSchedule({...schedule, isSafetyLockSet: !schedule.isSafetyLockSet})
    }

    const onUpdateSchedule = () => {
        const valid = () => {
            if (!isUndefined(schedule.contentId) && !isNil(schedule.contentId) &&
                !isUndefined(schedule.contentName) && !isNil(schedule.contentName) &&
                schedule.contentId !== '' && schedule.contentName !== '') {
            } else {
                toastr.error(t("MESSAGE_CONTENT_SELECT_A_CONTENT_P"));
                return false;
            }
            if (schedule.repeatType === 'ONCE') {
                if (schedule.start.getTime() >= schedule.end.getTime()) {
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                    return false;
                }
            } else {
                const start = new Date(schedule.startRecur+' '+schedule.startTime);
                const end  = new Date(schedule.startRecur+' '+schedule.endTime);
                if (start.getTime() >= end.getTime()){
                    toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                    return false;
                }
            }
            return true;
        }
        if (valid()){

            if (schedule.contentType === 'HW_IS') {
                schedule.inputSource = schedule.contentId;
                schedule.scheduleType = schedule.inputSource === 'PANEL_OFF' ? '01' : '03'
            } else {
                schedule.inputSource = undefined;
                schedule.scheduleType = '00';
            }

            if (schedule.repeatType === 'WEEKLY') {
                const daysOfWeek = [];
                schedule.repeatedDayOfWeekList.map(
                    day => daysOfWeek.push(getDayNumber(day))
                )
                daysOfWeek.sort();
                schedule.daysOfWeek = daysOfWeek;
            } else if (schedule.repeatType !== 'ONCE') {
                schedule.daysOfWeek = [0,1,2,3,4,5,6];
            }
            updateSchedule(schedule, mode);
        }
    }

    const videoWallOption = [
        {value: 'single', title: t("TEXT_PLAYER_P")},
        {value: 'vwl', title: t("COM_DID_RMS_DEVICE_VIDEO_WALL")}
    ];

    const handlePlayerMode = (value) => {
        setSchedule({...schedule, playerMode: value})
    }

    useEffect(()=> {
        const {repeatType} = schedule;
        let height = 200;
        if (repeatType === 'WEEKLY') {
            height = 230;
        } else if (repeatType === 'MONTHLY') {
            height = 340;
        }
        setStyle({...style, height: height});
    }, [])

    const dialogProps ={title: t("TEXT_CREATE_SCHEDULE_P"), closeOnEscape : true, position: {x: -300, y: -400} , modal:true, onClose:()=> closePopup()};

    const getLeftButtons = useCallback(()=> {
        if (schedule.contentId !== null && schedule.contentId !== undefined && schedule.contentId !== '') {
            return [{id: 'DELETE_SCHEDULE', title: t("COM_BUTTON_DELETE"), onClick: ()=>onRemoveSchedule()}]
        }
        return [];
    }, [])

    const emptyContent = () => {
        setSchedule({
            ...schedule,
            contentId: '',
            contentName: '',
            contentType: ''
        })
    }

    const showDateInput  = useCallback(() => {
        const {contentType} = schedule;
        if (contentType === 'HW_IS' && contents !== undefined && contents.isHW !== undefined && contents.isHW) {
            return false;
        }
        return true;
    }, [schedule.contentType])

    /*const getRepeatType = useCallback(()=> {
        const {repeatType, contentType} = schedule;
        if (contentType === 'HW_IS' && contents !== undefined && contents.isHW !== undefined && contents.isHW) {
            setSchedule({...schedule, repeatType: 'WEEKLY'});
            return 'WEEKLY';
        }
        return repeatType;
    }, [schedule.contentType])*/

    return (

        <MISDialog
            dialog={dialogProps}
            width={style.width}
            height={programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL ? style.height + 25 : style.height}
            ref={ref}
            buttons={{
                leftButtons: getLeftButtons(),
                rightButtons: [
                    {id: 'SAVE_SCHEDULE', title: t("COM_BUTTON_SAVE"), onClick: ()=>onUpdateSchedule()},
                    {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: ()=> closePopup()}
                ]
            }}
            >
            {
                contentPopup &&
                    <ContentPopup
                        isVwlMode={schedule.playerMode !== undefined && schedule.playerMode === 'vwl'}
                        closeContentPopup={openContentPopup}
                        selectContent={selectContent}
                        deviceType={deviceType}
                        deviceTypeVersion={deviceTypeVersion}
                        programType={programType}
                        misOptionSchedule={misOptionSchedule}
                    />
            }

            <div className="popup_contents">
                <div className="detail_view">
                    <table>
                        <colgroup><col width="184px" /><col width="" /></colgroup>
                        <tbody>

                        {
                            programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL &&
                            <>
                                <tr className="contentTr">
                                    <th>{t("TEXT_CONTENT_P")}</th>
                                    <td>
                                        <div style={{display: 'block-inline'}}>
                                            <RadioGroup selects={videoWallOption}
                                                        onChange={(e, value) => handlePlayerMode(value)}
                                                        value={schedule.playerMode} multiLang={false}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr className="contentTr">
                                    <th></th>
                                    <td>
                                        <div style={{display:'block-inline'}}>
                                            <div className="" style={{float:'left'}}>
                                                <span id="selectContentInput">
                                                    <TextInput value={schedule.contentName} readOnly width={220} isEmptyButton emptyText={emptyContent}/>
                                                </span>
                                            </div>
                                            <div style={{float:'left'}}>
                                                <button className="contentSelectSearchBtn" onClick={openContentPopup}></button>
                                            </div>
                                        </div>
                                        <span style={{display:'inline-block',marginLeft:7,marginTop:10}}>
                                            <Checkbox id={"scheduleContentLock"} name={t("BUTTON_CHECK_OUT")} onClick={handleSafetyLock} checked={schedule.isSafetyLockSet} readOnly/>
                                        </span>
                                    </td>
                                </tr>
                            </>
                        }
                        {
                            programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_VWL &&
                            <>
                                <tr className="contentTr">
                                    <th>{t("TEXT_CONTENT_P")}</th>
                                    <td>
                                        <div style={{display:'block-inline'}}>
                                            <div className="" style={{float:'left'}}>
                                                <span id="selectContentInput">
                                                    <TextInput value={schedule.contentName} readOnly width={220} isEmptyButton emptyText={emptyContent}/>
                                                </span>
                                            </div>
                                            <div style={{float:'left'}}>
                                                <button className="contentSelectSearchBtn" onClick={openContentPopup}></button>
                                            </div>
                                        </div>
                                        <span style={{display:'inline-block',marginLeft:7,marginTop:10}}>
                                            <Checkbox id={"scheduleContentLock"} name={t("BUTTON_CHECK_OUT")} onClick={handleSafetyLock} checked={schedule.isSafetyLockSet} readOnly/>
                                        </span>
                                    </td>
                                </tr>
                            </>
                        }
                        <tr>
                            <th>{t("TEXT_REPEAT_P")}</th>
                            <td>
                                <Select title={t("TEXT_ONCE_P")} width={120} value={schedule.repeatType} onChange={(e, value)=>handleRepeatType(value)} selects={
                                    schedule.contentType !== undefined && schedule.contentType === 'HW_IS' && (contents !== undefined && contents.isHW !== undefined && contents.isHW) ?
                                        [{id:'SCHEDULE_TYPE_WEEKLY', value:'WEEKLY', title: "TEXT_WEEKLY_P"}]:
                                        [
                                            {id:'SCHEDULE_TYPE_ONCE', value:'ONCE', title: "TEXT_ONCE_P"},
                                            {id:'SCHEDULE_TYPE_DAILY', value:'DAILY', title: "TEXT_DAILY_P"},
                                            {id:'SCHEDULE_TYPE_WEEKLY', value:'WEEKLY', title: "TEXT_WEEKLY_P"},
                                            {id:'SCHEDULE_TYPE_MONTHLY', value:'MONTHLY', title: "TEXT_MONTHLY_P"}]

                                } />

                                {
                                    schedule.repeatType === 'WEEKLY'
                                    && <div style={{marginTop: 10}}><WeeklyCheckbox checked={schedule.repeatedDayOfWeekList} onClick={onChangeReserveWeekly}/></div>
                                }

                                {
                                    schedule.repeatType === 'MONTHLY'
                                    && <div style={{marginTop: 10}}><MonthlyContainer checked={schedule.repeatedDateOfMonthList} onClick={onChangeReserveMonthly}/></div>
                                }
                            </td>
                        </tr>
                        {
                            showDateInput() &&
                            <tr>
                                <th>{t("MIS_SID_20_PLAYBACK_DATE")}</th>
                                <td>
                                    <div style={{display: 'flex'}}>
                                        <DateInput dateFormat={'yyyy-MM-dd'} date={getScheduleDate('start')}
                                                   onChange={date => handleDate(date, 'start')}
                                                   type={'schedule_date_warp'}
                                                   maxDate={getScheduleDate('end')}/>
                                        <span style={{width: 30, textAlign: 'center'}}>~</span>
                                        <DateInput dateFormat={'yyyy-MM-dd'} date={getScheduleDate('end')}
                                                   onChange={date => handleDate(date, 'end')}
                                                   minDate={getScheduleDate('start')}
                                                   disabled={schedule.isInfinitePlay} type={'schedule_date_warp'}/>
                                        <span style={{marginLeft: 5}}>
                                        <Checkbox id={"neverExpired"} name={t("MIS_SID_20_NEVER_EXPIRE")}
                                                  onClick={handleNeverExpired} checked={schedule.isInfinitePlay}/>
                                    </span>
                                    </div>
                                </td>
                            </tr>
                        }
                        <tr>
                            <th>{t("MIS_SID_20_PLAYBACK_TIME")}</th>
                            <td>
                                <div style={{display: 'flex'}}>
                                    <TimeInput width={80} showSeconds value={getScheduleTime('start')} style={{textAlign: 'center'}} onChange={(event, value)=>handleTime(event, value, 'start')} disabled={schedule.isAllDayPlay}/>
                                    <span style={{width: 30, textAlign: 'center'}}>~</span>
                                    <TimeInput width={80} showSeconds value={getScheduleTime('end')} style={{textAlign: 'center'}} onChange={(event, value)=>handleTime(event, value, 'end')} disabled={schedule.isAllDayPlay}/>

                                    <span style={{marginLeft:10}}>
                                        <Checkbox id={"24hours"} name={t("COM_IDS_STR_24_HOURS")} onClick={check24Hours} checked={schedule.isAllDayPlay}/>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    )


}

export default SelectContentsPlaylistsPopup;