import React from 'react';
import {Loading} from "../loading/Loading";
import Checkbox from "../checkbox/Checkbox";
import {useTranslation} from "react-i18next";
import PlayerIcon from "../icon/players";

const DeviceTypes = ({devices, toggleDeviceAll, deviceTypeCheckBox, toggleDevice, deviceTypeCheckAll}) => {

    const {t} = useTranslation();

    return (
        <div className="dev_filter_type_checkbox" id="filter_list_device_type">
            <h5><span className="device_type" style={{background:'#ffa6a6'}}></span>{t("MIS_TEXT_DEVICE_TYPE_P")}</h5>
            <div className='device_type_checkbox'>
                <ul className="type_checklist" style={{overflowY:'auto'}}>
                    <li>
                        <Checkbox id={"contentTab_device_type_all"} classname={'text_all'} name={t("TEXT_ALL_P")} ref={deviceTypeCheckAll} onChange={toggleDeviceAll} defaultChecked={true}/>
                    </li>
                    {
                        devices.length > 0 ?
                            devices.map(
                                (device, index) => (
                                    <li key={index} style={{display: 'flex'}}>
                                        <Checkbox id={"filter_"+device.type+"_"+device.version}
                                                  name={<PlayerIcon text={true} deviceType={device.type} deviceTypeVersion={device.version}/>}
                                                  ref={ref => deviceTypeCheckBox[device.id] = ref}
                                                  onChange={()=>toggleDevice(device.id)}
                                                  defaultChecked={true}
                                        />
                                    </li>
                                )
                            ) : <Loading />
                    }
                </ul>
            </div>
        </div>
    )
}
export default DeviceTypes;