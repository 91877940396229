import React, {Component} from "react";
import './DashboardPanel.css'
import './DeviceConnectionRealTimeChart.css'
import {SortableHandle} from "react-sortable-hoc";
import {Line} from 'react-chartjs-2';
import {withTranslation} from "react-i18next";
import Moment from "moment";
import {dashboardService} from "../../services";
import {toastr} from 'helper/toastrIntercept';
import {getErrorMessage} from "../../helper/responseHandler";

class DeviceConnectionRealTimeChart extends Component {

    state = {
        currentDeviceConnectionDate: null,
        currentDeviceConnectionInfo: {},
        connectionTimes: [],
        connectionCounts: [],
    };

    constructor(props) {
        super(props);
        this.onTimer = this.onTimer.bind(this);
        this.initTime = this.initTime.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
    }

    componentDidMount() {
        this.charElement = document.getElementById('line-chart');
        this.deviceConnectionInterval = setInterval(this.onTimer, 1000);
        this.initTime();
    }

    componentWillUnmount() {
        clearInterval(this.deviceConnectionInterval);
    }

    initTime() {
        let initConnectionTimes = [];
        let initConnectionCounts = [];
        for (let index = 0; index < 300; index++) {
            initConnectionTimes.push(null);
            initConnectionCounts.push(null);
        }
        this.setState({
            connectionTimes: initConnectionTimes,
            connectionCounts: initConnectionCounts,
        });
    }

    onTimer() {
        let currentDate = Moment(new Date()).format('hh:mm:ss');
        this.fetchGetCurrentDeviceConnectionInfo(currentDate);
    }

    fetchGetCurrentDeviceConnectionInfo = (date) => {
        dashboardService.fetchCurrentDeviceConnectionInfo().then(res => {
            let connectionTimes = this.state.connectionTimes;
            let connectionCounts = this.state.connectionCounts;
            connectionTimes.splice(0, 1);
            connectionCounts.splice(0, 1);
            connectionTimes.push(date);
            connectionCounts.push(res.items.connectionCount);
            this.setState({
                currentDeviceConnectionDate: date,
                currentDeviceConnectionInfo: res.items,
                connectionTimes: connectionTimes,
                connectionCounts: connectionCounts,
            });
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    render() {
        return (
            <div className='dashboard-widget'>
                {this.renderHeader()}
                <div className='dashboard-widget-body'>
                    <div className='realtime-connected-device'>
                        {this.renderChart()}
                        {this.renderLabel()}
                    </div>
                </div>
            </div>
        )
    };

    renderChart() {
        let charData = {
            labels: [],
            datasets: [],
        }
        let tick = {beginAtZero: true, min: 0, max: 10};
        if (this.charElement) {
            let chart = this.charElement.getContext('2d');
            let {connectionTimes, connectionCounts} = this.state;

            charData.labels = connectionTimes;

            let gradient = chart.createLinearGradient(0, 0, 0, 200);
            gradient.addColorStop(0, 'rgba(128, 171, 224, 0.7)');
            gradient.addColorStop(1, 'rgba(128, 171, 224, 0)');
            let connectionDataset = {
                label: "Connection",
                data: connectionCounts,
                borderColor: '#80ABE0',
                backgroundColor: gradient,
                borderWidth: 2,
            };
            charData.datasets.push(connectionDataset);
            let max = Math.max(...connectionCounts);
            if (max > 10) {
                tick = {beginAtZero: true};
            }
        }
        return (
            <div className='realtime-connected-device-chart'>
                <Line id='line-chart' data={charData} width={358} height={165} redraw={true}
                    options={{
                        legend: {
                            display: false,
                        },
                        animation: {
                            duration: 0
                        },
                        scales: {
                            xAxes: [{
                                display: false,
                            }],
                            yAxes: [{ticks: tick}]
                        },
                        elements: {point: {radius: 0}},
                        tooltips: {
                            displayColors: false,
                            xPadding: 10,
                            yPadding: 10,
                            callbacks: {
                                title: function (tooltipItems, data) {
                                    return null;
                                },
                                label: function (tooltipItem, data) {
                                    return tooltipItem.value;
                                },
                            },
                        }
                    }}/>
            </div>
        );
    }

    renderLabel() {
        let {connectionTimes} = this.state;
        return (
            <div className='realtime-connected-device-guide'>
                <div className='realtime-connected-device-guide-ago'>5 minutes ago</div>
                <div className='realtime-connected-device-guide-current'>{connectionTimes[connectionTimes.length - 1]}</div>
            </div>
        );
    }

    renderHeader() {
        let {id, isEditMode, t} = this.props;
        const DragHandle = SortableHandle(() =>
            <div className='title'>{t("MIS_SID_CONNECTED_DEVICES_REAL_TIME")}</div>
        );
        return (
            <div className='dashboard-widget-header'>
                <DragHandle />
                {
                    isEditMode && <div title="Close" className="remove" onClick={() => this.props.onRemoveLayoutClickEvent(id)}></div>
                }
            </div>
        );
    }
}

export default withTranslation()(DeviceConnectionRealTimeChart);

