import React, {useEffect, useMemo, useState} from 'react';
import "../../components/table/react-table.css";
import WhiteButton from "../../components/button/WhiteButton";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import {useTranslation} from 'react-i18next';
import {userService} from "../../services";
import Pagination from "../../components/table/Pagination";
import {commonConstants, contentConstants} from "../../constants";
import {connect} from "react-redux";
import {popupAction} from "../../actions";
import {toastr} from 'helper/toastrIntercept';
import './User.css';
import fileDownload from "js-file-download";
import {LoadingBar} from "../../components/loading/LoadingBar";
import {useMISOpt} from "../../components/misopt";
import {getPageSize, useCheckRefWithSelectedCnt as useCheckRef, useFilter} from "../../helper";
import {checkDefaultRole} from "../../helper/user/roleUtils";
import {getErrorMessage} from "../../helper/responseHandler";
import MagicInfoTable from "../../components/table/MagicInfoTable";
import {useTrGroupProps} from "../../helper/tables";

const Role = (props) => {
    const {t} = useTranslation();
    const {getAuthority} = useMISOpt();
    const userAuthority = getAuthority("USER");

    const sortColumns = [
        {id: 'roleName', value: 'role_name'},
        {id: 'organizationName', value: 'organization_name'},
        {id: 'userCount', value: 'user_count'}
    ];

    const [style, setStyle] = useState({
        height: '762px'
    });

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange, onKeywordChange] = useFilter({
        page: 0,
        pageSize: getPageSize('role', contentConstants.PAGE_SIZE_OPTIONS[3]),
        sorted: [{id: sortColumns[0].id, desc: false}],
        keyword: '',
    });

    const [data, setState] = useState({
        items: [],
        loading: false,
        totalCount: 0,
    });

    const [request, setLastRequest] = useState({
        searchText: undefined,
        sortOrder: undefined,
        sortColumn: undefined,
    });
    const [loadingbar, setLoadingBar] = useState(false);

    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted, keyword} = filter;
    const {addPopup, closePopup} = props;

    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        }
    }, []);

    useEffect(() => {
        fetchGetRoleList();
    }, [filter]);

    const updateDimensions = () => {
        let height = window.innerHeight - 204;
        setStyle({
            ...style,
            height: height
        })
    };

    const onClickExport = (docType) => {
        setLoadingBar(true);
        const {searchText, sortOrder, sortColumn} = request;
        userService.fetchUserFileDownload(docType, {
            groupType: "ROLE",
            searchText: searchText,
            sortOrder: sortOrder,
            sortColumn: sortColumn,
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(err => {
            toastr.error(getErrorMessage(err, err.errorMessage))
        }).finally( () => {setLoadingBar(false);});
    }

    const onClickSearch = value => {
        setFilter({...filter, keyword: value, page: 0});
    };

    const onClickCheck = (checked, index, id) => {
        if(id === 'roleName') {
            addPopup({
                type: commonConstants.EDIT_ROLE,
                id: commonConstants.EDIT_ROLE,
                role: items[index],
                onSave: checkDefaultRole(items[index].roleName) ? undefined : () => {
                    closePopup(commonConstants.EDIT_ROLE);
                    fetchGetRoleList();
                },
                onClose: () => closePopup(commonConstants.EDIT_ROLE)
            });
        } else if(id === 'userCount') {
            addPopup({
                type: commonConstants.VIEW_ROLE_USER_LIST,
                id: commonConstants.VIEW_ROLE_USER_LIST,
                role: items[index],
                onClose: () => closePopup(commonConstants.VIEW_ROLE_USER_LIST)
            });
        }
    };

    const addRole = () => {
        addPopup({
            type: commonConstants.ADD_ROLE,
            id: commonConstants.ADD_ROLE,
            onClose: () => closePopup(commonConstants.ADD_ROLE),
            onSave: () => {
                closePopup(commonConstants.ADD_ROLE);
                fetchGetRoleList();
            }
        });
    }

    const editRole = () => {
        let role = items[selected.current[0]];

        if (selectedCnt > 1) {
            addPopup({
                type: commonConstants.COMMON_ALERT_POPUP,
                id: commonConstants.COMMON_ALERT_POPUP,
                title: t("TEXT_TITLE_WARNING_P"),
                message: t("MESSAGE_COMMON_SELECT_ONE_CHECKBOX_P"),
                onClose: () => closePopup(commonConstants.COMMON_ALERT_POPUP),
            });
        } else {
            if (checkDefaultRole(role.roleName)) {
                toastr.error(t('MESSAGE_USER_CANT_EDIT_DEFAULT_ROLE_P'));
                return;
            }
            addPopup({
                type: commonConstants.EDIT_ROLE,
                id: commonConstants.EDIT_ROLE,
                role: role,
                onSave: () => {
                    closePopup(commonConstants.EDIT_ROLE);
                    fetchGetRoleList();
                },
                onClose: () => closePopup(commonConstants.EDIT_ROLE)
            });
        }
    }

    const deleteRole = () => {
        for (let index = 0; index < selected.current.length; ++index) {
            if (checkDefaultRole(items[selected.current[index]].roleName)) {
                toastr.error(t('MESSAGE_USER_CANT_DELETE_DEFAULT_ROLE_P'));
                return;
            }
        }
        addPopup({
            type: commonConstants.COMMON_CONFIRM_POPUP,
            id: commonConstants.COMMON_CONFIRM_POPUP,
            title: t("TEXT_TITLE_DEL_ROLE_P"),
            message: t("MESSAGE_USER_REALLY_DEL_ROLE_P"),
            width: 310,
            height: 130,
            onClickYes: () => {
                closePopup(commonConstants.COMMON_CONFIRM_POPUP);
                fetchDeleteUserRole();
            },
            onClose: () => closePopup(commonConstants.COMMON_CONFIRM_POPUP),
        });
    }

    const columns = useMemo(() => [
        {
            id: "checkbox",
            Header: () => {
                return (
                    <Checkbox
                        id={'ALL'}
                        classname={"table"}
                        name={"check"}
                        ref={checkAll}
                        onChange={toggleSelectAll}
                    />
                )
            },
            Cell: (row) => {
                return (
                    <Checkbox id={items[row.index].roleId}
                              classname="table"
                              name="check"
                              index={row.index}
                              onChange={toggleRow}
                              ref={setCheckBoxRefs}
                    />
                )
            },
            width: 46,
            resizable: false,
            sortable: false
        },
        {
            Header: t("TABLE_ROLE_NAME_P"),
            accessor: "roleName",
            Cell: (props) => <span className={"list_column_data"} title={props.original.roleName}>{props.original.roleName}</span>,
            width: 300,
        },
        {
            Header: t("TEXT_ORGANIZATION_P"),
            accessor: "organizationName",
            Cell: (props) => <span className={"list_column_data"} title={props.original.organizationName}>{props.original.organizationName}</span>,
            width: 300,
        },
        {
            Header: t("TABLE_USER_COUNT_P"),
            accessor: 'userCount',
            Cell: (props) => <span className={"list_column_data"} title={props.original.userCount}>{props.original.userCount}</span>,
            resizable: false,
        }
    ], [items]);

    const fetchGetRoleList = () => {
        setState({...data, loading: true});
        const {page, pageSize, sorted, keyword} = filter;
        userService.fetchUserRolesFilter(
            {
                startIndex: page === 0 ? page + 1 : page * pageSize + 1,
                pageSize: pageSize,
                searchText: keyword,
                sortOrder: sorted[0].desc ? 'DESC' : 'ASC',
                sortColumn: sortColumns.find(column => column.id === sorted[0].id).value,
            }).then(res => {
            let result = [];
            res.items.forEach((item) => {
                result.push({
                    ...item,
                });
            })
            setState({
                ...data,
                loading: false,
                items: result,
                totalCount: res.totalCount,
            });
            setLastRequest({
                ...request,
                searchText: keyword && keyword.length > 0 ? keyword : undefined,
                sortOrder: sorted[0].desc ? 'DESC' : 'ASC',
                sortColumn: sortColumns.find(column => column.id === sorted[0].id).value,
            });
        }).catch(err => {
            setState({...data, loading: false});
            toastr.error(getErrorMessage(err, err.errorMessage))
        });
    };

    const fetchDeleteUserRole = () => {
        let ids = selected.current.map(key => items[key].roleId);;
        userService.deleteUserRole({
            ids: ids,
        }).then(res => {
            toastr.success(t('ALERT_SUCCESS_DELETE'));
            let pageMin = page * Number(pageSize);
            let deletedTotalCount = totalCount - res.items.successList.length;
            let deletePage = page;
            if(deletedTotalCount <= pageMin) {
                deletePage = page - 1 < 0 ? 0 : page - 1;
            }
            setFilter({...filter, page: deletePage});
        }).catch(err => {
            let errorMessage = 'COM_IDS_MSG_UNEXPEXTED_ERROR';
            if(err.items.failList.length > 0){
                switch (err.items.failList[0]) {
                    case '400713':
                        errorMessage = 'MESSAGE_USER_NOT_DEL_ROLE_EXIST_USER_P';
                        break;
                    default :
                        errorMessage = err.items.failList[0].reason;
                        break;
                }
            }
            toastr.error(errorMessage);
        });
    };

    const {READ = false, CREATE = false, MANAGE = false} = userAuthority;
    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'role_tr_group');
    return (
        <div style={{width: '100%', height: '100%', display: props.currContent === 'ROLE' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"ADD"} name={t("COM_BUTTON_ADD")} onClick={addRole} authority={CREATE || MANAGE}/>
                    <WhiteButton id={"EDIT"} name={t("TABLE_CHANGE_ROLE_P")} disable={selectedCnt !== 1} onClick={editRole} authority={CREATE || MANAGE}/>
                    <WhiteButton id={"DELETE"} name={t("COM_BUTTON_DELETE")} disable={selectedCnt < 1} onClick={deleteRole} authority={CREATE || MANAGE}/>
                    <WhiteButton id={"CONTENT_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick={() => onClickExport('EXCEL')} authority={READ || CREATE || MANAGE}/>
                </div>
                <div className="rightButton">
                    <SearchBar id="searchOrganizationGroup"
                               placeholder={t('TABLE_ROLE_NAME_P')}
                               keyword={keyword}
                               onClickSearch={onKeywordChange}/>
                </div>
            </div>
            <div className='role_list_view'>
                <MagicInfoTable
                    data={items}
                    loading={loading}
                    sorted={sorted}
                    minRows={0}
                    showPagination={false}
                    columns={columns}
                    pageSize={items.length}
                    onSortedChange={onSortedChange}
                    className="-striped -highlight"
                    style={style}
                    getTbodyProps={() => {
                        return {
                            className: 'role_table_body',
                        };
                    }}
                    getTrGroupProps={getTrGroupPropsType2}
                    getTdProps={(state, rowInfo, colInfo) => {
                        return {
                            onClick: () => onClickCheck(!rowInfo.original.isCheck, rowInfo.index, colInfo.id),
                            className: 'role_td_props'
                        };
                    }}/>
                <Pagination totalCount={totalCount}
                            page={page}
                            pageSize={pageSize}
                            pageSizeOptions={contentConstants.PAGE_SIZE_OPTIONS}
                            onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            divide='role'/>
            </div>
            {loadingbar && <LoadingBar />}
        </div>
    );
};

export default connect(
    state => ({
        menu: state.menu
    }),
    dispatch => ({
        addPopup: (popup) => dispatch(popupAction.addPopup(popup)),
        closePopup: (id) => dispatch(popupAction.closePopup(id))
    })
)(Role);

