export const userConstants = {
    PAGE_SIZE_OPTIONS: [10, 25, 50, 100],

    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    ROLE_ID_SERVER_ADMINISTRATOR: 1,
    ROLE_ID_ADMINISTRATORS: 2,
    ROLE_ID_CONTENT_MANAGER: 3,
    ROLE_NAME_ADMINISTRATOR: 'Administrator',
    ROLE_NAME_SERVER_ADMINISTRATOR: 'Server Administrator',
    ORGAN_ID_ADMINISTRATOR: 0,
    ORGAN_NAME_ADMINISTRATORS: 'Administrators',
    ORGAN_NAME_API_ADMINISTRATORS: 'ROOT',
    EXPORT_OPTION: [
        {value: 'EXCEL', title: 'COM_TEXT_EXCEL_P'},
        {value: 'PDF', title: 'DID_LAYOUTS_COMMON_PDF'},
    ],
    BUTTON_KEY_ADD_USER: 'ADD_USER',
    BUTTON_KEY_ADD_ORGANIZATION: 'ADD_ORGANIZATION',
    BUTTON_KEY_REMOVE_USER: 'REMOVE_USER',
    BUTTON_KEY_CHANGE_ORGANIZATION: 'CHANGE_ORGANIZATION',
    BUTTON_KEY_CHANGE_ROLE: 'CHANGE_ROLE',
    BUTTON_KEY_CONTENT_EXPORT: 'CONTENT_EXPORT',
    BUTTON_KEY_USER_DEVICE_PERMISSION: 'USER_DEVICE_PERMISSION',
    BUTTON_KEY_APPROVAL_USER: 'APPROVAL_USER,',
    BUTTON_KEY_REJECT_USER: 'REJECTS_USER,',

    USER_ID_ADMIN : 'admin'
};
