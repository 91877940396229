import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {toastr} from 'helper/toastrIntercept';
import {popupAction} from "../../../actions";
import MISDialog from '../MISDialog';
import {userService} from "../../../services";
import EditUserDetailedInformation from './EditUserDetailedInformation';
import {checkPostValidation, checkPreValidation, handleChange, updateDetailed} from './common/userCommons';
import {commonConstants, userConstants} from "../../../constants";
import {useMISOpt} from "../../misopt";

const useUserInfo = (initialInfo, t) => {
    const [userInfo, setUserInfo] = useState(initialInfo);

    const onChangeUserInfo = (e, value) => {
        const newInfo = handleChange(e, value, userInfo, t);
        setUserInfo(newInfo);
    };

    const updateUserInfoPartially = (params) => {
        const newInfo = updateDetailed(userInfo, params);
        setUserInfo(newInfo);
    };

    return [userInfo, setUserInfo, updateUserInfoPartially, onChangeUserInfo];
};

const createUpdateInfo = ({dataField}, t) => {
    const updateInfo = {};
    for (let fieldName in dataField) {
        switch (fieldName) {
            case 'organizationName':
                updateInfo[fieldName] = dataField[fieldName] === t('TEXT_ROOT_GROUP_NAME_P') ? userConstants.ORGAN_NAME_ADMINISTRATORS : dataField[fieldName];
                break;
            default:
                updateInfo[fieldName] = dataField[fieldName];
        }
    }
    return updateInfo;
};

const EditUserPopup = ({onClose, onClickYes, addPopup, closePopup, userItem, organizationId}) => {
    const {t} = useTranslation();
    const timer = useRef(null);
    const [userInfo, setUserInfo, updateUserInfoPartially, onChangeUserInfo] = useUserInfo({
        dataField: {
            userId: userItem.userInfo.userId === undefined ? '' : userItem.userInfo.userId,
            userName: userItem.userInfo.userName === undefined ? '': userItem.userInfo.userName,
            email: userItem.userInfo.email === undefined ? '' : userItem.userInfo.email,
            team: userItem.userInfo.team === undefined ? '' : userItem.userInfo.team,
            jobPosition: userItem.userInfo.jobPosition === undefined ? '' : userItem.userInfo.jobPosition,
            phoneNum: userItem.userInfo.phoneNum === undefined ? '' : userItem.userInfo.phoneNum,
            mobileNum: userItem.userInfo.mobileNum === undefined ? '' : userItem.userInfo.mobileNum,
            organizationGroupName: userItem.curMngOrg === undefined ? '' : userItem.curMngOrg,
            organizationId: organizationId,
            organizationName: userItem.organizationName,
            groupId: userItem.groupId,
            roleName: userItem.roleName,
            signUpDate: userItem.signUpDate,
            lastLoginDate: userItem.lastLoginDate,
            passwordChangeDate: userItem.passwordChangeDate,
            canWriteUser: userItem.canWriteUser,
            serverAdmin: userItem.serverAdmin,
            ldapId: userItem.ldapId
        },
        errors: {},
    }, t);

    const {getAuthority} = useMISOpt();
    const userAuthority = getAuthority("USER");

    useEffect(() => {
        return () => {
            if(timer.current) {
                closePopup('EDIT_DEVICE_LOADING');
                clearInterval(timer.current);
            }
        };
    }, []);

    const onClickSave = () => {
        if (checkPreValidation(userInfo, setUserInfo, t)) {
            return;
        }

        if (checkPostValidation(userInfo, t)) {
            return;
        }

        addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP});
        userService.updateUsers(userInfo.dataField.userId, createUpdateInfo(userInfo, t)).then(res => {
            toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
            const newInfo = {...userInfo, errors: {}};
            setUserInfo(newInfo);

            onClickYes();
            onClose();
        }).catch(error => {
            let errorCode = error.errorCode !== undefined ? error.errorCode : error.body.errorCode;
            if (errorCode === '400716') {
                toastr.error(t('MIS_SID_SERVER_ONE_ADMINISTRATOR_REQUIRED_ORGANIZATION'));
            } else if (errorCode === '401001') {
                toastr.error(t('MIS_SID_20_THE_USER_IS_NOT_AUTHORIZED'));
            } else if (errorCode === '403001') {
                toastr.error(t('ERROR_NO_ROLE'));
            } else {
                toastr.error(error.errorMessage !== undefined ? error.errorMessage : error.body.errorMessage);
            }
        }).finally(() => {
            closePopup(commonConstants.LOADING_POPUP);
        });
    };

    return (
        <MISDialog
            dialog={{
                title: t(`COM_TEXT_TITLE_USER_EDIT_P`),
                closeOnEscape: true,
                modal: true,
                width: 730,
                height: 730,
                onClose: onClose,
            }}
            buttons={
                (userAuthority.READ === true && userAuthority.CREATE === false) ?
                {
                    rightButtons: [
                        {
                            id: 'DEVICE_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose,
                        }
                    ]
                }
                :
                {
                    rightButtons: [
                        {
                            id: 'DEVICE_SAVE',
                            title: t('COM_BUTTON_SAVE'),
                            onClick: onClickSave,
                            disable: false,
                        },
                        {
                            id: 'DEVICE_CANCEL',
                            title: t('BUTTON_CANCEL_P'),
                            onClick: onClose,
                        }
                    ]
                }

            }>

            <EditUserDetailedInformation userInfo={userInfo} updateUserInfoPartially={updateUserInfoPartially} onChange={onChangeUserInfo}/>

        </MISDialog>
    );
};

EditUserPopup.defaultProps = {
    onSave: () => {},
};

export default connect(
    null,
    dispatch => ({
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        closePopup: id => dispatch(popupAction.closePopup(id))
    })
)(EditUserPopup);