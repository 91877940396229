import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import WhiteButton from "../../components/button/WhiteButton";
import ReactTable from "react-table";
import Pagination from "../../components/table/Pagination";
import SearchBar from "../../components/search/SearchBar";
import {SETTING_PAGE_SIZE_OPTIONS} from "../../constants";
import React, {useEffect, useMemo, useState} from "react";
import { useFilter} from '../../helper';
import SelectBox from "../../components/selectbox/SelectBox";
import {StatisticsCalendar} from "../statistics/StatisticsCalendar";
import {settingService} from "../../services";
import {snakeCase} from "lodash";
import moment from "moment-timezone";
import Moment from "moment";
import "./Settings.css";
import fileDownload from "js-file-download";
import MagicInfoTable from "../../components/table/MagicInfoTable";

const LogManagement = (props) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    //const [logType, setLogType] = useState('ALL');
    //const [period, setPeriod] = useState({time: timeOptions[0].value, startDate: new Date(), endDate: new Date()});

    const timeOptions = [
        {value: 'aWeekAgo', title: 'COM_MESSAGE_STATISTICS_MENU_A_WEEK_AGO_P'},
        {value: 'aMonthAgo', title: 'MESSAGE_STATISTICS_MENU_A_MONTH_AGO_P'},
        {value: 'threeMonthAgo', title: 'MIS_MESSAGE_STATISTICS_MENU_THREE_MONTH_AGO_P'},
        {value: 'sixMonthAgo', title: 'MIS_MESSAGE_STATISTICS_MENU_SIX_MONTH_AGO_P'},
        {value: 'aYearAgo', title: 'MIS_MESSAGE_STATISTICS_MENU_A_YEAR_AGO_P'},
        {value: 'custom', title: 'COM_SID_CUSTOM'},
    ];

    const filterOption = [
        {value:'ALL', title:'TEXT_ALL_P'},
        {value:'CONTENT', title:'TEXT_TITLE_CONTENT_P'},
        {value:'PLAYLIST', title:'TEXT_TITLE_PLAYLIST_P'},
        {value:'RULESET', title:'MIS_SID_RULESET'},
        {value:'SCHEDULE', title:'TEXT_TITLE_SCHEDULE_P'},
        {value:'DEVICE', title:'TEXT_TITLE_DEVICE_P'},
        {value:'USER', title:'TEXT_TITLE_USER_P'},
        {value:'USER LOGIN', title:'COM_TV_USER_LOGIN'},
    ];

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange ] = useFilter({
        filterType : 'ALL',
        period: {time: timeOptions[0].value, startDate: new Date(0), endDate: new Date()},
        page: 0,
        pageSize: 30,
        searchText: '',
        sorted: [{id: 'eventTime', desc: true}],
        reload: false
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0,
    });
    const {items = [], loading = false, totalCount = 0, pages = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [style, setStyle] = useState({
        height: '500px'
    });

    useEffect(() => {
        fetchData();
    }, [filter]);

    useEffect(() => {
        setStyle({height: window.innerHeight - 195});
    }, []);

    const fetchData = () => {
        const {page, pageSize, sorted: [{id, desc}],  searchText} = filter;
        setData({...data, loading: true});
        settingService.fetchLog({
            startIndex: page*pageSize + 1,
            pageSize,
            sortColumn: snakeCase(id).toUpperCase(),
            sortOrder: desc === true ? 'DESC' : 'ASC',
            searchText: searchText,
            startDate : Moment(filter.period.startDate).format('YYYY-MM-DD'),
            endDate :Moment(filter.period.endDate).format('YYYY-MM-DD'),
            filterType : filter.filterType
        }).
        then(res=>{
            setData({...data, loading: false, items: res.items, totalCount: res.totalCount, pages: Math.ceil(res.totalCount / pageSize)});
        });

    }

    const onClickFilterType = (filterType) => {
        setFilter({...filter, page: 0, filterType : filterType});
    };

    const onChangeSearchText = value => {
        setFilter({...filter, searchText: value, page: 0})
    }

    const onClickDateOption = (time) => {
        let startDate = new Date();
        let endDate = new Date();
        switch (time) {
            case 'aWeekAgo':
                startDate.setDate(endDate.getDate() - 7);
                break;
            case 'aMonthAgo':
                startDate.setMonth(endDate.getMonth() - 1);
                break;
            case 'threeMonthAgo':
                startDate.setMonth(endDate.getMonth() - 3);
                break;
            case 'sixMonthAgo':
                startDate.setMonth(endDate.getMonth() - 6);
                break;
            case 'aYearAgo':
                startDate.setFullYear(endDate.getFullYear() - 1);
                break;
            case 'custom':
                startDate.setDate(endDate.getDate() - 7);
                endDate.setDate(endDate.getDate());
                break;
            default:
                break;
        }

        //setPeriod({time, startDate, endDate});
        setFilter({...filter, period : {time: time, startDate: startDate, endDate:endDate}});
    };

    const onExport = exportType => {
        const {page, pageSize, searchText, sorted: [{id, desc}]} = filter;
        settingService.exportServerLog({
            startIndex : 1,
            pageSize : data.totalCount,
            sortColumn : snakeCase(id).toUpperCase(),
            sortOrder : desc === true ? 'DESC' : 'ASC',
            searchText : searchText,
            startDate : Moment(filter.period.startDate).format('YYYY-MM-DD'),
            endDate : Moment(filter.period.endDate).format('YYYY-MM-DD'),
            filterType : filter.filterType
        }).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(error => console.log(error));
    };

    const columns = useMemo(()=>[

        {
            Header: t("COM_SID_MENU"),
            accessor: "menu",
            sortable: false,
            width: 200,
            style: {"paddingLeft": "15px"}

        },
        {
            Header: t("TABLE_EVENT_TYPE_P"),
            accessor: "eventType",
            sortable: false,
            width: 300
        },
        {
            Header: t("LIST_TITLE_NAME"),
            accessor: "name",
            sortable: false,
            width: 300
        },
        /*
        // 이후 구현 예정
        {
            Header: t("BUTTON_DETAIL_P"),
            accessor: "event_desc",
            sortable: false,
            width: 200
        },
         */
        {
            Header: t("TABLE_EVENT_TIME_P"),
            accessor: "eventTime",
            sortable: false,
            Cell: ({value}) => <span>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</span>,
            width: 200
        },
        {
            Header: t("TABLE_IP_ADDR_P"),
            accessor: "ipAddress",
            sortable: false,
            width: 200
        },
        {
            Header: t("COM_DID_ADMIN_USER_USERID"),
            accessor: "userId",
            sortable: false,
            width: 200
        }

    ])

    const onChangeStartDay = startDate => {
        if (startDate.getTime() > Moment(filter.period.endDate).toDate().getTime()) {
            startDate = filter.period.endDate
            setFilter({...filter, period : {...filter.period, startDate:startDate}})
        } else {
            setFilter({...filter, period : {...filter.period, startDate:startDate}})
        }
    };

    const onChangeEndDay = endDate => {
        if (endDate.getTime() < Moment(filter.period.startDate).toDate().getTime()) {
            endDate = filter.period.startDate
            setFilter({...filter, period : {...filter.period, endDate:endDate}})
        }else{
            setFilter({...filter, period : {...filter.period, endDate:endDate}})
        }
    };

    return (
        <div style={{width: '100%', display : props.currContent === 'LOG' ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"LOG_EXPORT"} name={t("BUTTON_EXPORT_P")} onClick ={onExport}/>
                </div>
                <div className="rightButton">
                    <SelectBox id={"FILTER_TYPE_SELECT"} title={t('COM_SID_FILTER_BY')}  selects={filterOption} multiLang={true} classname='setting_log_filter_button' onClick={onClickFilterType} title = {filter.filterType} defaultTitle={t('COM_SID_FILTER_BY')} zIndex={1000} />
                    <span className="period float_l">{t('COM_TEXT_PERIOD2_P')}</span>

                    <SelectBox defaultTitle={t('MESSAGE_UPLOADER_SEL_PERIOD_P')} multiLang={true} classname='float_l mr8' width={209} selects={timeOptions} onClick={onClickDateOption} zIndex={1000}  />

                    <div className='float_l mr8'  >
                        <StatisticsCalendar date={filter.period}
                                            onChangeStart={onChangeStartDay}
                                            onChangeEnd={onChangeEndDay}
                        />
                    </div>
                    <SearchBar id="logSearch" placeholder={t("LIST_TITLE_NAME") + ", " +  t("COM_DID_ADMIN_USER_USERID")} onClickSearch={onChangeSearchText} />
                </div>
            </div>
            <MagicInfoTable
                minRows={0}
                data={items}
                page={page}
                pages={pages}
                loading={loading}
                style={style}
                columns={columns}
                className="-striped -highlight"
                manual
                showPagination={false}
            />
            <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={SETTING_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} />
        </div>
    )
}

export default LogManagement