import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import ReactTable from "react-table";
import {useTranslation} from 'react-i18next';
import Moment from "react-moment";
import {snakeCase} from 'lodash';
import {toastr} from 'helper/toastrIntercept';
import {commonConstants, DEVICE_HEALTH_SUB_TABS, DEVICE_PAGE_SIZE_OPTIONS} from "../../constants";
import WhiteButton from "../../components/button/WhiteButton";
import "../../components/table/react-table.css";
import './DeviceHealth.css';
import {deviceService, userService} from '../../services';
import {popupAction} from "../../actions";
import SearchBar from "../../components/search/SearchBar";
import Checkbox from "../../components/checkbox/Checkbox";
import SubTab from '../../components/tab/SubTab';
import Pagination from '../../components/table/Pagination';
import {
    getPageSize,
    toMomentDateTimeFormat,
    useCheckRefWithSelectedCnt as useCheckRef,
    useDeviceDetail,
    useFilter
} from '../../helper';
import fileDownload from 'js-file-download';
import {getErrorMessage} from '../../helper/responseHandler';
import {useTrGroupProps} from '../../helper/tables';
import {useMISOpt} from '../../components/misopt';

const heightOffset = 204;

const DeviceHealth = ({type,currContent}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {misopt: {user: {dateFormat, timeFormat}}} = useMISOpt();
    const dateTimeFormat = toMomentDateTimeFormat(dateFormat, timeFormat);

    const [filter, setFilter, onPageChange, onPageSizeChange, onSortedChange] = useFilter({
        page: 0,
        pageSize: getPageSize('device', DEVICE_PAGE_SIZE_OPTIONS[0]),
        keyword: '',
        sorted: [{id: 'clientTime', desc: false}],
    });

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0,
        deviceSecurity: false
    });

    const [subTab, setSubTab] = useState(DEVICE_HEALTH_SUB_TABS[0]);
    const [style, setStyle] = useState({height: '500px'});
    const {items = [], loading = false, totalCount = 0} = data;
    const {page, pageSize, sorted} = filter;
    const [checkAll, checkBoxRefs, toggleSelectAll, toggleRow, setCheckBoxRefs, selected, selectedCnt] = useCheckRef(items);

    const fetchData = () => {
        const {page, pageSize, keyword, sorted: [{id, desc}]} = filter;

        setData({...data, loading: true});

        let warningType = '';
        if(type ==='warning') {
            warningType = subTab;
        }

        Promise.all([
            deviceService.fetchDeviceStatus({
                startIndex: page * pageSize + 1,
                pageSize,
                type,
                warningType,
                searchText: keyword,
                sortColumn: snakeCase(id),
                sortOrder: desc ? 'desc' : 'asc',
            }),
            userService.fetchUserLoginInformation()
        ]).then(res => {
            setData({...data, loading: false, items: res[0].items, totalCount: res[0].totalCount,
                deviceSecurity: res[1].items.hasDeviceSecurity
            });
        });
    };

    const onKeywordChange = value => {
        setFilter({...filter, keyword: value, page: 0, sorted: [{id: 'clientTime', desc: false}]});
    };

    const onClickSubTab = tab => {
        setSubTab(tab);
        setFilter({...filter});
    };

    const [renderDeviceNameCell, renderMACCell] = useDeviceDetail(false, data.deviceSecurity, false);

    const onClickError = (deviceId, deviceName) => {
        dispatch(popupAction.addPopup({
            id: commonConstants.DEVICE_ERROR_REPORT,
            type: commonConstants.DEVICE_ERROR_REPORT,
            listType: type,
            warningType: type ==='warning' ? subTab : '',
            deviceId,
            deviceName,
            onClose: () => dispatch(popupAction.closePopup(commonConstants.DEVICE_ERROR_REPORT))
        }));
    };

    const onExport = () => {
        let warningType = '';
        if(type ==='warning') {
            warningType = subTab;
        }

        deviceService.exportDeviceStatus({type, warningType, searchText: filter.keyword}).then(res => {
            fileDownload(res.blob, res.fileName);
        }).catch(error => toastr.error(getErrorMessage(error)));
    };

    useEffect(() => {
        setStyle({height: window.innerHeight - heightOffset});
    }, []);

    useEffect(() => {
        setFilter({
            page: 0, 
            pageSize: getPageSize('device', DEVICE_PAGE_SIZE_OPTIONS[0]),
            keyword: '',
            sorted: [{id: 'clientTime', desc: false}]
        });
    }, [type]);

    useEffect(() => {
        fetchData();
    }, [filter]);

    const columns = useMemo(() => [
        {
            id: 'checkbox',
            width: 40,
            sortable: false,
            resizable: false,
            Header: () => {
                return (
                    <Checkbox
                        id={'AllDevice_all'}
                        classname={"table"}
                        name={"check"}
                        onChange={toggleSelectAll}
                        ref={checkAll}
                    />
                )
            },
            Cell: row => {
                return (
                    <Checkbox
                        id={'AllDevice_'+row.index}
                        classname={"table"}
                        name={"check"}
                        index={row.index}
                        onChange={toggleRow}
                        ref={setCheckBoxRefs}
                    />
                );
            },
        },
        {
            Header: t('TABLE_DEVICE_NAME_P'),
            accessor: 'deviceName',
            width: 230,
            Cell: renderDeviceNameCell
        },
        {
            Header: t('DID_ADMIN_LICENSE_MAC'),
            accessor: 'deviceId',
            width: 160,
            Cell: renderMACCell
        },
        {
            Header: type === 'warning' && subTab === DEVICE_HEALTH_SUB_TABS[1] ? t('MIS_SID_SERVER_REPORTED') : t('COM_MSG_PROCESSING'),
            show: type !== 'resolved',
            accessor: 'inProgressCount',
            width: 100,
        },
        {
            Header: t('MIS_SID_RESOLVED'),
            show: type === 'resolved',
            accessor: 'resolvedCount',
            width: 100,
        },
        {
            Header: t('MIS_SID_RECENTLY_REPORTED'),
            show: type !== 'resolved',
            //sortable: false,
            Cell: ({original: {deviceId, deviceName , fault: {id, code, errorScript} = {}}}) => <span className='link_cell' onClick={() => onClickError(deviceId, deviceName)}>{`${code} ${errorScript}`}</span>
        },
        {
            Header: t('MIS_SID_RECENTLY_REPORTED'),
            show: type === 'resolved',
            //sortable: false,
            resizable: false,
            Cell: ({original: {deviceId, deviceName, fault: {id, code, errorScript, bodyFormat} = {}}}) => <span className='link_cell' onClick={() => onClickError(deviceId, deviceName)}>{`${code} ${errorScript} ` + '(' +`${bodyFormat}` + ')'}</span>
        },
        {
            Header: '',
            accessor: 'fault.clientTime',
            sortable: false,
            resizable: false,
            width: 160,
            Cell: (data) => {
                return data.original.fault.clientTime ? <Moment format={dateTimeFormat}>{data.original.fault.clientTime}</Moment> : '';
            }
        },
    ], [items]);

    const [getTrGroupPropsType1, getTrGroupPropsType2]= useTrGroupProps(items, checkBoxRefs, toggleRow,'device_tr_group');

    return (
        <div style={{width: '100%', display: (currContent === 'DEVICE_HEALTH_ERROR' || currContent === 'DEVICE_HEALTH_WARNING'|| currContent === 'DEVICE_HEALTH_RESOLVED') ? 'block':'none'}}>
            <div className="contents_buttonWrap">
                <div className="leftButton">
                    <WhiteButton id={"DEVICE_EXPORT"} name={t('BUTTON_EXPORT_P')} onClick={onExport} style={{borderColor: '#5e5e5e'}} />
                </div>
                <div className="rightButton">
                    <SearchBar id="deviceSearch" placeholder={t('TABLE_DEVICE_NAME_P')} keyword={filter.keyword} onClickSearch={onKeywordChange} />
                </div>
            </div>
            {
                (type === 'warning') &&
                <div style={{marginLeft: '25px', marginRight: '25px', marginBottom: '20px'}}>
                    <SubTab tabs={DEVICE_HEALTH_SUB_TABS} selected={subTab} multiLang={false} style={{width: '650px'}} onClick={onClickSubTab} />
                </div>
            }
            <div className='device_health_list_view_wrap'>
                <ReactTable
                    data={items}
                    loading={loading}
                    noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                    minRows={0}
                    sorted={sorted}
                    multiSort={false}
                    getTrGroupProps={getTrGroupPropsType2}
                    onSortedChange={onSortedChange}
                    columns= {columns}
                    showPagination={false}
                    defaultPageSize={pageSize}
                    className="-striped -highlight"
                    style={style}
                    manual
                />
                <Pagination totalCount={totalCount} page={page} pageSize={pageSize} pageSizeOptions={DEVICE_PAGE_SIZE_OPTIONS} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} divide={'device'} />
            </div>
        </div>
    );
};

export default DeviceHealth;
