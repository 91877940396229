import React, {useCallback, useEffect, useState} from 'react'
import {contentService, playlistService, scheduleService} from "../../../services";
import WhiteButton from "../../../components/button/WhiteButton";
import {useTranslation} from "react-i18next";
import {toastr} from 'helper/toastrIntercept';
import {useDispatch, useSelector} from "react-redux";
import {popupAction, scheduleAction} from "../../../actions";
import {
    commonConstants,
    CONTENT_SCHEDULE_CONFIG,
    CONTENT_SCHEDULE_PROGRAM_TYPE_AD,
    scheduleConstants
} from "../../../constants";
import ContentThumbnail from "../../../components/image/ContentThumbnail";
import Checkbox from "../../../components/checkbox/Checkbox";
import './ContentScheduleSubTable.css';
import Moment from "moment";
import DateInput from "../../../components/input/DateInput";
import TimeInput from "../../../components/input/TimeInput";
import moment from "moment-timezone";
import classNames from 'classnames';
import {snakeCase, toUpper} from "lodash";
import {inputSourceToString, simpleConvertSchedule} from "../../../helper/schedule/scheduleUtils";
import {progressAction} from "../../../actions/progress.action";
import {ProgressConstants} from "../../../constants/ProgressConstants";
import {getErrorMessage} from "../../../helper/responseHandler";
import {misInit, useMISOpt} from "../../../components/misopt";
import {rulesetService} from "../../../services/ruleset.service";


const RenderSimpleADScheduleRow = ({schedule, slot}) => {
    return (
        <tr >
            <td>
                <div style={{display: 'flex'}}>
                    <span style={{
                        display: 'table-cell',
                        lineHeight: '56px',
                        paddingLeft: 20
                    }}>{schedule.contentName}</span>
                </div>
            </td>
            <td>{slot.slotName}</td>
            <td>
                <div style={{display: 'flex'}}>
                    {
                        schedule.startDate
                    }
                    <span>~</span>
                    {
                        schedule.stopDate
                    }
                </div>

            </td>
            <td>
                <div style={{display: 'flex'}}>
                    {
                        schedule.startTime
                    }
                    <span>~</span>
                    {
                        schedule.stopTime
                    }
                </div>
            </td>
        </tr>
    )
}
const RenderSimpleScheduleRow = ({updateSchedule, oriSchedule, channel, frame, programType, deviceType, deviceTypeVersion}) => {
    //updateSchedule(channelNo, frameId, schedule)

    const [schedule, setSchedule] = useState({
        ...oriSchedule
    })

    const [update, setUpdate] = useState(false);

    const {dragInfo} = useSelector(state => ({
        dragInfo : state.schedules.contents.dragInfo
    }))

    const {misOption, misOptionSchedule, misOptionContent} = useMISOpt();

    const getPriority = (deviceType, deviceTypeVersion) => {
        let device;
        if (deviceType === 'IPLAYER' || deviceType === 'LPLAYER') {
            device = misInit.devices.find(device => device.deviceType === deviceType);
        } else {
            device = misInit.devices.find(device => device.deviceType === deviceType && device.deviceTypeVersion === deviceTypeVersion)
        }
        return device !== undefined ? device.priority : 1;
    }

    const programPriority = useCallback(()=>{
        return getPriority(deviceType, deviceTypeVersion);
    }, [])

    const isSupportRuleSet = useCallback(()=> {
        const scheduleInfo = misOptionSchedule(deviceType.toUpperCase(), deviceTypeVersion);        
        if (scheduleInfo !== undefined && scheduleInfo.contentSchedule !== undefined && scheduleInfo.contentSchedule.contents !== undefined)
            return scheduleInfo.contentSchedule.contents.ruleset;
        return false;
    }, [])

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const channelNo = channel.channelNo;
    const frameId = frame.frameId;

    const handleScheduleDate = (date, mode) => {
        if (schedule.repeatType === 'ONCE') {
            return false;
        }
        const updatedSchedule = Object.assign({}, schedule);
        if (mode === 'start') {
            if (date.getTime() > Moment(updatedSchedule.endDate+' 23:59:59').toDate().getTime()) {
                toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                return false;
            }
            updatedSchedule.startDate = Moment(date).format('YYYY-MM-DD');
        } else {
            if (date.getTime() < Moment(updatedSchedule.startDate+' 00:00:00').toDate().getTime()) {
                toastr.error(t("MESSAGE_SCHEDULE_CHECK_TIME_FIELDS_P"));
                return false;
            }
            updatedSchedule.endDate = Moment(date).format('YYYY-MM-DD');
        }
        setSchedule({...schedule, ...updatedSchedule});
    }

    const handleTime = (event, value, type) => {
        const updatedSchedule = Object.assign({}, schedule);
        if (type === 'start') {
            updatedSchedule.startTime = value;
        } else {
            updatedSchedule.endTime = value;
            if (updatedSchedule.startTime === '00:00:00' && value === '23:59:59') {
                updatedSchedule.isAllDayPlay = true;
            } else {
                updatedSchedule.isAllDayPlay = false;
            }
        }
        setSchedule({...schedule, ...updatedSchedule});

    }

    const handleExpired = () => {
        const updatedSchedule = Object.assign({}, schedule);
        updatedSchedule.isInfinitePlay = !updatedSchedule.isInfinitePlay;
        if (updatedSchedule.isInfinitePlay) {
            updatedSchedule.endDate = '2999-12-31';
        }
        setSchedule({...schedule, ...updatedSchedule});
    }

    const handleAllDayPlay = () => {
        const updatedSchedule = Object.assign({}, schedule);
        updatedSchedule.isAllDayPlay = !updatedSchedule.isAllDayPlay;
        if (updatedSchedule.isAllDayPlay) {
            updatedSchedule.startTime = '00:00:00';
            updatedSchedule.endTime = '23:59:59';
        }
        setSchedule({...schedule, ...updatedSchedule});

    }

    const getRepeatType = repeatType => {
        switch (repeatType) {
            case 'DAILY':
                return t("TEXT_DAILY_P");
            case 'ONCE':
                return t("TEXT_ONCE_P");
            case 'WEEKLY':
                return t("TEXT_WEEKLY_P");
            case 'MONTHLY':
                return t("TEXT_MONTHLY_P");
        }
    }

    const onDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (dragInfo) {
            const {id, contentType, mediaType} = dragInfo;
            dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));
            if (contentType === scheduleConstants.CONTENT_SCHEDULE_TYPE_PLAYLIST) {
                playlistService.fetchPlaylistById(id)
                    .then(
                        res=> {
                            if (res) {
                                const {playlistName, contents, deviceType : playlistDeviceType, deviceTypeVersion : playlistDeviceTypeVersion, playlistType, tags} = res.items;
                                let thumbFileId = undefined
                                if (playlistType === '5') {
                                    if (tags.length > 0 && tags[0].contents.length > 0) {
                                        thumbFileId = tags[0].contents[0].thumbFileId;
                                    }
                                } else {
                                    if (contents !== undefined && contents.length > 0) {
                                        thumbFileId = contents[0].thumbFileId;
                                    }
                                }
                                if (thumbFileId === undefined) {
                                    thumbFileId = 'NO_IMAGE'
                                }
                                const supportedPlaylistFilter = CONTENT_SCHEDULE_CONFIG[programType].supportedPlaylistFilter;
                                if (supportedPlaylistFilter) {
                                    const found = supportedPlaylistFilter.find(type => type === parseInt(playlistType, 10));
                                    if (found === undefined) {
                                        toastr.error(t('MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK'));
                                        return false;
                                    }
                                }
                                const priority = getPriority(playlistDeviceType.toUpperCase(), playlistDeviceTypeVersion);
                                if (programPriority() >= priority) {
                                    setSchedule({...schedule, thumbnailFileId: thumbFileId, contentName: playlistName, contentId: id, contentType: contentType});
                                    setUpdate(true);
                                } else {
                                    toastr.error(t("MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK"));
                                    return false;
                                }

                            }
                        }
                    ).catch(e=>toastr.error(getErrorMessage(e)))
                    .finally(()=>dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)))
            } else if (contentType === scheduleConstants.CONTENT_SCHEDULE_TYPE_CONTENT) {
                contentService.fetchContentById(id)
                    .then(
                        res => {
                            if (res) {
                                const {contentName, thumbFileId, expirationDate, mediaType, deviceType : contentDeviceType, deviceTypeVersion : contentDeviceTypeVersion} = res.items;
                                const priority = getPriority(contentDeviceType.toUpperCase(), contentDeviceTypeVersion);

                                if (expirationDate !== undefined) {
                                    const expiredDate = Moment(expirationDate, 'YYYYMMDD');
                                    if (expiredDate < Moment()) {
                                        toastr.error(t("MIS_SID_EXPIRED_CONTETN_NOT_ADDED"));
                                        return false;
                                    }
                                }
                                const {fileTypes} = misOptionContent(deviceType.toUpperCase(), deviceTypeVersion) || undefined;
                                if (fileTypes !== undefined) {
                                    const found = fileTypes.find(type => type.mediaType === mediaType);
                                    if (found === undefined) {
                                        toastr.error(t('MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK'));
                                        return false;
                                    }
                                }

                                //TODO check vwl content

                                if (programPriority() >= priority) {
                                    setSchedule({
                                        ...schedule,
                                        thumbnailFileId: thumbFileId,
                                        contentName: contentName,
                                        contentId: id,
                                        contentType: contentType
                                    });
                                    setUpdate(true);
                                } else {
                                    toastr.error(t("MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK"));
                                    return false;
                                }
                            }
                        }
                    ).catch(e => toastr.error(getErrorMessage(e)))
                    .finally(dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)))

            } else if (contentType === scheduleConstants.CONTENT_SCHEDULE_TYPE_INPUT_SOURCE) {
                if (deviceType.toUpperCase() === 'IPLAYER') {
                    setSchedule({...schedule, thumbnailFileId: undefined, contentName: id, contentId: id, contentType: 'HW_IS'});
                    setUpdate(true);
                } else {
                    toastr.error(t("MESSAGE_DEVICE_INVALID_MODEL_P"));
                }
                dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
            } else if (contentType === 'RULESET') {                
                if (isSupportRuleSet()) {
                    rulesetService.fetchRulesetById(id)
                        .then(
                            res=> {
                                if (res) {
                                    const {name} = res.items;
                                    const priority = getPriority(deviceType, deviceTypeVersion);
                                    
                                    if (programPriority() >= priority) {
                                        setSchedule({
                                            ...schedule,
                                            thumbnailFileId: 'RULESET_THUMBNAIL',
                                            contentName: name,
                                            contentId: id,
                                            contentType: 'RULESET'
                                        });
                                        setUpdate(true);
                                    } else {
                                        toastr.error(t("MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK"));
                                    }

                                }
                            }
                        ).catch(e=> toastr.error(getErrorMessage(e)))
                        .finally(()=>dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP)))
                } else {
                    toastr.error(t("MIS_SID_PREMIUM_FILE_NOT_SUPPORTED_CHECK"));
                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                }
            }
        }
    }

    const allowDrop = (e) => {
        e.preventDefault();
    }

    useEffect(()=> {
        updateSchedule(channelNo, frameId, schedule);
    }, [schedule])

    return (
        <tr >
            <td>
                <div style={{display: 'flex'}} onDrop={onDrop} onDragOver={allowDrop} className={classNames({'updated': update})}>
                    <ContentThumbnail id={schedule.thumbnailFileId} filename={schedule.thumbnailFileName} width={100} height={56}/>
                    <span style={{
                        display: 'table-cell',
                        lineHeight: '56px',
                        paddingLeft: 20
                    }}>{inputSourceToString(schedule.contentName)}</span>
                </div>
            </td>
            <td>{channel.channelNo}</td>
            {
                schedule.contentType !== undefined && schedule.contentType === 'HW_IS' &&
                <td>
                    {t('COM_SID_NOT_AVAILABLE_KR_USE')}
                </td>
            }
            {
                schedule.contentType !== undefined && schedule.contentType !== 'HW_IS' &&
                <td>
                    <div style={{display: 'flex'}}>
                        <DateInput
                            dateFormat={'yyyy-MM-dd'}
                            date={Moment(schedule.startDate, 'YYYY-MM-DD').toDate()}
                            onChange={date => handleScheduleDate(date, 'start')}
                            type={'schedule_date_warp'}
                            maxDate={Moment(schedule.endDate, 'YYYY-MM-DD').toDate()}
                            disabled={schedule.repeatType === 'ONCE'}/>
                        <span>~</span>
                        <DateInput
                            dateFormat={'yyyy-MM-dd'}
                            date={Moment(schedule.endDate, 'YYYY-MM-DD').toDate()}
                            onChange={date => handleScheduleDate(date, 'end')}
                            type={'schedule_date_warp'}
                            minDate={Moment(schedule.startDate, 'YYYY-MM-DD').toDate()}
                            disabled={schedule.isInfinitePlay || schedule.repeatType === 'ONCE'}/>
                    </div>
                    {
                        schedule.repeatType !== 'ONCE' &&
                        <div style={{marginTop: 5}}>
                            <Checkbox id={'never_expired_' + schedule.scheduleId} name={t("MIS_SID_20_NEVER_EXPIRE")} onChange={handleExpired} disabled={schedule.repeatType === 'ONCE'} checked={schedule.isInfinitePlay}/>
                        </div>
                    }
                </td>
            }


            <td>
                {getRepeatType(schedule.repeatType)}
            </td>
            <td>
                <div style={{display: 'flex'}}>
                    {
                        schedule !== undefined && schedule.startTime !== undefined &&
                        <TimeInput width={80} showSeconds value={schedule.startTime} style={{textAlign: 'center'}} onChange={(event, value)=>handleTime(event, value, 'start')} disabled={schedule.isAllDayPlay}/>
                    }
                    <span>~</span>
                    {
                        schedule != undefined && schedule.endDate !== undefined &&
                        <TimeInput width={80} showSeconds value={schedule.endTime} style={{textAlign: 'center'}} onChange={(event, value)=>handleTime(event, value, 'end')} disabled={schedule.isAllDayPlay}/>
                    }
                </div>
                <div style={{marginTop: 5}}>
                    <Checkbox id={'hours_24_' + schedule.scheduleId} name={t("COM_IDS_STR_24_HOURS")} checked={schedule.isAllDayPlay} onChange={handleAllDayPlay}/>
                </div>
            </td>
        </tr>
    )
}

export const ContentScheduleSubTable = ({programId, onExpandedChange}) => {

    const {t} = useTranslation();
    const [program, setProgram] = useState({});
    const dispatch = useDispatch();


    const getContentSchedule = () => {
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}));
        scheduleService.fetchContentScheduleById(programId).then(
            res=> {
                if (res) {
                    let program = Object.assign({}, res.items);
                    program.channels.map(
                        channel => {

                            if (channel.frame.events === undefined) {
                                channel.frame.events = [];
                            } else {
                                channel.frame.events !==undefined && channel.frame.events.length > 0 && channel.frame.events.map(
                                    event => {
                                        const end = moment(event.endDate+' '+event.startTime).add(event.durationInSeconds, 's');
                                        event.endTime = end.format('HH:mm:ss');
                                    }
                                )
                            }
                            channel.frame.frames !== undefined && channel.frame.frames.length > 0 && channel.frame.frames.map(
                                frame => {
                                    if (frame.events === undefined) {
                                        frame.events = [];
                                    } else {
                                        frame.events !== undefined && frame.events.map(
                                            event => {
                                                const end = moment(event.endDate+' '+event.startTime).add(event.durationInSeconds, 's');
                                                event.endTime = end.format('HH:mm:ss');
                                            }
                                        )
                                    }
                                }
                            )
                        }
                    )
                    setProgram({
                        ...program
                    })
                }
            }
        ).catch(
            e=> toastr.error(getErrorMessage(e))
        ).finally(
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP))
        )

    }

    const updateSchedule = (channelNo, frameId, schedule) => {
        const channels = program.channels;
        for (const channel of channels ){
            if (channel.channelNo === channelNo) {
                if (channel.frame !== undefined && channel.frame.frameId == frameId && channel.frame.events !== undefined && channel.frame.events.length > 0) {
                    const events = []
                    for (const event of channel.frame.events) {
                        events.push(event.scheduleId === schedule.scheduleId ? schedule : event);
                    }
                    channel.frame.events = events;
                }
                if (channel.frame !== undefined && channel.frame.frames !== undefined && channel.frame.frames !== undefined && channel.frame.frames.length > 0) {
                    for (const frame of channel.frame.frames) {
                        if (frame.events !== undefined && frame.events.length > 0) {
                            const events = []
                            for (const event of frame.events) {
                                events.push(event.scheduleId === schedule.scheduleId ? schedule : event);
                            }
                            frame.events = events;
                        }
                    }
                }
            }
        }
        setProgram({
            ...program,
            channels: channels
        })
    }

    const publishContentSchedule = () => {
        const saveProgram = Object.assign({}, program);
        saveProgram.channels.map(
            channel => {
                channel.frame.lineData = toUpper(snakeCase(channel.frame.lineData));
                if (channel.frame.events !== undefined && channel.frame.events.length > 0) {
                    const events = [];
                    channel.frame.events.map(event => events.push(simpleConvertSchedule(event)))
                    channel.frame.events = events;
                }
                if (channel.frame.frames !== undefined && channel.frame.frames.length > 0) {
                    channel.frame.frames.map(
                        frame => {
                            if (frame.lineData !== undefined && frame.lineData !== '') {
                                frame.lineData = toUpper(snakeCase(frame.lineData));
                            }
                            const events = [];
                            frame.events !== undefined && frame.events.length > 0 && frame.events.map(event => events.push(simpleConvertSchedule(event)))
                            frame.events = events;
                        }
                    )
                }
            }
        );
        scheduleService.updateContentSchedule(saveProgram.programId, saveProgram).then(
            res=>{
                if (saveProgram.deviceGroups !== undefined && saveProgram.deviceGroups.length > 0) {
                    dispatch(progressAction.addProgress(ProgressConstants.MODE_CONTENT_SCHEDULE, programId, saveProgram.programName));
                }
                toastr.success(t("MIS_MESSAGE_SCHEDULE_SAVE_SUCCESS_P"));
            }
        ).catch(
            res=>{
                toastr.error(res);
            }
        ).finally(
            onExpandedChange()
        )
    }

    useEffect(()=>{
        getContentSchedule()
    }, [])

    useEffect(()=> ()=> {
        dispatch(scheduleAction.unLoadSimpleScheduleSubmenu(programId));
    }, [])

    return (
            <div className={'content_schedule_sub_table'}>

                    <table>
                        <tbody className="simpleSchTable">
                        {
                            program.programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                            <tr>
                                <th style={{width:300}}>{t("DID_ADMIN_NOTICE_CONTENT")}</th>
                                <th style={{width:50}}>{t("COM_TEXT_CHANNEL_P")}</th>
                                <th style={{width:180}}>{t("MIS_SID_20_PLAYBACK_DATE")}</th>
                                <th style={{width:80}}>{t("TEXT_REPEAT_P")}</th>
                                <th style={{width:180}}>{t("TABLE_PLAY_TIME_P")}</th>
                            </tr>

                        }
                        {
                            program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                            <tr>
                                <th style={{width:300}}>{t("DID_ADMIN_NOTICE_CONTENT")}</th>
                                <th style={{width:50}}>{t("MIS_SID_20_SLOTS")}</th>
                                <th style={{width:180}}>{t("MIS_SID_20_PLAYBACK_DATE")}</th>
                                <th style={{width:180}}>{t("MIS_SID_20_PLAYBACK_TIME")}</th>
                            </tr>
                        }

                        {
                            program !== undefined && program.channels !== undefined && program.channels.map(
                                channel => {
                                    if (channel.frame.events !== undefined && channel.frame.events.length > 0) {
                                        {
                                            return channel.frame.events.map(
                                                schedule => <RenderSimpleScheduleRow oriSchedule={schedule} channel={channel} frame={channel.frame} updateSchedule={updateSchedule} programType={program.programType} deviceType={program.deviceType} deviceTypeVersion={program.deviceTypeVersion}/>
                                            )
                                        }
                                    }
                                    if (channel.frame.frames !== undefined && channel.frame.frames.length > 0) {
                                        for (const frame of channel.frame.frames) {
                                            if (frame.events !== undefined && frame.events.length > 0) {
                                                {
                                                    return frame.events.map(
                                                        schedule => <RenderSimpleScheduleRow oriSchedule={schedule} channel={channel} frame={frame} updateSchedule={updateSchedule} programType={program.programType} deviceType={program.deviceType} deviceTypeVersion={program.deviceTypeVersion}/>
                                                    )
                                                }
                                            }
                                        }
                                    }
                                    if (channel.frame.adSlots !== undefined && channel.frame.adSlots.length > 0) {
                                        let currentEvents = [];
                                        for (const slot of channel.frame.adSlots) {
                                            if (slot.events !== undefined && slot.events.length > 0) {
                                                currentEvents.push(slot.events.map(
                                                    schedule => <RenderSimpleADScheduleRow schedule={schedule} slot={slot} />
                                                ));
                                            }
                                        }
                                        return currentEvents;
                                    }
                                }
                            )
                        }
                        </tbody>
                    </table>

                <div style={{margin: 15}}>
                    <WhiteButton name={t("MIS_SID_SERVER_SAVE_AND_PUBLISH")} onClick={publishContentSchedule}/>
                </div>
            </div>
    )

}