import React, {useCallback, useEffect, useRef, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {menuAction, popupAction, scheduleAction} from "../../../actions";
import './MessageManager.css';
import SelectBox from "../../../components/selectbox/SelectBox";
import Checkbox from "../../../components/checkbox/Checkbox";
import classNames from 'classnames';
import DatePicker from "react-datepicker";
import 'rc-time-picker/assets/index.css';
import TimePicker from "rc-time-picker";
import Moment from "moment";
import {extendMoment} from 'moment-range';
import {SketchPicker} from 'react-color';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ConfirmPopup from "../../../components/popup/ConfirmPopup";
import SaveMessageSchedulePopup from "../../../components/popup/schedule/message/SaveMessageSchedulePopup";
import TXTImage_6 from '../../../images/img/message_txt_img_6.png';
import TXTImage_7 from '../../../images/img/message_txt_img_7.png';
import TXTImage_8 from '../../../images/img/message_txt_img_8.png';
import Background_1 from '../../../images/bg/message/background01.jpg';
import Background_2 from '../../../images/bg/message/background02.jpg';
import Background_3 from '../../../images/bg/message/background03.jpg';
import Background_4 from '../../../images/bg/message/background04.jpg';
import Background_5 from '../../../images/bg/message/background05.jpg';
import Background_6 from '../../../images/bg/message/background06.jpg';
import Background_7 from '../../../images/bg/message/background07.jpg';
import {toastr} from 'helper/toastrIntercept';
import Select from "../../../components/selectbox/Select";
import {useMISOpt} from "../../../components/misopt";
import {
    MESSAGE_SCHEDULE_MULTIPLE_REPEAT_TYPE,
    MESSAGE_SCHEDULE_NOT_SUPPORT_MULTIPLE_REPEAT_TYPE,
    scheduleConstants
} from "../../../constants";
import WeeklyContainer from "../content/WeeklyContainer";
import MonthlyContainer from "../content/MonthlyContainer";


const colorPickerStyle = {
    position: 'absolute',
    top: 115,
    left: 130,
    zIndex: 100
}

const MessageManager = ({mode, updateMessage, removeTab, destroyMessage}) => {

    const dispatch = useDispatch();

    const {misOptionSchedule} = useMISOpt();

    const {newMessage, editMessage, currentContentId} = useSelector(state => ({
        newMessage : state.schedules.messages.newMessage,
        editMessage: state.schedules.messages.editMessage,
        currentContentId: state.menu.currentContentId
    }));

    const [showConfirmPopup, setShowConfirmPopup] = useState({
        show : false
    });

    const [showSavePopup, setShowSavePopup] = useState({
        show: false
    });

    const [repeatType, setRepeatType] = useState('once');

    let message = {};
    if (mode === 'new') {
        message = newMessage;
    } else {
        message = editMessage;
    }

    const isSupportMultiple = useCallback(()=> {
        if (message !== undefined && message.message !== undefined) {
            const {deviceType, deviceTypeVersion} = message.message;
            const schedule = misOptionSchedule(deviceType.toUpperCase(), parseInt(deviceTypeVersion, 10));
            if (schedule !== undefined && schedule.messageSchedule !== undefined) {
                return schedule.messageSchedule.multiple;
            }
        }
        return false;

    }, [])

    const isSupportPositionLR = useCallback(()=> {
        if (message !== undefined && message.message !== undefined) {
            const {deviceType, deviceTypeVersion} = message.message;
            const schedule = misOptionSchedule(deviceType.toUpperCase(), parseInt(deviceTypeVersion, 10));
            if (schedule !== undefined && schedule.messageSchedule !== undefined) {
                return schedule.messageSchedule.positionLR;
            }
        }
        return false;
    },[]);

    const {t} = useTranslation();

    const neverExpireChk = useRef();
    const hours24Chk =  useRef();


    const [style, setStyle] = useState({
        messageWrap : {
            height: 500,
            marginTop: 1
        },
        message : {
            width:'auto',
            whiteSpace:'nowrap'
        },
        messageArea: {
            height: 35,
            lineHeight: '35px',
            padding: 10,
            display: 'inline-block',
            overflow: 'hidden',
            textAlign: 'initial',
            width: '100%',
            color: 'rgb(0, 0, 0)',
            position: 'absolute',
            marginTop: 130
        },
        startTimePicker: false,
        endTimePicker: false,
        endDatePicker: false
    });

    const [picker, setPicker] = useState({
        fontColorPicker: {
            show : false
        },
        bgColorPicker: {
            show: false
        }
    });

    const setMessageFontSizeMsgSch = (e, size) => {
        const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? ({...msg, size: size}) : msg)};
        dispatch(updateMessage(mode, oneMessage));
    }

    const setMessageBG = (e, bg) => {
        const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? ({...msg, bgImage: parseInt(bg)}) : msg)};
        dispatch(updateMessage(mode, oneMessage));
    }

    const setMessageBgImage = (e, imageNo) => {
        const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? ({...msg, bgImage: parseInt(imageNo)}) : msg)};
        dispatch(updateMessage(mode, oneMessage));
    }

    const setTextOption = option => {
        let oneMessage;
        switch (option) {
            case 'bold' :
                oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? ({...msg, bold: message.message.messageList[message.currentMessageIndex].bold === 'Y' ? 'N' : 'Y'}) : msg)};
                break;
            case 'italic':
                oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? ({...msg, italic: message.message.messageList[message.currentMessageIndex].italic === 'Y' ? 'N' : 'Y'}) : msg)};
                break;
            case 'underLine':
                oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? ({...msg, underline: message.message.messageList[message.currentMessageIndex].underline === 'Y' ? 'N' : 'Y'}) : msg)};
                break;
        }
        dispatch(updateMessage(mode, oneMessage));
    }

    const setMessageRepeatType = (e, type) => {
        let oneMessage
        oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, repeatType: type, monthdays: '', weekdays: ''} : msg)};
        dispatch(updateMessage(mode, oneMessage));
    }

    const setMessageDirection = (e, direction) => {
        const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, direction: direction} : msg)};
        dispatch(updateMessage(mode, oneMessage));
    }

    const setMessagePosition = (e, position) => {
        const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, position: position} : msg)};
        dispatch(updateMessage(mode, oneMessage));
    }

    const setMessageDate = (date, type) => {
        let oneMessage;
        if (type === 'start') {
            oneMessage = {
                ...message.message, 
                messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, startDate: Moment(date).format('YYYY-MM-DD'), endDate: (msg.repeatType=='once'?Moment(date).format('YYYY-MM-DD'):msg.endDate)} : msg)
            };
        } else {
            oneMessage = {
                ...message.message, 
                messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, endDate: Moment(date).format('YYYY-MM-DD'), startDate: (msg.repeatType=='once'?Moment(date).format('YYYY-MM-DD'):msg.startDate)} : msg)
            };
        }
        dispatch(updateMessage(mode, oneMessage));
    }

    const getTimeValue = time => {
        let rtn = Moment().toDate();
        time = time+"";

        if (time.indexOf(':') > -1) {
            const timeArray = time.split(':');
            rtn.setHours(timeArray[0]);
            rtn.setMinutes(timeArray[1]);
            rtn.setSeconds(timeArray[2]);
            rtn.setMilliseconds(0);
        }
        return rtn;
    }

    const getEndTimeValue = () => {
        const oneMessage = message.message.messageList[message.currentMessageIndex];
        const value = isSupportMultiple() ? Moment(getTimeValue(oneMessage.startTime)) : Moment().startOf('day');
        const time = value.add(oneMessage.duration, 'seconds');
        return time.toDate();
    }

    const handleNeverExpire = () => {
        const checked = neverExpireChk.current.checked;
        let endDate;
        let repeatType = message.message.messageList[message.currentMessageIndex].repeatType;
        if (checked) {
            endDate = '2999-12-31';
            if (repeatType === 'once') {
                repeatType = 'daily'
            }
        } else {
            if (repeatType !== 'once') {
                repeatType = 'once';
            }
            endDate = Moment().format('YYYY-MM-DD');
        }
        const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, endDate: endDate, repeatType: repeatType} : msg)};
        dispatch(updateMessage(mode, oneMessage));
    }

    const handle24Hours = (e) => {
        if (e.target.checked) {
            const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, startTime: '00:00:00', duration: 86399} : msg)};
            dispatch(updateMessage(mode, oneMessage));
        } else {
            const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, startTime: '00:00:00', duration: 7200} : msg)};
            dispatch(updateMessage(mode, oneMessage));
        }
    }

    const handleColorPicker = (type) => {
        if (type === 'color') {
            const {show} = picker.fontColorPicker.show;
            setPicker({...picker, fontColorPicker: {show : !show}});
        } else {
            const {show} = picker.bgColorPicker.show;
            setPicker({...picker, bgColorPicker: {show : !show}});
        }
    }

    const handleColorPickerClose = (type) => {
        if (type === 'color') {
            setPicker({...picker, fontColorPicker: {show : false}});
        } else {
            setPicker({...picker, bgColorPicker: {show : false }});
        }

    }

    const handleTimePicker = (value, type) => {
        if (type === 'start') {
            const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, startTime: value.format('HH:mm:ss')} : msg)};
            dispatch(updateMessage(mode, oneMessage));
        } else {
            const startTime = isSupportMultiple() ? Moment(getTimeValue(message.message.messageList[message.currentMessageIndex].startTime)) : Moment().startOf('day');
            const duration = value.diff(startTime, 'seconds');
            const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, duration: duration} : msg)};
            dispatch(updateMessage(mode, oneMessage));
        }
    }

    const updateText = event => {
        let value = event.target.value;
        if (value.length > 200) {
            toastr.error(t("MESSAGE_COMMON_MAX_200_P"));
            value  = value.substring(0, 200);
        }
        const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, messageText: value} : msg)};
        dispatch(updateMessage(mode, oneMessage));
    }

    const setUpdateDate = (day) => {
        const oneMessage = message.message.messageList[message.currentMessageIndex];
        let weekdays = oneMessage.weekdays;
        if (weekdays !== undefined) {
            let tempWeeks = [];
            if (weekdays !== ''){
                tempWeeks = Object.assign([], weekdays.split(','));
            }
            const index = tempWeeks.findIndex(value =>  {return value === day});
            if (index > -1) {
                tempWeeks = tempWeeks.filter(value=> value !== day);
            } else {
                tempWeeks.push(day);
            }
            weekdays = tempWeeks.toString();
        }
        const update = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, weekdays: weekdays} : msg)};
        dispatch(updateMessage(mode, update));
    }

    const setRepeatMonthly = (e, day) => {
        const oneMessage = message.message.messageList[message.currentMessageIndex];
        let monthlyDays = oneMessage.monthdays;
        if (monthlyDays !== undefined) {
            let tempMonths = [];
            if (monthlyDays !== ''){
                tempMonths = Object.assign([], monthlyDays.split(','));
            }
            const index = tempMonths.findIndex((value) => value === day);
            if (index > -1) {
                e.target.classList.remove('on');
                tempMonths = tempMonths.filter(value=> value !== day);
            } else {
                e.target.classList.add('on');
                tempMonths.push(day);
            }
            monthlyDays = tempMonths.toString();
        }
        const update = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, monthdays: monthlyDays} : msg)};
        dispatch(updateMessage(mode, update));
    }

    const handleMessageSpeed = (value) => {
        let speed = 'normal';
        switch(value) {
            case 10:
                speed = 'slow';
                break;
            case 20:
                speed = 'normal';
                break;
            case 30:
                speed = 'fast';
                break;
        }
        const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, speed: speed} : msg)};
        dispatch(updateMessage(mode, oneMessage));
    }


    const getSpeedNumberType = (value) => {
        let speed = 20;
        switch(value) {
            case 'slow':
                speed = 10;
                break;
            case 'normal':
                speed = 20;
                break;
            case 'fast':
                speed = 30
                break;
        }
        return speed
    }

    const onSavePopup = () => {
        const valid = () => {
            const moment = extendMoment(Moment);
            const messages = message.message.messageList;
            const timeArray = []
            for (const m of messages) {
                if (m.messageText === undefined || m.messageText === '') {
                    toastr.error(t('MIS_MESSAGE_SCHEDULE_ENTER_MESSAGE_P'));
                    return false;
                }
                const start = moment(m.startDate+' '+m.startTime);
                const endTime = moment(m.endDate+' 00:00:00');
                const end = endTime.clone().add(m.duration, 'seconds');

                if (timeArray.length > 0) {
                    const checkTimes = Object.assign([], timeArray);
                    for (const time of checkTimes) {
                        if (!time.contains(start) && !time.contains(end)) {
                            timeArray.push(moment.range(start, end));
                        } else {
                            toastr.error(t('MIS_MESSAGE_SCHEDULE_MSG_DUPLICATED_P'));
                            return false;
                        }
                    }
                } else {
                    timeArray.push(moment.range(start, end));
                }
                let startFormat = null;
                let stopFormat = null;
                if (m.repeatType !==  'once') {
                    startFormat = Moment(m.startDate+' '+m.startTime);
                    stopFormat = Moment(m.startDate+' 00:00:00').add(m.duration, 'seconds');
                } else {
                    startFormat = Moment(m.startDate+' '+m.startTime);
                    stopFormat = Moment(m.endDate+' 00:00:00').add(m.duration, 'seconds');
                }
                const tempDuration = (((stopFormat-startFormat)/1000));
                if (tempDuration < 1) {
                    toastr.error(t('MESSAGE_SCHEDULE_DURATION_TIME_MORE_ZERO_P'));
                    return false;
                }
            }
            return true;
        }
        if (valid()) {
            setShowSavePopup({show: !showSavePopup.show});
        }

    }

    const closeMessageManager = () => {
        setShowConfirmPopup({show: true});
    }

    const closeTab = () => {
        if (mode === 'new') {
            removeTab('NEW_MESSAGE');
        } else {
            removeTab('EDIT_MESSAGE');
        }
        destroyMessage(mode);
    }

    const cancelCloseTab = () => {
        setShowConfirmPopup({show: false});
    }

    const handleFontColorPicker = (color) => {
        const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, color: color.hex} : msg)};
        dispatch(updateMessage(mode, oneMessage));
    }

    const handleBgColorPicker = (color) => {
        const oneMessage = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, bgColor: color.hex} : msg)};
        dispatch(updateMessage(mode, oneMessage));
    }

    const updateStyle = () => {
        let oneMessage;
        if (mode === 'new') {
            oneMessage = newMessage.message.messageList[newMessage.currentMessageIndex];
        } else {
            oneMessage = editMessage.message.messageList[editMessage.currentMessageIndex];
        }

        setRepeatType(oneMessage.repeatType);

        let messageStyle = style;
        let {messageArea} = messageStyle;

        let fontSize;
        switch (oneMessage.size) {
            case 4: case '4':
                fontSize = '12px';
                break;
            case 6: case '6':
                fontSize = '18px';
                break;
            case 8: case '8':
                fontSize = '24px';
                break;
            default :
                fontSize = '12px';
        }
        messageStyle = {...messageStyle, message: {...messageStyle.message, fontSize: fontSize}}
        if (oneMessage.bold === 'Y') {
            messageStyle = {...messageStyle, message: {...messageStyle.message, fontWeight: 'bold'}}
        } else {
            messageStyle = {...messageStyle, message: {...messageStyle.message, fontWeight: undefined}}
        }
        if (oneMessage.italic === 'Y') {
            messageStyle = {...messageStyle, message: {...messageStyle.message, fontStyle: 'italic'}}
        } else {
            messageStyle = {...messageStyle, message: {...messageStyle.message, fontStyle: undefined}}
        }
        if (oneMessage.underline === 'Y') {
            messageStyle = {...messageStyle, message: {...messageStyle.message, textDecoration: 'underline'}}
        } else {
            messageStyle = {...messageStyle, message: {...messageStyle.message, textDecoration: undefined}}
        }

        switch (oneMessage.bgImage) {
            case 1: case "1":
                messageArea = {...messageArea, backgroundImage: 'url('+Background_1+')', backgroundSize: '100% 100%'};
                break;
            case 2: case "2":
                messageArea = {...messageArea, backgroundImage: 'url('+Background_2+')', backgroundSize: '100% 100%'};
                break;
            case 3: case "3":
                messageArea = {...messageArea, backgroundImage: 'url('+Background_3+')', backgroundSize: '100% 100%'};
                break;
            case 4: case "4":
                messageArea = {...messageArea, backgroundImage: 'url('+Background_4+')', backgroundSize: '100% 100%'};
                break;
            case 5: case "5":
                messageArea = {...messageArea, backgroundImage: 'url('+Background_5+')', backgroundSize: '100% 100%'};
                break;
            case 6: case "6":
                messageArea = {...messageArea, backgroundImage: 'url('+Background_6+')', backgroundSize: '100% 100%'};
                break;
            case 7: case "7":
                messageArea = {...messageArea, backgroundImage: 'url('+Background_7+')', backgroundSize: '100% 100%'};
                break;
            default :
                messageArea = {...messageArea, backgroundImage: undefined, backgroundSize: undefined};
                break;
        }

        let animationDuration = '10s';
        switch (oneMessage.speed) {
            case 'fast':
                animationDuration = '3s';
                break;
            case 'middle':
                animationDuration = '10s';
                break;
            case 'slow':
                animationDuration = '20s';
                break;
        }

        switch (oneMessage.direction) {
            case 'none':
                messageStyle = {...messageStyle, message: {...messageStyle.message, animationDuration: undefined, animationName: undefined, animationIterationCount: undefined}};
                break;
            case 'left':
                if (oneMessage.position !== 'left' && oneMessage.position !== 'right') {
                    messageStyle = {...messageStyle, message: {...messageStyle.message, animationDuration: animationDuration, animationName: 'slideLeft', animationIterationCount: 'infinite'}};
                } else {
                    messageStyle = {...messageStyle, message: {...messageStyle.message, animationDuration: animationDuration, animationName: 'slideLeftLeftRight', animationIterationCount: 'infinite'}};
                }
                break;
            case 'right':
                if (oneMessage.position !== 'left' && oneMessage.position !== 'right') {
                    messageStyle = {...messageStyle, message: {...messageStyle.message, animationDuration: animationDuration, animationName: 'slideRight', animationIterationCount: 'infinite'}};
                } else {
                    messageStyle = {...messageStyle, message: {...messageStyle.message, animationDuration: animationDuration, animationName: 'slideRightLeftRight', animationIterationCount: 'infinite'}};
                }

                break;
            case 'up':
                if (oneMessage.position !== 'left' && oneMessage.position !== 'right') {
                    messageStyle = {...messageStyle, message: {...messageStyle.message, animationDuration: animationDuration, animationName: 'slideTop', animationIterationCount: 'infinite'}};
                } else {
                    messageStyle = {...messageStyle, message: {...messageStyle.message, animationDuration: animationDuration, animationName: 'slideTopLeftRight', animationIterationCount: 'infinite'}};
                }
                break;
            case 'down':
                if (oneMessage.position !== 'left' && oneMessage.position !== 'right') {
                    messageStyle = {...messageStyle, message: {...messageStyle.message, animationDuration: animationDuration, animationName: 'slideBottom', animationIterationCount: 'infinite'}};
                } else {
                    messageStyle = {...messageStyle, message: {...messageStyle.message, animationDuration: animationDuration, animationName: 'slideBottomLeftRight', animationIterationCount: 'infinite'}};
                }
                break;
        }

        messageStyle = {...messageStyle, messageArea: {...messageArea, width:'100%', wordWrap:'none',whiteSpace:'nowrap', height:35, left : 'auto', right : 'auto', top : 'auto', bottom : 'auto', marginTop : 0}, message: {...messageStyle.message, whiteSpace: 'nowrap'}};
        //style.message
        switch (oneMessage.position) {
            case 'top':
                messageStyle = {...messageStyle, messageArea: {...messageStyle.messageArea,  marginTop: 0}};
                break;
            case 'middle':
                messageStyle = {...messageStyle, messageArea: {...messageStyle.messageArea, marginTop: 130}};
                break;
            case 'bottom':
                messageStyle = {...messageStyle, messageArea: {...messageStyle.messageArea, marginTop: 245}};
                break;
            case 'left':
                messageStyle = {...messageStyle, messageArea: {...messageStyle.messageArea, height: '100%', width: 0, wordWrap: 'break-word', left: 0}, message: {...messageStyle.message, whiteSpace: 'initial'}};

                break;
            case 'right':
                messageStyle = {...messageStyle, messageArea: {...messageStyle.messageArea, height: '100%', width: 0, wordWrap: 'break-word', right: 1}, message: {...messageStyle.message, whiteSpace: 'initial'}};
                break;
        }
        if (isSupportMultiple()) {

            if (oneMessage.endDate === '2999-12-31') {
                neverExpireChk.current.checked = true;
                messageStyle = {...messageStyle, endDatePicker: true}
            } else {
                neverExpireChk.current.checked = false;
                messageStyle = {...messageStyle, endDatePicker: false}
            }

            if (oneMessage.startTime === '00:00:00' && oneMessage.duration  === 86399) {
                hours24Chk.current.checked = true;
                messageStyle = {...messageStyle, startTimePicker: true};
                messageStyle = {...messageStyle, endTimePicker: true};
            } else {
                hours24Chk.current.checked = false;
                messageStyle = {...messageStyle, startTimePicker: false};
                messageStyle = {...messageStyle, endTimePicker: false};
            }
        }

        messageStyle = {...messageStyle, message: {...messageStyle.message, color: oneMessage.color}};
        messageStyle = {...messageStyle, messageArea: {...messageStyle.messageArea, backgroundColor: oneMessage.bgColor}};

        //height
        messageStyle = {...messageStyle, messageWrap: {...messageStyle.messageWrap, height : window.innerHeight - 160}};
        setStyle(messageStyle);
    }

    const onChangeWeekly = (e) => {
        const value = e.target.getAttribute('data-name');
        const oneMessage = message.message.messageList[message.currentMessageIndex];
        let weekdays = oneMessage.weekdays;
        if (weekdays !== undefined) {
            let tempWeeks = [];
            if (weekdays !== ''){
                tempWeeks = Object.assign([], weekdays.split(','));
            }
            const index = tempWeeks.findIndex(temp =>  {return temp.toUpperCase() === value.toUpperCase()});
            if (index > -1) {
                tempWeeks = tempWeeks.filter(temp=> temp !== value);
            } else {
                tempWeeks.push(value);
            }
            weekdays = tempWeeks.toString();
        }
        const update = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, weekdays: weekdays} : msg)};
        dispatch(updateMessage(mode, update));
    }

    const onChangeMonthly = (e) => {
        const value = parseInt(e.target.getAttribute('data-value'), 10);
        const oneMessage = message.message.messageList[message.currentMessageIndex];
        let monthlyDays = oneMessage.monthdays;
        if (monthlyDays !== undefined) {
            let tempMonths = [];
            if (monthlyDays !== ''){
                tempMonths = Object.assign([], monthlyDays.split(','));
            }
            const found = tempMonths.find((temp) => parseInt(temp) === value);
            if (found !== undefined) {
                tempMonths = tempMonths.filter(temp=> parseInt(temp) !== value);
            } else {
                tempMonths.push(value);
            }
            monthlyDays = tempMonths.toString();
        }
        const update = {...message.message, messageList: message.message.messageList.map((msg, index) => index === message.currentMessageIndex ? {...msg, monthdays: monthlyDays} : msg)};
        dispatch(updateMessage(mode, update));
    }

    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    useEffect(()=>{
        message = Object.assign({}, message);
    },[])

    useEffect(()=> {
        updateStyle();
    }, [newMessage, editMessage]);

    return(
        <div className={"message_schedule_manager_wrap"}
             style ={{display: (currentContentId === 'NEW_MESSAGE' && mode === scheduleConstants.CONTENT_SCHEDULE_NEW_MODE) || (currentContentId === 'EDIT_MESSAGE' && mode === scheduleConstants.CONTENT_SCHEDULE_EDIT_MODE) ? 'block':'none'}}
        >

            {
                showConfirmPopup.show &&
                <ConfirmPopup
                    title={t("COM_BUTTON_CONFIRM")}
                    message={t("MESSAGE_UPLOADER_REALLY_CANCEL_P")}
                    onClickYes={closeTab}
                    onClose={cancelCloseTab}

                />
            }

            {
                showSavePopup.show &&
                <SaveMessageSchedulePopup
                    mode={mode}
                    onClose={onSavePopup}
                    state={message.message}
                />
            }

            <div className="contents_buttonwrap mt20">
                <div className="float_l" id="leftButtonList">
                    <button className="base float_l mr8" style={{width:100}} id="messageSaveBtn" onClick={onSavePopup}>{t("COM_BUTTON_SAVE")}</button>
                    <button className="base float_l mr8" style={{width: 100}} id="messageCancelBtn" onClick={closeMessageManager}>{t("BUTTON_CANCEL_P")}</button>
                </div>
            </div>

            {
                message !== undefined &&
                <div className="contentsbox clearfix" id="messageWrap" style={style.messageWrap}>
                    <div id="createMessage1" value="" className="height100p" style={{overflowY: 'hidden'}}>
                        <div className="contents height100p" style={{padding:0}}>
                            <div className="schedule_message" style={{...style.messageWrap, background:'#f9fafb', height: 'auto'}}>
                                <h2>
                                    <button className="lnb_view"></button>
                                    {t("TEXT_MESSAGE_P")}
                                </h2>
                                <div>
                                    <div className="message_editerbox h_scroll">
                                        <div className="message_wrap">
                                            <div className="message_editer clearfix">
                                                <div className={"float_l"}>
                                                    <Select width={100} value={message.message.messageList[message.currentMessageIndex].size} onChange={setMessageFontSizeMsgSch}
                                                            selects={[
                                                                {id: 'message_schedule_size_small', value: "4", title: "DID_ADMIN_URGENT_SMALL"},
                                                                {id: 'message_schedule_size_medium', value: "6", title: "DID_ADMIN_URGENT_MEDIUM"},
                                                                {id: 'message_schedule_size_large', value: "8", title: "DID_ADMIN_URGENT_LARGE"}
                                                            ]}
                                                    />
                                                </div>
                                                <div className={"float_l fontColorPicker"}>

                                                    <div className="sp-replacer sp-light eventBgColor" onClick={()=>handleColorPicker('color')}>
                                                        <div className="sp-preview">
                                                            <div className="sp-preview-inner" style={{backgroundColor: message.message.messageList[message.currentMessageIndex].color}}></div>
                                                        </div>
                                                        <div className="sp-dd">▼</div>
                                                    </div>
                                                    { picker.fontColorPicker.show ?
                                                        <div style={colorPickerStyle} onClick={ ()=>handleColorPickerClose('color') }>
                                                            <div style={ cover } onClick={ ()=>handleColorPickerClose('color') }/>
                                                            <SketchPicker color={message.message.messageList[message.currentMessageIndex].color} onChange={(color)=>handleFontColorPicker(color)}  />
                                                        </div> : null }
                                                </div>
                                                <div className={"float_l"}>
                                                    <Select width={150} 
                                                            value={(message.message.messageList[message.currentMessageIndex].bgImage == null || message.message.messageList[message.currentMessageIndex].bgImage == '0')?'0':'1'} onChange={setMessageBG}
                                                            selects={[
                                                                {id: 'message_schedule_bg_color', value: '0', title: "COM_DID_ADMIN_DEVICE_FBASIC_BACKCOLOR"},
                                                                {id: 'message_schedule_bg_image', value: '1', title: "TEXT_BGIMAGE_P"}
                                                            ]}
                                                    />
                                                </div>
                                                {
                                                    (message.message.messageList[message.currentMessageIndex].bgImage == null || message.message.messageList[message.currentMessageIndex].bgImage.toString() === '0') 
                                                    &&
                                                    <div className={"float_l bgColorPicker"}>
                                                        <div className="sp-replacer sp-light eventBgColor" onClick={()=>handleColorPicker('bgColor')}>
                                                            <div className="sp-preview">
                                                                <div className="sp-preview-inner" style={{backgroundColor: message.message.messageList[message.currentMessageIndex].bgColor}}></div>
                                                            </div>
                                                            <div className="sp-dd">▼</div>
                                                        </div>
                                                        { picker.bgColorPicker.show ?
                                                            <div style={colorPickerStyle} onClick={ ()=>handleColorPickerClose('bgColor') }>
                                                                <div style={ cover } onClick={ ()=>handleColorPickerClose('color') }/>
                                                                <SketchPicker color={message.message.messageList[message.currentMessageIndex].bgColor === '' ? 'rgba(0, 0, 0, 0.1)' : message.message.messageList[message.currentMessageIndex].bgColor} onChange={(color)=>handleBgColorPicker(color)} />
                                                            </div> : null }
                                                    </div>
                                                }

                                                {
                                                    message.message.messageList[message.currentMessageIndex].bgImage > 0 &&
                                                    <div className={"float_l"}>
                                                        <Select width={150} value={message.message.messageList[message.currentMessageIndex].bgImage} multiLang={false} onChange={setMessageBgImage}
                                                                selects={[
                                                                    {id: 'message_schedule_bg_image_1', value: '1', title: t("TEXT_BGIMAGE_P")+" 1", multiLang: false},
                                                                    {id: 'message_schedule_bg_image_2', value: '2', title: t("TEXT_BGIMAGE_P")+" 2", multiLang: false},
                                                                    {id: 'message_schedule_bg_image_3', value: '3', title: t("TEXT_BGIMAGE_P")+" 3", multiLang: false},
                                                                    {id: 'message_schedule_bg_image_4', value: '4', title: t("TEXT_BGIMAGE_P")+" 4", multiLang: false},
                                                                    {id: 'message_schedule_bg_image_5', value: '5', title: t("TEXT_BGIMAGE_P")+" 5", multiLang: false},
                                                                    {id: 'message_schedule_bg_image_6', value: '6', title: t("TEXT_BGIMAGE_P")+" 6", multiLang: false},
                                                                    {id: 'message_schedule_bg_image_7', value: '7', title: t("TEXT_BGIMAGE_P")+" 7", multiLang: false},
                                                                ]}
                                                        />
                                                    </div>
                                                }

                                                <button className={classNames("base", "un_radius", "mr3", "float_l", {'on': message.message.messageList[message.currentMessageIndex].bold === 'Y'})} onClick={()=>setTextOption('bold')} >
                                                    <img src={TXTImage_6} />
                                                </button>
                                                <button className={classNames("base", "un_radius", "mr3", "float_l", {'on': message.message.messageList[message.currentMessageIndex].italic === 'Y'})} onClick={()=>setTextOption('italic')}>
                                                    <img src={TXTImage_7} />
                                                </button>
                                                <button className={classNames("base", "un_radius", "mr3", "float_l", {'on': message.message.messageList[message.currentMessageIndex].underline === 'Y'})} onClick={()=>setTextOption('underLine')}>
                                                    <img src={TXTImage_8} />
                                                </button>
                                            </div>
                                            <div className="message_area">
                                                <div style={style.messageArea} id="messageArea">
                                                    <div style={style.message}>

                                                        {message.message.messageList[message.currentMessageIndex].messageText}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="message_modify">
                                                <textarea onChange={updateText} value={message.message.messageList[message.currentMessageIndex].messageText}  />
                                            </div>
                                        </div>
                                        <div className="message_detail_setting">
                                            <table>
                                                <colgroup>
                                                    <col width="117px"/>
                                                    <col width=""/>
                                                </colgroup>
                                                <tbody>
                                                {
                                                    isSupportMultiple() &&
                                                    <tr className="tp20">
                                                        <th>{t("MIS_SID_20_PLAYBACK_DATE")}</th>
                                                        <td>
                                                            <div style={{display: 'inline-block'}}>

                                                                <DatePicker
                                                                    dateFormat="yyyy-MM-dd"
                                                                    selected={new Date(message.message.messageList[message.currentMessageIndex].startDate)}
                                                                    onChange={(date) => setMessageDate(date, 'start')}
                                                                    maxDate={new Date(message.message.messageList[message.currentMessageIndex].endDate)}
                                                                    width={120}
                                                                    disabled={false}
                                                                />

                                                                <span className="space mr10 float_l">&nbsp;~&nbsp;</span>

                                                                <DatePicker
                                                                    dateFormat="yyyy-MM-dd"
                                                                    selected={new Date(message.message.messageList[message.currentMessageIndex].endDate)}
                                                                    onChange={(date) => setMessageDate(date, 'end')}
                                                                    minDate={new Date(message.message.messageList[message.currentMessageIndex].startDate)}
                                                                    disabled={style.endDatePicker}
                                                                    width={120}
                                                                />

                                                                <Checkbox id={"messageScheduleNeverExpire"} name={t("MIS_SID_20_NEVER_EXPIRE")} onClick={()=>handleNeverExpire()} ref={neverExpireChk}/>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th>{t("TEXT_REPEAT_P")}</th>
                                                    <td>
                                                        <div style={{display: 'flex'}}>
                                                            <Select width={150} value={repeatType} onChange={setMessageRepeatType}
                                                                    selects={
                                                                        isSupportMultiple() ? MESSAGE_SCHEDULE_MULTIPLE_REPEAT_TYPE : MESSAGE_SCHEDULE_NOT_SUPPORT_MULTIPLE_REPEAT_TYPE}
                                                            />

                                                            {
                                                                message.message.messageList[message.currentMessageIndex].repeatType === 'day_of_week'
                                                                &&
                                                                <WeeklyContainer checked={message.message.messageList[message.currentMessageIndex].weekdays} onClick={onChangeWeekly}/>
                                                            }

                                                            {
                                                                message.message.messageList[message.currentMessageIndex].repeatType === 'day_of_month'
                                                                &&
                                                                <MonthlyContainer checked={message.message.messageList[message.currentMessageIndex].monthdays} onClick={onChangeMonthly}/>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr className="line">
                                                    <th>{t("MIS_SID_20_PLAYBACK_TIME")}</th>
                                                    <td>

                                                        {
                                                            isSupportMultiple() ?
                                                                <>
                                                                    <TimePicker
                                                                        showSecond={true}
                                                                        value={Moment(getTimeValue(message.message.messageList[message.currentMessageIndex].startTime))}
                                                                        className="scheduleTime"
                                                                        format={'HH:mm:ss'}
                                                                        inputReadOnly
                                                                        disabled={style.startTimePicker}
                                                                        allowEmpty={false}
                                                                        onChange={(value)=>handleTimePicker(value, 'start')}
                                                                    />
                                                                    ~
                                                                    <TimePicker
                                                                        showSecond={true}
                                                                        value={Moment(getEndTimeValue())}
                                                                        className="scheduleTime"
                                                                        format={'HH:mm:ss'}
                                                                        inputReadOnly
                                                                        allowEmpty={false}
                                                                        disabled={style.endTimePicker}
                                                                        onChange={(value)=>handleTimePicker(value, 'end')}
                                                                    />

                                                                    <Checkbox id={"messageSchedule24Hour"} name={t("COM_IDS_STR_24_HOURS")} onClick={handle24Hours} ref={hours24Chk}/>
                                                                </>
                                                                :
                                                                <>
                                                                    <TimePicker
                                                                        showSecond={true}
                                                                        value={Moment(getEndTimeValue())}
                                                                        className="scheduleTime"
                                                                        format={'HH:mm:ss'}
                                                                        inputReadOnly
                                                                        disabled={style.startTimePicker}
                                                                        allowEmpty={false}
                                                                        onChange={(value)=>handleTimePicker(value, 'end')}
                                                                    />
                                                                </>
                                                        }

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("MIS_SID_20_FLOW_EFFECT")}</th>
                                                    <td><span className="space float_l mr15">{t("TEXT_DIRECTION_P")}</span>
                                                        <div className={"float_l"}>
                                                            <Select width={150} value={message.message.messageList[message.currentMessageIndex].direction} onChange={setMessageDirection}
                                                                    selects={[
                                                                        {id: 'message_flow_effect_none', value: 'none', title: "TEXT_NONE_P"},
                                                                        {id: 'message_flow_effect_up', value: 'up', title: "TEXT_SHIFT_TO_THE_TOP_P"},
                                                                        {id: 'message_flow_effect_down', value: 'down', title: "TEXT_SHIFT_TO_THE_BOTTOM_P"},
                                                                        {id: 'message_flow_effect_left', value: 'left', title: "TEXT_SHIFT_TO_THE_LEFT_P"},
                                                                        {id: 'message_flow_effect_right', value: 'right', title: "TEXT_SHIFT_TO_THE_RIGHT_P"},
                                                                    ]}
                                                            />
                                                        </div>

                                                        <div className={"float_l"} style={{marginLeft: 15}}>
                                                            <span className="space float_l mr3">{t("COM_DID_ADMIN_URGENT_SPEED")}</span>
                                                        </div>
                                                        <div className="float_l" style={{width: 150, marginLeft: 10}}>
                                                            <Slider
                                                                min={10}
                                                                defaultValue={20}
                                                                value={getSpeedNumberType(message.message.messageList[message.currentMessageIndex].speed)}
                                                                max={30}
                                                                marks={{10: t("TEXT_SLOW_P"), 20: t("TEXT_NORMAL_P"), 30: t("TEXT_FAST_P")}}
                                                                step={null}
                                                                onChange={(value)=>handleMessageSpeed(value)}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("TEXT_POSITION_P")}</th>
                                                    <td>
                                                    {
                                                        isSupportPositionLR()?
                                                        <div className={"float_l"}>
                                                            <Select width={150} value={message.message.messageList[message.currentMessageIndex].position} onChange={setMessagePosition}
                                                                    selects={[
                                                                        {id: 'message_flow_position_top', value: 'top', title: "COM_DID_ADMIN_URGENT_UPPER"},
                                                                        {id: 'message_flow_position_middle', value: 'middle', title: "COM_TEXT_MIDDLE_P"},
                                                                        {id: 'message_flow_position_bottom', value: 'bottom', title: "TEXT_BOTTOM_P"},
                                                                        {id: 'message_flow_position_left', value: 'left', title: 'TEXT_LEFT_P'},
                                                                        {id: 'message_flow_position_right', value: 'right', title: 'TEXT_RIGHT_P'}
                                                                    ]}
                                                            />
                                                        </div>
                                                        :
                                                        <div className={"float_l"}>
                                                            <Select width={150} value={message.message.messageList[message.currentMessageIndex].position} onChange={setMessagePosition}
                                                                    selects={[
                                                                        {id: 'message_flow_position_top', value: 'top', title: "COM_DID_ADMIN_URGENT_UPPER"},
                                                                        {id: 'message_flow_position_middle', value: 'middle', title: "COM_TEXT_MIDDLE_P"},
                                                                        {id: 'message_flow_position_bottom', value: 'bottom', title: "TEXT_BOTTOM_P"}
                                                                    ]}
                                                            />
                                                        </div>
                                                    }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th colSpan="2"><span className="warring">
                                                    {t("MIS_MESSAGE_SCHEDULE_MSG_LOCATION_CONSTRAINTS_P")}
                                                </span>
                                                    </th>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )


}
export default connect(
    null,
    dispatch => ({
        removeTab: id => dispatch(menuAction.removeTab(id)),
        addPopup: popup => dispatch(popupAction.addPopup(popup)),
        updatePopup: popup => dispatch(popupAction.updatePopup(popup)),
        closePopup: popupId => dispatch(popupAction.closePopup(popupId)),
        setRemoveIndex: (type, index) =>  dispatch(scheduleAction.setRemoveIndex(type, index)),
        loadMessage: (type, index) => dispatch(scheduleAction.loadMessage(type, index)),
        updateMessage: (type, message) => dispatch(scheduleAction.updateMessage(type, message)),
        destroyMessage: (type) => dispatch(scheduleAction.destroyMessage(type))
    })
)(MessageManager);