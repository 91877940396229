import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../../MISDialog';
import RadioGroup from "../../../radio/RadioGroup";
import Checkbox from "../../../checkbox/Checkbox";
import isEmpty from "lodash/isEmpty";
import "./PrivacyPolicyPopup.css";
import {getLocationSID} from "../../../../constants";

const PrivacyPolicyPopup = ({ privacyPolicySettings, privacyPolicyList, onClose, onSave}) => {

    const {t} = useTranslation();

    const [privacyPolicy, setPrivacyPolicy] = useState(()=> ({
        location : privacyPolicySettings.privacyPolicyLocation,
        enable : privacyPolicySettings.privacyPolicyEnable,
        step : privacyPolicySettings.privacyPolicyLocation === null || isEmpty(privacyPolicySettings.privacyPolicyLocation) ? 0 : 1,
        invisibleAgree : false,
        policy : ''
    }));

    let locationAgreeType = [];
    let locationData = [];
    privacyPolicyList.forEach(key => {
        let data = {};

        data.title = t(getLocationSID(key.location));
        data.value = key.location;

        locationData.push(data);

        if(key.policyAgree === null && key.policyNoticeAgree === null)
        {
            locationAgreeType[key.location] = false;
        }else{
            locationAgreeType[key.location] = true;
        }
    })

    const onChangeLocation = (e, value) => {
        setPrivacyPolicy({...privacyPolicy, location:value, enable:false});
    };

    const getLocationList =() =>{
        return (

            <>
            <div className="location_area">
                <div className="locationTitle">
                    {t('TABLE_LOCATION_P')}
                </div>
                <div className="separate_line"></div>
                <div className="locationList">
                    <RadioGroup propertyName='radioPrivacyPolicyLocation' selects={locationData}
                                value={privacyPolicy.location} onChange={onChangeLocation}/>
                </div>

            </div>

            </>
        )
    }

    const onChangeAgree = (e) => {
        setPrivacyPolicy({...privacyPolicy, enable:e.target.checked});
    }

    const onPrevious= (e) => {
        setPrivacyPolicy({...privacyPolicy, step:0});
    }
    const onNext= (e) => {
        setPrivacyPolicy({...privacyPolicy, step:1});
    }
    const onClickSave = () => {
        privacyPolicySettings.privacyPolicyEnable = privacyPolicy.enable;
        privacyPolicySettings.privacyPolicyLocation = privacyPolicy.location;
        onSave(privacyPolicySettings);
    }

    const getPolicy =() =>{
        //let policy = "";
        for (const pp of privacyPolicyList) {
            if (pp.location === privacyPolicy.location) {

                privacyPolicy.policy = pp.policyAgree;

                if (privacyPolicy.policy === null) {
                    privacyPolicy.policy = pp.policyNoticeAgree;
                }

                if (privacyPolicy.policy === null) {
                    privacyPolicy.policy = pp.policyNotice;
                }
                break;
            }
        }
        return (
            <div>
                <div dangerouslySetInnerHTML={ {__html: privacyPolicy.policy} } className="pp_area"/>
                <div className="pp_agree_area">
                {
                    locationAgreeType[privacyPolicy.location] &&
                    <Checkbox name={t('COM_BDP_SID_INIT_SETTINGS_SMART_HUB_TNC_COND4_DESCRIPTION')}  id={'privacyPolicyAgreeCheckbox'} propertyName={'privacyPolicyAgreeCheckbox'} checked={privacyPolicy.enable}
                             onChange={onChangeAgree}/>
                }
                </div>
            </div>
        )
    }

    const getButtons =() =>{
        if (privacyPolicy.step === 0) {
            return [{
                id: 'NEXT',
                title: t('COM_BUTTON_NEXT'),
                disable: privacyPolicy.location === null,
                onClick: onNext,
            }];
        }

        if(privacyPolicy.step === 1 && !locationAgreeType[privacyPolicy.location]){
            return [
                {
                    id: 'PREVIOUS',
                    title: t('COM_SID_PREVIOUS'),
                    onClick: onPrevious,
                }
                ,
                {
                    id: 'DETAIL_CLOSE',
                    title: t('COM_BUTTON_CLOSE_P'),
                    onClick: ()=> onClickSave(),
                }
            ];
        }

        if(privacyPolicy.step === 1 && locationAgreeType[privacyPolicy.location]){
            return [
                {
                    id: 'PREVIOUS',
                    title: t('COM_SID_PREVIOUS'),
                    onClick: onPrevious,
                }
                ,
                {
                    id: 'SAVE',
                    title: t('COM_BUTTON_SAVE'),
                    onClick: ()=> onClickSave(),
                }

            ];
        }

    }



    return (
        <div className='device_memo_popup'>
            <MISDialog
                dialog={{
                    title: t('COM_TV_SID_PRIVACY_POLICY'),
                    isDraggable: false,
                    closeOnEscape: true,
                    modal: true,
                    width: 540,
                    height: 328,
                    onClose: onClose,
                }}
                buttons={{
                    rightButtons : getButtons()
                }}>
                <div className="detail_view mini_p" style={{width:530, height: 246}}>
                    {
                        privacyPolicy.step == 0? getLocationList() : getPolicy()
                    }
                </div>
            </MISDialog>
        </div>
    );
};

export default PrivacyPolicyPopup;