import React, {useEffect, useState} from 'react';
import {scheduleService} from "../../../../services";
import {useDispatch} from "react-redux";
import {menuAction, popupAction, scheduleAction} from "../../../../actions";
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import SubTab from "../../../tab/SubTab";
import ScheduleViewCalendar from "../ScheduleViewCalendar";
import ContentScheduleDetail from "./ContentScheduleDetail";
import ContentScheduleContents from "./ContentScheduleContents";
import {convertContentScheduleFromServer} from "../../../../helper/schedule/scheduleUtils";
import {getErrorMessage} from "../../../../helper/responseHandler";
import {commonConstants, CONTENT_SCHEDULE_PROGRAM_TYPE_AD, scheduleConstants} from "../../../../constants";
import './ContentScheduleDetailView.css'
import {useMISOpt} from "../../../misopt";
import {toastr} from 'helper/toastrIntercept';

const subTabs = [
    'BUTTON_DETAIL_P',
    'DID_ADMIN_NOTICE_CONTENT'
];

const ContentScheduleDetailView  = ({id,zIndex}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {getAuthority} = useMISOpt();

    const [isFetching, setIsFetching] = useState(false);
    const [program, setProgram] = useState(undefined);
    const [subTab, setSubTab] = useState(subTabs[0]);
    const [authority, setAuthority] = useState({});

    const updateProgram = (updatedProgram) => {
        setProgram(updatedProgram);
    }

    const editProgram = () => {
        scheduleService.fetchContentScheduleById(id).then(
            res => {
                const program = convertContentScheduleFromServer(res.items);
                const tab = {id: program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? 'EDIT_ADVERTISEMENT' : 'EDIT_SCHEDULE', title: program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? t('COM_DID_LFD_ADVERTISEMENT') : t("TEXT_EDIT_SCHEDULE_P"), active: true, close: true};

                new Promise(()=> {
                    if (program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD) {
                        dispatch(scheduleAction.initADSchedule(scheduleConstants.CONTENT_SCHEDULE_EDIT_MODE, {deviceType: res.items.deviceType, deviceTypeVersion: res.items.deviceTypeVersion}, program.programType, program))
                    } else {
                        dispatch(scheduleAction.initContentSchedule('edit', {deviceType: res.items.deviceType, deviceTypeVersion: res.items.deviceTypeVersion}, program.programType, program))
                    }
                }).then(
                    dispatch(menuAction.addTab(tab))
                ).then(
                    program.programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? dispatch(menuAction.loadContent('EDIT_ADVERTISEMENT')) : dispatch(menuAction.loadContent('EDIT_SCHEDULE'))
                )
            }
        ).catch(
            e=> toastr.error(getErrorMessage(e))
        ).finally(
            dispatch(popupAction.closePopup(id))
        )
    }

    const closePopup = (id) => {
        dispatch(popupAction.closePopup(id))
    }

    const onClickSubTab = (id) => {
        setSubTab(id)
    };

    const saveContentSchedule = () => {
        const {programId, programName} = program;
        if(programName == null || programName == ''){
            toastr.error(t("MESSAGE_SCHEDULE_ENTER_SCH_NAME_P"));
            return;
        }
        if(programName.trim() === ''){                                  
            toastr.error(t("MESSAGE_COMMON_CANT_NAME_SPACE_P"));
            return;
        } 
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}))
        scheduleService.updateContentScheduleNameGroup(programId, program)
            .then(
                res => {
                    if (res) {
                        toastr.success(t("TEXT_SUCCESS_P"))
                        closePopup(programId)
                    }
                }
            )
            .catch(
                e=> toastr.error(getErrorMessage(e))
            )
            .finally(()=> {
                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP))
                    dispatch(menuAction.forceReloadContent('ALL_CONTENT_SCHEDULE'));
                })
    }

    const dialogProps = {title: program !== undefined ? program.programName : '',isDraggable: true, closeOnEscape : true, allowMinimize : true, width :900, height :480, position: {x: -300, y: -400}, zIndex: zIndex, popupId:id, onClose: () => closePopup(id)};

    const buttons = {
        rightButtons: [
            {id: 'EDIT_CONTENT_SCHEDULE', title: t("TEXT_EDIT_SCHEDULE_P"), onClick: ()=> editProgram(), authority: authority.CREATE || authority.MANAGE},
            {
                id: 'SAVE_N_PUBLISH_SCHEDULE',
                title: t("COM_BUTTON_SAVE"),
                onClick: () => saveContentSchedule(),
                authority: authority.CREATE || authority.MANAGE
            },
            {id: 'CLOSE_POPUP', title: t("BUTTON_CANCEL_P"), onClick: () => closePopup(program.programId)}
        ]
    }

    useEffect(()=> {
        dispatch(popupAction.addPopup({type: commonConstants.LOADING_POPUP, id: commonConstants.LOADING_POPUP}))
        scheduleService.fetchContentScheduleById(id)
        .then(res=> {
            setProgram({...res.items});
            setIsFetching(true)
        })
        .catch(e=> toastr.error(getErrorMessage(e)))
        .finally(
            dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP))
        )
        setAuthority(getAuthority('SCHEDULE'));

    }, [])


    return (
        program !== undefined &&
         <MISDialog
            dialog={dialogProps}
            buttons={buttons}
            >

                <div className="popup_contents clearfix content_schedule_detail_view">
                    <div className="playlist_imgview mt25" style={{width:400}}>
                        <ScheduleViewCalendar type={"CONTENT"} program={program}/>
                    </div>
                    <div className="playlist_detail ml25 mb5" style={{width:'45%'}}>
                        <SubTab tabs={subTabs} selected={subTab} viewSize={2} onClick={onClickSubTab} />
                        { subTab === subTabs[0] && <ContentScheduleDetail program={program} updateProgram={updateProgram}/> }
                        { subTab === subTabs[1] && <ContentScheduleContents program={program} />}
                    </div>
                </div>

        </MISDialog>
    )

}
export default ContentScheduleDetailView;