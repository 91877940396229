
export const SPLAYER = 'SPLAYER';
export const LPLAYER = 'LPLAYER';
export const WPLAYER = 'WPLAYER';
export const SIGNAGE = 'SIGNAGE';
export const SIGNAGE_CHILD = "SIG_CHILD";
export const RSIGNAGE = 'RSIGNAGE';
export const LEDBOX = 'LEDBOX';
export const RLEDBOX = 'RLEDBOX';
export const IPLAYER = 'iPLAYER';
export const THIRDPLAYER = '3rdPartyPLAYER';
export const APLAYER = 'APLAYER';
export const RSPLAYER = 'RSPLAYER';
export const RIPLAYER = 'RIPLAYER';
export const FLIP = 'FLIP';

export const DEVICE_TYPE_I = "I Player";
export const DEVICE_TYPE_S = "S Player";
export const DEVICE_TYPE_LITE = "Lite Player";
export const DEVICE_TYPE_SIGNAGE = "Signage Player";
export const DEVICE_TYPE_LEDBOX = "LEDBOX";
export const DEVICE_TYPE_RM = "RM Player";
export const DEVICE_TYPE_ANDROID = "Android Player";
export const DEVICE_TYPE_FLIP = "Flip";
export const DEVICE_TYPE_WPLAYER = "W Player";

export const DEVICE_PAGE_SIZE_OPTIONS = [30, 50, 100, 200, 500, 1000];

export const ALL_DEVICE_SUB_TABS = [
    'COM_TEXT_INFORMATION_P',
    'TEXT_TIME_P',
    'TEXT_SETUP_P',
    'MIS_TEXT_DISPLAY_CONTROL_P',
    'COM_SID_SECURITY',
];

export const DEVICE_DETAIL_SUB_TABS = [
    'TEXT_TITLE_DEVICE_INFO_P',
    'MIS_SID_CONNECTED_DEVICE_INFO',
    'TEXT_PLAYING_CONTENT_P',
    'TEXT_TITLE_CONTENT_DOWNLOAD_STATUS_P',
    'MIS_TEXT_SYSTEM_USAGE_P',
    'COM_SID_DEVICE_HEALTH',
    'MIS_TEXT_TAG_P',
    'MIS_SID_20_LOG_DATA',
];

export const DEVICE_UPDATE_SUB_TABS = [
    'COM_TEXT_INFORMATION_P',
    'TEXT_TIME_P',
    'TEXT_SETUP_P',
    'MIS_TEXT_DISPLAY_CONTROL_P',
    'COM_SID_SECURITY',
];

export const DEVICE_UPDATE_SUB_TABS_FOR_CHILD = [
    'COM_TEXT_INFORMATION_P',
    'MIS_TEXT_DISPLAY_CONTROL_P',
];

export const DEVICE_HEALTH_SUB_TABS = [
    'HW',
    'SW'
];

export const DEVICE_SCHEDULES_TO_EXPIRES_SUB_TABS = [
    'TEXT_TITLE_SCHEDULE_P',
    'TEXT_DEVICE_P'
];

export const DEVICE_ACTIONS = {
    LOAD_LEDCABINET: 'LOAD_LEDCABINET',
    REFRESH_DEVICES: 'REFRESH_DEVICES',
    SOFTWARE_DOWNLOADING: 'SOFTWARE_DOWNLOADING',
    CUSTOMIZE_DOWNLOADING: 'CUSTOMIZE_DOWNLOADING'
};

export const DEVICE_EXPORT_OPTIONS = [
    {value: 'EXCEL', title: 'COM_TEXT_EXCEL_P'},
    {value: 'PDF', title: 'DID_LAYOUTS_COMMON_PDF'},
];

export const DEVICE_VWL_LAYOUT_OPTIONS = [
    {value: 'SETTING', title: 'COM_TEXT_SETTING_P', sub: [{value: 'SETTING_NEW', title: 'TEXT_NEW_P'}, {value: 'SETTING_OPEN', title: 'COM_MIS_TEXT_OPEN_P'}]},
    {value: 'PREVIEW', title: 'COM_DID_VWL_PREVIEW'},
    {value: 'CANCEL', title: 'BUTTON_CANCEL_P'},
];

export const DEVICE_CUSTOM_FILES_OPTIONS = {
    customLogo: {
        acceptFiles: '.jpg,.jpeg,.bmp,.png,.gif',
        acceptFilesVideo: '.avi,.mpg,.mpeg,.mp4,.vob,.vro,.ts,.trp,.tp,.svi,.mkv,.wmv,.asf,.3gp,.divx,.flv,.m2ts,.mov,.rm,.rmvb,.mts,.webm,.s4ud,.ps,.avs,.um4,.km4',
        maxSize: 1024 * 1024 * 50, // 50MB
        maxSizeVideo: 1024 * 1024 * 150, // 150MB
    },
    defaultContent: {
        acceptFiles: '',
		acceptFilesVideo: '.wmv,.vro,.vob,.ts,.trp,.tp,.svi,.mts,.mpg,.mpeg,.mp4,.mov,.mkv,.m2ts,.flv,.divx,.avi,.asf,.3gp',
		maxSize: 1024 * 1024 * 1024, // 1GB
		maxSizeVideo: 1024 * 1024 * 1024, // 1GB
    },
};

export const DEVICE_TYPES = {
    'iPLAYER': {deviceType: 'IPLAYER', deviceTypeVersion: 3},
    'SPLAYER': {deviceType: 'SPLAYER', deviceTypeVersion: 1},
    'S2PLAYER': {deviceType: 'SPLAYER', deviceTypeVersion: 2},
    'S3PLAYER': {deviceType: 'SPLAYER', deviceTypeVersion: 3},
    'S4PLAYER': {deviceType: 'SPLAYER', deviceTypeVersion: 4},
    'S5PLAYER': {deviceType: 'SPLAYER', deviceTypeVersion: 5},
    'S6PLAYER': {deviceType: 'SPLAYER', deviceTypeVersion: 6},
    'S7PLAYER': {deviceType: 'SPLAYER', deviceTypeVersion: 7},
    'S8PLAYER': {deviceType: 'SPLAYER', deviceTypeVersion: 8},
    'MPLAYER': {deviceType: 'MPLAYER', deviceTypeVersion: 1},
    'LPLAYER': {deviceType: 'LPLAYER', deviceTypeVersion: 1},
    '3rdPartyPLAYER': {deviceType: '3rdPartyPLAYER', deviceTypeVersion: 1},
    'SIGNAGE': {deviceType: 'SIGNAGE', deviceTypeVersion: 1},
    'SIGNAGE3':{deviceType: 'SIGNAGE', deviceTypeVersion: 3},
    'SIGNAGE4':{deviceType: 'SIGNAGE', deviceTypeVersion: 4},
    'SIGNAGE6':{deviceType: 'SIGNAGE', deviceTypeVersion: 6},
    'LEDBOX':{deviceType: 'LEDBOX', deviceTypeVersion: 1},
    'LEDBOX4':{deviceType: 'LEDBOX', deviceTypeVersion: 4},
    'LEDBOX6':{deviceType: 'LEDBOX', deviceTypeVersion: 6},
    'APLAYER':{deviceType: 'APLAYER', deviceTypeVersion: 1},
    'WPLAYER':{deviceType: 'WPLAYER', deviceTypeVersion: 1},
    'RIPLAYER':{deviceType: 'RIPLAYER', deviceTypeVersion: 1},
    'RSPLAYER':{deviceType: 'RSPLAYER', deviceTypeVersion: 1},
    'RSPLAYER4':{deviceType: 'RSPLAYER', deviceTypeVersion: 4},
    'RSPLAYER5':{deviceType: 'RSPLAYER', deviceTypeVersion: 5},
    'RSPLAYER6':{deviceType: 'RSPLAYER', deviceTypeVersion: 6},
    'RSPLAYER7':{deviceType: 'RSPLAYER', deviceTypeVersion: 7},
    'RSPLAYER8':{deviceType: 'RSPLAYER', deviceTypeVersion: 8},
    'FLIP':{deviceType: 'FLIP', deviceTypeVersion: 1},
    'FLIP2':{deviceType: 'FLIP', deviceTypeVersion: 2},
    'RLEDBOX':{deviceType: 'RLEDBOX', deviceTypeVersion: 1},
    'RLEDBOX4':{deviceType: 'RLEDBOX', deviceTypeVersion: 4},
    'RLEDBOX6':{deviceType: 'RLEDBOX', deviceTypeVersion: 6},

    'RSIGNAGE':{deviceType: 'RSIGNAGE', deviceTypeVersion: 1},
    'RSIGNAGE4':{deviceType: 'RSIGNAGE', deviceTypeVersion: 4},
    'RSIGNAGE6':{deviceType: 'RSIGNAGE', deviceTypeVersion: 6},

}


export const Devices = [

    {deviceType: FLIP, deviceTypeVersion: 2, priority: -50, text: 'Flip2', contentSchedule: false, messageSchedule: false, eventSchedule: false, playlist: false, ruleset: false, className:'flip2', scheduleInfo: {}},
    {deviceType: FLIP, deviceTypeVersion: 1, priority: -48, text: 'Flip', contentSchedule: false, messageSchedule: false, eventSchedule: false, playlist: false, ruleset: false, className:'flip', scheduleInfo: {}},
    {deviceType: RIPLAYER, deviceTypeVersion: 3, priority: -3, text: 'RM3', contentSchedule: false, messageSchedule: false, eventSchedule: false, playlist: false, ruleset: false, className:'rm3', scheduleInfo: {}},
    {deviceType: RIPLAYER, deviceTypeVersion: 2, priority: -3, text: 'RM2', contentSchedule: false, messageSchedule: false, eventSchedule: false, playlist: false, ruleset: false, className:'rm2', scheduleInfo: {}},
    {deviceType: RIPLAYER, deviceTypeVersion: 1, priority: -3, text: 'RM', contentSchedule: false, messageSchedule: false, eventSchedule: false, playlist: false, ruleset: false, className:'rm', scheduleInfo: {}},
    {deviceType: APLAYER, deviceTypeVersion: 1, priority: -1, text: 'A Player', contentSchedule: true, messageSchedule: true, eventSchedule: true, playlist: true, ruleset: false, className:'a_player', scheduleInfo: {contentSchedule: {supportVwl: false, supportSync: false, supportAd: false, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},

    /*{deviceType: LPLAYER, deviceTypeVersion: 1, priority: 1, text: 'Lite', contentSchedule: true, playlist: true},
    {deviceType: LPLAYER, deviceTypeVersion: 2, priority: 1, text: 'Lite', contentSchedule: true, playlist: true},*/
    {deviceType: LPLAYER, deviceTypeVersion: 3, priority: 1, text: 'Lite', contentSchedule: true, messageSchedule: true, eventSchedule: false, playlist: true, ruleset: false, className:'lite', scheduleInfo: {contentSchedule: {supportVwl: false, supportSync: false, supportAd: false, channel: false, frame: false}, messageSchedule: {multiple: false}, eventSchedule: {multiple: false}}},
    {deviceType: SPLAYER, deviceTypeVersion: 1, priority: 5, text: 'S1', contentSchedule: true, messageSchedule: true, eventSchedule: false, playlist: true, ruleset: false, className:'s_player', scheduleInfo: {contentSchedule: {supportVwl: false, supportSync: false, supportAd: false, channel: false, frame: false}, messageSchedule: {multiple: false}, eventSchedule: {multiple: false}}},
    {deviceType: SPLAYER, deviceTypeVersion: 2, priority: 10, text: 'S2', contentSchedule: true, messageSchedule: true, eventSchedule: true, playlist: true, ruleset: false, className:'s_player2', scheduleInfo: {contentSchedule: {supportVwl: true, supportSync: true, supportAd: true, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},
    {deviceType: SPLAYER, deviceTypeVersion: 3, priority: 20, text: 'S3', contentSchedule: true, playlist: true, className: 's_player3'},

    {deviceType: SIGNAGE, deviceTypeVersion: 1, priority: 20, text: 'Signage', contentSchedule: false, messageSchedule: true, eventSchedule: true, playlist: false, ruleset: false, className: 'signage', scheduleInfo: {contentSchedule: {supportVwl: false, supportSync: false, supportAd: false, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},
    {deviceType: SIGNAGE, deviceTypeVersion: 3, priority: 20, text: 'Signage2', contentSchedule: false, messageSchedule: true, eventSchedule: true, playlist: false, ruleset: false, className: 'signage2', scheduleInfo: {contentSchedule: {supportVwl: true, supportSync: true, supportAd: true, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},

    {deviceType: SPLAYER, deviceTypeVersion: 4, priority: 50, text: 'S4', contentSchedule: true, messageSchedule: true, eventSchedule: true, playlist: true, ruleset: false, className: 's_player4', scheduleInfo: {contentSchedule: {supportVwl: true, supportSync: true, supportAd: true, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},
    {deviceType: SIGNAGE, deviceTypeVersion: 4, priority: 50, text: 'Signage4', contentSchedule: false, messageSchedule: true, eventSchedule: true, playlist: false, ruleset: false, className: 'signage4', scheduleInfo: {contentSchedule: {supportVwl: true, supportSync: true, supportAd: true, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},
    {deviceType: LEDBOX, deviceTypeVersion: 4, priority: 50, text: 'LED4', contentSchedule: false, messageSchedule: false, eventSchedule: false, playlist: false, ruleset: false, className: 'led4', scheduleInfo: {contentSchedule: {supportVwl: true, supportSync: true, supportAd: true, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},

    {deviceType: SPLAYER, deviceTypeVersion: 5, priority: 60, text: 'S5', contentSchedule: true, messageSchedule: true, eventSchedule: true, playlist: true, ruleset: false, className: 's_player5', scheduleInfo: {contentSchedule: {supportVwl: true, supportSync: true, supportAd: true, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},
    {deviceType: SPLAYER, deviceTypeVersion: 6, priority: 70, text: 'S6', contentSchedule: true, messageSchedule: true, eventSchedule: true, playlist: true, ruleset: true, className: 's_player6', scheduleInfo: {contentSchedule: {supportVwl: true, supportSync: true, supportAd: true, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},
    {deviceType: LEDBOX, deviceTypeVersion: 6, priority: 70, text: 'LED6', contentSchedule: false, messageSchedule: false, eventSchedule: false, playlist: false, ruleset: false, className: 'led6', scheduleInfo: {contentSchedule: {supportVwl: true, supportSync: true, supportAd: true, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},
    {deviceType: SPLAYER, deviceTypeVersion: 7, priority: 80, text: 'S7', contentSchedule: true, messageSchedule: true, eventSchedule: true, playlist: true, ruleset: true, className: 's_player7', scheduleInfo: {contentSchedule: {supportVwl: true, supportSync: true, supportAd: true, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},
    {deviceType: SPLAYER, deviceTypeVersion: 8, priority: 90, text: 'S8', contentSchedule: true, messageSchedule: true, eventSchedule: true, playlist: true, ruleset: true, className: 's_player8', scheduleInfo: {contentSchedule: {supportVwl: true, supportSync: true, supportAd: true, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},

    /*{deviceType: IPLAYER, deviceTypeVersion: 1, priority: 110, text: 'I', contentSchedule: true, playlist: true},
    {deviceType: IPLAYER, deviceTypeVersion: 2, priority: 110, text: 'I', contentSchedule: true, playlist: true},*/
    {deviceType: IPLAYER, deviceTypeVersion: 3, priority: 110, text: 'I', contentSchedule: true, messageSchedule: true, eventSchedule: true, playlist: true, ruleset: true, className: 'i_player', scheduleInfo: {contentSchedule: {supportVwl: true, supportSync: true, supportAd: true, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},

    {deviceType: THIRDPLAYER, deviceTypeVersion: 1, priority: 1000, text: '3rdPartyPLAYER', contentSchedule: false, messageSchedule: false, eventSchedule: false, playlist: false, className: '3rd_party_player', scheduleInfo: {}},
    {deviceType: THIRDPLAYER, deviceTypeVersion: 2, priority: 1000, text: '3rdPartyPLAYER', contentSchedule: false, messageSchedule: false, eventSchedule: false, playlist: false, className: '3rd_party_player', scheduleInfo: {}},

    {deviceType: WPLAYER, deviceTypeVersion: 1, priority: -2, text: 'W Player', contentSchedule: true, messageSchedule: true, eventSchedule: true, playlist: true, ruleset: true, className: 'w_player', scheduleInfo: {contentSchedule: {supportSync: true, channel: true, frame: true}, messageSchedule: {multiple: true}, eventSchedule: {multiple: true}}},
]
