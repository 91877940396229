import React, {useEffect, useState} from 'react';
import MISDialog from "../../MISDialog";
import {useTranslation} from "react-i18next";
import './FramePopup.css';
import {SearchButton} from "../../../button/SearchButton";
import Checkbox from "../../../checkbox/Checkbox";
import {toastr} from 'helper/toastrIntercept';
import classNames from 'classnames';
import RenderFrame, {RenderButtons} from "../../../schedule/RenderFrame";
import {scheduleService} from "../../../../services/schedule.service";
import GroupsPopup from "../../GroupsPopup";
import ContentPopup from "../ContentPopup";
import Select from "../../../selectbox/Select";
import WhiteButton from "../../../button/WhiteButton";
import NewFramePopup from "./NewFramePopup";
import {scheduleAction} from "../../../../actions";
import {useDispatch, useSelector} from "react-redux";
import {createUUID} from "../../../../helper/schedule/scheduleUtils";
import {convertJsonToObjectForTemplateFrame} from "../../../../helper";
import {Loading} from "../../../loading/Loading";
import {getErrorMessage} from "../../../../helper/responseHandler";


const mainFrame = {
    frameName: 'Frame 0',
    frameIndex: 0,
    isMainFrame: true,
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    lineData: 'fixed',
    events: [],
    frames: []
}

const FramePopup = ({mode, close, originalFrame = mainFrame, resolution : oriResolution, deviceType, deviceTypeVersion}) => {


    const {t} = useTranslation();

    const dispatch = useDispatch();

    const {program, currentFrameId, currentChannelNo} = useSelector(state => ({
        currentFrameId : state.schedules.contents[mode].currentFrameId,
        currentChannelNo : state.schedules.contents[mode].currentChannelNo,
        program : state.schedules.contents[mode].program,
    }));

    const [resolution, setResolution] = useState(oriResolution);

    const [newFrame, setNewFrame] = useState(false);

    const [frame, setFrame] = useState({
        frame: originalFrame,
        firstStep: true,
        secondStep: false,
        frames: [],
        firstStepInfo: {
            resolution: resolution.width+'*'+resolution.height,
            selectedFrame: -1,
            width: 209,
            height: 115
        },
        secondStepInfo:{
            selectedFrame : -1,
            width: 380,
            height: 214
        },
        showContentPopup: false,
        contentPopupMode: '',
        showUserPopup: false,

    });

    const [frameList, setFrameList] = useState([]);

    const [showNewContentPopup, setShowNewContentPopup] = useState({
        show: false,
        frame: undefined
    });

    const [loading, setLoading] = useState(false);

    const selectFrameButtons = [
        {id: 'FRAME_POPUP_NEXT_BTN', name: t("COM_BUTTON_NEXT"), onClick: ()=> moveStep('second')},
        {id: 'FRAME_POPUP_CANCEL', name: t("BUTTON_CANCEL_P"), onClick: ()=> close()}
    ]

    const editFrameButtons = [
        {id: 'FRAME_POPUP_NEXT_BTN', name: t("COM_SID_PREVIOUS"), onClick: ()=> moveStep('first')},
        {id: 'FRAME_POPUP_SAVE_BTN', name: t("COM_BUTTON_SAVE"), onClick: ()=> saveFrame()},
        {id: 'FRAME_POPUP_CANCEL', name: t("BUTTON_CANCEL_P"), onClick: ()=> close()}
    ]

    const moveStep = (step) => {
        switch (step) {
            case 'first':
                setFrame({...frame, firstStep: true, secondStep: false, secondStepInfo: {...frame.secondStepInfo, selectedFrame: -1, frame: {}}});
                break;
            case 'second':
                let updateFrame;
                if (frame.firstStepInfo.selectedFrame < 0) {
                    updateFrame = originalFrame;
                } else {
                    setNewFrame(true);
                    updateFrame = frame.frames[frame.firstStepInfo.selectedFrame];
                }
                setFrame({...frame, firstStep: false, secondStep: true, frame: updateFrame})
                break;
        }
    }

    const getResolution = (frames) => {
        let width = 1920, height = 1080;
        const frame = frames.frames !== undefined && frames.frames.length > 0 ? frames.frames[0] : frames;
        if (frame.resolution !== undefined) {
            const resolution = frame.resolution;
            const resolutionSplit = resolution.split('*');
            if (resolutionSplit !== undefined && resolutionSplit.length > 1) {
                width = parseInt(resolutionSplit[0],10);
                height = parseInt(resolutionSplit[1], 10);
            }
        }
        return {width: width,  height: height};
    }

    const saveFrame = () => {
        const frames = frame.frame;
        const {width, height} = getResolution(frames);

        program.resolution = {
            width: width,
            height: height
        }
        if (newFrame) {
            // need to init frames
            const frameId = createUUID();
            if (frames.frames !== undefined && frames.frames.length > 0) {
                let index = 1;
                frames.frames.map(
                    f => {
                        f.frameIndex = index;
                        f.frameId = index === 1 ? frameId : createUUID();
                        f.events = [];
                        f.frameType = f.frameType.toUpperCase();
                        if (f.frameType === 'CUSTOM') {
                            f.lineData = 'CUSTOM_LAYOUT';
                        } else {
                            f.lineData = f.frameType;
                        }
                        f.templateType = undefined;
                        index++;
                    }
                )
                program.channels.map(
                    channel => {
                        if (channel.channelNo === currentChannelNo) {
                            channel.frame.frames = frames.frames;
                        }
                    }
                )
            }
            new Promise(()=>{
                dispatch(scheduleAction.updateContentSchedule(mode, {program: program, currentChannelNo: currentChannelNo, currentFrameId: frameId}));
            }).then(close())
        } else {
            // just update frames
            program.channels.map(
                channel => {
                    if (channel.channelNo === currentChannelNo) {
                        channel.frame.frames = frames.frames;
                    }
                }
            )
            new Promise(()=>{
                dispatch(scheduleAction.updateContentSchedule(mode, {...program, program: program, currentFrameId : currentFrameId, currentChannelNo : currentChannelNo}));
            }).then(close())
        }
    }

    const selectFrameOnFirstStep = index => {
        setFrame({...frame, firstStepInfo: {...frame.firstStepInfo, selectedFrame: index}});
    }

    const selectFrameOnSecondStep = (index, selectedFrame) => {
        const updateFrame = frame.frame.frames !== undefined && frame.frame.frames.length > 0 ? frame.frame.frames[index] : frame.frame;
        setFrame({...frame, secondStepInfo: {...frame.secondStepInfo, selectedFrame: index, frame: updateFrame}});
    }

    const showPopup = (type, mode) => {
        switch (type) {
            case 'content':
                if (mode !== 'ALL') {
                    if (frame.secondStepInfo !== undefined && frame.secondStepInfo.selectedFrame !== undefined && frame.secondStepInfo.selectedFrame > -1){

                    }
                    else {
                        toastr.error(t("MESSAGE_SCHEDULE_SELECT_FRAME_P"));
                        return false;
                    }
                }
                setFrame({...frame, showContentPopup: !frame.showContentPopup, contentPopupMode: mode})
                break;
            case 'user':
                if (mode !== 'ALL') {
                    if (frame.secondStepInfo !== undefined && frame.secondStepInfo.selectedFrame !== undefined && frame.secondStepInfo.selectedFrame > -1){

                    }
                    else {
                        toastr.error(t("MESSAGE_SCHEDULE_SELECT_FRAME_P"));
                        return false;
                    }
                }
                setFrame({...frame, showUserPopup: !frame.showUserPopup, userPopupMode: mode})
                break;
        }
    }

    const closePopup = (type) => {
        if (type === 'user') {
            setFrame({...frame, showUserPopup: false})
        } else if(type === 'content') {
            setFrame({...frame, showContentPopup: false})
        }
    }

    const openNewFramePopup = () => {
        setShowNewContentPopup({
            show: true
        })
    }
    const closeNewFramePopup = () => {
        setShowNewContentPopup({
            show : false
        })
    }

    const selectContent = (id, name, mode) => {

        let frames = frame.frame.frames !== undefined && frame.frame.frames.length > 0 ? frame.frame.frames : frame.frame;
        let updateSelectedFrame = frame.secondStepInfo.frame;
        if (mode === 'ALL') {
            if (Array.isArray(frames)) {
                frames.map(
                    f=> {
                        f.defaultContentId = id;
                        f.defaultContentName = name;
                    }
                )
                frames = {
                    frames: frames
                }
                if (updateSelectedFrame !== undefined) {
                    updateSelectedFrame.defaultContentId = id;
                    updateSelectedFrame.defaultContentName = name;
                }
            } else {
                frames.defaultContentId = id;
                frames.defaultContentName = name;
            }
        } else {
            const index = frame.secondStepInfo !== undefined && frame.secondStepInfo.selectedFrame !== undefined ? frame.secondStepInfo.selectedFrame : undefined;
            if (index === undefined) {
                toastr.error(t("MESSAGE_SCHEDULE_SELECT_FRAME_P"));
                return false;
            } else {
                if (Array.isArray(frames)) {
                    if (frames[index] === undefined) {
                        toastr.error(t("MESSAGE_SCHEDULE_SELECT_FRAME_P"));
                        return false;
                    } else {
                        frames[index].defaultContentId = id;
                        frames[index].defaultContentName = name;
                        frames = {
                            frames: frames
                        }
                    }
                } else {
                    frames.defaultContentId = id;
                    frames.defaultContentName = name;
                }
                updateSelectedFrame = {...updateSelectedFrame, defaultContentId: id, defaultContentName: name};
            }
        }
        setFrame({...frame, showContentPopup:false, frame: frames, secondStepInfo: {...frame.secondStepInfo, frame: {...frame.secondStepInfo.frame, ...updateSelectedFrame}}});
    }

    const selectResolution = (e, value) => {
        fetchTemplateFrameList(getPosition(value));
    }

    const fetchTemplateFrameList = (position) => {
        const {resolution, firstWidth, firstHeight, secondWidth, secondHeight} = position;
        const resolutionArray = resolution.split('*');
        setLoading(true);
        scheduleService.fetchContentScheduleTemplateFrames(resolutionArray[0], resolutionArray[1]).then((data)=>{
            if (data) {

                setResolution({width: resolutionArray[0], height: resolutionArray[1]})

                setFrameList(data.items);
                const frames = convertJsonToObjectForTemplateFrame(data.items);
                if (firstWidth !== undefined && firstHeight !== undefined && secondHeight !== undefined && secondWidth !== undefined) {
                    setFrame({...frame, frames: frames, firstStepInfo: {...frame.firstStepInfo, resolution: resolution, selectedFrame: -1, width: firstWidth, height: firstHeight}, secondStepInfo: {...frame.secondStepInfo, width: secondWidth, height: secondHeight}});
                } else {
                    setFrame({...frame, frames: frames})
                }
            }
        }).catch(e=> toastr.error(getErrorMessage(e))).finally(()=>setLoading(false))

    }


    const setUser = (groups, mode) => {
        if (groups !== undefined && groups.length > 0) {
            const updateGroups = [];
            groups.map(
                user => {
                    updateGroups.push({groupId: user.groupId, groupName: user.groupName});
                }
            )
            const updateFrame = Object.assign({}, frame);
            const frames = updateFrame.frame.frames !== undefined && updateFrame.frame.frames.length > 0 ? updateFrame.frame.frames : updateFrame.frame;
            if (mode === 'ALL') {
                if (Array.isArray(frames)) {
                    frames.map(
                        f => {
                            f.authorityGroups = updateGroups;
                        }
                    )
                    if (updateFrame.secondStepInfo.selectedFrame > -1) {
                        updateFrame.secondStepInfo.frame.authorityGroups = updateGroups;
                    }
                } else {
                    frames.authorityGroups = updateGroups;
                }
            } else {
                if (Array.isArray(frames)) {
                    const index = updateFrame.secondStepInfo.selectedFrame;
                    frames[index].authorityGroups = updateGroups;
                } else {
                    frames.authorityGroups = updateGroups;
                }
            }
            //setFrame({...frame, frame: updateFrame.frame})
            setFrame({...frame, frame: updateFrame.frame, secondStepInfo: {...frame.secondStepInfo, frame: {...updateFrame.secondStepInfo.frame, ...updateFrame.frame}}});
        }
        closePopup('user');
    }

    const handleFrameName = e => {
        const value = e.target.value;
        setFrame({...frame, secondStepInfo: {...frame.secondStepInfo, frame: {...frame.secondStepInfo.frame, frameName: value}}});
    }

    const onMainFrame = e => {
        if (frame.secondStepInfo !== undefined && frame.secondStepInfo.selectedFrame !== undefined) {

            const updateFrames = Object.assign({}, frame);
            let frames = updateFrames.frame.frames !== undefined && updateFrames.frame.frames.length > 0 ? updateFrames.frame.frames : updateFrames.frame;
            if (Array.isArray(frames)) {
                frames = frames.map(
                    (f, index) => index === frame.secondStepInfo.selectedFrame ? {...f, isMainFrame: !f.isMainFrame} : {...f, isMainFrame: false}
                )
                //frames[frame.secondStepInfo.selectedFrame].isMainFrame = !frames[frame.secondStepInfo.selectedFrame].isMainFram;
            } else {
                frames = {...frames, isMainFrame: frames.isMainFrame};
            }
            updateFrames.frame.frames !== undefined && updateFrames.frame.frames.length > 0 ? updateFrames.frame.frames = frames : updateFrames.frame = frames;
            setFrame({...frame, frame: updateFrames.frame, secondStepInfo: {...frame.secondStepInfo, frame: {...updateFrames.secondStepInfo.frame, isMainFrame: !updateFrames.secondStepInfo.frame.isMainFrame}}});
        } else {
            toastr.error(t("MESSAGE_SCHEDULE_SELECT_FRAME_P"));
            return false;
        }

    }

    useEffect(()=>{
        if (frame !== undefined && frame.firstStep && frame.frames !== undefined && frame.frames.length  === 0) {
            setLoading(true);
            fetchTemplateFrameList(getPosition());
        }
    }, [frame]);

    const reloadTemplateFrames = () => {
        setFrame({
            ...frame,
            frames: []
        })
    }

    const editTemplateFrame = (templateId) => {
        setShowNewContentPopup({
            show: true,
            frame: frameList.find(frame => frame.templateId === templateId)
        })
    }

    const dialogProps ={title: t("MIS_TITLE_FRAME_EDIT_P"), closeOnEscape : true, modal:true, onClose: ()=>close()};

    const removeDefaultContent = () => {
        const updateFrame = Object.assign({}, frame);
        if (updateFrame.secondStepInfo !== undefined && updateFrame.secondStepInfo.selectedFrame !== undefined) {
            if (updateFrame.frame.frames !== undefined && updateFrame.frame.frames.length > 0) {
                updateFrame.frame.frames.map(
                    (f, index) => {
                        if (index === updateFrame.secondStepInfo.selectedFrame) {
                            f.defaultContentName = undefined;
                            f.defaultContentId = undefined;
                        }
                    }
                )
            } else {
                updateFrame.frame.defaultContentName = undefined;
                updateFrame.frame.defaultContentId = undefined
            }
        }
        setFrame({...frame, frame: updateFrame.frame,  secondStepInfo: {...frame.secondStepInfo, frame: {...frame.secondStepInfo.frame, defaultContentName: undefined, defaultContentId: undefined}}})
    }

    const getPosition = (value) => {
        let resolutionStr = resolution.width+'*'+resolution.height;
        if (value) {
            resolutionStr = value;
        }
        let firstWidth = 209, firstHeight = 115, secondWidth = 380, secondHeight = 214;
        switch (resolutionStr) {
            case '3840*2160' :
            case '1920*1080' :
            case '1366*768':
            case '1360*768':
            case '1280*1024':
                firstWidth = 209;
                firstHeight = 115;
                secondWidth = 380;
                secondHeight = 214;
                break;
            case '768*1366':
            case '768*1360':
            case '1080*1920':
            case '1024*1280':
            case '2160*3840':
                firstWidth = 198;
                firstHeight= 258;
                secondWidth = 220;
                secondHeight = 286;
                break;
        }
        return {
            resolution: resolutionStr, firstWidth: firstWidth, firstHeight: firstHeight, secondWidth: secondWidth, secondHeight: secondHeight
        }
    }

    return (
        <MISDialog
            dialog={dialogProps}
        >

            {
                frame.showContentPopup &&
                    <ContentPopup
                        mode={'default_content_on_frame'}
                        closeContentPopup={()=>closePopup('content')}
                        selectContent={(id, name)=>selectContent(id, name, frame.contentPopupMode)}
                        deviceType={deviceType}
                        deviceTypeVersion={deviceTypeVersion}
                    />
            }

            {
                frame.showUserPopup &&
                    <GroupsPopup
                        mode={"user"}
                        checkbox={false}
                        save={(groups)=>setUser(groups, frame.userPopupMode)}
                        close={()=>closePopup('user')}
                    />
            }

            {
                showNewContentPopup.show &&
                    <NewFramePopup
                        close={()=>closeNewFramePopup()}
                        reload={()=>fetchTemplateFrameList(getPosition())}
                        originFrame={showNewContentPopup.frame}
                    />
            }


            <div className="content_schedule_frame_popup">
                <div className="device_step step7">
                    <div style={{width:'50%',cursor:'pointer'}} className={classNames({'on' : frame.firstStep === true})} onClick={()=>moveStep('first')}>
                        <span className="step">01</span>
                        <span className="step_title txt1">{t("TEXT_SELECT_FRAME_P")}</span>
                    </div>
                    <div style={{width:'50%',cursor:'pointer'}} className={classNames({'on' : frame.secondStep === true})} onClick={()=>moveStep('second')}>
                        <span className="step">02</span>
                        <span className="step_title txt1">{t("MIS_SID_20_EDIT_DETAILS")}</span>
                    </div>
                </div>
                <div id="selectContentWrap" style={{display: frame.firstStep === true  ? '':'none'}}>
                    <div className="detail_view mini_p mt14">
                        <table>
                            <colgroup>
                                <col width="182px"/>
                                <col width=""/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <th>{t("TEXT_TITLE_DISPLAY_RESOLUTION_P_KR_20")}</th>
                                <td>
                                    <Select
                                        value={frame.firstStepInfo.resolution}
                                        optionStyle={{marginTop: -65}}
                                        selects={[
                                            {title: '3840 x 2160 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE')+')', value: '3840*2160', multiLang: false},
                                            {title: '1920 x 1080 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE')+')', value: '1920*1080', multiLang: false},
                                            {title: '1366 x 768 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE')+')', value: '1366*768', multiLang: false},
                                            {title: '1360 x 768 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE')+')', value: '1360*768', multiLang: false},
                                            {title: '1280 x 1024 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_LANDSCAPE')+')', value: '1280*1024', multiLang: false},
                                            {title: '768 x 1366 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT')+')', value: '768*1366', multiLang: false},
                                            {title: '768 x 1360 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT')+')', value: '768*1360', multiLang: false},
                                            {title: '1080 x 1920 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT')+')', value: '1080*1920', multiLang: false},
                                            {title: '1024 x 1280 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT')+')', value: '1024*1280', multiLang: false},
                                            {title: '2160 x 3840 ('+t('COM_IDS_TXT_SETUP_OSD_ROTATION_PORTRAIT')+')', value: '2160*3840', multiLang: false}
                                        ]}
                                        onChange={selectResolution}
                                    />
                                </td>
                            </tr>
                            <tr className="va_t">
                                <td rowSpan="2">
                                    <WhiteButton name={t("MIS_SID_20_ADD_CUSTOM_FRAME")} onClick={openNewFramePopup}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="edit_frame mt20 clearfix">
                        <ul>
                            {
                                loading &&
                                    <li><Loading /></li>
                            }
                            {
                                !loading && frame !== undefined && frame.frames !== undefined && frame.frames.length > 0 &&
                                    frame.frames.map(
                                        (f, index) =>
                                            <li key={index} style={{width: frame.firstStepInfo.width, height: frame.firstStepInfo.height}} className={classNames({'on': frame.firstStepInfo.selectedFrame === index})} onClick={()=>selectFrameOnFirstStep(index)}>
                                                <RenderFrame frame={f} wrapHeight={frame.firstStepInfo.height} wrapWidth={frame.firstStepInfo.width} resolution={resolution} reloadTemplateFrames={reloadTemplateFrames} editTemplateFrame={editTemplateFrame}/>
                                            </li>
                                    )
                            }
                        </ul>
                    </div>
                </div>

                <div id="editContentWrap" style={{display: frame.secondStep === true ? '':'none'}}>
                    <input type="hidden" className="templateType" value="fixed"/>
                    <div className="detail_view mini_p mt14" >
                        <table>
                            <colgroup>
                                <col width="182px"/>
                                <col width=""/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <th>{t("MIS_SID_20_OVERALL_DEFAULT_CONTENT")}</th>
                                <td>
                                    <div className="float_l" style={{width:40}}>
                                        <SearchButton onClick={()=>showPopup('content', 'ALL')}/>
                                    </div>
                                    <div className="float_l">
                                        {
                                            frame.frame !== undefined && frame.frame.frames !== undefined && frame.frame.frames.length > 0 &&
                                                <input type="text" style={{width:200}} value={frame.frame.frames.every(f=> f.defaultContentId === frame.frame.frames[0].defaultContentId) ? frame.frame.frames[0].defaultContentName : frame.frame.defaultContentName} readOnly/>
                                        }
                                        {
                                            frame.frame !== undefined && (frame.frame.frames === undefined || frame.frame.frames.length === 0) &&
                                            <input type="text" style={{width:200}} value={frame.frame.defaultContentName} readOnly/>
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>{t("MIS_SID_20_OVERALL_FRAME_PERMISSION")}</th>
                                <td>
                                    <div>
                                        <div className="float_l" style={{width:40}}>
                                            <SearchButton onClick={()=>showPopup('user', 'ALL')}/>
                                        </div>
                                        <div className="float_l">
                                            <div style={{height:50,width:300,border: '1px solid #e7e7e7',borderRadius: 3,padding:10,marginTop:10,overflowY:'auto'}}>
                                                {
                                                    frame.frame !== undefined &&
                                                        frame.frame.frames !== undefined && frame.frame.frames.length > 0 &&
                                                        frame.frame.frames[0].authorityGroups !== undefined &&
                                                        frame.frame.frames.every(f => f.authorityGroups === frame.frame.frames[0].authorityGroups)
                                                            && frame.frame.frames[0].authorityGroups !== undefined && frame.frame.frames[0].authorityGroups.map(
                                                                group => <div>{group.groupName}</div>
                                                            )
                                                }
                                                {
                                                    frame.frame !== undefined &&
                                                    (frame.frame.frames === undefined || (frame.frame.frames !== undefined && frame.frame.frames.length === 0)) &&
                                                        frame.frame.authorityGroups !== undefined && frame.frame.authorityGroups.length > 0 ?
                                                        frame.frame.authorityGroups.map(
                                                            group => <div>{group.groupName}</div>
                                                        ) :
                                                        <div></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{borderBottom: '1px solid #e7e7e7',height:0}}></td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{width:'100%',textAlign:'center'}}>
                                    <div className="selected_frame_edit_wrap">
                                        <div className="mainFrame" style={{position:'relative',border:'1px solid #ccc',width: frame.secondStepInfo.width, height: frame.secondStepInfo.height}}>

                                            <RenderFrame frame={frame.frame} wrapWidth={frame.secondStepInfo.width} wrapHeight={frame.secondStepInfo.height} onSelectFrame={(index, selectedFrame)=>selectFrameOnSecondStep(index, selectedFrame)} selected={frame.secondStepInfo.selectedFrame} resolution={resolution} secondPage/>

                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>{t("TEXT_FRAME_NAME_P")}</th>
                                <td>
                                    <div className="float_l">
                                        <input type="text" style={{width:150}} maxLength="45" className="modifyFrameName" value={frame.secondStepInfo !== undefined && frame.secondStepInfo.frame !== undefined ? frame.secondStepInfo.frame.frameName : ''} onChange={handleFrameName}/>
                                    </div>
                                    <div className="float_l" style={{paddingTop:3,marginLeft:15}}>
                                        <Checkbox id={"modifyIsMainFrame"} name={t("MIS_SID_20_MAIN_FRAME")} onChange={onMainFrame} checked={frame.secondStepInfo !== undefined && frame.secondStepInfo.frame !== undefined && frame.secondStepInfo.frame.isMainFrame ? true : false}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>{t("COM_TEXT_DEFAULT_CONTENT_P")}</th>
                                <td>
                                    <div className="float_l" style={{width:40}}>
                                        <SearchButton onClick={()=>showPopup('content', 'single')}/>
                                    </div>
                                    <div className="float_l">
                                        <input type="text" className="frameContentName" readOnly id="frameContentName"
                                               value={frame.secondStepInfo !== undefined && frame.secondStepInfo.frame !== undefined && frame.secondStepInfo.frame.defaultContentName !== undefined ? frame.secondStepInfo.frame.defaultContentName  : ''}/>
                                        <button className="selectedDeviceDelBtn" style={{verticalAlign: 'middle'}} onClick={removeDefaultContent}></button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>{t("MIS_SID_20_FRAME_PERMISSION")}</th>
                                <td>
                                    <div>
                                        <div className="float_l" style={{width:40}}>
                                            <SearchButton onClick={()=>showPopup('user', 'single')}/>
                                        </div>
                                        <div className="float_l">
                                            <div style={{height:50,width:300,border: '1px solid #e7e7e7',borderRadius: 3,padding:10,marginTop:10,overflowY: 'auto'}}>
                                                {
                                                    frame.secondStepInfo !== undefined && frame.secondStepInfo.frame !== undefined &&
                                                    frame.secondStepInfo.frame.authorityGroups !== undefined &&
                                                    frame.secondStepInfo.frame.authorityGroups.map(
                                                        group => <div>{group.groupName}</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={'button_wrap'}>
                    {
                        frame.firstStep &&  <RenderButtons buttons={selectFrameButtons}/> ||
                        frame.secondStep && <RenderButtons buttons={editFrameButtons} />
                    }
                </div>

            </div>
        </MISDialog>
    )
}
export default FramePopup;