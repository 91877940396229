import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {isEmpty, isNil} from 'lodash';
import {toastr} from 'helper/toastrIntercept';
import {popupAction} from '../../../../actions';
import MISDialog from '../../MISDialog';
import {deviceService, userService} from '../../../../services';
import TextInput from '../../../input/TextInput';
import Select from '../../../selectbox/Select';
import './AddPreset.css';
import {commonConstants} from '../../../../constants';
import {deviceValidator, isAllNil} from '../../../../helper';

const AddPreset = ({mode, deviceSecurity = false, sourcePreset, onSave, onClose}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [preset, setPreset] = useState({});
    const [errors, setErrors] = useState({});
    const [orgs, setOrgs] = useState([]);

    const onClickSetup = () => {
        if(isEmpty(preset.name)) {
            setErrors({...errors, name: t('MESSAGE_COMMON_ENTER_NAME_P')});
            return;
        }

        if(isNil(preset.organizationId)) {
            setErrors({...errors, organizationId: t('TEXT_SEL_ORGANIZATION_P')});
            return;
        }

        if(mode === 'copy') {
            deviceService.copyDevicePreset(sourcePreset.preconfigId, preset).then(res => {
                toastr.success(t('MIS_TEXT_SUCCESS_SENT_P'));
                if(onSave) {
                    onSave();
                }
            }).catch(error => console.log(error));
        } else {
            onClose();
            dispatch(popupAction.addPopup({
                id: commonConstants.EDIT_PRESET_DETAIL,
                type: commonConstants.EDIT_PRESET_DETAIL,
                preset,
                deviceSecurity,
                onSave: () => {
                    if(onSave) {
                        onSave();
                    }
                    dispatch(popupAction.closePopup(commonConstants.EDIT_PRESET_DETAIL));
                },
                onClose: () => dispatch(popupAction.closePopup(commonConstants.EDIT_PRESET_DETAIL)),
            }));
        }
    };

    const onChangeName = e => {
        const {isValid, messageId} = deviceValidator('presetName', e.target.value);
        setPreset({...preset, name: e.target.value});
        if(!isValid) {
            setErrors({...errors, name: t(messageId)+ "( . _ " + t('COM_MIA_SID_TOOLBAR_SPACE') + " )" });
        } else {
            const newErrors = {...errors};
            delete newErrors['name'];
            setErrors(newErrors);
        }
    };

    const onChangeOrganization = (e, value) => {
        setPreset({...preset, organizationId: value});
        const newErrors = {...errors};
        delete newErrors['organizationId'];
        setErrors(newErrors);
    };

    useEffect(() => {
        userService.fetchOrganizations().then(res => {
            const orgList = res.items.map(org => ({value: org.organizationId, title: org.groupName}));
            if( orgList.findIndex(item => item.title === "ROOT") !== -1) {
                orgList.splice(orgList.findIndex(item => item.title === "ROOT"), 1);
            }
            setOrgs([{value: 0, title: 'Common'}, ...orgList]);
        });
    }, []);

    return (
        <MISDialog 
            dialog={{
                title: mode === 'copy' ? t('BUTTON_SAVE_AS_P') : t('MIS_SID_ADD_PRESET'),
                modal: false,
                isDraggable: true,
                closeOnEscape: true,
                width: 615,
                height: 180,
                // position: {x: -325, y: -156},
                onClose: onClose,
                modalDraggable: true
            }}
            buttons={{
                rightButtons: [
                    {
                        id: 'TEXT_SETUP_P',
                        title: t('TEXT_SETUP_P'),
                        onClick: onClickSetup,
                        disable: !isEmpty(errors) || isEmpty(preset.name) || isAllNil(preset.name, preset.organizationId)
                    },
                    {
                        id: 'BUTTON_CANCEL_P',
                        title: t('BUTTON_CANCEL_P'),
                        onClick: onClose,
                    }
                ]
            }}>
            <div className='devicepop add_preset_wrap'>
                <div className="detail_view">
                    <table>
                        <colgroup>
                            <col width="140px"/>
                            <col width=""/>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{t('LIST_TITLE_NAME')}</th>
                                <td>
                                    <TextInput width={420} maxLength={60} value={preset.name} onChange={onChangeName} error={errors.name} />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('TABLE_ORGANIZATION_P')}</th>
                                <td>
                                    <Select width={420} selects={orgs} multiLang={false} value={preset.organizationId} defaultTitle={t('MESSAGE_USER_SEL_ORGANIZATION_P')}
                                        onChange={onChangeOrganization} error={errors.organizationId} optionStyle={{height: 120}} />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('TEXT_DESCRIPTION_P')}</th>
                                <td>
                                    <textarea className='preset_desc' value={preset.description} onChange={e => setPreset({...preset, description: e.target.value})} maxLength={60} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </MISDialog>
    );
};

AddPreset.defaultProps = {
    onSave: () => {},
    onClose: () => {}
};

export default AddPreset;