import isHtml from 'is-html';
import {stringIsEnglish, isRightIpv4Andv6} from './utils';

const validatorMap = {
    'deviceName': validateDeviceName,
    'presetName': validatePresetName,
    'cabinetIPAddress': validateIpAddress,
};
const validResult = {isValid: true};

export const deviceValidator = (name, ...args) => {
    const {[name]: validators} = validatorMap;
    if(validators) {
        if(Array.isArray(validators)) {
            for(const validator of validators) {
                const result = validator(...args);
                if(!result.isValid) {
                    return result;
                }
            }
            return validResult;
        }
        return validators(...args);
    } else {
        return null;
    }
};

export function validateDeviceName(name) {
    let message = undefined;
    let isValid = true;
    let messageId = undefined;

    if(!name || name.length < 1 || name.length > 50){
        messageId = 'MESSAGE_COMMON_MAX_50_P';
        isValid = false;
    } else {
       if(!stringIsEnglish(name)){
            messageId = 'MESSAGE_DEVICE_CHECK_INPUT_CHAR_P';
            message = isHtml(name) ? name : '';
            isValid = false;
       }
    }

    return {isValid, messageId, message};
}

function validatePresetName(name) {
    let messageId;
    let isValid = true;

    if(!/^[a-zA-Z0-9._ ]+$/.test(name)) {
        messageId = 'MIS_SID_ONLY_ALPHANUMERIC_CHARACTERS_AND_FOLLOWING_ALLOWED'
        isValid = false;
    }

    return {isValid, messageId};
}

export function validateNumbers(value){
    let messageId;
    let isValid = true;

    if( !/^\d+$/.test(value)){
        messageId = 'COM_SID_SIGANGE_CAN_ONLY_USE_NUMBER';
        isValid = false;
    }
    return {isValid, messageId};
}


export function validatePort(value){
    return !(value < 0 || value > 65536);
}

export function validateWhiteSpace(value){
    return /\s/.test(value);
}


export function validateIpAddress(value) {
    if(!isRightIpv4Andv6(value)) {
        return {isValid: false, messageId: 'MIS_TEXT_IP_ADDRESS_INCORRECT_P'};
    }

    return validResult;
}