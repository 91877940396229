import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {
    commonConstants,
    CONTENT_SCHEDULE_CONFIG,
    CONTENT_SCHEDULE_PROGRAM_TYPE_AD,
    contentConstants,
    inputSources,
    playlistConstants,
    scheduleConstants
} from "../../../constants";
import {snakeCase} from "lodash";
import PerfectScrollbar from "perfect-scrollbar";
import {contentService, playlistService, rulesetService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import {popupAction, scheduleAction} from "../../../actions";
import SelectBox from "../../../components/selectbox/SelectBox";
import GroupButton from "../../../components/button/GroupButton";
import Filter from "../../../components/filter/Filter";
import SwitchListNImage from "../../../components/switch/SwitchListNImage";
import SearchBar from "../../../components/search/SearchBar";
import {Loading} from "../../../components/loading/Loading";
import ContentThumbnail from "../../../components/image/ContentThumbnail";
import CategoryButton from "../../../components/category/CategoryButton";
import Chip from "../../../components/chip/Chip";

const SubmenuSimpleScheduleContainer = ({programType='LFD', deviceType='SPLAYER', deviceTypeVersion=1}) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [style, setStyle] = useState({
        marginTop: 15,
        overflowY: 'auto',
        height: 900,
    })


    const inputSource = inputSources;

    const [mode, setMode] = useState(programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? 'PLAYLIST' : 'CONTENT');
    const [view, setView] = useState('IMAGE');

    const playlistTypes = programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? CONTENT_SCHEDULE_CONFIG[programType].supportedPlaylistFilter : [0,5,6] ;

    const [end, setEnd] = useState(false);

    const [isFetching, setIsFetching] = useState();

    const getPlaylistType = (playlistType) => {
        return t(playlistConstants.PLAYLISTTYPES.find(type=> type.id === playlistType).name);
    }

    const [content, setContent] = useState({
        force: true,
        count: 0,
        isFetching: false,
        filter: {
            startIndex: 1,
            pageSize: 50,
            contentTypes: ['ALL'],
            groupId: undefined,
            creatorIds: undefined,
            tagIds: undefined,
            fileSizes: undefined,
            searchText: undefined,
            expirationStatus: 'VALID',
            sortColumn: snakeCase('lastModifiedDate').toUpperCase(),
            sortOrder: 'DESC',
            approvalStatus: "APPROVED"
        },
        list: []
    })

    const [playlist, setPlaylist] = useState({
        force: true,
        count: 0,
        isFetching: false,
        filter: {
            startIndex: 1,            
            pageSize: 50,
            groupId: undefined,
            playlistTypes: playlistTypes,
            userId: undefined,
            deviceType: programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? deviceType : undefined,
            deviceTypeVersion: programType === CONTENT_SCHEDULE_PROGRAM_TYPE_AD ? deviceTypeVersion : undefined,
            sortColumn: snakeCase('lastModifiedDate').toUpperCase(),
            sortOrder: 'DESC'
        },
        list: []
    })

    const [ruleSet, setRuleSet] = useState({
        force: true,
        count: 0,
        isFetching: false,
        filter: {
            startIndex:	1,
            pageSize: 50,
            /*deviceType: deviceType,
            deviceTypeVersion: deviceTypeVersion+'.0',*/
            includeTemp: false,
            groupId: undefined,
            userId: undefined,
            searchText: undefined,
            sortColumn: snakeCase('modifyDate').toUpperCase(),
            sortOrder:	'DESC'
        },
        list:[]
    })

    const getThumbnailId = (thumbnailInfo, index) => {
        const images = thumbnailInfo.split("|");
        if (images.length > 1) {
            return images[index]
        } else {
            return ''
        }
    }

    const initScroll = useRef(false);
    const perfectScrollbar = useRef(undefined);

    useEffect(()=> {
        if(!initScroll.current) {
            updateDimensions();
            window.addEventListener("resize", updateDimensions);
            const ps = new PerfectScrollbar('#simple_external-events', {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true
            });
            perfectScrollbar.current = ps;
            initScroll.current = true;
            document.querySelector('#simple_external-events').addEventListener('ps-y-reach-end', () => {
                setEnd(true)
            });
        } else {
            perfectScrollbar.current.update();
        }

    }, [content.list, playlist.list, ruleSet.list, mode])

    const updatePerfectScrollBar = () => {
        if (initScroll.current && perfectScrollbar.current !== undefined) {
            perfectScrollbar.current.update();
        }
    }

    useEffect(()=> {
        window.addEventListener('resize', updatePerfectScrollBar);
        return () => {
            window.removeEventListener('resize', updatePerfectScrollBar);
        }
    }, [])

    const fetchContents = () => {
        const {filter} = content;
        contentService.fetchContentFilter(filter)
            .then(res => {
                    setContent({
                        ...content,
                        list: res.items !== undefined && res.items.length > 0 ? content.list.concat(res.items) : content.list,
                        isFetching: false,
                        count : content.count +1,
                        totalCount : res.totalCount,
                        approvalStatus: "APPROVED",
                        filter: {
                            ...content.filter,
                            startIndex: content.filter.startIndex + res.items.length
                        }
                    })
                }
            ).catch(res=> toastr.error(res))
    }

    const fetchPlaylists = () => {
        const {filter} = playlist;
        playlistService.fetchPlaylistForSchedule(filter)
            .then(res => {
                setPlaylist({
                    ...playlist,
                    list: res.items !== undefined && res.items.length > 0 ? playlist.list.concat(res.items) : playlist.list,
                    isFetching: false,
                    count : playlist.count +1,
                    totalCount : res.totalCount,
                    filter: {
                        ...playlist.filter,
                        startIndex: playlist.filter.startIndex + res.items.length
                    }
                })
                }
            ).catch(res=> toastr.error(res))
    }

    const fetchRuleSets = () => {
        const {filter} = ruleSet;
        rulesetService.fetchRulesetFilter(filter)
            .then(res => {
                setRuleSet({
                    ...ruleSet,
                    list: res.items !== undefined && res.items.length > 0 ? ruleSet.list.concat(res.items) : ruleSet.list,
                    isFetching: false,
                    count : ruleSet.count +1,
                    totalCount : res.totalCount,
                    filter: {
                        ...ruleSet.filter,
                        startIndex: ruleSet.filter.startIndex + res.items.length
                    }
                })
                }
            ).catch(res=> toastr.error(res))
    }


    const updateDimensions = () => {
        let height = window.innerHeight - 205;
        setStyle({
            ...style,
            height: height
        })
    }

    const onGroupPopup = () => {
        let type, params;
        switch (mode) {
            case 'CONTENT':
                type = 'content';
                params = {groupType : contentConstants.MY_CONTENT_GROUP};
                break;
            case 'PLAYLIST':
                type = 'playlist';
                break;
            case 'RULE_SET':
                type = 'ruleset';
                break;
        }
        dispatch(popupAction.addPopup({
            id: commonConstants.COMMON_GROUP_POPUP,
            type: commonConstants.COMMON_GROUP_POPUP,
            mode: type,
            title : t("MIS_SID_SELECT_FOLDER"),
            params: params !== undefined ? params : undefined,
            save: (groups)=>filterGroups(groups),
            close: ()=>dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP))
        }))
    }

    const filterGroups = (groups) => {
        dispatch(popupAction.closePopup(commonConstants.COMMON_GROUP_POPUP));
        if (mode === 'CONTENT') {
            setContent({
                ...content,
                filter: {
                    ...content.filter,
                    startIndex: 1,
                    groupType: groups!== undefined && groups.length > 0 ? 'GROUPED' : undefined,
                    groupId: groups!== undefined && groups.length > 0 ? groups[0].groupId.toString() : undefined,
                    groupName: groups!== undefined && groups.length > 0 ? groups[0].groupName : undefined,
                },
                count: 0,
                list: []
            })
        } else if (mode === 'PLAYLIST') {
            setPlaylist({
                ...playlist,
                filter: {
                    ...playlist.filter,
                    startIndex: 1,
                    groupId: groups!== undefined && groups.length > 0 ? groups[0].groupId.toString() : undefined,
                    groupType: groups!== undefined && groups.length > 0 ? 'GROUPED' : undefined,
                    groupName: groups!== undefined && groups.length > 0 ? groups[0].groupName : undefined,
                },
                count: 0,
                list: []
            })
        } else if (mode === 'RULE_SET') {
            setRuleSet({
                ...ruleSet,
                filter: {
                    ...ruleSet,
                    startIndex: 1,
                    groupId: groups!== undefined && groups.length > 0 ? groups[0].groupId.toString() : undefined
                },
                count: 0,
                list: []
            })
        }

    }

    const changeContent = (mode) => {
        const container = document.querySelector('#simple_external-events');
        container.scrollTop = 0;
        setMode(mode);

        if (mode === 'CONTENT' &&content.count === 0 && content.list.length === 0) {
            setContent({...content, force: !content.force});
        } else if (mode === 'PLAYLIST' && playlist.count === 0 && playlist.list.length === 0) {
            setPlaylist({...playlist, force: !playlist.force});
        } else if (mode === 'RULE_SET' && ruleSet.count === 0 && ruleSet.list.length === 0) {
            setRuleSet({...ruleSet, force: !ruleSet.force});
        }
    }

    const onChangeListNImage = () => {
        setView(view === 'LIST' ? 'IMAGE' : 'LIST');
    }

    const onSearch = (value) => {
        if (mode === 'CONTENT') {
            setContent({
                ...content,
                filter: {
                    ...content.filter,
                    searchText: value,
                    startIndex: 1
                },
                list: [],
                count: 0
            })
        } else if (mode === 'PLAYLIST') {
            setPlaylist({
                ...playlist,
                filter: {
                    ...playlist.filter,
                    searchText: value,
                    startIndex: 1
                },
                list: [],
                count: 0
            })
        } else if (mode == 'RULE_SET') {
            setRuleSet({
                ...ruleSet,
                filter: {
                    ...ruleSet.filter,
                    searchText: value,
                    startIndex: 1
                },
                list: [],
                count: 0
            })
        }
    }

    const onSaveFilter = (filter) => {
        const container = document.querySelector('#simple_external-events');
        if(container !== null) container.scrollTop = 0;
        if (mode === 'CONTENT') {
            const {contents, users, mediaTags, fileSizes} = filter;
            setContent({
                ...content,
                filter: {
                    ...content.filter,
                    startIndex: 1,
                    contentTypes: contents,
                    creatorIds: users,
                    tagIds: mediaTags,
                    fileSizes: fileSizes,
                    approvalStatus: "APPROVED",
                },
                list: [],
                count: 0
            })
        } else if (mode === 'PLAYLIST') {
            const {playlistTypes, users} = filter;
            const types =[];
            playlistTypes.map(
                type => {
                    if (type instanceof Object ){
                        types.push(type.id);
                    } else {
                        types.push(type)
                    }
                }

            )
            setPlaylist({
                ...playlist,
                filter: {
                    ...playlist.filter,
                    startIndex: 1,
                    creatorIds: users,
                    playlistTypes: types,
                },
                list: [],
                count: 0,
            })
        } else if (mode === 'RULE_SET') {

        }

    }

    const onCategoryCheck = (checkedIds) => {
        
        if (mode === 'CONTENT') {
            setContent({...content, list:[], count: 0, filter: {...content.filter, startIndex: 1, categoryIds: checkedIds}})
        } else if (mode === 'PLAYLIST') {
            setPlaylist({...playlist, list:[], count: 0, filter: {...playlist.filter, startIndex: 1, categoryIds: checkedIds}})
        }
    };

    const getSearchValue = () => {
        if (mode === 'CONTENT') {
            return content.filter.searchText !== undefined ? content.filter.searchText : '';
        } else if (mode === 'PLAYLIST') {
            return playlist.filter.searchText !== undefined ? playlist.filter.searchText : '';
        } else if (mode === 'RULE_SET') {
            return ruleSet.filter.searchText !== undefined ? ruleSet.filter.searchText : '';
        }
    }

    useEffect(()=>{
        if (mode === 'CONTENT' && content.list !== undefined && content.count < 1) {
            fetchContents();
        }
    }, [content])

    useEffect(()=> {
        if (mode === 'PLAYLIST' && playlist.list !== undefined && playlist.count < 1) {
            fetchPlaylists();
        }
    }, [playlist])

    useEffect(()=> {
        if (mode === 'RULE_SET' && ruleSet.list !== undefined && ruleSet.count < 1) {
            fetchRuleSets();
        }
    }, [ruleSet])

    useEffect(()=> {
        if (end) {
            switch (mode) {
                case 'CONTENT':
                    if (content.totalCount >= content.filter.startIndex) {
                        setContent({
                            ...content,
                            count: 0
                        })
                    }
                    break;
                case 'PLAYLIST':
                    if (playlist.totalCount >= playlist.filter.startIndex) {
                        setPlaylist({
                            ...playlist,
                            count: 0
                        })
                    }

                    break;
                case 'RULE_SET':
                    if (ruleSet.totalCount >= ruleSet.filter.startIndex) {
                        setRuleSet({
                            ...ruleSet,
                            count: 0
                        })
                    }
                    break;
            }
            setEnd(false)
        }
    }, [end])

    useEffect(()=> ()=>{
        window.removeEventListener('resize', updateDimensions, true);
    }, [])

    const drag = (e) => {
        let el;
        if (e.target.tagName === 'A') {
            el =  e.target.parentElement;
        } else {
            el = e.target.parentElement.parentElement.parentElement;
        }
        const contentType = el.getAttribute('data-content-type');
        const mediaType = el.getAttribute('data-media-type');
        dispatch(scheduleAction.startDrag(el.getAttribute('id'), contentType, mediaType))
        /*e.dataTransfer.setData("name", el.getAttribute('title'));
        e.dataTransfer.setData("id", el.getAttribute('id'));
        if (contentType === 'PLAYLIST') {
            const thumbnailInfo = el.getAttribute('data-thumbnail');
            const thumbs = thumbnailInfo.split('|');
            if (thumbs !== undefined && thumbs.length > 1) {
                e.dataTransfer.setData("thumbnail", thumbs[0]);
            }
        } else {
            e.dataTransfer.setData("thumbnail", el.getAttribute('data-thumbnail'));
        }
        e.dataTransfer.setData("contentType", contentType);*/
    }

    const onRemoveGroupFilter = () => {
        if (mode === 'CONTENT') {
            setContent({...content, list:[], count: 0, filter: {...content.filter, startIndex: 1, groupType: undefined, groupId: undefined, groupName: undefined}})
        } else if (mode === 'PLAYLIST') {
            setPlaylist({...playlist, list:[], count: 0, filter: {...playlist.filter, startIndex: 1, groupType: undefined, groupId: undefined, groupName: undefined}})
        }
    }

    const contentButton = [
        {key: 'CONTENT_SCHEDULE_CONTENT_BUTTON', title: "DID_MAIN_CONTENT", value:'CONTENT', popup: false, onClick: ()=>changeContent('CONTENT')},
        {key: 'CONTENT_SCHEDULE_PLAYLIST_BUTTON', title: "TEXT_TITLE_PLAYLIST_P", value: 'PLAYLIST', popup: false, onClick: ()=>changeContent('PLAYLIST')},
        {key: 'CONTENT_SCHEDULE_INPUT_SOURCE_BUTTON', title: "TABLE_INPUT_SOURCE_P", value: 'INPUT_SOURCE', popup: false, onClick: ()=>changeContent('INPUT_SOURCE')},
        {key: 'CONTENT_SCHEDULE_RULE_SET_BUTTON', title: "MIS_SID_RULESET", popup: false, value: 'RULE_SET', onClick: ()=>changeContent('RULE_SET')}];

    return (
        <div className={"content_schedule_wrap"}>

            <div className="schedule_lnb_title" style={{display: 'flex'}} >
                {
                    programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                        <SelectBox selects={contentButton} value={mode} whiteButton={true} width={110}/>
                }

                {
                    (mode === 'CONTENT' || mode === 'PLAYLIST') &&
                    <CategoryButton defaultCheckedKeys={mode === 'CONTENT' ? content.filter.categoryIds || [] : playlist.filter.categoryIds || []}  onCategoryCheck={onCategoryCheck} whiteButton={true}/>
                }
            </div>
            {
                mode === 'CONTENT'  && content.filter !== undefined && content.filter.groupId !== undefined && content.filter.groupType === 'GROUPED' &&
                <div style={{padding: 20, paddingBottom:5}}>
                    <Chip title={content.filter.groupName} isWhiteBackground height={25} onDelete={onRemoveGroupFilter}/>
                </div>
            }
            {
                mode === 'PLAYLIST'  && playlist.filter !== undefined && playlist.filter.groupId !== undefined && playlist.filter.groupType === 'GROUPED' &&
                <div style={{padding: 20, paddingBottom:5}}>
                    <Chip title={playlist.filter.groupName} isWhiteBackground height={25} onDelete={onRemoveGroupFilter}/>
                </div>
            }
            {
                (mode !== 'INPUT_SOURCE') &&
                <div style={{height: 70}}>
                    <div className="list_top">
                        <GroupButton type={"contentSchedule"} onClick={()=>onGroupPopup()}/>
                        {
                            mode === 'CONTENT' &&
                            <Filter type={"content_schedule"} width={765} onSaveFilter={(filter)=>onSaveFilter(filter)}/>
                        }
                        {
                            mode === 'PLAYLIST' && programType !== CONTENT_SCHEDULE_PROGRAM_TYPE_AD &&
                            <Filter type={"content_schedule_playlist"} width={390} onSaveFilter={(filter)=>onSaveFilter(filter)}/>
                        }
                        <SwitchListNImage type={"contentSchedule"} view={view} onClick={()=>onChangeListNImage()}/>
                        {
                            mode === 'CONTENT' && <SearchBar width={85} type={"contentSchedule"} onClickSearch={(value)=>onSearch(value)} keyword={content.filter.searchText !== undefined && content.filter.searchText !== '' ? content.filter.searchText : ''}/>
                        }
                        {
                            mode === 'PLAYLIST' && <SearchBar width={85} type={"contentSchedule"} onClickSearch={(value)=>onSearch(value)} keyword={playlist.filter.searchText !== undefined && playlist.filter.searchText !== '' ? playlist.filter.searchText : ''}/>
                        }
                        {
                            mode === 'RULE_SET' && <SearchBar width={85} type={"contentSchedule"} onClickSearch={(value)=>onSearch(value)} keyword={ruleSet.filter.searchText !== undefined && ruleSet.filter.searchText !== '' ? ruleSet.filter.searchText : ''}/>
                        }
                    </div>
                </div>
            }

            <div id="simple_external-events" style={{display: 'block',position: 'absolute', height: style.height, overflow: 'auto', width: '100%'}}>
                {
                    isFetching &&
                    <div className={'loading'}>
                        <Loading
                            isLoading={content.isFetching || playlist.isFetching || ruleSet.isFetching}
                        />
                    </div>
                }


                <ul className="schedule_contents scrollbar" id={"submenu_schedule_contents_scroll"}>
                    {
                        // for contents
                        mode === 'CONTENT' && content !== undefined && content.list.length > 0 &&
                        content.list.map(
                            (item, index) =>
                                view === 'IMAGE' ?
                                    <li className="thumbnail" key={item.contentId}>
                                        <div className="thumbnail_box" draggable={true} onDragStart={drag} type={scheduleConstants.CONTENT_SCHEDULE_TYPE_CONTENT} title={item.contentName} id={item.contentId} data-media-type={item.mediaType} data-content-type={'CONTENT'} data-thumbnail={item.thumbFileId} >
                                            <ContentThumbnail id={item.thumbFileId} filename={item.thumbFileName} width={100} height={56} index={index} icon={true} contentType={item.mediaType}/>
                                        </div>
                                        <p>{item.contentName}</p>
                                    </li> :
                                    <li className={'list'} key={item.contentId} style={{display: 'flex'}} >
                                        <div className="thumbnail_box"  draggable={true} onDragStart={drag} style={{width:90,height:50}} type={scheduleConstants.CONTENT_SCHEDULE_TYPE_CONTENT} title={item.contentName} id={item.contentId} data-media-type={item.mediaType} data-content-type={'CONTENT'} data-thumbnail={item.thumbFileId}>
                                            <ContentThumbnail id={item.thumbFileId} filename={item.thumbFileName} width={100} height={56} icon={true} contentType={item.mediaType}/>
                                        </div>
                                        <div className="detail">
                                            <div className="contentName">{item.contentName}</div>
                                            <div className="contentType">{item.mediaType}</div>
                                            <div className="duration">{item.playTimeInString}</div>
                                        </div>
                                    </li>
                        )
                    }
                    {
                        // for playlist
                        mode === 'PLAYLIST' && playlist !== undefined && playlist.list.length > 0 &&
                        playlist.list.map(
                            item =>
                                view === 'IMAGE' ?
                                    <li className="thumbnail" key={item.playlistId}>
                                        <div className="thumbnail_box" draggable={true} onDragStart={drag} type={scheduleConstants.CONTENT_SCHEDULE_TYPE_PLAYLIST} title={item.playlistName} id={item.playlistId} data-media-type={item.playlistType} data-content-type={'PLAYLIST'} data-thumbnail={item.thumbnailInfo} >
                                            <ContentThumbnail id={getThumbnailId(item.thumbnailInfo, 0)} filename={getThumbnailId(item.thumbnailInfo, 1)} width={100} height={56}/>
                                        </div>
                                        <p>{item.playlistName}</p>
                                    </li> :
                                    <li className={'list'} key={item.playlistId} style={{display: 'flex'}}>
                                        <div className="thumbnail_box" draggable={true} onDragStart={drag} style={{width:90,height:50}} type={scheduleConstants.CONTENT_SCHEDULE_TYPE_PLAYLIST} title={item.playlistName} id={item.playlistId} data-media-type={item.playlistType} data-content-type={'PLAYLIST'} data-thumbnail={item.thumbnailInfo} >
                                            <ContentThumbnail id={getThumbnailId(item.thumbnailInfo, 0)} filename={getThumbnailId(item.thumbnailInfo, 1)} width={100} height={56}/>
                                        </div>
                                        <div className="detail">
                                            <div className="contentName">{item.playlistName}</div>
                                            <div className="contentType">{getPlaylistType(item.playlistType)}</div>
                                            <div className="duration">{item.playTime}</div>
                                        </div>
                                    </li>
                        )
                    }
                    {
                        // for input source
                        mode === 'INPUT_SOURCE' &&
                        inputSource.map(
                            item =>
                                <li className="source" key={item.value} draggable={true} onDragStart={drag} type={scheduleConstants.CONTENT_SCHEDULE_TYPE_INPUT_SOURCE} id={item.value} title={item.text} data-content-type={scheduleConstants.CONTENT_SCHEDULE_TYPE_INPUT_SOURCE}>
                                    <a href={"#"} className={"thumbnail_box"}>
                                        <span className={item.icon}></span>
                                        {item.text}
                                    </a>
                                </li>
                        )
                    }
                    {
                        // for rule set
                        mode === 'RULE_SET' && ruleSet !== undefined && ruleSet.list.length > 0 ?
                            ruleSet.list.map(
                                item =>
                                    view === 'IMAGE' ?
                                        <li className="thumbnail" key={item.rulesetId}>
                                            <div className="thumbnail_box" type="rulset" draggable={true} onDragStart={drag} type={scheduleConstants.CONTENT_SCHEDULE_TYPE_RULE_SET} title={item.name} id={item.rulesetId} data-content-type={'RULESET'} data-thumbnail={item.thumbFileId} >
                                                <ContentThumbnail id={item.thumbFileId} filename={item.thumbFileName} width={100} height={56}/>
                                            </div>
                                            <p>{item.name}</p>
                                        </li> :
                                        <li className={'list'} key={item.rulesetId} draggable={true} onDragStart={drag} style={{display: 'flex'}}>
                                            <div className="thumbnail_box" style={{width:90,height:50}} type={scheduleConstants.CONTENT_SCHEDULE_TYPE_RULE_SET} title={item.name} id={item.rulesetId} data-media-type={'RULESET'} data-content-type={'RULESET'} data-thumbnail={item.thumbFileId} >
                                                <ContentThumbnail id={item.thumbFileId} filename={item.thumbFileName} width={100} height={56}/>
                                            </div>
                                            <div className="detail">
                                                <div className="contentName">{item.name}</div>
                                            </div>
                                        </li>
                            )
                            : ''
                    }
                </ul>

            </div>
        </div>
    )


}
export default SubmenuSimpleScheduleContainer;