import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Moment from 'react-moment';
import {isNil, toInteger, toUpper} from 'lodash';
import {toastr} from 'helper/toastrIntercept';
import Size from '../../../utils/Size';
import {
    APLAYER,
    commonConstants,
    DEVICE_TYPE_ANDROID,
    DEVICE_TYPE_FLIP,
    DEVICE_TYPE_I,
    DEVICE_TYPE_LITE,
    DEVICE_TYPE_S,
    DEVICE_TYPE_SIGNAGE,
    FLIP,
    IPLAYER
} from '../../../../constants';
import {useMISOpt} from '../../../misopt';
import {menuAction, popupAction} from '../../../../actions';
import {deviceService} from '../../../../services';
import {getErrorMessage} from "../../../../helper/responseHandler";

const getDeviceTypeStr = (deviceType, deviceTypeVersion) => {
    let deviceTypeString = '';
    if(deviceType){
        switch(deviceType.toUpperCase()){
            case 'IPLAYER':
                deviceTypeString = DEVICE_TYPE_I;
                break;
            case 'SPLAYER':
                if(parseInt(deviceTypeVersion) > 1){
                    deviceTypeString = DEVICE_TYPE_S + ' ' + parseInt(deviceTypeVersion);
                } else {
                    deviceTypeString = DEVICE_TYPE_S;
                }
                break;
            case 'SIGNAGE':
                deviceTypeString = DEVICE_TYPE_SIGNAGE;
                break;
            case 'APLAYER':
                deviceTypeString = DEVICE_TYPE_ANDROID;
                break;
            case 'LPLAYER':
                deviceTypeString = DEVICE_TYPE_LITE;
                break;
            case 'FLIP':
                if(parseInt(deviceTypeVersion) > 1){
                    deviceTypeString = DEVICE_TYPE_FLIP + ' ' + parseInt(deviceTypeVersion);
                } else {
                    deviceTypeString = DEVICE_TYPE_FLIP;
                }
                break;
            default:
                deviceTypeString = deviceType;
        }
    }
    return deviceTypeString;
};

const VersionInfo = ({versions}) => {
    return (versions && versions.length > 0) ? versions.map((ver, i) => (<div key={i}>{ver}</div>)) : null;
};

function convertMomentDateTimeFormatWithSec(dateFormat, timeFormat) {
    return `${dateFormat.toUpperCase()} ${timeFormat === 'ampm' ? 'hh:mm:ss.SSSSSS A' : 'HH:mm:ss.SSSSSS'}`;
}

const DeviceInfoDetail = ({device}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {misopt: {user: {dateFormat, timeFormat}}, misOption} = useMISOpt();
    const dateTimeFormat = convertMomentDateTimeFormatWithSec(dateFormat, timeFormat);
    const resultFetchHandle = useRef();
    const fetchCount = useRef(0);
    const option = misOption(device.deviceType.toUpperCase(), Number(device.deviceTypeVersion)) || {};
    const {submenu} = useSelector(state => state.menu);

    const fetchPresetResult = (callback) => {
        dispatch(popupAction.addPopup({id: commonConstants.LOADING_POPUP, type: commonConstants.LOADING_POPUP}));
        deviceService.fetchDevicePresetResult(device.deviceId, fetchCount.current).then(res => {
            if (typeof res.items !== 'undefined' &&  typeof res.items.preconfigResult === 'undefined')   {
                if(fetchCount.current >= 5) {
                    dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                    clearInterval(resultFetchHandle.current);
                    fetchCount.current = 0;
                    toastr.error(t('MESSAGE_COMMON_NO_DATA_P'));
                } else {
                    fetchCount.current ++;
                    if(callback) {
                        resultFetchHandle.current = setInterval(callback, 2000);
                    }
                }
            } else {
                clearInterval(resultFetchHandle.current);
                fetchCount.current = 0;
                dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                dispatch(popupAction.addPopup({
                    id: commonConstants.PRESET_RESULT,
                    type: commonConstants.PRESET_RESULT,
                    presetResult: res.items,
                    onClose: () => dispatch(popupAction.closePopup(commonConstants.PRESET_RESULT))
                }));
            }
        }).catch(error => {
            if(error.errorCode === '404001') {
                fetchCount.current ++;
                if(callback) {
                    resultFetchHandle.current = setInterval(callback, 2000);
                }
            } else {
                dispatch(popupAction.closePopup(commonConstants.LOADING_POPUP));
                clearInterval(resultFetchHandle.current);
                fetchCount.current = 0;

                if(error.errorCode === '404000' || fetchCount.current >= 5) {
                    toastr.error(t('MESSAGE_COMMON_NO_DATA_P'));
                } else if (error.errorCode === '400631') {
                    toastr.error(getErrorMessage(error, error.errorMessage) )
                } else {
                    toastr.error(t('MESSAGE_COMMON_ERROR_P'));
                }
            }
        });
    };

    const onPresetResult = () => {
        fetchCount.current = 0;
        clearInterval(resultFetchHandle.current);
        fetchPresetResult(() => fetchPresetResult());
    };

    const onClickGroup = (depth) => async (e) =>{
        try {
            const group = submenu.nodes[0].children[1].group;
            if (group && group.length > 0) {
                dispatch(menuAction.selectGroup('DEVICE_BY_GROUP',
                    device.groupPathList.filter((g, i) => i <= depth).map(g => String(g.groupId))));
            } else {
                await dispatch(menuAction.loadGroup('DEVICE','DEVICE_BY_GROUP',null, device.groupPathList.filter((g, i) => i <= depth).map(g => String(g.groupId))))
                dispatch(menuAction.selectGroup('DEVICE_BY_GROUP',
                    device.groupPathList.filter((g, i) => i <= depth).map(g => String(g.groupId))));
            }
        }
        catch(e) {
        }
    };

    const onClickSchedule = (id, type) => {
        dispatch(popupAction.openDetailView({type: commonConstants.COMMON_DETAIL_VIEW, viewType: type, id: id}));
    };

    useEffect(() => {
        return () => clearInterval(resultFetchHandle.current);
    }, []);

    return (
        <div className="detail_view mini_1 mt15" >
            <table>
                <colgroup>
                    <col width="148px"/>
                    <col width=""/>
                </colgroup>
                <tbody>
                    {
                        (option.contentSchedule || option.messageSchedule || option.eventSchedule) &&
                        <>
                            <tr className="bullet_line">
                                <th colSpan="2" style={{paddingBottom:6}}>{t('TEXT_TITLE_SCHEDULE_P')}</th>
                            </tr>
                            {
                                option.contentSchedule && 
                                <tr className="bullet_line">
                                    <th><span className="bull">{t('TEXT_CONTENT_P')}</span></th>
                                    <td>
                                        <div className="autoline">
                                            {
                                                (toUpper(device.isDefaultContentSchedule) === 'Y' || !device.contentScheduleName) ?
                                                <span>-</span> :
                                                <span className={device.contentScheduleName ? "data_name" : ''} onClick={device.contentScheduleName ? () => onClickSchedule(device.contentScheduleId, 'SCHEDULE_CONTENT') : undefined}>
                                                    {device.contentScheduleName || '-'}
                                                </span>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            }
                            {
                                option.messageSchedule && 
                                <tr className="bullet_line">
                                    <th><span className="bull">{t('TEXT_MESSAGE_P')}</span></th>
                                    <td>
                                        <div className="autoline">
                                            <span className={device.messageScheduleName ? "data_name" : ''} onClick={device.messageScheduleName ? () => onClickSchedule(device.messageScheduleId, "SCHEDULE_MESSAGE") : undefined}>
                                                {device.messageScheduleName || '-'}</span>
                                        </div>
                                    </td>
                                </tr>
                            }
                            {
                                option.eventSchedule && 
                                <tr className="bullet_line">
                                    <th><span className="bull">{t('COM_IDS_EVENT_MAMAIN')}</span></th>
                                    <td>
                                        <div className="autoline">
                                            <span  className={device.eventScheduleName ? "data_name" : ''} onClick={device.eventScheduleName ? () => onClickSchedule(device.eventScheduleId, "SCHEDULE_EVENT") : undefined}>
                                                {device.eventScheduleName || '-'}</span>
                                        </div>
                                    </td>
                                </tr>
                            }
                        </>
                    }
                    <tr className="bullet_line">
                        <th>{t('MIS_TEXT_DEVICE_TYPE_P')}</th>
                        <td>
                            <div className="autoline">{getDeviceTypeStr(device.deviceType, device.deviceTypeVersion)}</div>
                        </td>
                    </tr>
                    {
                        device.preconfigVersion &&
                        <tr className="bullet_line">
                            <th>{t('MIS_SID_SERVER_PRESET')}</th>
                            <td>
                                <div className="autoline">
                                    {
                                        device.preconfigVersion === '-' ? '-' :
                                        <span className='link_cell' onClick={onPresetResult}>
                                            {`${device.preconfig ? device.preconfig.name : ''} (${t('MIS_SID_SERVER_APPLIED')} ${device.preconfigSuccess}/${device.preconfigSuccess + device.preconfigFailed})`}
                                        </span>
                                    }
                                </div>
                            </td>
                        </tr>
                    }
                    {
                        device.deviceType !== FLIP &&
                        <tr className="bullet_line">
                            <th>{t('TABLE_DEVICE_SERIAL_P')}</th>
                            <td>
                                <div className="autoline">{device.deviceSerial || 'none'}</div>
                            </td>
                        </tr>
                    }
                    <tr className="bullet_line">
                        <th>{t('TEXT_GROUP_P')}</th>
                        <td>
                            <div className="float_l link">
                                {
                                    device.groupPathList.map(({groupName}, i) => {
                                        return (
                                            <React.Fragment key={`${groupName}_${i}`}>
                                                <span className='link_cell' onClick={onClickGroup(i)}>{groupName}</span>
                                                {
                                                    i < (device.groupPathList.length - 1) &&
                                                    <span>&gt;</span>
                                                }
                                            </React.Fragment>
                                        );
                                    })
                                }
                            </div>
                        </td>
                    </tr>
                    {
                        (device.deviceType === IPLAYER || device.deviceType === APLAYER) ?
                        <tr className="bullet_line">
                            <th>{t('MIS_SID_SERVER_PLAYER_VERSION')}</th>
                            <td>
                                <div className="float_l link">
                                    {
                                        <VersionInfo versions={device.applicationVersion ? device.applicationVersion.split(',') : []} />
                                    }
                                </div>
                            </td>
                        </tr> :
                        device.playerVersion && 
                        <tr className="bullet_line">
                            <th>{t('MIS_SID_SERVER_PLAYER_VERSION')}</th>
                            <td>
                                <div className="float_l link">
                                    <VersionInfo versions={device.playerVersion.split(',')} />
                                </div>
                            </td>
                        </tr>
                    }
                    <tr className="bullet_line">
                        <th>{t('TABLE_MAC_ADDR_P')}</th>
                        <td>
                            <div className="autoline">{device.deviceId}</div>
                        </td>
                    </tr>
                    {
                        device.rmRuleVersion &&
                        <tr className="bullet_line">
                            <th>RM Rule</th>
                            <td>
                                <div className="autoline">
                                    {device.rmRuleVersion}
                                </div>
                            </td>
                        </tr>
                    }
                    <tr className="bullet_line">
                        <th>{t('MIS_TEXT_LAST_CHECK_TIME_P')}</th>
                        <td>
                            <div className="autoline">
                                <Moment format={dateTimeFormat}>{device.lastConnectionTime}</Moment>
                            </div>
                        </td>
                    </tr>
                    {
                        device.deviceType !== APLAYER &&
                        <tr className="bullet_line">
                            <th>{t('TABLE_FIRMWARE_VERSION_P')}</th>
                            <td>
                                <div className="autoline">
                                    {
                                        (device.deviceType === IPLAYER) ?
                                            <VersionInfo versions={device.firmwareVersion ? device.firmwareVersion.split(',') : []} /> :
                                            <VersionInfo versions={device.applicationVersion ? device.applicationVersion.split(';') : []} />
                                    }
                                </div>
                            </td>
                        </tr>
                    }
                    {
                        device.osImageVersion &&
                        <tr className="bullet_line">
                            <th>{t('TABLE_OS_IMAGE_VERSION_P')}</th>
                            <td>
                                <div className="autoline">
                                    {
                                        (device.deviceType === IPLAYER || device.deviceType === APLAYER) ?
                                        <VersionInfo versions={device.osImageVersion ? device.osImageVersion.split(',') : []} /> :
                                        device.osImageVersion
                                    }
                                </div>
                            </td>
                        </tr>
                    }
                    <tr className="bullet_line">
                        <th>{t('TABLE_DISK_SPACE_AVAILABLE_P')}</th>
                        <td>
                            <div className="autoline">
                                {
                                    !isNil(device.diskSpaceRepository) ?
                                    <Size size={device.diskSpaceRepository} /> :
                                    device.diskSpaceAvailable && device.diskSpaceAvailable.split(';').map((e, i) => {
                                        const [drive, size] = e.split(':');
                                        return (
                                            drive && size &&
                                            <div key={`available-${i}`}>
                                                {drive}: <Size size={toInteger(size * 1024)} />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </td>
                    </tr>
                    <tr className="bullet_line">
                        <th>{t('TABLE_IP_ADDR_P')}</th>
                        <td>
                            <div className="autoline">{device.ipAddress}</div>
                        </td>
                    </tr>
                    { 
                        device.disconnectedTime && 
                        <tr className="bullet_line">
                            <th>{t('TEXT_DISCONNECTED_P')}</th>
                            <td>
                                <div className="autoline">{device.disconnectedTime}</div>
                            </td>
                        </tr>
                    }
                    <tr className="bullet_line">
                        <th>{t('TABLE_DEVICE_MODEL_NAME_P')}</th>
                        <td>
                            <div className="autoline">{device.deviceModelName}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default DeviceInfoDetail;