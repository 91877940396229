import React, {useState, useRef, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import MISDialog from '../MISDialog';
import './ContentShare.css';
import {useDispatch} from "react-redux";
import {popupAction, menuAction} from "../../../actions";
import {contentService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import {contentConstants} from "../../../constants";
import {useTrGroupProps} from '../../../helper/tables';
import ReactTable from "react-table";


const ContentShare = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {id, contentIds} = props;

    const [data, setData] = useState({
        items: [],
        loading: false,
        totalCount: 0,
        pages: 0
    });

    const [style, setStyle] = useState({
        height: '335px'
    });

    const [selectIndex, setSelectIndex] = useState(0);

    const {items = [], loading = false} = data;

    const closePopup = () => {
        dispatch(popupAction.closePopup(id));
    };

    const fetchData = () => {
        const params = {
            groupType : contentConstants.SHARED_GROUP
        };
        contentService.fetchGroups(params).then(res => {
            if(res.status == 'Success'){
                setData({...data, items: res.items});
            }
        }).catch(error => {

        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const shareContent = () => {
        const groupId = items[selectIndex].groupId;
        const params = {
            sharedGroupId: groupId,
            contentIds: contentIds
        };

        contentService.shareContent(params).then(res => {
            if(res.status == 'Success'){
                toastr.success(t('MIS_MESSAGE_STATISTICS_SAVE_P'));
                dispatch(menuAction.reloadGroup('SHARED_CONTENT', 0));
            };
        }).catch(error => {
            let errorMessage = error.errorMessage;
            switch(error.errorCode) {
                case '403001':
                    errorMessage = t('ERROR_NO_ROLE');
                    break;
            }
            toastr.error(errorMessage);
        }).finally(() => {
            closePopup(id);
        });
    };

    const getTrProps = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e) => {
                    setSelectIndex(rowInfo.index);
                },
                className: selectIndex === rowInfo.index ? 'selected_row' : ''
            }
        }else{
            return {}
        }
    };

    const [getTrGroupPropsType1] = useTrGroupProps(null, null, null,'content_share_tr_group');

    return (
        <div>
            <MISDialog dialog={{
                title: t('MIS_SID_SELECT_FOLDER'),
                isDraggable: false,
                closeOnEscape: true,
                modal: true,
                width: 302,
                height: 336,
                onClose: () => closePopup(id),
            }}
                       buttons={{
                           rightButtons: [
                               {
                                   id: 'SAVE',
                                   title: t('COM_BUTTON_SAVE'),
                                   disable: items.length < 1,
                                   onClick: () => shareContent(),
                               },
                               {
                                   id: 'CANCEL',
                                   title: t('BUTTON_CANCEL_P'),
                                   onClick: () => closePopup(id),
                               }
                           ],
                       }}>
                <div className='content_share_popup'>
                    <ReactTable
                        data={items}
                        minRows={0}
                        getTrGroupProps={getTrGroupPropsType1}
                        getTrProps={getTrProps}
                        TheadComponent={props => null}
                        showPagination={false}
                        resizable={false}
                        loading={loading}
                        noDataText={t('MESSAGE_COMMON_NO_DATA_P')}
                        columns={
                            [
                                {
                                    Header: null,
                                    width: 249,
                                    accessor: 'groupName',
                                }
                            ]
                        }
                        className="-striped -highlight"
                        manual
                        style={style}
                    />
                </div>
            </MISDialog>
        </div>
    );
};

export default ContentShare;