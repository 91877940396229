import React from 'react';
import {useTranslation} from "react-i18next";
import Checkbox from "../checkbox/Checkbox";

const RulesetStatus = ({rulesetStatus, rulesetStatusCheckAll, toggleRulesetStatusAll, rulesetStatusCheckBox, toggleRulesetStatus}) => {
    const {t} = useTranslation();

    return (
        <div className="dev_filter_type_checkbox" id="filter_list_rulesetStatusFilter"><h5><span
            className="rulesetStatusFilter" style={{background:'#009966'}}></span>{t("TEXT_STATUS_P")}</h5>
            <div>
                <ul className="type_checklist">
                    <li>
                        <Checkbox
                            id={"ruleset_status_ALL"}
                            name={t("TEXT_ALL_P")}
                            ref={rulesetStatusCheckAll}
                            onChange={toggleRulesetStatusAll}
                            defaultChecked={true}
                        />
                    </li>
                    { rulesetStatus && rulesetStatus.map((status, index) => (
                        <li key={index}>
                            <Checkbox
                                id={"ruleset_status_" + status.value}
                                name={t(status.label)}
                                ref={ref => rulesetStatusCheckBox[status.value] = ref}
                                onChange={() => toggleRulesetStatus(status)}
                                defaultChecked={true}
                            />
                        </li>
                    ))

                    }
                </ul>
            </div>
        </div>
    )
}

export default RulesetStatus;