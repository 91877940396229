import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import SwitchListNImage from "../../switch/SwitchListNImage";
import SearchBar from "../../search/SearchBar";
import classNames from "classnames";
import {playlistService} from "../../../services";
import {toastr} from 'helper/toastrIntercept';
import ContentThumbnail from "../../image/ContentThumbnail";
import Size from "../../utils/Size";
import PerfectScrollbar from 'perfect-scrollbar';
import DateToStr from "../../utils/DateToStr";
import {Loading} from "../../loading/Loading";
import MyGroups from "./MyGroups";
import SupportedDeviceIcon from "../../icon/SupportedDeviceIcon";
import MultiSelectButton from "../../button/MultiSelectButton";
import {
    CONTENT_SCHEDULE_CONFIG,
    CONTENT_SCHEDULE_PROGRAM_TYPE_LFD,
    CONTENT_SCHEDULE_PROGRAM_TYPE_VWL,
    playlistConstants
} from "../../../constants";


const getThumbnailId = (thumbnailInfo, index) => {
    const images = thumbnailInfo.split("|");
    if (images.length > 1) {
        return images[index]
    } else {
        return ''
    }
}

const ListView = ({multiple, selectedIds, playlists, selectedId, handleSelect}) => {

    const {t} = useTranslation();
    const checkSelectedPlaylist = (playlistId) => {
        if (multiple) {
            return selectedIds.find(s=> s === playlistId) !== undefined
        } else {
            if (selectedIds !== undefined && selectedIds.length > 0) {
                return selectedIds[0] === playlistId
            }
        }
        return false;
    }

    return (
        <table style={{width: '100%'}}>
            <colgroup>
                <col width="87px"/>
                <col width=""/>
            </colgroup>
            <tbody>
            {
                playlists !== undefined && playlists.length > 0 &&
                playlists.map(
                    item  =>
                        <tr key={item.playlistId} className={classNames({'on' : checkSelectedPlaylist(item.playlistId)})} onClick={()=>handleSelect(item.playlistId, item.playlistName, item.thumbnailInfo, item.playlistType, item.totalSize)}>
                            <td className="thum">
                                <div className="thumb_wrap">
                                    <div style={{width:'75px',height:'42px',textAlign:'center',lineHeight:'42px'}}>
                                        <ContentThumbnail id={getThumbnailId(item.thumbnailInfo, 0)} width={75} height={42}/>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <h4 className="contentName">
                                    <span className="text-overflow" style={{width:'300px'}}>{item.playlistName}</span>
                                </h4>
                                <ul>
                                    <li className="supportedIcon">
                                        {t("COM_MAPP_SID_SUPPORTED_DEVICES")} : <SupportedDeviceIcon deviceType={item.deviceType} deviceTypeVersion={item.deviceTypeVersion}/>
                                    </li>
                                    <li>{t("ADMIN_CONTENT_REGISTRATION_CONTENTS_LIST_JSP_FILESIZE")} : <span><Size size={item.totalSize}/></span></li>
                                    <li>{t("COM_TEXT_MODIFY_DATE_P")} : <span><DateToStr date={item.lastModifiedDate} newLine={false}/></span></li>
                                </ul>
                            </td>
                        </tr>
                )
            }
            </tbody>
        </table>
    )
}

const ImageView  = ({multiple, selectedIds, playlists, handleSelect}) => {

    const checkSelectedPlaylist = (playlistId) => {
        if (multiple) {
            return selectedIds.find(s=> s === playlistId) !== undefined
        } else {
            if (selectedIds !== undefined && selectedIds.length > 0) {
                return selectedIds[0] === playlistId
            }
        }
        return false;
    }

    return (
        <div className={'thumbnailView_wrap'}>
            <ul className="clearfix mt20">
                {
                    playlists !== undefined && playlists.length > 0 &&
                    playlists.map(
                        item =>
                            <li>
                                <div style={{cursor:'pointer'}} className={classNames('thumbview_box', {'on' : checkSelectedPlaylist(item.playlistId)})} onClick={()=>handleSelect(item.playlistId, item.playlistName, item.thumbnailInfo, item.playlistType, item.totalSize)}>
                                    <div style={{width:75,height:42,textAlign:'center'}}>
                                        <ContentThumbnail id={getThumbnailId(item.thumbnailInfo, 0)} width={75} height={42}/>
                                    </div>
                                    <div className="device_info"><span className="contentName">{item.playlistName}</span></div>
                                    <div className="check"><span></span></div></div>
                            </li>
                    )
                }
            </ul>
        </div>
    )
}

export const Playlists = ({multiple, onSelect, filter, selectedId = undefined, selectedIds, programType = CONTENT_SCHEDULE_PROGRAM_TYPE_LFD, isVwlMode = false}) => {

    const {t} = useTranslation();

    const [view, setView] = useState('LIST');

    const [playlist, setPlaylist] = useState({
        filter: filter,
        list: [],
        isFetching: false,
        loading: false,
        count: 0
    })

    const [active, setActive] = useState('ALL');

    const [end, setEnd] = useState(false);

    const fetchPlaylists = () => {
        setPlaylist({
            ...playlist,
            loading: true
        })
        playlistService.fetchPlaylistForSchedule(playlist.filter)
            .then(res => {
                setPlaylist({
                    ...playlist,
                    list: playlist.list.concat(res.items),
                    isFetching: false,
                    loading: false,
                    count: playlist.count + 1,
                    totalCount: res.totalCount,
                    filter: {
                        ...playlist.filter,
                        startIndex: playlist.filter.startIndex + res.items.length
                    }
                })
            }).catch(res=> toastr.error(res))
    }

    const onSearch = (value) => {
        setPlaylist({...playlist, list: [], loading: false, isFetching: false, count: 0, filter: {...playlist.filter, startIndex: 1, searchText: value}});
    }

    const handleSelect = (id, name, thumbnailInfo, playlistType, totalSize) => {
        const getThumbnailId = (thumbnailInfo, index) => {
            const images = thumbnailInfo.split("|");
            if (images.length > 1) {
                return images[index]
            } else {
                return ''
            }
        }        
        onSelect(id, name, getThumbnailId(thumbnailInfo), playlistType, parseInt(totalSize));
    }

    const handleListNImage = () => {
        setView(view === 'LIST' ? 'IMAGE' : 'LIST');
    }

    const activeMenu = (menu) => {
        if (menu === 'ALL') {
            setPlaylist({...playlist, list: [], loading: false, isFetching: false, count: 0, filter: {...playlist.filter, startIndex: 1,  groupId: undefined, groupType: 'ALL'}})
        }
        setActive(menu);
    }

    const selectGroup = (groupId) => {
        if (groupId) {
            resetScrollPosition();
            setPlaylist({...playlist, list: [], loading: false, isFetching: false, count: 0, filter: {...playlist.filter, startIndex: 1,  groupId: groupId, groupType: 'GROUPED'}})
        }
    }

    const resetScrollPosition = () => {
        const container = document.querySelector('#select_playlist_scroll_wrap');
        if (container) {
            container.scrollTop = 0;
        }
    }

    const getPlaylistTypes = useCallback(()=> {
        const playlistTypes = [];
        const filterPlaylistTypes = playlist.filter.playlistTypes;
        const supportPlaylistTypes = programType === CONTENT_SCHEDULE_PROGRAM_TYPE_VWL && isVwlMode ? ['2'] : CONTENT_SCHEDULE_CONFIG[programType].supportedPlaylistFilter;
        supportPlaylistTypes.map(
            id => {
                const playlistType = filterPlaylistTypes.find(playlistType => playlistType === id);
                const type = playlistConstants.PLAYLISTTYPES.find(playlist => playlist.id === String(id));
                if (type !== undefined) {
                    playlistTypes.push({ id:  type.id, name : t(type.name), isChecked: playlistType !== undefined ? true : false })
                }
            }
        )
        return playlistTypes;
    }, [filter])

    const onChangePlaylistType = (Ids) => {
        setPlaylist({
            ...playlist,
            list: [],
            loading: false,
            isFetching: false,
            count: 0,
            filter: {
                ...playlist.filter,
                playlistTypes : Ids,
                startIndex: 1
            }
        })
    }

    const initScroll = useRef(false);
    const perfectScrollbar = useRef(undefined);

    useEffect(()=>{
        if (!initScroll.current) {
            const ps = new PerfectScrollbar('#select_playlist_scroll_wrap', {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true
            });
            document.querySelector('#select_playlist_scroll_wrap').addEventListener('ps-y-reach-end', () => {
                setEnd(true);
            });
            perfectScrollbar.current = ps;
            initScroll.current = true;
        } else {
            perfectScrollbar.current.update();
        }
    }, [playlist.list])

    useEffect(()=>{
        if (playlist !== undefined && !playlist.loading && !playlist.isFetching && playlist.list !== undefined && playlist.list.length === 0 && playlist.count < 1) {
            resetScrollPosition();
            fetchPlaylists();
        }
        if (playlist !== undefined && !playlist.loading && playlist.isFetching) {
            fetchPlaylists();
        }
    }, [playlist])

    useEffect(()=> {
        if (end) {
            if (playlist.totalCount > playlist.filter.startIndex) {
                setPlaylist({
                    ...playlist,
                    filter: {
                        ...playlist.filter,
                    },
                    isFetching: true
                });
            }
            setEnd(false);
        }
    }, [end])

    return (
        <div className="" style={{display: 'inline-flex'}}>
            <div className="lnb">
                <ul>
                    <li className={classNames({'on' : active === 'ALL'})} onClick={()=>activeMenu('ALL')}>
                        <a href="#" id="allContentListBtn">{t("COM_SID_ALL")}</a>
                    </li>
                    <li className={classNames({'on' : active === 'MY_CONTENT'})} onClick={()=>activeMenu('MY_CONTENT')}>
                        <a href="#" id="myContentListBtn">{t("TEXT_TITLE_MY_PLAYLIST_VIEW_P")}</a>
                        {
                            active === 'MY_CONTENT' && <MyGroups mode={'PLAYLIST'} selectGroup={selectGroup}/>
                        }
                    </li>
                </ul>
            </div>
            <div className="schedule_content_wrap">
                <div className="mt10 mr10 mb10 ml20" style={{height: 30}}>
                    <div style={{float: 'left'}}>
                        <span className="space"></span></div>
                    <div style={{float: 'right'}}>
                        <MultiSelectButton itemAll={true} title={t("TABLE_TYPE_P")} data={getPlaylistTypes()} onChange={onChangePlaylistType}/>
                        <SwitchListNImage view={view} onClick={handleListNImage}/>
                        <SearchBar width={150} onClickSearch={onSearch} placeholder={t("TABLE_PLAYLIST_NAME_P")}/>
                    </div>
                </div>
                <div className="pop_list" id={'select_playlist_scroll_wrap'} style={{height:'365px', borderTop: '1px solid #e7e7e7', display: 'block', position: 'absolute', width: '100%'}}>
                    {
                        playlist.loading &&
                        <div style={{display: 'flex', justifyContent: 'center'}}><Loading /></div>

                    }
                    {
                        view === 'LIST' &&
                            <ListView multiple={multiple} playlists={playlist.list} selectedId={selectedId} handleSelect={handleSelect} selectedIds={selectedIds}/>
                    }
                    {
                        view === 'IMAGE' &&
                            <ImageView multiple={multiple} playlists={playlist.list} selectedId={selectedId} handleSelect={handleSelect} selectedIds={selectedIds}/>
                    }
                </div>
            </div>
        </div>
    )
}