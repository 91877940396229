
const getUserId = () => {
    const user = localStorage.getItem('user');
    if (user) {
        return JSON.parse(user).id;
    }
    return null;
}

export function getPageSize(divide, defaultSize = 25) {
    return localStorage.getItem(divide+ `_pageSize_${getUserId()}`) || defaultSize;
}